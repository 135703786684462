var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"layout-all"},[_c('div',{staticClass:"title-content"},[_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_esg_page, "title"))+" "),_c('div',[_c('v-img',{staticClass:"title-icon",attrs:{"src":require(`@/${_vm.item_esg_page.img_dot}`),"contain":""}})],1)])]),_c('hr',{staticClass:"hr-border"}),_c('div',{staticClass:"esg-content"},[_c('div',{staticClass:"content-left-left"},[_c('v-img',{staticClass:"img-esg",attrs:{"src":require(`@/${_vm.item_esg_page.img_logo_CAC}`)}})],1),_c('div',{staticClass:"content-left-right"},[(
              _vm.$vuetify.breakpoint.name !== 'xs' &&
              _vm.$vuetify.breakpoint.name !== 'sm' &&
              _vm.$vuetify.breakpoint.name !== 'md'
            )?_c('p',{staticClass:"text-description-box",domProps:{"innerHTML":_vm._s(_vm.FiltersLanguage(_vm.item_esg_page, 'description'))}}):_vm._e(),(
              _vm.$vuetify.breakpoint.name === 'xs' ||
              _vm.$vuetify.breakpoint.name === 'sm' ||
              _vm.$vuetify.breakpoint.name === 'md'
            )?_c('p',{staticClass:"text-description-box",domProps:{"innerHTML":_vm._s(_vm.FiltersLanguage(_vm.item_esg_page, 'description_mobile'))}}):_vm._e()])]),_c('div',{staticClass:"gray-box"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_esg_page, "title_dimension"))+" ")])])]),_c('div',{staticClass:"bg-gradient pb-5"},[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"layout-all"},[_c('div',[_c('div',{staticClass:"bg-image-title",style:({
              backgroundImage: `url(${require(`@/${_vm.item_economy.img_background_title}`)})`,
            }),on:{"click":function($event){return _vm.$router.push({ name: 'economyNews' })}}},[_c('v-img',{staticClass:"title-text-image",attrs:{"src":require(`@/${_vm.item_economy.img_title}`),"contain":""}})],1),_c('div',{staticClass:"mx-n3"},[_c('img',{staticClass:"image_detail",attrs:{"contain":"","src":require(`@/${_vm.FiltersLanguage(
                  _vm.item_economy,
                  'image_economy'
                )}`)},on:{"click":function($event){_vm.setImageDialog(
                  _vm.FiltersLanguage(_vm.item_economy, 'image_economy')
                )}}})])]),_c('hr',{staticClass:"hr-border"}),_c('groupNews',{attrs:{"items":_vm.itemsEconomy,"loading":_vm.loadingEconomy}})],1)])]),_c('div',{staticClass:"bg-gradient pb-5"},[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"layout-all"},[_c('div',[_c('div',{staticClass:"bg-image-title",style:({
              backgroundImage: `url(${require(`@/${_vm.item_environment.img_background_title}`)})`,
            }),on:{"click":function($event){return _vm.$router.push({ name: 'environmentNews' })}}},[_c('v-img',{staticClass:"title-text-image mb-5",attrs:{"src":require(`@/${_vm.item_environment.img_title}`),"contain":""}})],1),_c('div',{staticClass:"mx-n3"},[_c('img',{staticClass:"image_detail",attrs:{"contain":"","src":require(`@/${_vm.FiltersLanguage(
                  _vm.item_environment,
                  'image_environment'
                )}`)},on:{"click":function($event){_vm.setImageDialog(
                  _vm.FiltersLanguage(_vm.item_environment, 'image_environment')
                )}}})])]),_c('hr',{staticClass:"hr-border"}),_c('groupNews',{attrs:{"items":_vm.itemsEnvironment,"loading":_vm.loadingEnvironment}})],1)])]),_c('div',{staticClass:"bg-gradient pb-8"},[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"layout-all"},[_c('div',[_c('div',{staticClass:"bg-image-title",style:({
              backgroundImage: `url(${require(`@/${_vm.item_society.img_background_title}`)})`,
            }),on:{"click":function($event){return _vm.$router.push({ name: 'societyNews' })}}},[_c('v-img',{staticClass:"title-text-image",attrs:{"src":require(`@/${_vm.item_society.img_title}`),"contain":""}})],1),_c('div',{staticClass:"mx-n3"},[_c('img',{staticClass:"image_detail",attrs:{"contain":"","src":require(`@/${_vm.FiltersLanguage(
                  _vm.item_society,
                  'image_society'
                )}`)},on:{"click":function($event){_vm.setImageDialog(
                  _vm.FiltersLanguage(_vm.item_society, 'image_society')
                )}}})])]),_c('hr',{staticClass:"hr-border"}),_c('groupNews',{attrs:{"items":_vm.itemsSociety,"loading":_vm.loadingSociety}})],1)])]),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.dialogImg),callback:function ($$v) {_vm.dialogImg=$$v},expression:"dialogImg"}},[_c('div',{staticClass:"py-6 px-5 bg-dialog-structure"},[_c('div',{staticClass:"size-img-structure"},[(_vm.imgDialog)?_c('img',{staticClass:"img-on-dialog",attrs:{"src":require(`@/${_vm.imgDialog}`)}}):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-n5",staticStyle:{"width":"100%"}},[_c('v-btn',{staticClass:"close-btn",on:{"click":function($event){_vm.dialogImg = false}}},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_economy, "btn_close"))+" ×")])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
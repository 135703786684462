<template>
  <div>
    <div class="main-content">
      <!-- title -->
      <div class="layout-all">
        <div class="title-content">
          <div class="d-flex justify-center">
            {{ FiltersLanguage(item_management_team_page, "title") }}
            <div>
              <v-img
                class="title-icon"
                :src="require(`@/${item_management_team_page.img_dot}`)"
              ></v-img>
            </div>
          </div>
        </div>
        <div>
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs, value }">
                <div class="layout-btn-list pointer" v-on="on">
                  <div class="title-btn-list">
                    {{ FiltersLanguage(titleActive, "title") }}
                    <hr class="line-color" />
                  </div>

                  <v-btn dark class="icon-btn-list" v-bind="attrs" icon>
                    <v-icon large>{{
                      value === false ? "mdi-chevron-down" : "mdi-chevron-up"
                    }}</v-icon>
                  </v-btn>
                </div>
              </template>
              <v-list class="layout-list-all">
                <v-list-item
                  link
                  v-for="(
                    item_management_team, index
                  ) in item_management_team_page.list_team_all"
                  :key="index"
                >
                  <v-list-item-title
                    class="text-title-list"
                    @click="
                      showTitleList(
                        item_management_team.tag,
                        item_management_team
                      )
                    "
                  >
                    <v-icon class="text-title-list">mdi-chevron-right</v-icon
                    >{{
                      FiltersLanguage(item_management_team, "title")
                    }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <cpn-loading :show="loadingUserTeam" />
        <!--  show list team  -->
        <div>
          <div class="layout-team-all">
            <div
              v-for="(items_list, i) in itemsTeam"
              :key="`${i}-items_list`"
              @click="
                setItemsDialog(
                  items_list,
                  tag === 'BoardOfDirectors' ? true : false
                )
              "
              class="items-team slide-in-fast"
              :style="`animation-delay: ${
                i === 0 ? 0.3 : i / 2.5
              }s; animation-duration:0.5s;`"
            >
              <v-img
                :src="`${env}/api/v1/file/${items_list.profile.image}`"
                class="size-img-team"
              ></v-img>
              <div class="title-items-list">
                {{ FiltersLanguage(items_list.profile, "title") }}
                {{ FiltersLanguage(items_list.profile, "firstname") }}
                {{ FiltersLanguage(items_list.profile, "lastname") }}
              </div>
              <div>
                <span
                  class="positions-style"
                  v-for="(items_positions, i) in items_list.positions"
                  :key="`${i}-items_position`"
                >
                  {{ FiltersLanguage(items_positions, "title") }}
                  <span v-if="i + 1 !== items_list.positions.length">/</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- section2 Management team -->
    <div class="layout-section2">
      <div class="main-content">
        <div class="space-section2">
          <div class="title-section2">
            <div class="d-flex justify-center">
              {{ FiltersLanguage(item_management_team_page, "title_section2") }}
              <div>
                <v-img
                  class="title-icon"
                  :src="require(`@/${item_management_team_page.img_dot}`)"
                ></v-img>
              </div>
            </div>
          </div>
          <hr class="hr-border" />

          <!--  show list team  -->
          <div>
            <cpn-loading :show="loadingManagement" />
            <div class="layout-team-all">
              <div
                v-for="(items_list, i) in itemsManagement"
                :key="`${i}-items_list`"
                @click="setItemsDialog(items_list, false)"
                class="items-team slide-in-fast"
                :style="`animation-duration: ${i === 0 ? 0.3 : i / 2}s;`"
              >
                <v-img
                  :src="`${env}/api/v1/file/${items_list.profile.image}`"
                  class="size-img-team"
                ></v-img>
                <div class="title-items-list">
                  {{ FiltersLanguage(items_list.profile, "title") }}
                  {{ FiltersLanguage(items_list.profile, "firstname") }}
                  {{ FiltersLanguage(items_list.profile, "lastname") }}
                </div>
                <div>
                  <span
                    class="positions-style"
                    v-for="(items_positions, i) in items_list.positions"
                    :key="`${i}-items_position`"
                  >
                    {{ FiltersLanguage(items_positions, "title") }}
                    <span v-if="i + 1 !== items_list.positions.length">/</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- dialog item team -->
    <v-dialog v-model="dialogItemTeam" fullscreen>
      <div
        v-if="dialogItemTeam"
        class="py-6 px-5 bg-dialog-structure fade-in"
        v-on:click.self="dialogItemTeam = false"
      >
        <div class="layout-dialog" v-on:click.self="dialogItemTeam = false">
          <div class="background-item-dialog">
            <div class="layout-img-dialog">
              <img
                v-if="itemsDialog.profile.image"
                class="img-on-dialog"
                :src="`${env}/api/v1/file/${itemsDialog.profile.image}`"
              />
            </div>
            <div class="layout-detail-profile">
              <div class="title-items-dialog">
                {{ FiltersLanguage(itemsDialog.profile, "title") }}
                {{ FiltersLanguage(itemsDialog.profile, "firstname") }}
                {{ FiltersLanguage(itemsDialog.profile, "lastname") }}
              </div>
              <div class="detail-items-dialog">
                <div>
                  {{ FiltersLanguage(item_management_team_page, "title_age") }}
                  : {{ itemsDialog.profile.age }}
                  {{ FiltersLanguage(item_management_team_page, "unit_age") }}
                </div>
                <div>
                  {{
                    FiltersLanguage(item_management_team_page, "title_position")
                  }}
                  :
                </div>
                <div
                  v-for="(items_positions, i) in itemsDialog.positions"
                  :key="`${i}-items_position`"
                >
                  <ul class="set-ul">
                    <li>{{ FiltersLanguage(items_positions, "title") }}</li>
                  </ul>
                </div>
                <div v-if="statusShowDatePositions">
                  {{
                    FiltersLanguage(
                      item_management_team_page,
                      "title_start_position"
                    )
                  }}
                  :
                  {{
                    itemsDialog.positions.length == 0 ||
                    itemsDialog.positions[0].start_date == ""
                      ? "-"
                      : returnDateFull(
                          itemsDialog.positions[0].start_date,
                          language
                        )
                  }}
                </div>
                <div>
                  {{
                    FiltersLanguage(
                      item_management_team_page,
                      "title_educational"
                    )
                  }}
                  :
                </div>
                <div>
                  <ul class="set-ul">
                    <li
                      v-for="(items_educationals, i) in itemsDialog.profile
                        .educationals"
                      :key="`${i}-items_educationals`"
                    >
                      {{ FiltersLanguage(items_educationals, "title") }}
                    </li>
                  </ul>
                </div>
                <div>
                  {{
                    FiltersLanguage(item_management_team_page, "title_volume")
                  }}
                  :
                  {{
                    itemsDialog.stock.volume == 0
                      ? FiltersLanguage(
                          item_management_team_page,
                          "title_status_no"
                        )
                      : `${returncommaNumber(
                          itemsDialog.stock.volume
                        )} ${FiltersLanguage(
                          item_management_team_page,
                          "unit_volume"
                        )} `
                  }}
                </div>
                <div>
                  {{
                    FiltersLanguage(item_management_team_page, "title_ratio")
                  }}
                  :
                  {{
                    itemsDialog.stock.ratio == 0
                      ? FiltersLanguage(
                          item_management_team_page,
                          "title_status_no"
                        )
                      : `${itemsDialog.stock.ratio}%`
                  }}
                </div>
                <diV>
                  {{
                    FiltersLanguage(
                      item_management_team_page,
                      "title_relationship"
                    )
                  }}
                  :
                  {{
                    itemsDialog.profile.relationship.executive
                      ? FiltersLanguage(
                          item_management_team_page,
                          "title_status_yes"
                        )
                      : FiltersLanguage(
                          item_management_team_page,
                          "title_status_no"
                        )
                  }}
                </diV>
              </div>
            </div>
          </div>
          <div style="width: 100%" class="d-flex justify-center mt-n5">
            <v-btn @click="dialogItemTeam = false" class="close-btn mb-5">
              {{ FiltersLanguage(item_management_team_page, "btn_close") }}
              &times;</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      statusShowDatePositions: false,
      loadingUserTeam: false,
      loadingManagement: false,
      tag: 'BoardOfDirectors',
      itemsDialog: {
        profile_id: '',
        img_profile: '',
        profile: {
          title_th: '',
          title_eng: '',
          firstname_th: '',
          firstname_eng: '',
          lastname_th: '',
          lastname_eng: '',
          age: '',
          educationals: [
            {
              education_id: '',
              title_eng: '',
              title_th: '',
              description_eng: '',
              description_th: '',
              create_date: '',
              update_date: ''
            }
          ],
          relationship: {
            executive: false
          }
        },
        positions: [
          {
            position_id: '',
            start_date: '',
            title_eng: '',
            title_th: '',
            description_eng: '',
            description_th: '',
            create_date: '',
            update_date: '',
            position_tags: []
          },
          {
            position_id: '',
            start_date: '',
            title_eng: '',
            title_th: '',
            description_eng: '',
            description_th: '',
            create_date: '',
            update_date: '',
            position_tags: []
          }
        ],
        stock: {
          volume: 0,
          ratio: 0
        },
        group_tags: []
      },
      dialogItemTeam: false,
      titleActive: {
        title_th: 'คณะกรรมการบริษัท',
        title_eng: 'Board of Directors',
        tag: 'BoardOfDirectors'
      },
      itemsTeam: [],
      env: '',
      itemsManagement: []
    }
  },
  async created () {
    this.env = process.env.VUE_APP_API
    this.intUserTeam()
    this.intUserManagement()
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  methods: {
    setItemsDialog (path, ShowDatePositions = true) {
      this.statusShowDatePositions = ShowDatePositions
      this.itemsDialog = path
      this.dialogItemTeam = true
    },
    showTitleList (tag, item) {
      this.titleActive = item
      this.tag = tag
      this.intUserTeam()
    },
    async intUserTeam () {
      this.loadingUserTeam = true
      const payload = {
        page: 1,
        row: 100,
        keyword: '',
        tags: this.tag
      }
      this.itemsTeam = []
      const res = await this.getUserTeam(payload)
      this.loadingUserTeam = false
      if (res.status) {
        this.itemsTeam = res.data.data
      } else {
        this.antmessage.error(res.message)
      }
    },
    async intUserManagement () {
      this.loadingManagement = true
      const payload = {
        page: 1,
        row: 100,
        keyword: '',
        tags: 'ManagementTeam'
      }
      this.itemsManagement = []
      const res = await this.getUserTeam(payload)
      this.loadingManagement = false
      if (res.status) {
        this.itemsManagement = res.data.data
      } else {
        this.antmessage.error(res.message)
      }
    }
  },
  computed: {
    ...mapGetters(['item_management_team_page', 'language', 'valScroll'])
  }
}
</script>

<style scoped>
>>> .v-dialog {
  box-shadow: unset !important;
}
.layout-all {
  margin-right: 20px;
  margin-left: 20px;
}
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
  margin-bottom: 20px;
}
.title-icon {
  width: 16px;
  height: 16px;
  padding: 3px;
}
/* menu list  */
.layout-btn-list {
  display: flex;
  width: 100%;
}

.icon-btn-list {
  margin-top: 12px;
  background: linear-gradient(
    180deg,
    var(--v-gradientBtnList1-base) 0%,
    var(--v-gradientBtnList2-base) 100%
  );
}
.pointer {
  cursor: pointer;
}
.title-btn-list {
  width: 99%;
  font-size: 22px;
  color: var(--v-linerPrimary-base) !important;
  text-align: left;
}
.line-color {
  color: var(--v-linerPrimary-base) !important;
}
.text-title-list {
  font-size: 22px;
  color: var(--v-textMenuList-base) !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.text-title-list:hover {
  font-size: 22px;
  color: var(--v-hoverListText-base) !important;
  background-color: var(--v-hoverList-base) !important;
  border-radius: 15px;
}
.layout-list-all {
  background: rgba(57, 57, 57, 0.7) !important;
  border-radius: 0px 0px 20px 20px !important;
}
.v-menu__content {
  box-shadow: none !important;
}
/* show list team  */
.layout-team-all {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.items-team {
  width: 25%;
  margin-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.size-img-team {
  width: 100%;
  cursor: pointer;
}
.title-items-list {
  font-size: 22px;
  font-weight: 700;
  color: var(--v-textPrimary-base) !important;
  margin-top: 20px;
}
.positions-style {
  font-size: 14px;
  font-weight: 500;
  color: var(--v-textPrimary-base) !important;
}
/* dialg */
.bg-dialog-structure {
  background-color: rgba(0, 0, 0, 0.68);
  height: 100%;
  overflow-y: auto;
}
.layout-dialog {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.background-item-dialog {
  display: flex;
  background-color: var(--v-backgroundItemDialog-base);
  width: 60%;
  padding-top: 70px;
  padding-bottom: 70px;
}
.layout-img-dialog {
  width: 45%;
  padding-right: 30px;
  padding-left: 30px;
}
.img-on-dialog {
  object-fit: contain;
  width: 100%;
}
.layout-detail-profile {
  width: 55%;
  padding-right: 30px;
}
.title-items-dialog {
  font-size: 32px;
  font-weight: 700;
  color: var(--v-textPrimary-base) !important;
  margin-bottom: 10px;
}
.detail-items-dialog {
  font-size: 20px;
  font-weight: 400;
}
.set-ul {
  margin: 0px;
}
.close-btn {
  cursor: pointer;
  width: 148px;
  height: 38;
  border-radius: 51px;
  background-color: var(--v-btnClose-base) !important;
  color: var(--v-bgPrimary-base) !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

/* section2 Management team */
.layout-section2 {
  background-color: var(--v-backgroundManagementTeam-base) !important;
}
.space-section2 {
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 50px;
}
.title-section2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
  margin-bottom: 20px;
}
.hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media screen and (max-width: 1600px) {
  /* dialg */
  .background-item-dialog {
    width: 80%;
  }
}
@media screen and (max-width: 1400px) {
  /* dialg */
  .background-item-dialog {
    width: 90%;
  }
}
@media screen and (max-width: 990px) {
  .items-team {
    width: 33%;
  }
  /* dialog */
  .background-item-dialog {
    flex-wrap: wrap;
    width: 98%;
    justify-content: center;
  }
  .layout-dialog {
    align-content: unset;
  }
  .layout-img-dialog {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  .img-on-dialog {
    width: 50% !important;
  }
  .layout-detail-profile {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 700px) {
  .img-on-dialog {
    width: 100% !important;
  }
}
@media screen and (max-width: 660px) {
  .items-team {
    width: 50%;
  }

  /* dialog */
}
@media screen and (max-width: 460px) {
  .items-team {
    width: 100%;
  }
}
</style>

<template>
    <div style="width: 100%">
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="textFieldDate"
            append-icon="mdi-calendar-range"
            outlined
            v-bind="attrs"
            v-on="on"
            :color="colorDatePicker"
            :loading="loading"
            :disabled="loading||disabled"
            class="custom-font"
            dense
            :rules="[Rule.noInPut, Rule.notNull]"
            :placeholder="placeholder"
          >
            <template v-slot:append>
              <v-icon :color="colorDatePicker">mdi-calendar-range</v-icon>
            </template>
          </v-text-field>
        </template>
        <!-- locale="th" -->
        <v-date-picker
          v-model="date"
          :color="colorDatePicker"
          no-title
          :range="range"
          scrollable
          :max="max"
          :min="min"
          :header-date-format="headerDateFormat"
          :month-format="monthFormat"
          :year-format="yearFormat"
          :type="type"
        >
          <v-spacer></v-spacer>
          <v-btn
            dark
            :color="colorDatePicker"
            @click="$refs.dialog.save(date), setDateFilter()"
            class="custom-font"
          >
            ตกลง
          </v-btn>
          <v-btn
            dark
            color="red"
            @click="modal = false"
            class="custom-font"
          >
            ยกเลิก
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
  </template>

<script>
export default {
  props: {
    value: {
      // type: Array,
      // default: () => []
    },
    type: {
      type: String,
      default: 'date'
    },
    max: {
      type: String,
      default: ''
    },
    min: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    range: {
      type: Boolean,
      default: false
    },
    colorDatePicker: {
      type: String,
      default: 'green'
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  watch: {
    value (value) {
      this.setDateFilter('created')
    }
  },
  // ใช้ดักตอนค่า value เปลี่ยน
  computed: {
    date: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      modal: false,
      textFieldDate: '',
      months: [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม'
      ]
    }
  },
  created () {
    this.setDateFilter('created')
  },
  methods: {
    headerDateFormat (isoDate) {
      const t = this.$moment(isoDate)
      return this.months[t.month()] + ' ' + (t.year() + 543)
    },
    monthFormat (isoDate) {
      const t = this.$moment(isoDate)
      return this.months[t.month()]
    },
    yearFormat (isoDate) {
      const t = this.$moment(isoDate)
      return t.year() + 543
    },
    setDateFilter (from = '') {
      if (this.type === 'month') {
        if (this.range) {
          if (this.date.length === 1) {
            this.textFieldDate = this.$moment(this.date[0])
              .add(543, 'year')
              .format('MM/YYYY')
          } else if (this.date.length > 1) {
            const date = this.date.sort()
            if (this.date[0] !== this.date[1]) {
              this.textFieldDate =
                  this.$moment(date[0]).add(543, 'year').format('MM/YYYY') +
                  ' - ' +
                  this.$moment(date[1]).add(543, 'year').format('MM/YYYY')
            } else {
              this.textFieldDate = this.$moment(date[0])
                .add(543, 'year')
                .format('MM/YYYY')
            }
          } else {
            this.textFieldDate = ''
          }
          if (from !== 'created') {
            this.$emit('change', this.date.sort())
          }
        } else {
          if (this.date !== '') {
            this.textFieldDate = this.$moment(this.date)
              .add(543, 'year')
              .format('MM/YYYY')
          } else {
            this.textFieldDate = ''
          }
          if (from !== 'created') {
            this.$emit('change', this.date)
          }
        }
      } else if (this.type === 'date') {
        if (this.range) {
          if (this.date.length === 1) {
            this.textFieldDate = this.$moment(this.date[0])
              .add(543, 'year')
              .format('DD/MM/YYYY')
          } else if (this.date.length > 1) {
            const date = this.date.sort()
            if (this.date[0] !== this.date[1]) {
              this.textFieldDate =
                  this.$moment(date[0]).add(543, 'year').format('DD/MM/YYYY') +
                  ' - ' +
                  this.$moment(date[1]).add(543, 'year').format('DD/MM/YYYY')
            } else {
              this.textFieldDate = this.$moment(date[0])
                .add(543, 'year')
                .format('DD/MM/YYYY')
            }
          } else {
            this.textFieldDate = ''
          }
          if (from !== 'created') {
            this.$emit('change', this.date.sort())
          }
        } else {
          if (this.date !== '') {
            const myArray = this.date.split('-')
            myArray[0] = parseInt(myArray[0]) + 543
            let newYear = ''
            for (let index = myArray.length - 1; index >= 0; index--) {
              newYear = `${newYear}${index === myArray.length - 1 ? myArray[index] : ('/' + myArray[index])}`
            }
            this.textFieldDate = newYear
          } else {
            this.textFieldDate = ''
          }
          if (from !== 'created') {
            this.$emit('change', this.date)
          }
        }
      }
    }
  }
}
</script>

  <style lang="css" scoped>
  >>> .v-text-field fieldset,
  .v-text-field .v-input__control,
  .v-text-field .v-input__slot {
    /* border-radius: 8px; */
  }
  >>> .custom-font .v-input__control .v-input__slot .v-text-field__slot input {
    font-size: 17px !important;
  }
  >>> .custom-font .v-btn__content {
    font-size: 17px !important;
    font-weight: 500;
  }
  </style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="1000px"
    transition="dialog-top-transition"
    persistent
    fullscreen
  >
    <v-card class="layout-card">
      <div class="d-flex pt-5 px-5">
        <div>
          <v-btn
            color="#4F9F8C"
            text
            @click="languageShow = 'th'"
            :disabled="languageShow === 'th'"
            ><span style="font-weight: 800">TH</span></v-btn
          >
          <v-btn
            color="#4F9F8C"
            text
            @click="languageShow = 'eng'"
            :disabled="languageShow === 'eng'"
            ><span style="font-weight: 800">ENG</span></v-btn
          >
        </div>
        <div class="ml-auto">
          <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
        </div>
      </div>
      <div class="main-content">
        <div class="title-news">
          {{ FiltersLanguageShow(items, "title") }}
        </div>
        <div class="date-news">
          <span class="mr-5">
            {{ returnFormateDate(items.create_date, languageShow) }}
          </span>
        </div>
        <div class="img-baner">
          <v-img
            v-if="
              items.banner !== '' &&
              items.banner !== undefined &&
              items.banner !== null
            "
            :src="`${env}/api/v1/file/${items.banner}`"
          ></v-img>
        </div>
        <div class="output ql-snow">
          <div
            class="ql-editor base-style"
            v-html="FiltersLanguageShow(items, 'description')"
          ></div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      languageShow: 'th',
      env: ''
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    items: {
      type: Object,
      default: () => {
        return {
          banner: '',
          title_th: '',
          title_eng: '',
          description_th: '',
          description_eng: '',
          tags: [],
          status: false
        }
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {},
  async created () {
    this.env = process.env.VUE_APP_API
  },
  methods: {
    FiltersLanguageShow (data, key) {
      return data[`${key}_${this.languageShow}`]
    },
    close () {
      this.dialog = false
      this.$emit('close')
      this.languageShow = 'th'
    }
  }
}
</script>

<style scoped lang="css">
.layout-card {
  padding: 30px 20px 30px 20px;
}
.img-baner {
  padding: 34px 15px 44px 15px;
}
.text-no-data {
  text-align: center;
  font-size: 22px;
  color: var(--v-textComingSoon-base) !important;
}
>>> img {
  margin-bottom: 20px;
  margin-top: 20px !important;
}
.base-style {
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.main-content {
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 50px;
  padding-bottom: 80px;
}
.title-news {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
  padding: 12px 15px;
}
.date-news {
  color: var(--v-colorDate-base) !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 15px;
}
</style>

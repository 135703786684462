<template>
  <div class="footer-bg">
    <div class="footer-main">
      <div class="contact-box">
        <div class="contact-title">
          {{ FiltersLanguage(itemFoot, "title") }}
        </div>
        <!-- line -->
        <v-divider class="contact-line my-3"></v-divider>

        <div
          v-if="$vuetify.breakpoint.name !== 'xs'"
          v-html="FiltersLanguage(itemFoot, 'address')"
          class="contact-text"
        ></div>

        <div
          v-if="$vuetify.breakpoint.name === 'xs'"
          v-html="FiltersLanguage(itemFoot, 'address_mobile')"
          class="contact-text"
        ></div>
        <div class="contact-text">Tel : {{ itemFoot.phone }}</div>
        <div class="contact-text">Email : {{ itemFoot.email }}</div>
      </div>
      <div class="social-icons">
        <a href="tel:0-2257-7000" class="my-5">
          <v-icon class="social-icons-text">mdi-phone</v-icon>
        </a>

        <a href="mailto:ir@inet.co.th" class="my-5">
          <v-icon class="social-icons-text">mdi-email</v-icon>
        </a>

        <a href="https://www.facebook.com/INETClub" target="_blank" class="my-5">
          <v-icon class="social-icons-text">mdi-facebook</v-icon>
        </a>
      </div>
    </div>
    <div class="copy-right d-flex justify-center align-center">
      © 2023 INTERNET THAILAND PLC. | ALL RIGHTS RESERVED.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['itemFoot'])
  }
}
</script>

<style scoped>
.footer-bg {
  background-color: var(--v-bgFooter-base);
  padding-top: 70px;
  padding-bottom: 30px;
  padding-left: 60px;
  padding-right: 60px;
}
.footer-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 70px;
}
.contact-box {
  width: 400px;
}
.contact-title {
  font-family: "Noto Sans Thai";
  font-size: 16px;
  font-weight: 600;
  color: var(--v-textFooter-base) !important;
}
.contact-line {
  border: 1px solid var(--v-contactLine-base);
}
.contact-text {
  font-family: "Noto Sans Thai";
  font-size: 16px;
  font-weight: 300;
  color: var(--v-textFooter-base) !important;
}
.copy-right {
  height: 30px;
  border-radius: 15px;
  background: linear-gradient(
    136.16deg,
    var(--v-gradientFooter1-base) 44.53%,
    var(--v-gradientFooter2-base) 100%
  );
  font-family: "Noto Sans Thai";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.social-icons-text {
  color: var(--v-bgFooter-base) !important;
  font-size: 30px;
}
.social-icons a {
  margin-left: 30px;
  display: grid;
  place-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--v-bgPrimary-base);
}
@media screen and (max-width: 500px) {
  .footer-bg {
    padding-top: 40px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .footer-main {
    padding-bottom: 40px;
  }
}
</style>

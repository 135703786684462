<template>
  <div v-if="show" class="d-flex justify-center pa-5">
    <div style="max-width: 300px; width: 100%;">
      <div class="text-coming-soon">loading...</div>
      <v-progress-linear
        indeterminate
        rounded
        color="green"
        height="7"
      ></v-progress-linear>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  created () {},
  methods: {}
}
</script>

<style lang="css" scoped>
.text-coming-soon {
  text-align: center;
  font-size: 22px;
  color: var(--v-textComingSoon-base) !important;
}
</style>

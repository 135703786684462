<template>
  <div>
    <div class="layout-comment-search">
      <v-spacer></v-spacer>
      <div class="box-text-field">
        <div>
          <v-text-field
            v-model="search"
            :append-icon="'mdi-magnify'"
            label="ค้นหาจากปี"
            color="primaryIconAdmin"
          ></v-text-field>
        </div>
      </div>
    </div>
    <tableAdminAll
      :headers="headers"
      :items="items"
      :pagination="pagination"
      @onChangePagination="changePagination"
      :warpHeader="false"
      :search="search"
      :height="$vuetify.breakpoint.height-345"
    >
      <template v-slot:[`item.number`]="{ item }">
        <span>
          {{
            pagination.row * pagination.page -
            pagination.row +
            (items.indexOf(item) + 1)
          }}
        </span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="layout-action">
          <v-btn icon><v-icon class="btn-edit" @click="editItem(item,items.indexOf(item))">mdi-pencil</v-icon></v-btn>
          <v-btn
            icon
            :disabled="items.indexOf(item) + 1 === items.length"
            @click="changeIndexSub('down', items.indexOf(item))"
            ><v-icon class="btn-down"
              >mdi-arrow-down-circle-outline</v-icon
            ></v-btn
          >
          <v-btn
            icon
            :disabled="items.indexOf(item) === 0"
            @click="changeIndexSub('up', items.indexOf(item))"
            ><v-icon class="btn-up">mdi-arrow-up-circle-outline</v-icon></v-btn
          >
          <v-btn icon
            ><v-icon class="btn-delete" @click="deleteItem(items.indexOf(item))"
              >mdi-trash-can-outline</v-icon
            ></v-btn
          >
        </div>
      </template>
    </tableAdminAll>
  </div>
</template>

<script>
import tableAdminAll from '../../../components/adminpage/tableAll/tableAdminAll.vue'
export default {
  components: {
    tableAdminAll
  },
  props: {
    pagination: {
      type: Object,
      default: () => {
        return { page: 1, row: 10, total: 0 }
      }
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    // itemKey: {
    //   type: String,
    //   default: ''
    // },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: ''
    }
  },
  methods: {
    changePagination (val) {
      this.$emit('changePagination', val)
    },
    changeIndexSub (status, indexData) {
      this.$emit('changeIndexSub', {
        status: status,
        index: indexData
      })
    },
    deleteItem (indexData) {
      this.$emit('delete', indexData)
    },
    editItem (item, indexData) {
      this.$emit('edit', { item: item, index: indexData })
    }
  }
}
</script>

<style scoped>
>>> table .v-data-table-header th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  min-width: 100px !important;
}
>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-top: 10px;
  padding-bottom: 10px;
}
.layout-comment-search {
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.text-comment {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}
.layout-action {
  min-width: 120px;
}
.btn-edit {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-delete {
  color: var(--v-cancleBtn-base) !important;
}
.btn-down {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-up {
  color: var(--v-primaryIconAdmin-base) !important;
}
@media screen and (max-width: 500px) {
  .layout-comment-search {
    flex-wrap: wrap;
  }
  .box-text-field {
    width: 100%;
  }
}
</style>

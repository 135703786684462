<template>
  <div>
    <div class="layout-all">
      <v-icon class="icon-title mr-5">{{ iconTitle }}</v-icon>
      <div class="title-menu">{{ titleMenu }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    iconTitle: {
      type: String,
      default: ''
    },
    titleMenu: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.layout-all {
  display: flex;
  margin-bottom: 10px;
}
.icon-title {
  font-size: 36px;
  color: var(--v-primaryIconAdmin-base) !important;
}
.title-menu {
  font-size: 24px;
  font-weight: 500;
  color: var(--v-textPrimaryAdmin-base);
}
</style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="700px"
    transition="dialog-top-transition"
    persistent
  >
    <v-card>
      <div class="from-persion-card">
        <div class="header-title">
          <v-icon class="icon-header">{{ iconTitle }}</v-icon
          >{{ titleHeader }}
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn icon @click="close()"> <v-icon>mdi-close</v-icon></v-btn>
        </div>
      </div>
      <hr class="hr-header" />
      <div class="from-layout">
        <v-form ref="formPersionTeam">
          <div class="layout-two-colum">
            <div class="one-colum">
              <div class="title-text-field">เลือกบุคคล</div>
              <div class="box-text-field">
                <v-autocomplete
                  color="colorFilter"
                  item-color="primaryIconAdmin"
                  v-model="person"
                  :items="itemPerson"
                  :item-text="customShowText"
                  item-value="profile_id"
                  return-object
                  dense
                  outlined
                  :loading="loadingItemPerson"
                  placeholder="กรุณาเลือกบุคคลที่ต้องการเพิ่มเข้าหมวดหมู่"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-autocomplete>
              </div>
            </div>
          </div>
        </v-form>
        <div class="layout-btn-record-cancle">
          <div class="layout-two-btn">
            <v-btn
              elevation="0"
              class="btn-add mb-2"
              rounded
              @click="validateFrom()"
              >บันทึก</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              class="btn-cancle mb-2"
              rounded
              @click="close()"
              >ยกเลิก</v-btn
            >
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      env: '',
      NameComponent: 'เพิ่มเข้าหมวดหมู่',
      person: null
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    iconTitle: {
      type: String,
      default: ''
    },
    titleHeader: {
      type: String,
      default: ''
    },
    loadingItemPerson: {
      type: Boolean,
      default: false
    },
    itemPerson: {
      type: Array,
      default: () => []
    }
  },
  watch: {},
  async created () {},
  methods: {
    close () {
      this.dialog = false
      this.$refs.formPersionTeam.reset()
    },
    customShowText (item) {
      if (item.profile !== undefined) {
        return `${item.profile.firstname_th} ${item.profile.lastname_th}`
      }
    },
    validateFrom () {
      if (this.$refs.formPersionTeam.validate()) {
        // TODO Update Data
        this.$swal({
          allowEnterKey: false,
          title: 'เพิ่มบุคคล',
          text: 'คุณต้องการเพิ่มบุคคลนี้ใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก',
          cancelButtonColor: '#E74444',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#4CC558'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$emit('addPerson', this.person)
          }
        })
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="css">
>>> .v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__prepend-outer {
  display: none !important;
}
.v-input--selection-controls {
  margin-top: 5px;
  padding-top: 4px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
.btn-cancle {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-cancleBtn-base) !important;
  font-size: 16px;
}
.from-persion-card {
  display: flex;
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.from-layout {
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.header-title {
  color: var(--v-primaryIconAdmin-base) !important;
  font-size: 24px;
  font-weight: 600;
}
.icon-header {
  color: var(--v-primaryIconAdmin-base) !important;
  font-size: 24px;
  margin-right: 10px;
}

.hr-header {
  color: var(--v-primaryIconAdmin-base) !important;
}
.comment-img {
  color: var(--v-textPrimaryAdmin-base) !important;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}
.title-text-field {
  font-size: 16px;
}
.layout-two-colum {
  width: 100%;
  display: flex;
}
.layout-file-img {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.box-show-img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.width-img {
  /* max-height: 250px;
  max-width: 250px; */
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.bg-file-img {
  background-color: var(--v-bgFileImg-base);
  height: 250px;
  width: 250px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #adadad;
}
.file-input {
  width: 50%;
}
.two-colum {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}
.one-colum {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.layout-add-positions {
  display: flex;
  widows: 100%;
  flex-wrap: wrap;
  background-color: var(--v-bgAddsection-base);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.layout-add-educationals {
  display: flex;
  widows: 100%;
  flex-wrap: wrap;
  background-color: var(--v-bgAddsection-base);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.layout-btn-record-cancle {
  width: 100%;
  display: flex;
  justify-content: center;
}
.layout-two-btn {
  width: 200px;
  display: flex;
}
.btn-delete {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

@media screen and (max-width: 650px) {
  .from-layout {
    padding-left: 20px;
    padding-right: 20px;
  }
  .file-input {
    width: 100%;
  }
  .layout-two-colum {
    flex-wrap: wrap;
  }
  .two-colum {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .one-colum {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>

<template>
  <div>
    <titleHeaderMenu :iconTitle="iconTitle" :titleMenu="titleMenu" />
    <div class="btn-hearder">
      <div class="box-btn">
        <v-btn
          class="btn-add"
          elevation="0"
          rounded
          @click="openFormAddEditAnnounce('add')"
          ><v-icon>mdi-plus</v-icon>เพิ่มประกาศ</v-btn
        >
      </div>
      <v-spacer></v-spacer>
      <div class="box-text-field">
        <v-text-field
          v-model="search"
          :append-icon="'mdi-magnify'"
          label="ค้นหาจากหัวข้อ"
          color="primaryIconAdmin"
        ></v-text-field>
        <v-btn
          @click="searchTable()"
          class="ml-2 btn-add"
          rounded
          elevation="0"
          >ค้นหา</v-btn
        >
      </div>
    </div>
    <!-- {{ itemsTableAnnounce }} -->
    <div>
      <tableAdminAll
        :headers="headerTableAnnounce"
        :items="itemsTableAnnounce"
        :loading="loadingTableAnnounce"
        :pagination="paginationTableAnnounce"
        @onChangePagination="onChangeSummaryTableAnnounce"
        warpHeader
        :height="$vuetify.breakpoint.height-280"
      >
        <template v-slot:[`item.number`]="{ item }">
          <span>
            {{
              paginationTableAnnounce.row * paginationTableAnnounce.page -
              paginationTableAnnounce.row +
              (itemsTableAnnounce.indexOf(item) + 1)
            }}
          </span>
        </template>
        <template v-slot:[`item.title_th`]="{ item }">
          <div class="min-width">
            {{ item.title_th }}
          </div>
        </template>
        <template v-slot:[`item.title_eng`]="{ item }">
          <div class="min-width">
            {{ item.title_eng }}
          </div>
        </template>
        <template v-slot:[`item.start_date`]="{ item }">
          {{ returnFormateDate(item.start_date, "th") }}
        </template>
        <template v-slot:[`item.end_date`]="{ item }">
          {{ returnFormateDate(item.end_date, "th") }}
        </template>
        <template v-slot:[`item.create_date`]="{ item }">
          {{ returnFormateDate(item.create_date, "th") }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            :color="returnColorStatus(item.start_date, item.end_date)"
            class="status-table"
            >{{ returnTextStatus(item.start_date, item.end_date) }}</v-chip
          >
        </template>
        <template v-slot:[`item.action`]="{item}">
          <div class="layout-action">
            <v-btn icon ><v-icon class="btn-edit" @click="itemForm = JSON.parse(JSON.stringify(item)),openFormAddEditAnnounce('edit')">mdi-pencil</v-icon></v-btn>
            <v-btn icon
              ><v-icon class="btn-view" @click="openShowExAnnounce(item)">mdi-eye-outline</v-icon></v-btn
            >
            <v-btn icon @click="recheckDeleteAnnounce(item)"
              ><v-icon class="btn-delete">mdi-trash-can-outline</v-icon></v-btn
            >
          </div>
        </template>
      </tableAdminAll>
    </div>
    <formAddEditAnnounce
      ref="fromAnnounce"
      v-model="statusOpenForm"
      :dataFromAnnounce="itemForm"
      :status="statusForm"
      :iconTitle="iconTitleFrom"
      :titleHeader="titleFrom"
      @addAndEdit="addAndEditAnnounce"
      @resetForm="itemForm=defalutItemForm"
      :loading="loadingFormAnnounce"
      @showEx="openShowExAnnounce"
    />
    <show-announce v-model="statusDialog" :items="itemShowExAnnounce" @close="closeShowExAnnounce"  />
    <dialog-loading v-model="loadingFormAnnounce" />
  </div>
</template>

<script>
import titleHeaderMenu from '../../../components/adminpage/titleHeaderMenu.vue'
import tableAdminAll from '../../../components/adminpage/tableAll/tableAdminPagination.vue'
import formAddEditAnnounce from '../../../components/adminpage/formAnnounce/formAddEditAnnounce.vue'
export default {
  components: {
    titleHeaderMenu,
    tableAdminAll,
    formAddEditAnnounce
  },
  data () {
    return {
      iconTitle: 'mdi-chat-alert',
      iconTitleFrom: '',
      titleMenu: 'ประกาศ',
      titleFrom: '',
      search: '',
      loadingTableAnnounce: false,
      headerTableAnnounce: [
        { text: 'ลำดับ', align: 'center', value: 'number', sortable: false },
        {
          text: 'หัวข้อภาษาไทย',
          align: 'start',
          value: 'title_th',
          sortable: false
        },
        {
          text: 'หัวข้อภาษาอังกฤษ',
          align: 'start',
          value: 'title_eng',
          sortable: false
        },
        {
          text: 'วันที่เริ่ม',
          align: 'center',
          value: 'start_date',
          sortable: false
        },
        {
          text: 'วันที่สิ้นสุด',
          align: 'center',
          value: 'end_date',
          sortable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'center',
          value: 'create_date',
          sortable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false
        },
        {
          text: 'สถานะ',
          align: 'center',
          value: 'status',
          sortable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false
        }
      ],
      itemsTableAnnounce: [],
      paginationTableAnnounce: {
        total: 0,
        page: 1,
        row: 10
      },
      statusOpenForm: false,
      statusForm: '',
      loadingFormAnnounce: false,
      defalutItemForm: {
        start_date: '',
        end_date: '',
        title_eng: '',
        title_th: '',
        description_th: '',
        description_eng: '',
        image: '',
        url: '',
        icon: '',
        type: '',
        time_zone: 'UTC'
      },
      itemForm: {
        start_date: '',
        end_date: '',
        title_eng: '',
        title_th: '',
        description_th: '',
        description_eng: '',
        image: '',
        url: '',
        icon: '',
        type: '',
        time_zone: 'UTC'
      },

      // show ex
      statusDialog: false,
      itemShowExAnnounce: []
    }
  },
  async created () {
    this.init()
  },
  methods: {
    init () {
      this.initItemTable()
    },
    returnColorStatus (start, end) {
      const today = this.$moment().startOf('day')
      const diffStart = this.$moment(start).diff(this.$moment(today).startOf('day'), 'day')
      const diffEnd = this.$moment(end).diff(this.$moment(today).endOf('day'), 'day')
      if (diffStart > 0) {
        return 'comingStatus'
      } else if (diffStart <= 0 && diffEnd >= 0) {
        return 'showStatus'
      } else if (diffEnd < 0) {
        return 'endStatus'
      }
    },
    returnTextStatus (start, end) {
      const today = this.$moment().startOf('day')
      const diffStart = this.$moment(start).diff(this.$moment(today).startOf('day'), 'day')
      const diffEnd = this.$moment(end).diff(this.$moment(today).endOf('day'), 'day')
      if (diffStart > 0) {
        return 'กำลังจะมาถึง'
      } else if (diffStart <= 0 && diffEnd >= 0) {
        return 'กำลังแสดง'
      } else if (diffEnd < 0) {
        return 'สิ้นสุด'
      }
    },
    async initItemTable () {
      this.itemsTableAnnounce = []
      this.loadingTableAnnounce = true
      const payload = {
        keyword: this.search,
        page: this.paginationTableAnnounce.page,
        row: this.paginationTableAnnounce.row
      }
      const res = await this.getAnnouncerTable(payload)
      this.loadingTableAnnounce = false
      if (res.status) {
        this.itemsTableAnnounce = res.data.data
        this.paginationTableAnnounce.total = res.data.pagination.total
        this.paginationTableAnnounce.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    searchTable () {
      this.paginationTableAnnounce.page = 1
      this.initItemTable()
    },
    onChangeSummaryTableAnnounce (val) {
      this.paginationTableAnnounce.page = val.page
      this.paginationTableAnnounce.row = val.row
      this.initItemTable()
    },
    openFormAddEditAnnounce (status) {
      if (status === 'add') {
        this.statusForm = 'add'
        this.titleFrom = 'เพิ่มประกาศ'
        this.iconTitleFrom = 'mdi-plus'
        this.statusOpenForm = true
      } else if (status === 'edit') {
        this.statusForm = 'edit'
        this.titleFrom = 'แก้ไขประกาศ'
        this.iconTitleFrom = 'mdi-pencil'
        this.statusOpenForm = true
      }
    },
    addAndEditAnnounce (item) {
      if (item.status === 'add') {
        this.addAnnounce(item.item)
      } else if (item.status === 'edit') {
        this.editAnnounce(item.item)
      }
    },
    async addAnnounce (item) {
      this.loadingFormAnnounce = true
      const res = await this.createAnnounce(item)
      this.loadingFormAnnounce = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มประกาศสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.$refs.fromAnnounce.close()
        this.initItemTable()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async editAnnounce (item) {
      this.loadingFormAnnounce = true
      const res = await this.updateAnnounce(item)
      this.loadingFormAnnounce = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขประกาศสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.$refs.fromAnnounce.close()
        this.initItemTable()
      } else {
        this.antmessage.error(res.message)
      }
    },
    openShowExAnnounce (item) {
      this.statusDialog = true
      this.itemShowExAnnounce = [item]
    },
    closeShowExAnnounce () {
      this.statusDialog = false
      setTimeout(() => {
        this.itemShowExAnnounce = []
      }, 400)
    },
    recheckDeleteAnnounce (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบประกาศ',
        text: 'คุณต้องการลบประกาศใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.deleteAnnounceItem(item)
        }
      })
    },
    async deleteAnnounceItem (item) {
      this.loadingFormAnnounce = true
      const res = await this.deleteAnnounce(item)
      this.loadingFormAnnounce = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบประกาศสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.paginationTableAnnounce.page = 1
        this.initItemTable()
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
>>> table .v-data-table-header th span {
  white-space: nowrap !important;
}
.btn-hearder {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
.layout-action {
  min-width: 120px;
}
.btn-edit {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-view {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-delete {
  color: var(--v-cancleBtn-base) !important;
}
.status-table {
  font-size: 18px;
  font-weight: 400;
  color: var(--v-textLoginbtn-base);
}
.min-width {
  min-width: 150px;
}
.box-text-field {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .btn-hearder {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .box-text-field {
    margin-top: 10px;
    width: 100%;
  }
  .box-btn {
    width: 100%;
  }
}
</style>

<template>
  <div class="img-bg-login">
    <div class="layout-all">
      <div class="layout-box-all">
        <div class="box-left">
          <div class="img-logo">
            <v-img
              :src="
                require(`@/assets/images/loginAdmin/homeInvestorRelations_text.png`)
              "
            ></v-img>
          </div>
        </div>
        <div class="box-right">
          <div class="layout-login">
            <div class="title-login">เข้าสู่ระบบ</div>
            <div class="sub-title">เข้าสู่ระบบด้วยบัญชีผู้ใช้งานของคุณ</div>
            <v-form ref="formSignIn">
              <div>
                <div class="title-text-field">ชื่อผู้ใช้งาน</div>
                <div class="box-text-field">
                  <v-text-field
                    v-model="signInData.username"
                    :rules="[
                      Rule.noInPut,
                      Rule.noSpace,
                      Rule.englishOnly,
                      Rule.emailOnly,
                    ]"
                    label="กรอกชื่อผู้ใช้งาน(E-mail)"
                    rounded
                    outlined
                    dense
                    color="primaryIconAdmin"
                    @keyup.enter="validateLogin()"
                  ></v-text-field>
                </div>
              </div>
              <div>
                <div class="title-text-field">รหัสผ่าน</div>
                <div class="box-text-field">
                  <v-text-field
                    v-model="signInData.password"
                    :rules="[Rule.noInPut, Rule.noSpace]"
                    :append-icon="signInData.show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="signInData.show ? 'text' : 'password'"
                    @click:append="signInData.show = !signInData.show"
                    label="กรุณากรอกรหัสผ่าน"
                    rounded
                    outlined
                    dense
                    color="primaryIconAdmin"
                    @keyup.enter="validateLogin()"
                  ></v-text-field>
                </div>
              </div>
              <!-- <div class="layout-forget-btn">
                <v-btn
                  class="text-forget-btn"
                  text
                  rounded
                  @click="dialogForget = true"
                  >ลืมรหัสผ่าน ?</v-btn
                >
              </div> -->
              <div class="layout-login-btn">
                <v-btn
                  class="text-login-btn"
                  @click.prevent="validateLogin"
                  type="submit"
                  color="primaryIconAdmin"
                  :loading="loading"
                  block
                  rounded
                  elevation="0"
                  >เข้าสู่ระบบ</v-btn
                >
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
    <DialogForgetPassword
      ref="resetpassword"
      v-model="dialogForget"
      @emailUser="resetPassword"
    />
  </div>
</template>

<script>
import DialogForgetPassword from '../../components/adminpage/formLogin/dialogForgetPassword.vue'
export default {
  components: {
    DialogForgetPassword
  },
  data () {
    return {
      signInData: {
        username: '',
        password: '',
        show: false
      },
      loading: false,
      dialogForget: false
    }
  },
  async created () {},
  methods: {
    async validateLogin () {
      if (this.$refs.formSignIn.validate()) {
        this.loading = true
        const payload = {
          username: this.signInData.username,
          password: this.signInData.password
        }
        const res = await this.loginAdminPage(payload)
        this.loading = false
        if (res.code === 200) {
          if (res.status === true) {
            localStorage.setItem('Accesstoken', res.data.accesstoken)
            this.$router.push({ name: 'adminPage' }).catch(() => {})
          } else {
            this.antmessage.error(res.message)
          }
        } else {
          this.antmessage.error(res.message)
        }
      }
    },
    async resetPassword (val) {
      const payload = {
        email: val
      }
      const res = await this.sendEmailresetPasswordUser(payload)
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'กรุณาตรวจเช็คอีเมลเพื่อเปลี่ยนรหัสผ่านในอีเมลของท่าน',
          icon: 'success',
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000
        })
        this.dialogForget = false
        this.$refs.resetpassword.reset()
      } else {
        if (res.message === 'email is not exists') {
          this.$swal({
            allowEnterKey: false,
            title: 'ไม่สำเร็จ',
            text: 'ข้อมูลไม่ถูกต้องกรุณาเช็คอีเมล',
            icon: 'error',
            showConfirmButton: false,
            timer: 3000
          })
        } else {
          this.antmessage.error(res.message)
        }
      }
    }
  }
}
</script>

<style scoped>
.img-bg-login {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: url("@/assets/images/loginAdmin/bgLoginAdmin.png") no-repeat
    center center;
  background-size: cover;
}
.layout-all {
  display: flex;
  align-items: center;
  width: 100%;
}
.layout-box-all {
  display: flex;
  width: 100%;
  padding: 30px;
  justify-content: center;
}
.box-left {
  display: flex;
  width: 580px;
  height: 590px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-right: 20px;
  background: url("@/assets/images/loginAdmin/imgBgLogo.jpg") no-repeat center
    center;
  background-size: cover;
  justify-content: center;
}
.img-logo {
  border-radius: 10px;
}
.box-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 580px;
  height: 590px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: var(--v-bgLogin-base);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 100px;
  padding-left: 100px;
}
.img-logo {
  width: 65%;
  margin-top: 170px;
}
.layout-login {
  width: 100%;
}
.title-login {
  width: 100%;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.sub-title {
  width: 100%;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}
.title-text-field {
  font-size: 18px;
}
.layout-forget-btn {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}
.text-forget-btn {
  font-size: 18px;
}
.layout-login-btn {
  width: 100%;
}
.text-login-btn {
  font-size: 20px;
  color: var(--v-textLoginbtn-base) !important;
}
.text-error {
  color: red;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: -10px;
  font-size: 13px;
}
@media screen and (max-width: 1150px) {
  .layout-box-all {
    flex-wrap: wrap;
  }
  .box-left {
    width: 48%;
  }
  .box-right {
    width: 48%;
  }
  .box-right {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media screen and (max-width: 950px) {
  .box-right {
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media screen and (max-width: 800px) {
  .box-left {
    width: 100%;
    margin-right: 0px;
    height: auto;
  }
  .box-right {
    width: 100%;
    padding-right: 70px;
    padding-left: 70px;
    padding-top: 60px;
    padding-bottom: 70px;
    margin-top: 20px;
    height: auto;
  }
  .img-logo {
    margin-bottom: 170px;
  }
  .img-bg-login {
    height: auto;
  }
}
@media screen and (max-width: 500px) {
  .box-right {
    padding-right: 30px;
    padding-left: 30px;
  }
}
</style>

<template>
  <div>
    <titleHeaderMenu :iconTitle="iconTitle" :titleMenu="titleMenu" />
    <div class="btn-hearder">
      <div class="layout-btn-select">
        <div class="layout-box-category">
          <div class="box-category">เลือกตารางที่ต้องการจัดการ</div>
          <v-select
            class="width-select"
            v-model="category"
            :items="itemsCategory"
            item-text="text"
            item-value="value"
            color="primaryIconAdmin"
            item-color="primaryIconAdmin"
            @change="initItemsTable()"
            dense
          ></v-select>
        </div>
        <div class="box-btn">
          <v-btn class="btn-add" elevation="0" rounded @click="addItemsTable"
            ><v-icon>mdi-plus</v-icon>เพิ่มข้อมูล</v-btn
          >
        </div>
      </div>
    </div>
    <!-- บัญชีทางการเงินที่สำคัญ -->
    <tableFinancialHighlights
      v-if="category === 'Financial Highlights'"
      :headers="headerTableFinancialHighlights"
      :items="itemsTableFinancialHighlights"
      :loading="loadingTableFinancialHighlights"
      :pagination="paginationTableFinancialHighlights"
      @onChangePagination="onChangeTableFinancialHighlights"
      @delete="recheckDeleteFinancialHighlights"
      @changeIndexSub="recheckChangeIndexFinancialHighlights"
      @edit="editItemsTable"
      class="mb-10"
    />
    <!-- อัตราส่วนทางการเงินที่สำคัญ -->
    <tableFinancialRatio
      v-if="category === 'Financial Ratio'"
      :headers="headerTableFinancialRatios"
      :items="itemsTableFinancialRatio"
      :loading="loadingTableFinancialRatios"
      :pagination="paginationTableFinancialRatios"
      @onChangePagination="onChangeTableFinancialRatios"
      @delete="recheckDeleteFinancialRatios"
      @changeIndexSub="recheckChangeIndexFinancialRatios"
      @edit="editItemsTable"
      class="mb-10"
    />
    <!-- รายชื่อผู้ถือหลักทรัพย์ตั้งแต่ 0.5% ของบริษัท -->
    <div v-if="category === 'The first top 10 shareholders'" class="box-edit">
      <div class="ma-1 d-flex box-edit-description">
        <v-text-field
          v-model="description_thTop10Shareholders"
          outlined
          dense
          hide-details
          :disabled="!statusEditTop10Shareholders"
          :loading="loadingEditTop10Shareholders"
          color="green"
        >
        </v-text-field>
      </div>
      <div class="ma-1 box-edit-description">
        <v-text-field
          v-model="description_engTop10Shareholders"
          outlined
          dense
          hide-details
          :disabled="!statusEditTop10Shareholders"
          :loading="loadingEditTop10Shareholders"
          color="green"
        ></v-text-field>
      </div>
      <div>
        <v-btn
        class="ma-1"
          v-if="statusEditTop10Shareholders === false"
          dark
          color="green"
          @click="statusEditTop10Shareholders = true"
          :loading="loadingEditTop10Shareholders"
          >แก้ไข</v-btn
        >
        <v-btn
          class="ma-1"
          v-if="statusEditTop10Shareholders === true"
          dark
          color="green"
          :loading="loadingEditTop10Shareholders"
          @click="editDataTheFirstTop10ShareholdersDescription"
          >บันทึก</v-btn
        >
        <v-btn
          class="ma-1"
          v-if="statusEditTop10Shareholders === true"
          dark
          color="red"
          :loading="loadingEditTop10Shareholders"
          @click="
            (description_thTop10Shareholders =
              itemsTableTheFirstTop10ShareholdersAll.description_th),
              (description_engTop10Shareholders =
                itemsTableTheFirstTop10ShareholdersAll.description_eng),
              (statusEditTop10Shareholders = false)
          "
          >ยกเลิก</v-btn
        >
      </div>
    </div>
    <tableTheFirstTop10Shareholders
      v-if="category === 'The first top 10 shareholders'"
      :headers="headerTableTheFirstTop10Shareholders"
      :items="itemsTableTheFirstTop10Shareholders"
      :loading="loadingTableTheFirstTop10Shareholders"
      :pagination="paginationTableTheFirstTop10Shareholders"
      @onChangePagination="onChangeTableTheFirstTop10Shareholders"
      @delete="recheckDeleteTheFirstTop10Shareholders"
      @changeIndexSub="recheckChangeIndexTheFirstTop10Shareholders"
      @edit="editItemsTable"
      class="mb-10"
    />
    <!-- ข้อมูลการจ่ายเงินปันผลของบริษัท -->
    <!-- {{ itemsTableTheCompanyDividendPaymentInfo }} -->
    <tableTheCompanyDividendPaymentInfo
      v-if="category === 'The Company’s Dividend Payment Info'"
      :headers="headerTableTheCompanyDividendPaymentInfo"
      :items="itemsTableTheCompanyDividendPaymentInfo"
      :loading="loadingTableTheCompanyDividendPaymentInfo"
      :pagination="paginationTableTheCompanyDividendPaymentInfo"
      @onChangePagination="onChangeTableTheCompanyDividendPaymentInfo"
      @delete="recheckDeleteTheCompanyDividendPaymentInfo"
      @changeIndexSub="recheckChangeIndexTheCompanyDividendPaymentInfo"
      @edit="editItemsTable"
      class="mb-10"
    />
    <formFinancialHighlights
      ref="formFinancialHighlights"
      v-model="statusDialogFormFinancialHighlights"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :status="statusForm"
      :loading="loadingForm"
      :dataForm="dataFinancialHighlights"
      :itemsYear="itemsYearSelect"
      @resetForm="resetFormFinancialHighlights"
      @addAndEdit="addAndEditFinancialHighlights"
    />

    <formFinancialRatios
      ref="formFinancialRatios"
      v-model="statusDialogFormFinancialRatios"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :status="statusForm"
      :loading="loadingForm"
      :dataForm="dataFinancialRatios"
      :itemsYear="itemsYearSelect"
      @resetForm="resetFormFinancialRatios"
      @addAndEdit="addAndEditFinancialRatios"
    />
    <formTheFirstTop10Shareholders
      ref="formTheFirstTop10Shareholders"
      v-model="statusDialogFormTheFirstTop10Shareholders"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :status="statusForm"
      :loading="loadingForm"
      :dataForm="dataTheFirstTop10Shareholders"
      :itemsYear="itemsYearSelect"
      @resetForm="resetFormTheFirstTop10Shareholders"
      @addAndEdit="addAndEditTheFirstTop10Shareholders"
    />
    <formTheCompanyDividendPaymentInfo
      ref="formTheCompanyDividendPaymentInfo"
      v-model="statusDialogFormTheCompanyDividendPaymentInfo"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :status="statusForm"
      :loading="loadingForm"
      :dataForm="dataTheCompanyDividendPaymentInfo"
      :itemsYear="itemsYearSelect"
      @resetForm="resetFormTheCompanyDividendPaymentInfo"
      @addAndEdit="addAndEditTheCompanyDividendPaymentInfo"
    />
    <dialog-loading v-model="loadingForm" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import titleHeaderMenu from '../../../components/adminpage/titleHeaderMenu.vue'
import tableFinancialHighlights from '../../../components/adminpage/tablesTablePage/tableFinancialHighlights.vue'
import tableFinancialRatio from '../../../components/adminpage/tablesTablePage/tableFinancialRatio.vue'
import tableTheFirstTop10Shareholders from '../../../components/adminpage/tablesTablePage/tableTheFirstTop10Shareholders.vue'
import tableTheCompanyDividendPaymentInfo from '../../../components/adminpage/tablesTablePage/tableTheCompanyDividendPaymentInfo.vue'
import formFinancialHighlights from '../../../components/adminpage/formTables/formFinancialHighlights.vue'
import formFinancialRatios from '../../../components/adminpage/formTables/formFinancialRatios.vue'
import formTheFirstTop10Shareholders from '../../../components/adminpage/formTables/formTheFirstTop10Shareholders.vue'
import formTheCompanyDividendPaymentInfo from '../../../components/adminpage/formTables/formTheCompanyDividendPaymentInfo.vue'

export default {
  components: {
    titleHeaderMenu,
    tableFinancialHighlights, // บัญชีทางการเงินที่สำคัญ,
    formFinancialHighlights,
    tableFinancialRatio, // อัตราส่วนทางการเงินที่สำคัญ
    formFinancialRatios,
    tableTheFirstTop10Shareholders, // รายชื่อผู้ถือหลักทรัพย์ตั้งแต่ 0.5% ของบริษัท
    formTheFirstTop10Shareholders,
    tableTheCompanyDividendPaymentInfo, // ข้อมูลการจ่ายเงินปันผลของบริษัท
    formTheCompanyDividendPaymentInfo
  },
  data () {
    return {
      iconTitle: 'mdi-table-edit',
      titleMenu: 'ตาราง',
      category: 'Financial Highlights',
      itemsCategory: [
        { text: 'บัญชีทางการเงินที่สำคัญ', value: 'Financial Highlights' },
        { text: 'อัตราส่วนทางการเงินที่สำคัญ', value: 'Financial Ratio' },
        {
          text: 'ข้อมูลการจ่ายเงินปันผลของบริษัท',
          value: 'The Company’s Dividend Payment Info'
        },
        {
          text: 'รายชื่อผู้ถือหลักทรัพย์ตั้งแต่ 0.5% ของบริษัท',
          value: 'The first top 10 shareholders'
        }
      ],
      // บัญชีทางการเงินที่สำคัญ
      loadingTableFinancialHighlights: false,
      headerTableFinancialHighlights: [
        {
          text: 'ลำดับ',
          align: 'center',
          value: 'number',
          sortable: false,
          filterable: false
        },
        {
          text: 'ปี ภาษาไทย',
          align: 'center',
          value: 'year_th',
          sortable: false
        },
        {
          text: 'ปี ภาษาอังกฤษ',
          align: 'center',
          value: 'year_eng',
          sortable: false
        },
        {
          text: 'สินทรัพย์รวม',
          align: 'end',
          value: 'total_assets',
          sortable: false,
          filterable: false
        },
        {
          text: 'หนี้สินรวม',
          align: 'end',
          value: 'total_liabilities',
          sortable: false,
          filterable: false
        },
        {
          text: 'ส่วนของผู้ถือหุ้น',
          align: 'end',
          value: 'shareholder_equity',
          sortable: false,
          filterable: false
        },
        {
          text: 'มูลค่าหุ้นที่เรียกชำระแล้ว',
          align: 'end',
          value: 'paid_up_capital',
          sortable: false,
          filterable: false
        },
        {
          text: 'รายได้รวม',
          align: 'end',
          value: 'total_revenues',
          sortable: false,
          filterable: false
        },
        {
          text: 'กำไรสุทธิ',
          align: 'end',
          value: 'net_profit',
          sortable: false,
          filterable: false
        },
        {
          text: 'กำไรต่อหุ้น (บาท)',
          align: 'end',
          value: 'eps',
          sortable: false,
          filterable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false,
          filterable: false
        }
      ],
      itemsTableFinancialHighlightsAll: [],
      itemsTableFinancialHighlights: [],
      paginationTableFinancialHighlights: {
        total: 0,
        page: 1,
        row: 999
      },
      // อัตราส่วนทางการเงินที่สำคัญ
      loadingTableFinancialRatios: false,
      headerTableFinancialRatios: [
        {
          text: 'ลำดับ',
          align: 'center',
          value: 'number',
          sortable: false,
          filterable: false
        },
        {
          text: 'ปี ภาษาไทย',
          align: 'center',
          value: 'year_th',
          sortable: false
        },
        {
          text: 'ปี ภาษาอังกฤษ',
          align: 'center',
          value: 'year_eng',
          sortable: false
        },
        {
          text: 'ROA (%)',
          align: 'end',
          value: 'roa',
          sortable: false,
          filterable: false
        },
        {
          text: 'ROE (%)',
          align: 'end',
          value: 'roe',
          sortable: false,
          filterable: false
        },
        {
          text: 'อัตรากำไรสุทธิ (%)',
          align: 'end',
          value: 'net_profit_margin',
          sortable: false,
          filterable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false,
          filterable: false
        }
      ],
      itemsTableFinancialRatioAll: [],
      itemsTableFinancialRatio: [],
      paginationTableFinancialRatios: {
        total: 0,
        page: 1,
        row: 999
      },
      // ผู้ถือหุ้นรายใหญ่ 10 ลำดับแรกของบริษัทฯ
      statusEditTop10Shareholders: false,
      loadingEditTop10Shareholders: false,
      description_engTop10Shareholders: '',
      description_thTop10Shareholders: '',
      loadingTableTheFirstTop10Shareholders: false,
      headerTableTheFirstTop10Shareholders: [
        {
          text: 'ลำดับ',
          align: 'center',
          value: 'number',
          sortable: false,
          filterable: false
        },
        {
          text: 'ชื่อไทย',
          align: 'center',
          value: 'shareholders_th',
          sortable: false
        },
        {
          text: 'ชื่ออังกฤษ',
          align: 'center',
          value: 'shareholders_eng',
          sortable: false
        },
        {
          text: 'จำนวนหุ้น',
          align: 'end',
          value: 'shares',
          sortable: false,
          filterable: false
        },
        {
          text: '% ของจำนวนหุ้น',
          align: 'end',
          value: 'percent_shares',
          sortable: false,
          filterable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false,
          filterable: false
        }
      ],
      itemsTableTheFirstTop10ShareholdersAll: [],
      itemsTableTheFirstTop10Shareholders: [],
      paginationTableTheFirstTop10Shareholders: {
        total: 0,
        page: 1,
        row: 999
      },
      // ข้อมูลการจ่ายเงินปันผลของบริษัท
      loadingTableTheCompanyDividendPaymentInfo: false,
      headerTableTheCompanyDividendPaymentInfo: [
        {
          text: 'ลำดับ',
          align: 'center',
          value: 'number',
          sortable: false,
          filterable: false
        },
        {
          text: 'ปี ภาษาไทย',
          align: 'center',
          value: 'year_th',
          sortable: false
        },
        {
          text: 'ปี ภาษาอังกฤษ',
          align: 'center',
          value: 'year_eng',
          sortable: false
        },
        {
          text: 'จ่ายเงินปันผลประจำปี อัตราหุ้นละ (บาท)',
          align: 'center',
          value: 'dividend_per_share',
          sortable: false,
          filterable: false
        },
        {
          text: 'คิดเป็นเงินปันผลจำนวน (ล้านบาท)',
          align: 'center',
          value: 'dividend_in_total',
          sortable: false,
          filterable: false
        },
        {
          text: 'อัตราการจ่ายเงินปันผลต่อกำไรสุทธิ (%)',
          align: 'center',
          value: 'dividend_per_net_profit',
          sortable: false,
          filterable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false,
          filterable: false
        }
      ],
      itemsTableTheCompanyDividendPaymentInfoAll: [],
      itemsTableTheCompanyDividendPaymentInfo: [],
      paginationTableTheCompanyDividendPaymentInfo: {
        total: 0,
        page: 1,
        row: 999
      },
      itemsYearSelect: [],
      titleHeader: '',
      iconTitleaAddEdit: '',
      statusForm: 'add',
      loadingForm: false,
      indexEdit: null,

      // form add edit
      // บัญชีทางการเงินที่สำคัญ
      statusDialogFormFinancialHighlights: false,
      dataFinancialHighlights: {
        year_th: '',
        year_eng: '',
        total_assets: '',
        total_liabilities: '',
        shareholder_equity: '',
        paid_up_capital: '',
        total_revenues: '',
        net_profit: '',
        eps: '',
        create_by: '',
        update_by: ''
      },
      defalutDataFinancialHighlights: {
        year_th: '',
        year_eng: '',
        total_assets: '',
        total_liabilities: '',
        shareholder_equity: '',
        paid_up_capital: '',
        total_revenues: '',
        net_profit: '',
        eps: '',
        create_by: '',
        update_by: ''
      },
      // อัตราส่วนทางการเงินที่สำคัญ
      statusDialogFormFinancialRatios: false,
      dataFinancialRatios: {
        year_th: '',
        year_eng: '',
        roa: '',
        roe: '',
        net_profit_margin: '',
        create_by: '',
        update_by: ''
      },
      defalutDataFinancialRatios: {
        year_th: '',
        year_eng: '',
        roa: '',
        roe: '',
        net_profit_margin: '',
        create_by: '',
        update_by: ''
      },
      // ผู้ถือหุ้นรายใหญ่ 10 ลำดับแรกของบริษัทฯ
      statusDialogFormTheFirstTop10Shareholders: false,
      dataTheFirstTop10Shareholders: {
        shareholders_th: '',
        shareholders_eng: '',
        shares: '',
        percent_shares: '',
        create_by: '',
        update_by: ''
      },
      defalutDataTheFirstTop10Shareholders: {
        shareholders_th: '',
        shareholders_eng: '',
        shares: '',
        percent_shares: '',
        create_by: '',
        update_by: ''
      },
      // ข้อมูลการจ่ายเงินปันผลของบริษัท
      statusDialogFormTheCompanyDividendPaymentInfo: false,
      dataTheCompanyDividendPaymentInfo: {
        year_th: '',
        year_eng: '',
        dividend_per_share: '',
        dividend_in_total: '',
        dividend_per_net_profit: '',
        create_by: '',
        update_by: ''
      },
      defalutDataTheCompanyDividendPaymentInfo: {
        year_th: '',
        year_eng: '',
        dividend_per_share: '',
        dividend_in_total: '',
        dividend_per_net_profit: '',
        create_by: '',
        update_by: ''
      }
    }
  },
  async created () {
    this.init()
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    init () {
      this.inititemsYear()
      this.initItemsTable()
    },
    initItemsTable () {
      if (this.category === 'Financial Highlights') {
        // บัญชีทางการเงินที่สำคัญ
        this.initTableFinancialHighlights()
      } else if (this.category === 'Financial Ratio') {
        // อัตราส่วนทางการเงินที่สำคัญ
        this.initTableFinancialRatios()
      } else if (this.category === 'The Company’s Dividend Payment Info') {
        // ข้อมูลการจ่ายเงินปันผลของบริษัท
        this.initTableTheCompanyDividendPaymentInfo()
      } else if (this.category === 'The first top 10 shareholders') {
        // ผู้ถือหุ้นรายใหญ่ 10 ลำดับแรกของบริษัทฯ
        this.initTableTheFirstTop10Shareholders()
      }
    },
    inititemsYear () {
      const years = parseInt(this.$moment().format('YYYY'))
      const itemsYears = []
      for (let index = years; index >= 2014; index--) {
        itemsYears.push({
          text: JSON.stringify(index + 543),
          val: JSON.stringify(index)
        })
      }
      this.itemsYearSelect = itemsYears
    },
    addItemsTable () {
      if (this.category === 'Financial Highlights') {
        this.titleHeader = 'เพิ่มข้อมูล'
        this.iconTitleaAddEdit = 'mdi-plus'
        this.statusForm = 'add'
        this.statusDialogFormFinancialHighlights = true
      } else if (this.category === 'Financial Ratio') {
        this.titleHeader = 'เพิ่มข้อมูล'
        this.iconTitleaAddEdit = 'mdi-plus'
        this.statusForm = 'add'
        this.statusDialogFormFinancialRatios = true
      } else if (this.category === 'The Company’s Dividend Payment Info') {
        this.titleHeader = 'เพิ่มข้อมูล'
        this.iconTitleaAddEdit = 'mdi-plus'
        this.statusForm = 'add'
        this.statusDialogFormTheCompanyDividendPaymentInfo = true
      } else if (this.category === 'The first top 10 shareholders') {
        this.titleHeader = 'เพิ่มข้อมูล'
        this.iconTitleaAddEdit = 'mdi-plus'
        this.statusForm = 'add'
        this.statusDialogFormTheFirstTop10Shareholders = true
      }
    },
    editItemsTable (item) {
      if (this.category === 'Financial Highlights') {
        this.titleHeader = 'แก้ไขข้อมูล'
        this.iconTitleaAddEdit = 'mdi-pencil'
        this.statusForm = 'edit'
        this.dataFinancialHighlights = item.item
        this.indexEdit = item.index
        this.statusDialogFormFinancialHighlights = true
      } else if (this.category === 'Financial Ratio') {
        this.titleHeader = 'แก้ไขข้อมูล'
        this.iconTitleaAddEdit = 'mdi-pencil'
        this.statusForm = 'edit'
        this.dataFinancialRatios = item.item
        this.indexEdit = item.index
        this.statusDialogFormFinancialRatios = true
      } else if (this.category === 'The Company’s Dividend Payment Info') {
        this.titleHeader = 'แก้ไขข้อมูล'
        this.iconTitleaAddEdit = 'mdi-pencil'
        this.statusForm = 'edit'
        this.dataTheCompanyDividendPaymentInfo = item.item
        this.indexEdit = item.index
        this.statusDialogFormTheCompanyDividendPaymentInfo = true
      } else if (this.category === 'The first top 10 shareholders') {
        this.titleHeader = 'แก้ไขข้อมูล'
        this.iconTitleaAddEdit = 'mdi-pencil'
        this.statusForm = 'edit'
        this.dataTheFirstTop10Shareholders = item.item
        this.indexEdit = item.index
        this.statusDialogFormTheFirstTop10Shareholders = true
      }
    },
    // บัญชีทางการเงินที่สำคัญ
    async initTableFinancialHighlights () {
      this.loadingTableFinancialHighlights = true
      const payload = {
        page: this.paginationTableFinancialHighlights.page,
        row: this.paginationTableFinancialHighlights.row,
        keyword: this.category
      }
      this.itemsTableFinancialHighlights = []
      const res = await this.getItemsTable(payload)
      this.loadingTableFinancialHighlights = false
      if (res.status) {
        this.itemsTableFinancialHighlightsAll = res.data.data[0]
        this.itemsTableFinancialHighlights = res.data.data[0].datas
        this.paginationTableFinancialHighlights.total =
          res.data.pagination.total
        this.paginationTableFinancialHighlights.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    onChangeTableFinancialHighlights (val) {
      this.paginationTableFinancialHighlights.page = val.page
      this.paginationTableFinancialHighlights.row = val.row
      // this.initTableFinancialHighlights()
    },
    resetFormFinancialHighlights () {
      this.dataFinancialHighlights = this.defalutDataFinancialHighlights
    },
    addAndEditFinancialHighlights (data) {
      if (data.status === 'add') {
        this.addDataFinancialHighlights(data.items)
      } else if (data.status === 'edit') {
        this.editDataFinancialHighlights(data.items)
      }
    },
    async addDataFinancialHighlights (data) {
      data.update_by = this.userInfo.user_id
      data.create_by = this.userInfo.user_id
      this.loadingForm = true
      const payload = Object.assign({}, this.itemsTableFinancialHighlightsAll)
      payload.datas.unshift(data)
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มข้อมูลตารางสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableFinancialHighlights()
        this.statusDialogFormFinancialHighlights = false
        this.$refs.formFinancialHighlights.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async editDataFinancialHighlights (data) {
      this.loadingForm = true
      const payload = Object.assign({}, this.itemsTableFinancialHighlightsAll)
      payload.datas[this.indexEdit] = data
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขข้อมูลตารางสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableFinancialHighlights()
        this.statusDialogFormFinancialHighlights = false
        this.$refs.formFinancialHighlights.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    recheckDeleteFinancialHighlights (index) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบข้อมูลตาราง',
        text: 'คุณต้องการลบข้อมูลตารางนี้ ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableFinancialHighlights)
          )
          let item1 = JSON.parse(
            JSON.stringify(this.itemsTableFinancialHighlights)
          )
          item1 = item1.splice(0, index)
          let item2 = JSON.parse(
            JSON.stringify(this.itemsTableFinancialHighlights)
          )
          item2 = item2.splice(index + 1, itemAll.length)
          const newItem = item1.concat(item2)
          this.itemsTableFinancialHighlightsAll.datas = newItem
          this.DeleteFinancialHighlights(this.itemsTableFinancialHighlightsAll)
        }
      })
    },
    async DeleteFinancialHighlights (item) {
      this.loadingForm = true
      const payload = Object.assign({}, item)
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบข้อมูลตารางสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableFinancialHighlights()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckChangeIndexFinancialHighlights (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'เปลี่ยนลำดับข้อมูล',
        text: 'คุณต้องการเปลี่ยนลำดับข้อมูลตาราง ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableFinancialHighlights)
          )
          if (item.status === 'up') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(this.itemsTableFinancialHighlights[item.index - 1])
            )
            itemAll[item.index - 1] = JSON.parse(
              JSON.stringify(this.itemsTableFinancialHighlights[item.index])
            )
          } else if (item.status === 'down') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(this.itemsTableFinancialHighlights[item.index + 1])
            )
            itemAll[item.index + 1] = JSON.parse(
              JSON.stringify(this.itemsTableFinancialHighlights[item.index])
            )
          }
          this.itemsTableFinancialHighlightsAll.datas = itemAll
          this.changeIndexFinancialHighlights(
            this.itemsTableFinancialHighlightsAll
          )
        }
      })
    },
    async changeIndexFinancialHighlights (item) {
      this.loadingForm = true
      const payload = Object.assign({}, item)
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เปลี่ยนลำดับข้อมูลตาราง สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableFinancialHighlights()
      } else {
        this.antmessage.error(res.message)
      }
    },
    // อัตราส่วนทางการเงินที่สำคัญ
    async initTableFinancialRatios () {
      this.loadingTableFinancialRatios = true
      const payload = {
        page: this.paginationTableFinancialRatios.page,
        row: this.paginationTableFinancialRatios.row,
        keyword: this.category
      }
      this.itemsTableFinancialRatio = []
      const res = await this.getItemsTable(payload)
      this.loadingTableFinancialRatios = false
      if (res.status) {
        this.itemsTableFinancialRatioAll = res.data.data[0]
        this.itemsTableFinancialRatio = res.data.data[0].datas
        this.paginationTableFinancialRatios.total = res.data.pagination.total
        this.paginationTableFinancialRatios.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    onChangeTableFinancialRatios (val) {
      this.paginationTableFinancialRatios.page = val.page
      this.paginationTableFinancialRatios.row = val.row
      // this.initTableFinancialRatios()
    },
    resetFormFinancialRatios () {
      this.dataFinancialRatios = this.defalutDataFinancialRatios
    },
    addAndEditFinancialRatios (data) {
      if (data.status === 'add') {
        this.addDataFinancialRatios(data.items)
      } else if (data.status === 'edit') {
        this.editDataFinancialRatios(data.items)
      }
    },
    async addDataFinancialRatios (data) {
      data.update_by = this.userInfo.user_id
      data.create_by = this.userInfo.user_id
      this.loadingForm = true
      const payload = Object.assign({}, this.itemsTableFinancialRatioAll)
      payload.datas.unshift(data)
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มข้อมูลตารางสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableFinancialRatios()
        this.statusDialogFormFinancialRatios = false
        this.$refs.formFinancialRatios.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async editDataFinancialRatios (data) {
      this.loadingForm = true
      const payload = Object.assign({}, this.itemsTableFinancialRatioAll)
      payload.datas[this.indexEdit] = data
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขข้อมูลตารางสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableFinancialRatios()
        this.statusDialogFormFinancialRatios = false
        this.$refs.formFinancialRatios.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    recheckDeleteFinancialRatios (index) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบข้อมูลตาราง',
        text: 'คุณต้องการลบข้อมูลตารางนี้ ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableFinancialRatio)
          )
          let item1 = JSON.parse(JSON.stringify(this.itemsTableFinancialRatio))
          item1 = item1.splice(0, index)
          let item2 = JSON.parse(JSON.stringify(this.itemsTableFinancialRatio))
          item2 = item2.splice(index + 1, itemAll.length)
          const newItem = item1.concat(item2)
          this.itemsTableFinancialRatioAll.datas = newItem
          this.DeleteFinancialRatios(this.itemsTableFinancialRatioAll)
        }
      })
    },
    async DeleteFinancialRatios (item) {
      this.loadingForm = true
      const payload = Object.assign({}, item)
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบข้อมูลตารางสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableFinancialRatios()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckChangeIndexFinancialRatios (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'เปลี่ยนลำดับข้อมูล',
        text: 'คุณต้องการเปลี่ยนลำดับข้อมูลตาราง ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableFinancialRatio)
          )
          if (item.status === 'up') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(this.itemsTableFinancialRatio[item.index - 1])
            )
            itemAll[item.index - 1] = JSON.parse(
              JSON.stringify(this.itemsTableFinancialRatio[item.index])
            )
          } else if (item.status === 'down') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(this.itemsTableFinancialRatio[item.index + 1])
            )
            itemAll[item.index + 1] = JSON.parse(
              JSON.stringify(this.itemsTableFinancialRatio[item.index])
            )
          }
          this.itemsTableFinancialRatioAll.datas = itemAll
          this.changeIndexFinancialRatios(this.itemsTableFinancialRatioAll)
        }
      })
    },
    async changeIndexFinancialRatios (item) {
      this.loadingForm = true
      const payload = Object.assign({}, item)
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เปลี่ยนลำดับข้อมูลตาราง สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableFinancialRatios()
      } else {
        this.antmessage.error(res.message)
      }
    },
    // ผู้ถือหุ้นรายใหญ่ 10 ลำดับแรกของบริษัทฯ
    async initTableTheFirstTop10Shareholders () {
      this.loadingTableTheFirstTop10Shareholders = true
      const payload = {
        page: this.paginationTableTheFirstTop10Shareholders.page,
        row: this.paginationTableTheFirstTop10Shareholders.row,
        keyword: this.category
      }
      this.itemsTableTheFirstTop10Shareholders = []
      const res = await this.getItemsTable(payload)
      this.loadingTableTheFirstTop10Shareholders = false
      if (res.status) {
        this.itemsTableTheFirstTop10ShareholdersAll = res.data.data[0]
        this.itemsTableTheFirstTop10Shareholders = res.data.data[0].datas
        this.paginationTableTheFirstTop10Shareholders.total =
          res.data.pagination.total
        this.paginationTableTheFirstTop10Shareholders.page =
          res.data.pagination.page
        this.description_engTop10Shareholders =
          res.data.data[0].description_eng
        this.description_thTop10Shareholders = res.data.data[0].description_th
      } else {
        this.antmessage.error(res.message)
      }
    },
    onChangeTableTheFirstTop10Shareholders (val) {
      this.paginationTableTheFirstTop10Shareholders.page = val.page
      this.paginationTableTheFirstTop10Shareholders.row = val.row
      // this.initTableTheFirstTop10Shareholders()
    },
    resetFormTheFirstTop10Shareholders () {
      this.dataTheFirstTop10Shareholders =
        this.defalutDataTheFirstTop10Shareholders
    },
    addAndEditTheFirstTop10Shareholders (data) {
      if (data.status === 'add') {
        this.addDataTheFirstTop10Shareholders(data.items)
      } else if (data.status === 'edit') {
        this.editDataTheFirstTop10Shareholders(data.items)
      }
    },
    async addDataTheFirstTop10Shareholders (data) {
      data.update_by = this.userInfo.user_id
      data.create_by = this.userInfo.user_id
      this.loadingForm = true
      const payload = Object.assign(
        {},
        this.itemsTableTheFirstTop10ShareholdersAll
      )
      payload.datas.push(data)
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มข้อมูลตารางสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableTheFirstTop10Shareholders()
        this.statusDialogFormTheFirstTop10Shareholders = false
        this.$refs.formTheFirstTop10Shareholders.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async editDataTheFirstTop10ShareholdersDescription () {
      this.$swal({
        allowEnterKey: false,
        title: 'แก้ไขข้อมูลหัวตาราง',
        text: 'คุณต้องการแก้ไขข้อมูลหัวตาราง ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loadingEditTop10Shareholders = true
          const payload = Object.assign(
            {},
            this.itemsTableTheFirstTop10ShareholdersAll
          )
          payload.description_th = this.description_thTop10Shareholders
          payload.description_eng = this.description_engTop10Shareholders
          const res = await this.updateItemsTable(payload)
          this.loadingEditTop10Shareholders = false
          if (res.status) {
            this.$swal({
              allowEnterKey: false,
              title: 'สำเร็จ',
              text: 'แก้ไขข้อมูลสำเร็จ',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false
            })
            this.initTableTheFirstTop10Shareholders()
            this.statusEditTop10Shareholders = false
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },
    async editDataTheFirstTop10Shareholders (data) {
      this.loadingForm = true
      const payload = Object.assign(
        {},
        this.itemsTableTheFirstTop10ShareholdersAll
      )
      payload.datas[this.indexEdit] = data
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขข้อมูลตารางสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableTheFirstTop10Shareholders()
        this.statusDialogFormTheFirstTop10Shareholders = false
        this.$refs.formTheFirstTop10Shareholders.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    recheckDeleteTheFirstTop10Shareholders (index) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบข้อมูลตาราง',
        text: 'คุณต้องการลบข้อมูลตารางนี้ ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableTheFirstTop10Shareholders)
          )
          let item1 = JSON.parse(
            JSON.stringify(this.itemsTableTheFirstTop10Shareholders)
          )
          item1 = item1.splice(0, index)
          let item2 = JSON.parse(
            JSON.stringify(this.itemsTableTheFirstTop10Shareholders)
          )
          item2 = item2.splice(index + 1, itemAll.length)
          const newItem = item1.concat(item2)
          this.itemsTableTheFirstTop10ShareholdersAll.datas = newItem
          this.DeleteTheFirstTop10Shareholders(
            this.itemsTableTheFirstTop10ShareholdersAll
          )
        }
      })
    },
    async DeleteTheFirstTop10Shareholders (item) {
      this.loadingForm = true
      const payload = Object.assign({}, item)
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบข้อมูลตารางสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableTheFirstTop10Shareholders()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckChangeIndexTheFirstTop10Shareholders (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'เปลี่ยนลำดับข้อมูล',
        text: 'คุณต้องการเปลี่ยนลำดับข้อมูลตาราง ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableTheFirstTop10Shareholders)
          )
          if (item.status === 'up') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(
                this.itemsTableTheFirstTop10Shareholders[item.index - 1]
              )
            )
            itemAll[item.index - 1] = JSON.parse(
              JSON.stringify(
                this.itemsTableTheFirstTop10Shareholders[item.index]
              )
            )
          } else if (item.status === 'down') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(
                this.itemsTableTheFirstTop10Shareholders[item.index + 1]
              )
            )
            itemAll[item.index + 1] = JSON.parse(
              JSON.stringify(
                this.itemsTableTheFirstTop10Shareholders[item.index]
              )
            )
          }
          this.itemsTableTheFirstTop10ShareholdersAll.datas = itemAll
          this.changeIndexTheFirstTop10Shareholders(
            this.itemsTableTheFirstTop10ShareholdersAll
          )
        }
      })
    },
    async changeIndexTheFirstTop10Shareholders (item) {
      this.loadingForm = true
      const payload = Object.assign({}, item)
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เปลี่ยนลำดับข้อมูลตาราง สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableTheFirstTop10Shareholders()
      } else {
        this.antmessage.error(res.message)
      }
    },
    // ข้อมูลการจ่ายเงินปันผลของบริษัท
    async initTableTheCompanyDividendPaymentInfo () {
      this.loadingTableTheCompanyDividendPaymentInfo = true
      const payload = {
        page: this.paginationTableTheCompanyDividendPaymentInfo.page,
        row: this.paginationTableTheCompanyDividendPaymentInfo.row,
        keyword: this.category
      }
      this.itemsTableTheCompanyDividendPaymentInfo = []
      const res = await this.getItemsTable(payload)
      this.loadingTableTheCompanyDividendPaymentInfo = false
      if (res.status) {
        this.itemsTableTheCompanyDividendPaymentInfoAll = res.data.data[0]
        this.itemsTableTheCompanyDividendPaymentInfo = res.data.data[0].datas
        this.paginationTableTheCompanyDividendPaymentInfo.total =
          res.data.pagination.total
        this.paginationTableTheCompanyDividendPaymentInfo.page =
          res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    onChangeTableTheCompanyDividendPaymentInfo (val) {
      this.paginationTableTheCompanyDividendPaymentInfo.page = val.page
      this.paginationTableTheCompanyDividendPaymentInfo.row = val.row
      // this.initTableTheCompanyDividendPaymentInfo()
    },
    resetFormTheCompanyDividendPaymentInfo () {
      this.dataTheCompanyDividendPaymentInfo =
        this.defalutDataTheCompanyDividendPaymentInfo
    },
    addAndEditTheCompanyDividendPaymentInfo (data) {
      if (data.status === 'add') {
        this.addDataTheCompanyDividendPaymentInfo(data.items)
      } else if (data.status === 'edit') {
        this.editDataTheCompanyDividendPaymentInfo(data.items)
      }
    },
    async addDataTheCompanyDividendPaymentInfo (data) {
      data.update_by = this.userInfo.user_id
      data.create_by = this.userInfo.user_id
      this.loadingForm = true
      const payload = Object.assign(
        {},
        this.itemsTableTheCompanyDividendPaymentInfoAll
      )
      payload.datas.unshift(data)
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มข้อมูลตารางสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableTheCompanyDividendPaymentInfo()
        this.statusDialogFormTheCompanyDividendPaymentInfo = false
        this.$refs.formTheCompanyDividendPaymentInfo.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async editDataTheCompanyDividendPaymentInfo (data) {
      this.loadingForm = true
      const payload = Object.assign(
        {},
        this.itemsTableTheCompanyDividendPaymentInfoAll
      )
      payload.datas[this.indexEdit] = data
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขข้อมูลตารางสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableTheCompanyDividendPaymentInfo()
        this.statusDialogFormTheCompanyDividendPaymentInfo = false
        this.$refs.formTheCompanyDividendPaymentInfo.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    recheckDeleteTheCompanyDividendPaymentInfo (index) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบข้อมูลตาราง',
        text: 'คุณต้องการลบข้อมูลตารางนี้ ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableTheCompanyDividendPaymentInfo)
          )
          let item1 = JSON.parse(
            JSON.stringify(this.itemsTableTheCompanyDividendPaymentInfo)
          )
          item1 = item1.splice(0, index)
          let item2 = JSON.parse(
            JSON.stringify(this.itemsTableTheCompanyDividendPaymentInfo)
          )
          item2 = item2.splice(index + 1, itemAll.length)
          const newItem = item1.concat(item2)
          this.itemsTableTheCompanyDividendPaymentInfoAll.datas = newItem
          this.DeleteTheCompanyDividendPaymentInfo(
            this.itemsTableTheCompanyDividendPaymentInfoAll
          )
        }
      })
    },
    async DeleteTheCompanyDividendPaymentInfo (item) {
      this.loadingForm = true
      const payload = Object.assign({}, item)
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบข้อมูลตารางสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableTheCompanyDividendPaymentInfo()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckChangeIndexTheCompanyDividendPaymentInfo (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'เปลี่ยนลำดับข้อมูล',
        text: 'คุณต้องการเปลี่ยนลำดับข้อมูลตาราง ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableTheCompanyDividendPaymentInfo)
          )
          if (item.status === 'up') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(
                this.itemsTableTheCompanyDividendPaymentInfo[item.index - 1]
              )
            )
            itemAll[item.index - 1] = JSON.parse(
              JSON.stringify(
                this.itemsTableTheCompanyDividendPaymentInfo[item.index]
              )
            )
          } else if (item.status === 'down') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(
                this.itemsTableTheCompanyDividendPaymentInfo[item.index + 1]
              )
            )
            itemAll[item.index + 1] = JSON.parse(
              JSON.stringify(
                this.itemsTableTheCompanyDividendPaymentInfo[item.index]
              )
            )
          }
          this.itemsTableTheCompanyDividendPaymentInfoAll.datas = itemAll
          this.changeIndexTheCompanyDividendPaymentInfo(
            this.itemsTableTheCompanyDividendPaymentInfoAll
          )
        }
      })
    },
    async changeIndexTheCompanyDividendPaymentInfo (item) {
      this.loadingForm = true
      const payload = Object.assign({}, item)
      const res = await this.updateItemsTable(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เปลี่ยนลำดับข้อมูลตาราง สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableTheCompanyDividendPaymentInfo()
      } else {
        this.antmessage.error(res.message)
      }
    },
    //
    returnText (val) {
      const item = this.itemsCategory.filter((item) => item.value === val)
      return item[0].text
    }
  }
}
</script>

<style scoped>
.box-edit {
  display: flex;
  align-items: center;
}
.btn-hearder {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.layout-btn-select {
  display: flex;
  align-items: center;
}
.layout-box-category {
  display: flex;
  margin-top: 14px;
  padding-right: 20px;
}
.box-category {
  font-size: 18px;
  margin-top: 10px;
  padding-right: 10px;
}
.width-select {
  width: 200px;
}
.box-btn {
  padding-right: 20px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
@media screen and (max-width: 1000px) {
  .btn-hearder {
    flex-wrap: wrap;
  }
  .layout-box-category {
    width: 100%;
  }
  .box-btn {
    padding-right: 0px;
  }
}

@media screen and (max-width: 728px) {
  .box-edit {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .box-edit-description{
    width: 47%;
  }
}
@media screen and (max-width: 599px) {
  .box-edit-description{
    width: 100%;
  }
  .layout-btn-select {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .layout-box-category {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 10px;
  }
  .box-btn {
    width: 100%;
  }
}
@media screen and (max-width: 466px) {
  .layout-box-category {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
  .btn-hearder {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .box-btn {
    width: 100%;
  }
}
</style>

<template>
  <div class="main-page">
    <Toolbar v-model="drawer" :userName="userInfo.methods[0].username" @logout="logoutAdmin" @resetpassword="resetPasswordUser()" />
    <Navigation v-model="drawer" :menus="menus" />
    <v-main class="main-layout-manage">
      <div class="layout-all">
        <router-view style="height: 100%"></router-view>
      </div>
    </v-main>
    <formResetPassword  v-model="dialogResetPassword" titleHeader="แก้ไขรหัสผ่าน" :userID="user_id" />
  </div>
</template>
<script>
import Toolbar from '../../components/adminpage/toolbarAdminPage.vue'
import Navigation from '../../components/adminpage/navigationAdminPage.vue'
import formResetPassword from '../../components/adminpage/formUserPage/resetPassword.vue'

import { mapGetters } from 'vuex'
export default {
  components: {
    Toolbar,
    Navigation,
    formResetPassword
  },
  data () {
    return {
      drawer: true,
      menus: [],
      // reset password
      dialogResetPassword: false,
      user_id: ''
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  created () {
    this.getUserMenu()
    this.getUserInfo()
  },
  methods: {
    resetPasswordUser () {
      this.dialogResetPassword = true
      this.user_id = this.userInfo.user_id
    },
    logoutAdmin () {
      localStorage.clear()
      this.$router.push({ name: 'loginAdmin' }).catch(() => {})
    },
    async getUserMenu () {
      const res = await this.initUserMenu()
      if (res.status) {
        this.menus = res.data
      } else {
        this.antmessage.error(res.message)
      }
    },
    async getUserInfo () {
      const res = await this.initUserInfo()
      if (res.status) {
        this.$store.commit('setUserInfo', res.data)
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
.main-page {
  /* background: green; */
  height: 100vh;
  overflow: hidden;
}
.main-layout-manage {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}
.layout-all {
  padding-top: 40px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
}
@media screen and (max-width: 959px) {
  .layout-all {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 800px) {
  .layout-all {
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>

<template>
  <div>
    <div class="main-content">
      <!-- title -->
      <div class="layout-all">
        <div class="title-content">
          <div class="d-flex justify-center">
            {{ FiltersLanguage(item_md_and_a_page, "title") }}
            <div>
              <v-img
                class="title-icon"
                :src="require(`@/${item_md_and_a_page.img_dot}`)"
              ></v-img>
            </div>
          </div>
        </div>
        <hr class="hr-border" />
        <div class="layout-section1">
          <div class="box-1-section1">
            <v-img
              class="size-image"
              :src="require(`@/${item_md_and_a_page.img_background}`)"
            >
              <div class="layout-select">
                <div class="title-text">
                  {{ FiltersLanguage(item_md_and_a_page, "title_download") }}
                </div>
                <div class="select-style">
                  <v-select
                    :items="itemsYaer"
                    outlined
                    item-color="green"
                    background-color="#fff"
                    v-model="selectYear"
                    @change="intFileDownload()"
                    :disabled="loadingSelect"
                    :loading="loadingSelect"
                    color="loadingAll"
                    class="custom-select"
                  >
                    <template v-slot:selection="{ item }">
                      <span class="label-select">
                        {{ FiltersLanguage(item_md_and_a_page, "title_year") }}
                        {{ returnFormateYear(item, language) }}</span
                      >
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="items-select">{{
                        returnFormateYear(item, language)
                      }}</span>
                    </template>
                  </v-select>
                </div>
              </div>
            </v-img>
          </div>
          <div class="box-2-section1">
            <div
              class="box-file"
              v-for="(itemsFileDownload, i) in itemsFile"
              :key="`${i}-itemsFileDownload`"
            >
              <div class="text-file-name">{{ itemsFileDownload.title }}</div>
              <div class="box-icon">
                <a
                  :href="`${env}/api/v1/file/${itemsFileDownload.filename}`"
                  target="_blank"
                >
                  <v-icon class="icon-download"> mdi-download </v-icon>
                </a>
              </div>
            </div>
          </div>
          <div
            class="box-3-section1"
            :style="`background : url(${require('@/assets/images/mdAndA/MDandA.jpg')}); background-size: cover; `"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      minYear: 2015,
      itemsYaer: [],
      selectYear: '',
      env: '',
      tag: 'MDandA',
      loadingSelect: false,
      itemsFile: []
    }
  },
  created () {
    this.env = process.env.VUE_APP_API
    this.yearNow = this.$moment().add(2, 'year').format('YYYY')
    this.selectYear = this.$moment().add(2, 'year').format('YYYY')
    this.intFileDownload('created')
    this.$EventBus.$on('change-language', () => {
      this.intFileDownload()
    })
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  methods: {
    itemsYearSelect (year) {
      // const minYear = 2557
      const itemsYears = []
      const maxYear = year
      for (let maxYears = maxYear; maxYears >= this.minYear; maxYears--) {
        itemsYears.push(String(maxYears))
      }
      this.itemsYaer = itemsYears
    },
    async intFileDownload (from = '') {
      this.loadingSelect = true
      this.itemsFile = []
      const payload = {
        page: 1,
        row: 99,
        keyword: '',
        tags: `${this.tag},${this.selectYear},${
          this.language === 'th' ? 'thai' : 'eng'
        }`
      }
      const res = await this.getFileDownload(payload)
      this.loadingSelect = false
      if (res.status) {
        this.itemsFile = res.data.data
        if (from === 'created') {
          if (res.data.data.length === 0 && this.yearNow > this.minYear) {
            this.yearNow = this.yearNow - 1
            this.selectYear = String(this.yearNow)
            this.intFileDownload('created')
          } else {
            this.itemsYearSelect(this.yearNow)
          }
        }
      } else {
        this.antmessage.error(res.message)
      }
    }
  },
  computed: {
    ...mapGetters(['item_md_and_a_page', 'language'])
  }
}
</script>

<style scoped>
/* title */
>>> .custom-select .v-input__control .v-input__slot {
  border-radius: 10px !important;
}
::v-deep .theme--light.v-list-item.v-list-item--highlighted::before {
  opacity: 0.3;
  border-radius: 10px !important;
  background: linear-gradient(
    136.16deg,
    var(--v-hoverListItem1-base) 44.53%,
    var(--v-hoverListItem2-base) 100%
  );
}
>>> .v-list-item {
  border-radius: 10px !important;
}
>>> .v-list-item:hover {
  background: linear-gradient(
    136.16deg,
    var(--v-hoverListItem3-base) 44.53%,
    var(--v-hoverListItem4-base) 100%
  );
}
.layout-all {
  margin-right: 20px;
  margin-left: 20px;
}
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
  margin-bottom: 20px;
}
.title-icon {
  width: 16px;
  height: 16px;
  padding: 3px;
}
.hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 40px;
  margin-bottom: 40px;
}
/* section download */
.layout-section1 {
  display: flex;
  width: 100%;
  margin-bottom: 100px;
}
.box-1-section1 {
  width: 34%;
}
.size-image {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  padding: 50px;
}
.layout-select {
  display: flex;
  flex-wrap: wrap;
}
.title-text {
  width: 80%;
  font-size: 40px;
  font-weight: 700;
  color: var(--v-titleFinancial-base) !important;
  margin-bottom: 20px;
}
.select-style {
  width: 80%;
  display: flex;
}
.label-select {
  font-size: 35px !important;
}
.items-select {
  font-size: 24px !important;
}
.box-2-section1 {
  width: 34%;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 50px;
  background-color: var(--v-bgBoxFinancial-base) !important;
}
.box-file {
  display: flex;
  height: 25%;
  width: 100%;
}
.text-file-name {
  font-size: 25px;
  font-weight: 600;
  color: var(--v-textPrimary-base) !important;
  width: 85%;
  margin-right: 10px;
}
.box-icon {
  width: 8%;
}
.icon-download {
  font-size: 40px;
  color: var(--v-iconDownload-base) !important;
}
.icon-download:hover {
  background: linear-gradient(
    136deg,
    var(--v-gradientUnderLine1-base) 100%,
    var(--v-gradientUnderLine2-base) 100%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.box-3-section1 {
  width: 34%;
  min-height: 200px;
  border-radius: 0px 20px 20px 0px;
}
@media screen and (max-width: 1255px) {
  /* section download */
  .title-text {
  width: 86%;
}
}
@media screen and (max-width: 1200px) {
  /* section download */
  .size-image {
    padding: 30px;
  }
  .select-style {
    width: 80%;
  }
}
@media screen and (max-width: 1073px) {
  /* section download */
  .title-text {
  width: 100%;
}
}
@media screen and (max-width: 1044px) {
  /* section download */
  .title-text {
    font-size: 36px;
  }
}
@media screen and (max-width: 1000px) {
  /* section download */
  .box-1-section1 {
    width: 44%;
  }
  .box-2-section1 {
    width: 46%;
  }
  .box-3-section1 {
    width: 10%;
    background-position: bottom !important ;
  }
}
@media screen and (max-width: 984px) {
  .hr-border {
    color: var(--v-linerPrimary-base) !important;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .layout-select {
    width: 100%;
  }
  .select-style {
    width: 100%;
  }
}
@media screen and (max-width: 688px) {
  /* section download */
  .layout-section1 {
    flex-wrap: wrap;
    text-align: center;
    margin-bottom: 50px;
  }
  .box-1-section1 {
    width: 100%;
  }
  .size-image {
    max-height: 300px;
    border-radius: 20px 20px 0px 0px;
  }
  .layout-select {
    justify-content: center;
  }
  .select-style {
    width: 90%;
  }
  .label-select {
    font-size: 30px !important;
  }
  .box-2-section1 {
    width: 100%;
    padding-bottom: 30px;
    text-align: start;
  }
  .box-file {
    margin-bottom: 20px;
    height: unset;
  }
  .box-3-section1 {
    width: 100%;
    border-radius: 0px 0px 20px 20px;
    background-position: center !important;
  }
}
</style>

<template>
  <div>
    <div
      class="px-5 box-section1"
      :style="`background : url(${require('@/assets/images/dividendPolicy/backgroundSection.jpg')}); background-size: cover; background-position: center;`"
    >
      <div class="mx-auto">
        <div class="title-content">
          <div class="d-flex justify-center">
            {{ FiltersLanguage(item_dividend_policy_page, "title") }}
            <div>
              <v-img
                class="title-icon"
                :src="require(`@/${item_dividend_policy_page.img_dot}`)"
              ></v-img>
            </div>
          </div>
        </div>
        <hr class="hr-dividend-policy mb-6 mt-2 mx-auto" />
        <div class="description-content">
          <div  class="box-description-content" v-if="$vuetify.breakpoint.name !== 'xs'&&$vuetify.breakpoint.name !== 'sm'" v-html="FiltersLanguage(item_dividend_policy_page, 'description')"></div>
          <div  class="box-description-content" v-if="$vuetify.breakpoint.name === 'xs'||$vuetify.breakpoint.name === 'sm'" v-html=" FiltersLanguage(item_dividend_policy_page, 'description_mobile')"></div>
        </div>
      </div>
    </div>

    <div class="main-content">
      <div class="layout-all">
        <!-- table_payment_info -->
        <div class="title-table">
          {{
            FiltersLanguage(
              item_dividend_policy_page,
              "title_table_payment_info"
            )
          }}
        </div>
        <div class="table-style">
          <tableComponent
            :headers="modifiedHeaders(headerTablePaymentInfo)"
            :items="itemTablePaymentInfo"
            ColorTextHeader="#000000"
            :loading="loadingTablePaymentInfo"
            bgHeader="#ffffff"
            class="custom-bg-row"
          >
            <template v-slot:[`item.year`]="{ item }">
              <div
                :class="`colum-year first-row-left  ${
                  returnIndexItem(
                    paginationTablePaymentInfo.row,
                    paginationTablePaymentInfo.page,
                    itemTablePaymentInfo,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
                {{ FiltersLanguage(item, 'year') }}
              </div>
            </template>
            <template v-slot:[`item.dividend_per_share`]="{ item }">
              <div
                :class="`${
                  returnIndexItem(
                    paginationTablePaymentInfo.row,
                    paginationTablePaymentInfo.page,
                    itemTablePaymentInfo,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
                {{
                  item.dividend_per_share === 0 ||  item.dividend_per_share === '0'
                    ? FiltersLanguage(
                        item_dividend_policy_page,
                        "text_no_dividend"
                      )
                    : returncommaNumberPayment( item.dividend_per_share,null)
                }}
              </div>
            </template>
            <template v-slot:[`item.dividend_in_total`]="{ item }">
              <div
                :class="`${
                  returnIndexItem(
                    paginationTablePaymentInfo.row,
                    paginationTablePaymentInfo.page,
                    itemTablePaymentInfo,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
                {{
                  item.dividend_in_total === 0 || item.dividend_in_total === '0'
                    ? FiltersLanguage(
                        item_dividend_policy_page,
                        "text_no_dividend"
                      )
                    : returncommaNumberPayment(item.dividend_in_total,null)
                }}
              </div>
            </template>
            <template v-slot:[`item.dividend_per_net_profit`]="{ item }">
              <div
                :class="`first-row-right ${
                  returnIndexItem(
                    paginationTablePaymentInfo.row,
                    paginationTablePaymentInfo.page,
                    itemTablePaymentInfo,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
                {{
                  item.dividend_per_net_profit === 0 || item.dividend_per_net_profit === '0'
                    ? FiltersLanguage(
                        item_dividend_policy_page,
                        "text_no_dividend"
                      )
                    : returncommaNumberPayment(item.dividend_per_net_profit,null)
                }}
              </div>
            </template>
          </tableComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tableComponent from '../../../components/table.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    tableComponent
  },
  data () {
    return {
      itemTablePaymentInfo: [],
      headerTablePaymentInfo: [],
      loadingTablePaymentInfo: false,
      paginationTablePaymentInfo: {
        total: 0,
        page: 1,
        row: 999
      }
    }
  },
  created () {
    // this.setHeaderTablePaymentInfo()
    // this.$EventBus.$on('change-language', () => {
    //   this.setHeaderTablePaymentInfo()
    // })
    this.initTablePaymentInfo()
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  methods: {
    returnIndexItem (row, page, items, itemRow) {
      return row * page - row + (items.indexOf(itemRow) + 1)
    },
    modifiedHeaders (itemHeader) {
      return itemHeader.map(header => ({
        ...header,
        text: header[`text_${this.language}`] // Change the text to text_th
      }))
    },
    async initTablePaymentInfo () {
      this.loadingTablePaymentInfo = true
      const payload = {
        page: this.paginationTablePaymentInfo.page,
        row: this.paginationTablePaymentInfo.row,
        keyword: 'The Company’s Dividend Payment Info'
      }
      this.itemTablePaymentInfo = []
      const res = await this.getItemsTable(payload)
      this.loadingTablePaymentInfo = false
      if (res.status) {
        this.headerTablePaymentInfo = res.data.data[0].headers
        this.itemTablePaymentInfo = res.data.data[0].datas
        this.paginationTablePaymentInfo.total =
          res.data.pagination.total
        this.paginationTablePaymentInfo.page =
          res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    }
    // setHeaderTablePaymentInfo () {
    //   if (this.language === 'th') {
    //     this.headerTablePaymentInfo =
    //       this.item_dividend_policy_page.header_table_payment_info_th
    //   } else if (this.language === 'eng') {
    //     this.headerTablePaymentInfo =
    //       this.item_dividend_policy_page.header_table_payment_info_eng
    //   }
    // }
  },
  computed: {
    ...mapGetters(['item_dividend_policy_page', 'language'])
  }
}
</script>

<style scoped>
.hr-dividend-policy{
  max-width: 200px;
}
.box-section1{
  min-height: 435px !important;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.description-content {
  color: var(--v-textPrimary-base) !important;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 200%;
  display: flex;
  justify-content: center;
}
.box-description-content{
  max-width: 900px;
}
>>> .custom-bg-row .v-data-table .v-data-table__wrapper table tbody tr td {
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
>>> .custom-bg-row .v-data-table .v-data-table__wrapper table tbody tr td div {
  padding-top: 12px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
}
>>> .theme--light.v-data-table.v-data-table--fixed-header thead th {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 1);
}
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
  margin-bottom: 20px;
}
.title-icon {
  width: 16px;
  height: 16px;
  padding: 3px;
}
.table-style {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  margin-bottom: 50px;
}
.first-row {
  background: linear-gradient(
    180.16deg,
    var(--v-hoverListItem5-base) 44.53%,
    var(--v-hoverListItem6-base) 100%
  );
}
.first-row-right {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.first-row-left {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.colum-year {
  font-weight: 600;
}
.layout-all {
  margin-right: 20px;
  margin-left: 20px;
}
.title-table {
  margin-top: 20px;
  text-align: center;
  background-color: var(--v-bgTitleTable-base) !important;
  border-radius: 51px;
  margin-bottom: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 28px;
  font-weight: 600;
  color: var(--v-textSecond-base) !important;
}
@media screen and (max-width: 688px) {
  .title-table {
    font-size: 26px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 599px) {
  .first-row {
    border-radius: 10px;
  }
}
</style>

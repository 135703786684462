var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"clipped":"","app":"","bottom":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"version-text"},[_c('span',[_vm._v("Copyright ®  ")]),_vm._v(" "+_vm._s(new Date().getFullYear())+" "),_c('span',[_vm._v(" ManageAI")]),_c('span',[_vm._v(" "+_vm._s(_vm.version))])])]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"navigation"},_vm._l((_vm.menus),function(item){return _c('router-link',{key:item.text,attrs:{"custom":"","to":{ name: item.name },"id":`menu-name-${item.text}`},scopedSlots:_vm._u([{key:"default",fn:function({ isActive, isExactActive }){return [(item.items.length == 0)?_c('v-list-item',{class:[
          isActive && 'active',
          isExactActive && 'router-link-exact-active',
        ],attrs:{"to":{ name: item.name },"active-class":"active-item"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',{staticClass:"menu-text"},[_vm._v(_vm._s(item.text))])],1):_c('v-list-group',{class:[
          isActive && 'active-no-action',
          isExactActive && 'router-link-exact-active-no-action',
        ],attrs:{"value":false,"no-action":"","prepend-icon":item.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',{class:[
              isActive && 'active-no-action',
              isExactActive && 'router-link-exact-active-no-action',
            ]},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]},proxy:true}],null,true)},_vm._l((item.items),function(item){return _c('router-link',{key:item.text,attrs:{"custom":"","to":{ name: item.name },"id":`menu-name-${item.text}`},scopedSlots:_vm._u([{key:"default",fn:function({ isActive, isExactActive }){return [_c('v-list-item',{class:[
              isActive && 'active',
              isExactActive && 'router-link-exact-active',
            ],staticStyle:{"padding-left":"42px"},attrs:{"to":{ name: item.name },"active-class":"active-item"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]}}],null,true)})}),1)]}}],null,true)})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'
import mixin from '@/scripts/mixin.js'
import VueRouter from 'vue-router'
import homePage from '@/views/homePage.vue'
import failPage from '@/views/failPage.vue'
import homeInvestorRelations from '@/views/homePage/homeInvestorRelations.vue'
import corporateOverview from '@/views/homePage/corporateOverview.vue'
import financialInformationMain from '@/views/homePage/financialInformationMain.vue'
import shareholderInformation from '@/views/homePage/shareholderInformation.vue'
import corporateGovernanceMain from '@/views/homePage/corporateGovernanceMain.vue'
import newsAll from '@/views/homePage/newsAll.vue'
import contactPage from '@/views/homePage/contactPage.vue'
import corporateInformation from '@/views/homePage/corporateOverview/corporateInformation.vue'
import managementTeam from '@/views/homePage/corporateOverview/managementTeam.vue'
import financialInformation from '@/views/homePage/financialInformationMain/financialInformation.vue'
// import mdAndA from '@/views/homePage/financialInformationMain/mdAndA.vue'
import annualReport561Main from '@/views/homePage/financialInformationMain/annualReport56_1Main.vue'
import stockInformation from '@/views/homePage/shareholderInformation/stockInformation.vue'
import shareholdingStructure from '@/views/homePage/shareholderInformation/shareholdingStructure.vue'
import shareholderMeeting from '@/views/homePage/shareholderInformation/shareholderMeeting.vue'
import dividendPolicy from '@/views/homePage/shareholderInformation/dividendPolicy.vue'
import inetW2 from '@/views/homePage/shareholderInformation/inetW2.vue'
import corporateGovernance from '@/views/homePage/corporateGovernanceMain/corporateGovernance.vue'
import cacPage from '@/views/homePage/corporateGovernanceMain/cacPage.vue'
import esgPage from '@/views/homePage/corporateGovernanceMain/esgPage.vue'
import annualReport561 from '@/views/homePage/financialInformationMain/annualReport56-1/annualReport56_1.vue'
import economyNews from '@/views/homePage/corporateGovernanceMain/esgNews/economyNews.vue'
import environmentNews from '@/views/homePage/corporateGovernanceMain/esgNews/environmentNews.vue'
import societyNews from '@/views/homePage/corporateGovernanceMain/esgNews/societyNews.vue'
import annualReport from '@/views/homePage/financialInformationMain/annualReport56-1/annualReport.vue'
import OneReport561 from '@/views/homePage/financialInformationMain/annualReport56-1/56_1OneReport.vue'
import detailsNews from '@/views/homePage/detailsNews.vue'
import ESGstampNews from '@/components/ESGstampNews.vue'
import stats from '@/views/statsPage.vue'
import adminPage from '@/views/adminPage/adminPage.vue'
import newsPage from '@/views/adminPage/menuAdmin/newsPage.vue'
import teamPage from '@/views/adminPage/menuAdmin/teamPage.vue'
import fileDownloadPage from '@/views/adminPage/menuAdmin/fileDownloadPage.vue'
import tablePage from '@/views/adminPage/menuAdmin/tablePage.vue'
import filePage from '@/views/adminPage/menuAdmin/filePage.vue'
import dashboardPage from '@/views/adminPage/menuAdmin/dashboardPage.vue'
import announcePage from '@/views/adminPage/menuAdmin/announcePage.vue'
import userPage from '@/views/adminPage/menuAdmin/userPage.vue'
import loginAdmin from '@/views/adminPage/loginAdmin.vue'
import addPerson from '@/views/adminPage/menuAdmin/teamPage/addPerson.vue'
import sortPersonTeam from '@/views/adminPage/menuAdmin/teamPage/sortPersonTeam.vue'
import resetPasswordOnEmail from '@/components/adminpage/formUserPage/resetPasswordOnEmail.vue'

Vue.use(VueRouter)
Vue.mixin(mixin)

const routes = [
  { path: '*', redirect: { name: 'homePage' } },
  {
    path: '/password/new',
    name: 'ResetPasswordOnEmail',
    meta: { requiresLogin: false },
    component: resetPasswordOnEmail
  },
  {
    path: '/home',
    name: 'homePage',
    meta: { requiresLogin: false },
    component: homePage,
    redirect: { name: 'homeInvestorRelations' },
    children: [
      {
        path: 'esg-stamp-news/:tag',
        name: 'ESGstampNews',
        meta: { requiresLogin: false },
        component: ESGstampNews
      },
      {
        path: 'home-investor-relations',
        name: 'homeInvestorRelations',
        meta: { requiresLogin: false },
        component: homeInvestorRelations
      },
      {
        path: 'corporate-overview',
        redirect: { name: 'corporateInformation' },
        name: 'corporateOverview',
        meta: { requiresLogin: false },
        component: corporateOverview,
        children: [
          {
            path: 'corporate-information',
            name: 'corporateInformation',
            meta: { requiresLogin: false },
            component: corporateInformation
          },
          {
            path: 'management-team',
            name: 'managementTeam',
            meta: { requiresLogin: false },
            component: managementTeam
          }
        ]
      },
      {
        path: 'financial-information-main',
        redirect: { name: 'financialInformation' },
        name: 'financialInformationMain',
        meta: { requiresLogin: false },
        component: financialInformationMain,
        children: [
          {
            path: 'financial-information',
            name: 'financialInformation',
            meta: { requiresLogin: false },
            component: financialInformation
          }
          // {
          //   path: 'md-and-a',
          //   name: 'mdAndA',
          //   meta: { requiresLogin: false },
          //   component: mdAndA
          // }
        ]
      },
      {
        path: 'annual-report-56_1-main',
        redirect: { name: 'annualReport56_1' },
        name: 'annualReport56_1Main',
        meta: { requiresLogin: false },
        component: annualReport561Main,
        children: [
          {
            path: 'annual-report-56_1',
            name: 'annualReport56_1',
            meta: { requiresLogin: false },
            component: annualReport561
          },
          {
            path: 'annual-report',
            name: 'annualReport',
            meta: { requiresLogin: false },
            component: annualReport
          },
          {
            path: '56-1-one-report',
            name: '56_1OneReport',
            meta: { requiresLogin: false },
            component: OneReport561
          }
        ]
      },
      {
        path: 'shareholder-information',
        redirect: { name: 'stockInformation' },
        name: 'shareholderInformation',
        meta: { requiresLogin: false },
        component: shareholderInformation,
        children: [
          {
            path: 'stock-information',
            name: 'stockInformation',
            meta: { requiresLogin: false },
            component: stockInformation
          },
          {
            path: 'shareholding-structure',
            name: 'shareholdingStructure',
            meta: { requiresLogin: false },
            component: shareholdingStructure
          },
          {
            path: 'shareholder-meeting',
            name: 'shareholderMeeting',
            meta: { requiresLogin: false },
            component: shareholderMeeting
          },
          {
            path: 'dividend-policy',
            name: 'dividendPolicy',
            meta: { requiresLogin: false },
            component: dividendPolicy
          },
          {
            path: 'inet-w3',
            name: 'inetW2',
            meta: { requiresLogin: false },
            component: inetW2
          }
        ]
      },
      {
        path: 'corporate-governance-main',
        redirect: { name: 'corporateGovernance' },
        name: 'corporateGovernanceMain',
        meta: { requiresLogin: false },
        component: corporateGovernanceMain,
        children: [
          {
            path: 'corporate-governance',
            name: 'corporateGovernance',
            meta: { requiresLogin: false },
            component: corporateGovernance
          },
          {
            path: 'cac-page',
            name: 'cacPage',
            meta: { requiresLogin: false },
            component: cacPage
          },
          {
            path: 'esg-page',
            name: 'esgPage',
            meta: { requiresLogin: false },
            component: esgPage
          }
        ]
      },
      {
        path: 'news-all',
        name: 'newsAll',
        meta: { requiresLogin: false },
        component: newsAll
      },
      {
        path: 'news/:news_id',
        name: 'detailsNews',
        meta: { requiresLogin: false },
        component: detailsNews
      },
      {
        path: 'contact-page',
        name: 'contactPage',
        meta: { requiresLogin: false },
        component: contactPage
      },
      {
        path: 'economy-news',
        name: 'economyNews',
        meta: { requiresLogin: false },
        component: economyNews
      },
      {
        path: 'environment-news',
        name: 'environmentNews',
        meta: { requiresLogin: false },
        component: environmentNews
      },
      {
        path: 'society-news',
        name: 'societyNews',
        meta: { requiresLogin: false },
        component: societyNews
      }
    ]
  },
  {
    path: '/fail',
    name: 'failPage',
    meta: { requiresLogin: false },
    component: failPage
  },
  {
    path: '/stats',
    name: 'stats',
    meta: { requiresLogin: false },
    component: stats
  },
  {
    path: '/admin-page',
    name: 'adminPage',
    meta: { requiresLogin: true },
    redirect: { name: 'newsPage' },
    component: adminPage,
    children: [
      {
        path: 'news-page',
        name: 'newsPage',
        meta: { requiresLogin: true },
        component: newsPage
      },
      {
        path: 'team-page',
        name: 'teamPage',
        meta: { requiresLogin: true },
        component: teamPage,
        redirect: { name: 'addPerson' },
        children: [
          {
            path: 'add-person',
            name: 'addPerson',
            meta: { requiresLogin: true },
            component: addPerson
          },
          {
            path: 'sort-person-team',
            name: 'sortPersonTeam',
            meta: { requiresLogin: true },
            component: sortPersonTeam
          }
        ]
      },
      {
        path: 'file-download-page',
        name: 'fileDownloadPage',
        meta: { requiresLogin: true },
        component: fileDownloadPage
      },
      {
        path: 'table-page',
        name: 'tablePage',
        meta: { requiresLogin: true },
        component: tablePage
      },
      {
        path: 'file-page',
        name: 'filePage',
        meta: { requiresLogin: true },
        component: filePage
      },
      {
        path: 'dashboard-page',
        name: 'dashboardPage',
        meta: { requiresLogin: true },
        component: dashboardPage
      },
      {
        path: 'announce-page',
        name: 'announcePage',
        meta: { requiresLogin: true },
        component: announcePage
      },
      {
        path: 'user-page',
        name: 'userPage',
        meta: { requiresLogin: true },
        component: userPage
      }
    ]
  },
  {
    path: '/login-admin',
    name: 'loginAdmin',
    meta: { requiresLogin: false },
    component: loginAdmin
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const ls = localStorage.getItem('Accesstoken')
  if (to.matched.some((x) => x.meta.requiresLogin)) {
    if (ls === undefined || ls === null) {
      // # เป็นหน้าที่ต้อง Authen และไม่มี Authen
      next({ name: 'loginAdmin' })
    } else {
      // # เป็นหน้าที่ต้อง Authen และมี Authen
      next()
    }
  } else {
    if (ls === undefined || ls === null) {
      // # เป็นหน้าที่ไม่ต้องต้อง Authen และไม่มี Authen
      next()
    } else {
      // # เป็นหน้าที่ไม่ต้อง Authen และมี Authen และไม่ใช่ login
      if (to.name === 'loginAdmin') next({ name: 'adminPage' })
      else next()
    }
  }
})

export default router

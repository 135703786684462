<template>
  <div class="main-content">
    <div class="layout-all">
      <!-- subTopic image -->
      <div
        :style="{
          backgroundImage: `url(${require(`@/${item_environment.img_background_title}`)})`,
        }"
        class="bg-image-title"
      >
        <v-img
          class="title-text-image"
          :src="require(`@/${item_environment.img_title}`)"
          contain
        ></v-img>
      </div>
      <hr class="hr-border" />
      <!-- News -->
      <div class="news-content">
        <diV v-for="(item, i) in items" :key="i"
        class="news-card slide-in-fast"  :style="`animation-delay: ${i===0 ?0.3: i/2.5}s; animation-duration:0.5s;`">
          <div class="box-img">
            <v-img
              class="news-img"
              :src="`${env}/api/v1/file/${item.banner}`"
            ></v-img>
          </div>
          <div>
            <!-- title -->
            <a class="three-lines" @click="showDetailNew(item.news_id)">
              {{ FiltersLanguage(item, "title") }}
            </a>
            <div style="text-align: end">
              <v-btn @click="showDetailNew(item.news_id)" text class="px-1 btn-show-detail">
                <span class="more-text"
                  >{{ FiltersLanguage(readMore, "readMore") }}
                </span>
              </v-btn>
            </div>
            <div class="text-date">{{ returnFormateDate(item.create_date,language)}}</div>
          </div>
        </diV>
      </div>
      <div
        v-if="items.length === 0 && !loading"
        class="text-coming-soon"
      >
        coming soon...
      </div>
      <cpn-loading :show="loading" />
      <hr class="hr-border" />
      <div class="pagination-news">
        <!-- ปุ่มซ้าย -->
        <v-btn
          icon
          large
          class="btn-pagination-default color-btn"
          :loading="loading"
          :disabled="pagination.page === 1"
          @click="pagination.page--, onChangePagination()"
          ><v-icon size="40px" icon color="white"
            >mdi-chevron-left</v-icon
          ></v-btn
        >
        <!-- หน้า/หน้าทั้งหมด -->
        <span class="pagination-text"
          >{{ FiltersLanguage(item_environment, "text_page") }} {{ pagination.total === 0 ? 0 : pagination.page }}/{{
            Math.ceil(pagination.total / pagination.row)
          }}</span
        >
        <!-- ปุ่มขวา -->
        <v-btn
          icon
          large
          class="btn-pagination-default color-btn"
          :disabled="
            pagination.page === Math.ceil(pagination.total / pagination.row) ||
            Math.ceil(pagination.total / pagination.row) === 0
          "
          @click="pagination.page++, onChangePagination()"
          :loading="loading"
          ><v-icon size="40px" color="white">mdi-chevron-right</v-icon></v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      env: '',
      loading: false,
      items: [],
      tags: 'environment',
      keyword: '',
      pagination: {
        total: 0,
        page: 1,
        row: 12
      },
      readMore: {
        readMore_th: 'อ่านเพิ่มเติม',
        readMore_eng: 'more'
      }
    }
  },
  computed: {
    ...mapGetters(['item_environment', 'language'])
  },
  created () {
    this.env = process.env.VUE_APP_API
    this.init()
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  methods: {
    async init () {
      this.items = []
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลข่าวที่จะเอามาแสดง
      const payload = {
        keyword: this.keyword,
        page: this.pagination.page,
        row: this.pagination.row,
        tags: this.tags,
        status: true
      }
      const res = await this.getNews(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination.total = res.data.pagination.total
        this.pagination.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    onChangePagination () {
      const element = document.getElementById('top-page')
      element.scrollIntoView(true)
      this.init()
    },
    showDetailNew (id) {
      this.$router
        .push({ name: 'detailsNews', params: { news_id: id } })
        .catch(() => {})
    }
  }
}
</script>

<style lang="css" scoped>
.btn-show-detail:hover {
  background-color: var(--v-hoverBtn-base) !important;
}
.text-coming-soon {
  text-align: center;
  font-size: 22px;
  color: var(--v-textComingSoon-base) !important;
}
.color-btn.theme--light.v-btn.v-btn--disabled {
  background: var(--v-disableBg-base) !important;
}
.color-btn.theme--light.v-btn {
  background: var(
    --inet,
    linear-gradient(
      135deg,
      #bce778 -32.66%,
      #8ed8a3 31.48%,
      #6accc5 89.51%,
      #5dc8d2 120.06%
    )
  ) !important;
}
.btn-pagination-default {
  background: var(--v-btnPaginationDefault-base);
  font-size: 20px;
  width: 45px;
  height: 45px;
}
.pagination-news {
  text-align: center;
  margin-bottom: 45px;
}
.pagination-text {
  color: var(--v-PaginationText-base) !important;
  margin-right: 28px;
  margin-left: 28px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

/* หัวข้อ */
.layout-all {
  margin-right: 20px;
  margin-left: 20px;
}
.bg-image-title {
  margin-top: 50px;
  margin-bottom: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  height: 250px;
  background-size: cover;
}
.title-text-image {
  width: 334px;
  height: 200px;
}
.hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 50px;
  margin-bottom: 50px;
}
/* ข่าว */
.news-content {
  display: flex;
  flex-wrap: wrap;
}
.news-card {
  width: 25%;
  padding: 12px;
}
.box-img {
  height: 250px;
}
.news-img {
  width: 100%;
  height: 100%;
  background-size: contain;
}
.title-one-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: normal;

  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  margin-top: 20px;
  color: var(--v-newstextForESG-base) !important;
}
.three-lines {
  min-height: 90px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: var(--v-newstextForESG-base) !important;
}
.more-text {
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  font-weight: 500;
  text-decoration-line: underline;
  letter-spacing: 0px;
  color: var(--v-newstextForESG-base) !important;
}
.three-lines + a > {
  display: none;
}
.text-date {
  color: var(--v-newsdateForESG-base) !important;
  font-size: 20px;
  font-weight: 500;
}
@media screen and (max-width: 984px) {
  .news-card {
    width: 50%;
  }
}
@media screen and (max-width: 560px) {
  .news-card {
    width: 100%;
  }
}
</style>

<template>
  <div>
    <titleHeaderMenu :iconTitle="iconTitle" :titleMenu="titleMenu" />
    <div class="btn-hearder">
      <div class="layout-btn-select">
        <div class="box-btn">
          <v-btn class="btn-add" elevation="0" rounded @click="addItemsFile"
            ><v-icon>mdi-plus</v-icon>เพิ่มไฟล์</v-btn
          >
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="box-text-field">
        <div>
          <v-text-field
            v-model="search"
            :append-icon="'mdi-magnify'"
            label="ค้นหาจากหัวข้อ"
            color="primaryIconAdmin"
          ></v-text-field>
        </div>
      </div>
    </div>
    <tableAdminAll
      :headers="headerTableFile"
      :items="itemsTableFile"
      @onChangePagination="changePagination"
      :loading="loadingTableFile"
      :search="search"
      warpHeader
      :height="$vuetify.breakpoint.height-280"
    >
      <template v-slot:[`item.number`]="{ item }">
        <span>
          {{
            paginationTableFile.row * paginationTableFile.page -
            paginationTableFile.row +
            (itemsTableFile.indexOf(item) + 1)
          }}
        </span>
      </template>
      <template v-slot:[`item.create_date`]="{ item }">
        {{ returnFormateDate(item.create_date, "th") }}
      </template>
      <template v-slot:[`item.path`]="{ item }">
        <a :href="`${env}/api/v1/file/${item.filename}`" target="_blank"
          >{{ env }}/api/v1/file/{{ item.filename }}</a
        >
        <v-btn icon @click="copyLink(`${env}/api/v1/file/${item.filename}`)"><v-icon>mdi-content-copy</v-icon></v-btn>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="layout-action">
          <v-btn icon @click="recheckDeleteFile(item)"
            ><v-icon class="btn-delete">mdi-trash-can-outline</v-icon></v-btn
          >
        </div>
      </template>
    </tableAdminAll>
    <formFileUpload
      ref="formFileUpload"
      v-model="statusDialogFormFileUpload"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :loading="loadingForm"
      @uploadFile="uploadFileItem"
    />
    <dialog-loading v-model="loadingForm" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import titleHeaderMenu from '../../../components/adminpage/titleHeaderMenu.vue'
import tableAdminAll from '../../../components/adminpage/tableAll/tableAdminAll.vue'
import formFileUpload from '../../../components/adminpage/formFile/formFileUpload.vue'
export default {
  components: {
    titleHeaderMenu,
    tableAdminAll,
    formFileUpload
  },
  data () {
    return {
      env: '',
      search: '',
      iconTitle: 'mdi-file-cloud-outline',
      titleMenu: 'อัปโหลดไฟล์',
      titleHeader: '',
      iconTitleaAddEdit: '',
      loadingForm: false,
      statusDialogFormFileUpload: false,
      loadingTableFile: false,
      headerTableFile: [
        {
          text: 'ลำดับ',
          align: 'center',
          value: 'number',
          sortable: false,
          filterable: false
        },
        {
          text: 'หัวข้อ',
          align: 'center',
          value: 'title',
          sortable: false
        },
        {
          text: 'ไฟล์',
          align: 'center',
          value: 'filename',
          sortable: false,
          filterable: false
        },
        {
          text: 'ลิงก์',
          align: 'start',
          value: 'path',
          sortable: false,
          filterable: false
        },
        {
          text: 'วันที่อัปโหลด',
          align: 'end',
          value: 'create_date',
          sortable: false,
          filterable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false,
          filterable: false
        }
      ],
      itemsTableFile: [],
      paginationTableFile: {
        total: 0,
        page: 1,
        row: 9999
      }
    }
  },
  async created () {
    this.env = process.env.VUE_APP_API
    this.init()
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    init () {
      this.initItemsTableFile()
    },
    async initItemsTableFile () {
      this.loadingTableFile = true
      const payload = {
        page: this.paginationTableFile.page,
        row: this.paginationTableFile.row,
        // keyword: 'uploadFile',
        tags: 'uploadFile'
      }
      this.itemsTableFile = []
      const res = await this.getFileDownload(payload)
      this.loadingTableFile = false
      if (res.status) {
        this.itemsTableFile = res.data.data
        this.paginationTableFile.total = res.data.pagination.total
        this.paginationTableFile.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    changePagination (val) {
      this.paginationTableFile.page = val.page
      this.paginationTableFile.row = val.row
    },
    addItemsFile () {
      this.titleHeader = 'เพิ่มไฟล์'
      this.iconTitleaAddEdit = 'mdi-plus'
      this.statusDialogFormFileUpload = true
    },
    async uploadFileItem (data) {
      this.loadingForm = true
      const payload = {
        file: data.file,
        title: data.title,
        description: 'สำหรับอัปโหลดไฟล์เพื่อใช้ลิงค์',
        tags: ['uploadFile']
      }
      const res = await this.uploadFile(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มไฟล์สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.$refs.formFileUpload.close()
        this.initItemsTableFile()
      } else {
        this.antmessage.error(res.message)
      }
    },
    copyLink (text) {
      const textCopy = text
      // Copy the text inside the text field
      navigator.clipboard.writeText(textCopy)
    },
    recheckDeleteFile (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบไฟล์อัปโหลด',
        text: 'คุณต้องการลบไฟล์อัปโหลด ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.DeleteFileUpload(item)
        }
      })
    },
    async DeleteFileUpload (item) {
      this.loadingForm = true
      const payload = Object.assign({}, item)
      const res = await this.deleteFile(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบไฟล์อัปโหลดสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initItemsTableFile()
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
.btn-delete {
  color: var(--v-cancleBtn-base) !important;
}
.btn-hearder {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.layout-btn-select {
  display: flex;
  align-items: center;
}
.layout-box-category {
  display: flex;
  margin-top: 14px;
  padding-right: 20px;
}
.box-category {
  font-size: 18px;
  margin-top: 10px;
  padding-right: 10px;
}
.width-select {
  width: 200px;
}
.box-btn {
  padding-right: 20px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
@media screen and (max-width: 1000px) {
  .btn-hearder {
    flex-wrap: wrap;
  }
  .layout-box-category {
    width: 100%;
  }
  .box-btn {
    padding-right: 0px;
  }
}
@media screen and (max-width: 599px) {
  .box-text-field {
    width: 100%;
  }
  .layout-btn-select {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .layout-box-category {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 10px;
  }
  .box-btn {
    width: 100%;
  }
}
@media screen and (max-width: 466px) {
  .layout-box-category {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
  .btn-hearder {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .box-btn {
    width: 100%;
  }
}
</style>

<template>
  <div>
    <div class="main-content">
      <div class="layout-All">
        <!-- section 1: ดูแลกำกับกิจการ-->
        <div class="corporate-governance-main">
          <!--section 1:title -->
          <div class="title-content">
            <div class="d-flex justify-center">
              {{ FiltersLanguage(item_corporate_governance_page, "title") }}
              <div>
                <v-img
                  class="title-icon"
                  :src="require(`@/${item_corporate_governance_page.img_dot}`)"
                ></v-img>
              </div>
            </div>
          </div>
          <hr class="hr-border" />
          <!-- image & description -->
          <div class="corporate-description">
            <!-- left content -->
            <div class="corporate-description-left">
              <v-img
                :src="
                  require(`@/${item_corporate_governance_page.img_build_inet}`)
                "
              ></v-img>
            </div>
            <!-- right content -->
            <div class="corporate-description-right">
              <span class="corporate-descriptionBox-title">
                {{
                  FiltersLanguage(
                    item_corporate_governance_page,
                    "title_name_inet"
                  )
                }}
              </span>
              <p v-if="$vuetify.breakpoint.name !== 'xs'&&$vuetify.breakpoint.name !== 'sm'&&$vuetify.breakpoint.name !== 'md'"  v-html=" FiltersLanguage(item_corporate_governance_page, 'inet_description')" class="corporate-descriptionBox-text"></p>
                <p v-if="$vuetify.breakpoint.name === 'xs'||$vuetify.breakpoint.name === 'sm'||$vuetify.breakpoint.name === 'md'" v-html=" FiltersLanguage(item_corporate_governance_page, 'inet_description_mobile')"  class="corporate-descriptionBox-text"></p>

            </div>
          </div>
          <!-- CG score and AGM score -->
          <div class="cg-agm-score-content">
            <!-- left: CG score -->
            <div class="cg-agm-score-content-left">
              <div
                v-for="(image_cGsore, i) in item_corporate_governance_page
                  .cg_score.img_file"
                :key="i"
                class="ca-agm-image-box"
                contain
              >
                <v-img :src="require(`@/${image_cGsore}`)"> </v-img>
              </div>
              <!-- cac-description -->
              <div class="cg-agm-box">
                <div class="cg-agm-box-title">
                  {{
                    FiltersLanguage(
                      item_corporate_governance_page.cg_score,
                      "title_cg_score"
                    )
                  }}
                </div>
                <div class="d-flex justify-center">
                  <hr class="underline-title" />
                </div>
                <div v-if="$vuetify.breakpoint.name !== 'xs'&&$vuetify.breakpoint.name !== 'sm'&&$vuetify.breakpoint.name !== 'md'" v-html="FiltersLanguage(item_corporate_governance_page.cg_score,'description')" class="cg-agm-description"></div>
                  <div v-if="$vuetify.breakpoint.name === 'xs'||$vuetify.breakpoint.name === 'sm'||$vuetify.breakpoint.name === 'md'"  v-html="FiltersLanguage(item_corporate_governance_page.cg_score,'description_mobile')" class="cg-agm-description">
                </div>
              </div>
            </div>

            <!-- Vertical-Line -->
            <div class="vertical-line"></div>
            <!-- right : agm Score -->
            <div class="cg-agm-score-content-right">
              <div
                v-for="(image_agm, i) in item_corporate_governance_page
                  .agm_score.img_file"
                :key="i"
                class="ca-agm-image-box"
              >
                <v-img :src="require(`@/${image_agm}`)"> </v-img>
              </div>
              <!-- cgAndagm__Box -->
              <div class="cg-agm-box">
                <div class="cg-agm-box-title">
                  {{
                    FiltersLanguage(
                      item_corporate_governance_page.agm_score,
                      "title_cg_score"
                    )
                  }}
                </div>
                <div class="d-flex justify-center">
                  <hr class="underline-title" />
                </div>
                <div v-if="$vuetify.breakpoint.name !== 'xs'&&$vuetify.breakpoint.name !== 'sm'&&$vuetify.breakpoint.name !== 'md'" v-html="FiltersLanguage(item_corporate_governance_page.agm_score,'description')" class="cg-agm-description"></div>
                  <div v-if="$vuetify.breakpoint.name === 'xs'||$vuetify.breakpoint.name === 'sm'||$vuetify.breakpoint.name === 'md'"  v-html="FiltersLanguage(item_corporate_governance_page.agm_score,'description_mobile')" class="cg-agm-description"></div>
                <!-- <div class="cg-agm-description">
                  {{
                    FiltersLanguage(
                      item_corporate_governance_page.agm_score,
                      "description"
                    )
                  }}
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="background-section-cac-esg">
      <div class="main-content cac-esg-content ">
            <div class="image-link ">
              <v-img
                :src="
                  require(`@/${item_corporate_governance_page.cac_section.img_logo_cac}`)
                "
                @click="$router.push('cac-page')"
                class="img-cac-esg mx-auto"
              ></v-img>
              <div class="title-logo-cac-esg">
                {{
                  FiltersLanguage(
                    item_corporate_governance_page.cac_section,
                    "title_cac"
                  )
                }}
              </div>
            </div>
            <div class="image-link">
              <v-img
                :src="
                  require(`@/${item_corporate_governance_page.esg_section.img_logo_esg}`)
                "
                @click="$router.push('esg-page')"
                class="img-cac-esg mx-auto"
              >
              </v-img>
              <div class="title-logo-cac-esg">
                {{
                  FiltersLanguage(
                    item_corporate_governance_page.esg_section,
                    "title_esg"
                  )
                }}
              </div>
            </div>
            </div>
          </div>
    <!-- boredrline-section2 -->
    <div class="borderline-section2"></div>
    <div class="main-content">
      <div class="layout-All">
        <!-- section 2: นโยบายที่เกี่ยวข้อง -->
        <div class="d-flex justify-center">
          <div class="governance-policy-main pink">
            <!-- section 2: นโยบายที่เกี่ยวข้อง (Governance Policy) -->
            <div class="governance-policy-title">
              {{
                FiltersLanguage(
                  item_corporate_governance_page.governance_policy,
                  "title"
                )
              }}
            </div>
            <!-- get PDF file -->
            <div
              class="governanace-policy-description d-flex justify-space-between"
              v-for="(
                item_file_governance, i
              ) in itemaTableGovernanacePolicy"
              :key="i"
            >
              <div class="governanace-policy-text">
                {{ FiltersLanguage(item_file_governance, "title") }}
              </div>
              <!-- icon download file -->
              <div class="d-flex align-center">
                <a
                  :href="`${env}/api/v1/file/${FiltersLanguage(item_file_governance, 'file')}`"
                  target="_blank"
                >
                  <v-icon class="icon-download"> mdi-download </v-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      env: '',
      loadingTableGovernanacePolicy: false,
      paginationTableGovernanacePolicy: {
        total: 0,
        page: 1,
        row: 999
      },
      itemaTableGovernanacePolicy: []
    }
  },
  computed: {
    ...mapGetters(['item_corporate_governance_page'])
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  created () {
    this.env = process.env.VUE_APP_API
    this.initTableGovernanacePolicy()
  },
  methods: {
    async initTableGovernanacePolicy  () {
      this.loadingTableGovernanacePolicy = true
      const payload = {
        page: this.paginationTableGovernanacePolicy.page,
        row: this.paginationTableGovernanacePolicy.row,
        keyword: 'Governance Policy'
      }
      this.itemaTableGovernanacePolicy = []
      const res = await this.getList(payload)
      this.loadingTableGovernanacePolicy = false
      if (res.status) {
        if (res.data.data.length !== 0) {
          this.itemaTableGovernanacePolicy = res.data.data[0].items
        } else {
          this.itemaTableGovernanacePolicy = []
        }
        this.paginationTableGovernanacePolicy.total = res.data.pagination.total
        this.paginationTableGovernanacePolicy.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
.layout-All {
  margin-right: 20px;
  margin-left: 20px;
}
/* section 1 */
.corporate-governance-main {
  width: 100%;
  max-width: 1296px;
}
.title-content {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: var(--v-textPrimary-base) !important;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
}
.title-icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
.hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 40px;
  margin-bottom: 40px;
}
.corporate-description {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 60px;
}
/* image corporate*/
.corporate-description-left {
  width: 50%;
}
.corporate-description-right {
  padding-left: 60px;
  padding-right: 10px;
  width: 50%;
}
.corporate-descriptionBox-title {
  color: var(--v-textPrimary-base) !important;
  font-size: 22px;
  font-weight: 700;
}
.corporate-descriptionBox-text {
  color: var(--v-textPrimary-base) !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 200%;
}
.title-logo-cac-esg {
  margin-top: 20px;
}

/* section 2 */
.borderline-section2 {
  /* layout */
  width: 100%;
  height: 30px;
  /* style */
  background: linear-gradient(
    180deg,
    var(--v-gradientBgCacEsg2-base) 0%,
    var(--v-gradientBgCacEsg1-base)  100%
  );
}
.cg-agm-score-content {
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  margin-bottom: 80px;
}
.background-section-cac-esg{
  background: linear-gradient(
    180deg,
    var(--v-gradientBgCacEsg1-base) 0%,
    var(--v-gradientBgCacEsg1-base) 33%,
    var(--v-gradientBgCacEsg2-base) 33%,
    var(--v-gradientBgCacEsg1-base) 74.9%
  );
}
.cac-esg-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
/* section3 */
.cg-agm-score-content-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 50%;
  margin-bottom: 20px;
}

.ca-agm-image-box {
  width: 13%;
  margin-top: 20px;
  margin-bottom: 30px;
  margin-right: 10px;
  margin-left: 10px;
}

.underline-title {
  width: 250px;
  height: 4px;
  background: linear-gradient(
    136deg,
    var(--v-gradientUnderLine1-base) 100%,
    var(--v-gradientUnderLine2-base) 100%
  );
  margin-top: 10px;
  margin-bottom: 20px;
  border: none;
}

/* cg Score and agm Score */
.cg-agm-score-content-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 50%;
  margin-bottom: 20px;
}
.cg-agm-box {
  text-align: center;
  width: 90%;
}
.cg-agm-box-title {
  color: var(--v-textPrimary-base) !important;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}
.cg-agm-description {
  color: var(--v-textPrimary-base) !important;
  font-size: 18px;
  font-weight: 400;
}
.image-link {
  width: 50%;
  /* font */
  color: var(--v-textPrimary-base) !important;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 80px;

  cursor: pointer;
}

/* Governance_policy */
.governance-policy-main {
  width: 100%;
  max-width: 636px;
  background-color: var(--v-bgPrimary-base) !important;
  margin-top: 30px;
  margin-bottom: 86px;
}
.governance-policy-title {
  /* layout */
  display: inline-flex;
  padding: 4px 18px;
  justify-content: center;
  align-items: center;
  /* style */
  width: 100%;
  border-radius: 51px;
  background: var(--v-tilteGovernanacePolicy-base) !important;
  /* font */
  color: var(--v-textSecond-base) !important;
  font-size: 28px;
  font-weight: 700;
}
/* ชื่อdownload file */
.governanace-policy-description {
  border-bottom: 1px solid var(--v-textPrimary-base);
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.governanace-policy-text {
  /* font */
  color: var(--v-textPrimary-base) !important;
  max-width: 518px;
  width: 100%;
  font-size: 25px;
  font-weight: 400;
  padding-top: 35px;
  padding-bottom: 35px;
}
/*  icon download*/
.icon-download {
  font-size: 40px;
  color: var(--v-mainIconDownloadInetW2-base) !important;
}
.icon-download:hover {
  background: linear-gradient(
    136deg,
    var(--v-gradientUnderLine1-base) 100%,
    var(--v-gradientUnderLine2-base) 100%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.vertical-line {
  border-right: 1px solid var(--v-verticalLineCorporate-base);
}
@media screen and (max-width: 1270px) {
  .background-section-cac-esg{
  background: linear-gradient(
    180deg,
    var(--v-gradientBgCacEsg1-base) 0%,
    var(--v-gradientBgCacEsg1-base) 31.8%,
    var(--v-gradientBgCacEsg2-base) 31.8%,
    var(--v-gradientBgCacEsg1-base) 74.93%
  );
}
}

@media screen and (max-width: 1100px) {
  /* section 1 : Corporate Governance */
  .cg-agm-score-content {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 1077px) {
  .background-section-cac-esg{
  background: linear-gradient(
    180deg,
    var(--v-gradientBgCacEsg1-base) 0%,
    var(--v-gradientBgCacEsg1-base) 30.7%,
    var(--v-gradientBgCacEsg2-base) 30.7%,
    var(--v-gradientBgCacEsg1-base) 74.93%
  );
}
}
@media screen and (max-width: 1000px) {
  .background-section-cac-esg{
  background: linear-gradient(
    180deg,
    var(--v-gradientBgCacEsg1-base) 0%,
    var(--v-gradientBgCacEsg1-base) 29%,
    var(--v-gradientBgCacEsg2-base) 29%,
    var(--v-gradientBgCacEsg1-base) 74.93%
  );
}
}
@media screen and (max-width: 984px) {
  .hr-border {
    color: var(--v-linerPrimary-base) !important;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .background-section-cac-esg{
  background: linear-gradient(
    180deg,
    var(--v-gradientBgCacEsg1-base) 0%,
    var(--v-gradientBgCacEsg1-base) 29%,
    var(--v-gradientBgCacEsg2-base) 29%,
    var(--v-gradientBgCacEsg1-base) 74.93%
  );
  }
}
@media screen and (max-width: 869px) {
  .background-section-cac-esg{
  background: linear-gradient(
    180deg,
    var(--v-gradientBgCacEsg1-base) 0%,
    var(--v-gradientBgCacEsg1-base) 28.3%,
    var(--v-gradientBgCacEsg2-base) 28.3%,
    var(--v-gradientBgCacEsg1-base) 74.93%
  );
  }
}
@media screen and (max-width: 800px) {
  /* section 1 : Corporate Governance */
  .corporate-description {
    margin-bottom: 0px;
  }
  .corporate-description-left {
    width: 100%;
  }
  .corporate-description-right {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 30px;
  }
  .cg-agm-description {
    padding-left: 10px;
    padding-right: 10px;
  }
  .ca-agm-image-box {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .vertical-line {
    display: none;
  }

  /* cg score and agm score */
  .cg-agm-score-content {
    flex-wrap: wrap;
  }
  .cg-agm-score-content-left {
    width: 100%;
  }
  .cg-agm-score-content-right {
    width: 100%;
  }
  .cg-agm-box {
    margin-top: 20px;
  }
  .background-section-cac-esg{
  background: none;
  }
  .image-link {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 0;
  }
  .img-cac-esg{
    max-width: 500px;
  }
  .cac-esg-content {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 636px) {
  /* section 1 : Corporate Governance */
  .ca-agm-image-box {
    width: 15%;
    margin-right: 5px;
    margin-left: 5px;
  }
}
</style>

<template>
  <div v-if="items.length !== 0">
    <v-carousel
      v-if="!loading"
      v-model="model3"
      class="carousel-three carousel-custom"
      hide-delimiters
      height="470"
    >
      <v-carousel-item v-for="n in Math.ceil(items.length / 3)" :key="n">
        <div class="box-groups mx-auto">
          <div
            v-for="(item, index) in returnItems(n)"
            :key="index"
            class="box-group"
          >
            <div class="size-image-group mx-auto">
              <v-img
                @click="
                  $router.push({
                    name: 'ESGstampNews',
                    params: { tag: item.tag },
                  })
                "
                :src="require(`@/${item.image}`)"
              ></v-img>
            </div>
            <div class="box-list-news mt-n16">
              <div v-for="indexList in 4" :key="indexList">
                <div v-if="indexList <= item.data.length">
                  <v-btn
                    text
                    class="pa-1 width-btn"
                    @click="showDetailNew(item.data[indexList - 1].news_id)"
                  >
                    <div class="list-text">
                      {{ FiltersLanguage(item.data[indexList - 1], "title") }}
                    </div>
                  </v-btn>
                  <hr v-if="indexList !== 4" class="my-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
    <v-carousel
      v-if="!loading"
      v-model="model2"
      class="carousel-two carousel-custom"
      hide-delimiters
      height="470"
    >
      <v-carousel-item v-for="n in Math.ceil(items.length / 2)" :key="n">
        <div class="box-groups mx-auto">
          <div
            v-for="(item, index) in returnItems2(n)"
            :key="index"
            class="box-group"
          >
            <div class="size-image-group mx-auto">
              <v-img :src="require(`@/${item.image}`)"></v-img>
            </div>
            <div class="box-list-news mt-n16">
              <div v-for="indexList in 4" :key="indexList">
                <div v-if="indexList <= item.data.length">
                  <v-btn
                    text
                    class="pa-1 width-btn"
                    @click="showDetailNew(item.data[indexList - 1].news_id)"
                  >
                    <div class="list-text">
                      {{ FiltersLanguage(item.data[indexList - 1], "title") }}
                    </div>
                  </v-btn>
                  <hr v-if="indexList !== 4" class="my-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
    <v-carousel
      v-if="!loading"
      v-model="model1"
      class="carousel-one carousel-custom"
      hide-delimiters
      height="470"
    >
      <v-carousel-item v-for="n in Math.ceil(items.length)" :key="n">
        <div class="box-groups mx-auto">
          <div class="box-group">
            <div class="size-image-group mx-auto">
              <v-img :src="require(`@/${items[n - 1].image}`)"></v-img>
            </div>
            <div class="box-list-news mt-n16">
              <div v-for="indexList in 4" :key="indexList">
                <div v-if="indexList <= items[n - 1].data.length">
                  <v-btn
                    text
                    class="pa-1 width-btn"
                    @click="showDetailNew(items[n - 1].data[indexList-1].news_id)"
                  >
                    <div class="list-text">
                      {{
                        FiltersLanguage(items[n - 1].data[indexList-1], "title")
                      }}
                    </div>
                  </v-btn>
                  <hr v-if="indexList !== 4" class="my-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>
    <cpn-loading :show="loading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['language'])
  },
  props: {
    loading: {
      type: Boolean,
      default: () => false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      model3: 0,
      model2: 0,
      model1: 0
    }
  },
  methods: {
    showDetailNew (id) {
      this.$router
        .push({ name: 'detailsNews', params: { news_id: id } })
        .catch(() => {})
    },
    returnItems (page) {
      const items = this.items.slice((page - 1) * 3, (page - 1) * 3 + 3)
      return items
    },
    returnItems2 (page) {
      const items = this.items.slice((page - 1) * 2, (page - 1) * 2 + 2)
      return items
    }
  }
}
</script>

<style lang="css" scoped>
>>> .carousel-custom .v-window__container .v-window__prev button {
  background: linear-gradient(
    360deg,
    rgba(72, 203, 179, 0.8) 0%,
    rgba(122, 228, 136, 0.8) 100%
  );
}
>>> .carousel-custom .v-window__container .v-window__next button {
  background: linear-gradient(
    360deg,
    rgba(72, 203, 179, 0.8) 0%,
    rgba(122, 228, 136, 0.8) 100%
  );
}

.list-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.box-groups {
  width: 95%;
  display: flex;
}
.box-group {
  width: 33.3%;
  padding-left: 10px;
  padding-right: 10px;
}
.size-image-group {
  width: 110px;
  cursor: pointer;
}
.box-list-news {
  width: 100%;
  padding-top: 95px;
  padding-bottom: 40px;
  padding-left: 48px;
  padding-right: 48px;
  border-radius: 20px;
  background-color: var(--v-bgListsNews-base);
  border: 4px solid var(--v-borderListsNews-base);
  min-height: 413px;
}
.width-btn {
  width: 100%;
}
>>> .width-btn .v-btn__content {
  width: 100%;
}

.carousel-two {
  display: none;
}
.carousel-one {
  display: none;
}
@media screen and (max-width: 1100px) {
  .carousel-three {
    display: none;
  }
  .carousel-two {
    display: unset;
  }
  .carousel-one {
    display: none;
  }
  .box-group {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 800px) {
  .carousel-three {
    display: none;
  }
  .carousel-two {
    display: none;
  }
  .carousel-one {
    display: unset;
  }
  .box-group {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 400px) {
  .box-group {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .box-list-news {
    padding-left: 28px;
    padding-right: 28px;
  }
}
</style>

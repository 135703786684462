<template>
  <div class="main-content">
    <div class="layout-all">
      <div class="cacPage-content">
        <!-- title -->
        <div class="title-content">
          <div class="d-flex justify-center">
            {{ FiltersLanguage(item_cac_page, "title") }}
            <div>
              <v-img
                class="title-icon"
                :src="require(`@/${item_cac_page.img_dot}`)"
                contain
              ></v-img>
            </div>
          </div>
        </div>
        <hr class="hr-border" />
        <!-- section 1: การต่อต้านการทุจริตและคอร์รัปชัน : CACs-->
        <div class="anti-corruption-content">
          <!-- left -->
          <div class="content-left-right">
            <v-img
              class="img-size-cac"
              :src="require(`@/${item_cac_page.img_logo_CAC}`)"
            ></v-img>
          </div>
          <!-- right -->
          <div class="content-left-right">
            <div class="description-box">
              <span class="title-description-box">
                {{ FiltersLanguage(item_cac_page, "title_inet") }}
              </span>
              <!-- <p class="text-description-box">
                {{ FiltersLanguage(item_cac_page, "description") }}
              </p> -->
              <p v-if="$vuetify.breakpoint.name !== 'xs'&&$vuetify.breakpoint.name !== 'sm'&&$vuetify.breakpoint.name !== 'md'"  v-html=" FiltersLanguage(item_cac_page, 'description')" class="text-description-box"></p>
                <p v-if="$vuetify.breakpoint.name === 'xs'||$vuetify.breakpoint.name === 'sm'||$vuetify.breakpoint.name === 'md'" v-html=" FiltersLanguage(item_cac_page, 'description_mobile')"  class="text-description-box"></p>
            </div>
          </div>
        </div>
        <!-- section 2 : ช่องทางการร้องเรียน -->
        <div>
          <!-- complaintChannels title -->
          <div class="complaint-channels-title-box">
            {{ FiltersLanguage(item_complaint_channels, "title") }}
          </div>
          <!-- complaintChannels content -->
          <div class="complaint-content">
            <!-- card left -->
            <div class="left-card">
              <div class="compaint-description">
                <div class="description-text">
                  {{ "1. "
                  }}{{
                    FiltersLanguage(item_complaint_channels, "description_p1")
                  }}
                </div>
                <div class="d-flex justify-center">
                  <div class="text-box-white">
                    <v-img
                      class="icon-web"
                      contain
                      :src="require(`@/${item_complaint_channels.img_web}`)"
                    ></v-img>
                    <div>
                      <a
                        :href="`https://${FiltersLanguage(
                          item_complaint_channels,
                          'web'
                        )}`"
                        target="_blank"
                        class="link-text"
                      >
                        {{ FiltersLanguage(item_complaint_channels, "web") }}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="description-text mt-6">
                  {{
                    FiltersLanguage(item_complaint_channels, "description_p2")
                  }}
                </div>
                <div class="d-flex justify-center">
                  <div class="text-box-white">
                    <v-img
                      class="icon-web"
                      contain
                      :src="require(`@/${item_complaint_channels.img_mail}`)"
                    ></v-img>
                    <div>
                      <a
                        :href="`mailto:${item_complaint_channels.mail}`"
                        class="link-text"
                      >
                        {{ item_complaint_channels.mail }}
                      </a>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <!-- card right -->
            <div class="right-card">
              <div class="compaint-description">
                <div class="description-text">
                  {{ "2. "
                  }}{{
                    FiltersLanguage(item_complaint_channels, "description_p3")
                  }}
                </div>
                <!-- เรียน ประธานกรรมการตรวจสอบ -->
                <div
                  class="audit-committee-description"
                  v-on:click="
                    copyDescription_p4(
                      FiltersLanguage(item_complaint_channels, 'description_p4')
                    )
                  "
                >
                  <v-img
                    class="auditcommittee-description-image"
                    :src="require(`@/${item_complaint_channels.img_contact}`)"
                  ></v-img>
                  <div class="d-flex justify-space-between">
                    <div class="auditcommittee-description-title">
                      {{ FiltersLanguage(item_complaint_channels, "title_p4") }}
                    </div>
                    <div>
                      <v-btn icon>
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <p v-if="$vuetify.breakpoint.name !== 'xs'&&$vuetify.breakpoint.name !== 'sm'&&$vuetify.breakpoint.name !== 'md'"  v-html=" FiltersLanguage(item_complaint_channels, 'description_p4')" class="auditcommittee-description-text"></p>
                <p v-if="$vuetify.breakpoint.name === 'xs'||$vuetify.breakpoint.name === 'sm'||$vuetify.breakpoint.name === 'md'" v-html=" FiltersLanguage(item_complaint_channels, 'description_p4_mobile')"  class="auditcommittee-description-text"></p>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
     {{  FiltersLanguage(tiemSnackbar, 'copy') }} : {{  FiltersLanguage(item_complaint_channels, 'description_p4') }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
        {{  FiltersLanguage(tiemSnackbar, 'close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      // snackbar
      snackbar: false,
      timeout: 2000,
      tiemSnackbar: {
        copy_th: 'คัดลอก',
        copy_eng: 'copy',
        close_th: 'ปิด',
        close_eng: 'close'
      }
    }
  },
  computed: {
    ...mapGetters(['item_cac_page', 'item_complaint_channels'])
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  methods: {
    copyDescription_p4 (text) {
      const textCopy = text
      // Copy the text inside the text field
      navigator.clipboard.writeText(textCopy)

      // Alert the copied text
      this.snackbar = true
    }
  }
}
</script>

<style scoped>
/* section 1 */
.layout-all {
  margin-right: 20px;
  margin-left: 20px;
}
.img-size-cac {
  width: 100%;
  max-width: 500px;
}
content {
  width: 100%;
  margin-bottom: 85px;
}
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
}
.title-icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
.hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 40px;
  margin-bottom: 40px;
}

/* section 1: antiCorruption__content */

.anti-corruption-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 95px;
}
/* image */
.content-left-right {
  display: flex;
  justify-content: center;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.description-box {
  margin-right: 10px;
  margin-left: 10px;
}
.title-description-box {
  color: var(--v-textPrimary-base) !important;
  font-size: 22px;
  font-weight: 700;
}
.text-description-box {
  color: var(--v-textPrimary-base) !important;
  font-size: 18px;
  font-weight: 400;
  line-height: 200%;
  margin-bottom: 0px;
}

/* ---------------section 2: complaintChannels ------------------------ */
.complaint-channels-title-box {
  display: flex;
  width: 100%;
  padding: 4px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 51px;
  background: var(--v-titleComplaintCacPage-base) !important;

  /* font */
  color: var(--v-textSecond-base) !important;
  font-size: 28px;
  font-weight: 700;
}
.complaint-content {
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
  margin-bottom: 63px;
}
.left-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 20px;
  background: var(--v-contentComplaintCacPage-base) !important;
  width: 50%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: 24px;
}
.right-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 20px;
  background: var(--v-contentComplaintCacPage-base) !important;
  width: 50%;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 30px;
  padding-left: 30px;
}

.compaint-description {
  max-width: 510px;
  width: 100%;
  color: var(--v-textPrimary-base);
  font-size: 22px;
  font-weight: 400;
}
.description-text {
  margin-bottom: 25px;
}
.text-box-white {
  /* layout */
  display: flex;
  width: 100%;
  padding: 5px 70px 5px 70px;
  justify-content: center;
  align-items: center;
  /* style */
  border-radius: 62.287px;
  border: 1.221px solid var(--v-borderTextBoxWhith-base) !important;
  background: var(--v-bgPrimary-base) !important;
}
.icon-web {
  max-width: 17.098px;
  height: 24.426px;
  margin-right: 20px;
}
.link-text {
  color: var(--v-textPrimary-base) !important;
  text-decoration: none;
}

/* เรียนกรรมการตรวจสอบ */
.audit-committee-description {
  position: relative;
  margin-top: 26px;

  width: 100%;
  justify-content: center;
  align-items: center;
  /* style */
  border-radius: 30px;
  border: 1px solid var(--v-borderDescription-base) !important;
  background: var(--v-bgPrimary-base) !important;

  padding-top: 20px;
  padding-left: 54px;
  padding-right: 34px;
  padding-bottom: 11px;
}
.auditcommittee-description-title {
  color: var(--v-textPrimary-base) !important;
  font-size: 22px;
  font-weight: 700;
}
.auditcommittee-description-text {
  color: var(--v-textPrimary-base) !important;
  font-size: 18px;
  font-weight: 400;
}

.auditcommittee-description-image {
  position: absolute;
  width: 96.97px;
  height: 57.4px;
  left: -33px;
  top: -11px;
}
@media screen and (max-width: 1293px) {
.description-box {
  margin-right: 5px;
  margin-left: 0px;
}
}
@media screen and (max-width: 912px) {
  .link-text {
    font-size: 18px;
  }
  .text-box-white {
    /* layout */
    padding: 5px 40px 5px 40px;
  }
}

@media screen and (max-width: 800px) {
  /* section 1 */
  .anti-corruption-content {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .content-left-right {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .complaint-content {
    display: flex;
    flex-wrap: wrap;
  }
  .description-box {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .left-card {
    width: 100%;
    margin-right: 0px;
  }
  .right-card {
    width: 100%;
  }
  /* section 2 */
  .complaint-channels-title-box {
    /* font */
    font-size: 22px;
  }
  .right-card {
    margin-top: 20px;
  }
  .auditcommittee-description-image {
    left: -18px;
  }
  .text-box-white {
    /* layout */
    padding: 5px 70px 5px 30px;
  }

  @media screen and (max-width: 450px) {
    .text-box-white {
      /* layout */
      padding: 5px 30px 5px 30px;
    }
    .left-card {
      padding-right: 20px;
      padding-left: 20px;
    }
    .right-card {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}
</style>

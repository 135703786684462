<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  created () {}
}
</script>
<style>
.hidden {
  display: none;
}
.fade-in {
  /* REF https://developer.mozilla.org/en-US/docs/Web/CSS/animation-delay */
  /* ชื่อของ Animation ที่เป็น @keyframe */
  animation-name: fadeItIn;
  /* ระยะเวลาการเล่น Animation โดยจะสัมพันธ์กับ % ของ Keyframe */
  animation-duration: 1s;
  /* หน่วงเวลาเท่าไหร่จึงจะเล่น animation */
  animation-delay: 0s;
  /* mode ในการเล่น Animation */
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  opacity: 0;
  /* background-color: blue; */
}
.slide-in-fast {
  /* REF https://developer.mozilla.org/en-US/docs/Web/CSS/animation-delay */
  /* ชื่อของ Animation ที่เป็น @keyframe */
  animation-name: slideInFast;
  /* ระยะเวลาการเล่น Animation โดยจะสัมพันธ์กับ % ของ Keyframe */
  animation-duration: 1s;
  /* หน่วงเวลาเท่าไหร่จึงจะเล่น animation */
  animation-delay: 0s;
  /* mode ในการเล่น Animation */
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  opacity: 0;
  /* background-color: blue; */
}
.fade-img {
  animation-name: fadeInImg;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}
.fade-out {
  animation-name: fadeItOut;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}
.slide-in {
  /* REF https://developer.mozilla.org/en-US/docs/Web/CSS/animation-delay */
  /* ชื่อของ Animation ที่เป็น @keyframe */
  animation-name: slideIn;
  /* ระยะเวลาการเล่น Animation โดยจะสัมพันธ์กับ % ของ Keyframe */
  animation-duration: 0.5s;
  /* หน่วงเวลาเท่าไหร่จึงจะเล่น animation */
  animation-delay: 0s;
  /* mode ในการเล่น Animation */
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  opacity: 0;
  /* background-color: blue; */
}
.swal2-confirm {
  border-radius: 40px !important;
  font-size: 18px !important;
  padding-top: 7px !important;
  padding-bottom: 7px;
}
.swal2-cancel {
  border-radius: 40px !important;
  font-size: 18px !important;
  padding-top: 7px !important;
  padding-bottom: 7px;
}
@keyframes fadeInImg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fadeItIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
  }
}
@keyframes slideInFast {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeItOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    /* transform: translate(-100px); */
  }
}
/* *{
  color: var(--v-textPrimary-base) !important;
} */

</style>

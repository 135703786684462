var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"layout-all"},[_c('div',{staticClass:"layout-main-content"},[_c('div',{staticClass:"inet_w2-main-layout"},[_c('div',{staticClass:"title-content"},[_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_inet_w2_page, "title"))+" "),_c('div',[_c('v-img',{staticClass:"title-icon",attrs:{"src":require(`@/${_vm.item_inet_w2_page.img_dot}`)}})],1)])]),_c('div',[_vm._l((_vm.item_inet_w2_page.exercise_date),function(item_inet_data,i){return _c('div',{key:`${i}-item_inet_data`,staticClass:"block-content-section1"},[_c('div',{staticClass:"in-content-section1"},[_c('div',{staticClass:"left-area"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(item_inet_data, "title"))+" ")]),_c('div',{staticClass:"right-area"},_vm._l((_vm.FiltersLanguage(
                    item_inet_data,
                    'description'
                  )),function(item,ii){return _c('div',{key:`${ii}-item`},[_c('ul',[_c('li',[_vm._v(" "+_vm._s(item)+" ")])])])}),0)])])}),_c('hr',{staticClass:"hr-border"}),_c('div',{staticClass:"block-content-section1"},_vm._l((_vm.item_inet_w2_page.exercise_ratio_price),function(item_inet_data,i){return _c('div',{key:i,staticClass:"in-content-section1"},[_c('div',{staticClass:"left-area"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(item_inet_data, "title"))+" ")]),_vm._l((_vm.FiltersLanguage(
                  item_inet_data,
                  'description'
                )),function(iteme,i){return _c('div',{key:i,staticClass:"right-area"},[_c('ul',{staticClass:"d-flex flex-wrap"},[_c('li',{domProps:{"innerHTML":_vm._s(iteme)}},[_vm._v(" "+_vm._s(iteme)+" ")])])])})],2)}),0),_c('hr',{staticClass:"hr-border"}),_c('div',{staticClass:"block-content-section1"},_vm._l((_vm.item_inet_w2_page.notification_period),function(item_inet_data,i){return _c('div',{key:i,staticClass:"in-content-section1"},[_c('div',{staticClass:"left-area"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(item_inet_data, "title"))+" ")]),_c('div',{staticClass:"right-area"},_vm._l((_vm.FiltersLanguage(
                    item_inet_data,
                    'description'
                  )),function(iteme,i){return _c('div',{key:i},[_c('ul',[_c('li',{staticClass:"set-css-p",domProps:{"innerHTML":_vm._s(iteme)}},[_vm._v(" "+_vm._s(iteme)+" ")])])])}),0)])}),0),_c('hr',{staticClass:"hr-border"}),_vm._l((_vm.item_inet_w2_page.place_for_exercise_of_warrants),function(item_inet_data,i){return _c('div',{key:i,staticClass:"block-content-section1"},[_c('div',{staticClass:"in-content-section1"},[_c('div',{staticClass:"left-area"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(item_inet_data, "title"))+" ")]),_c('div',{staticClass:"right-area"},[(_vm.$vuetify.breakpoint.name !== 'xs')?_c('div',_vm._l((_vm.FiltersLanguage(
                      item_inet_data,
                      'description'
                    )),function(iteme,i){return _c('div',{key:i},[_c('ul',[_c('li',{domProps:{"innerHTML":_vm._s(iteme)}},[_vm._v(" "+_vm._s(iteme)+" ")])])])}),0):_vm._e(),(_vm.$vuetify.breakpoint.name === 'xs')?_c('div',_vm._l((_vm.FiltersLanguage(
                      item_inet_data,
                      'description_mobile'
                    )),function(iteme,i){return _c('div',{key:i},[_c('ul',[_c('li',{domProps:{"innerHTML":_vm._s(iteme)}},[_vm._v(" "+_vm._s(iteme)+" ")])])])}),0):_vm._e()])])])}),_c('hr',{staticClass:"hr-border"})],2)])]),_c('div',{staticClass:"layout-main-content"},[_c('div',{staticClass:"detail-card-left"},[_c('v-img',{staticClass:"size-image",attrs:{"src":require(`@/${_vm.item_inet_w2_page.img_title_detail}`)}},[_c('div',{staticClass:"title-box-section2"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_inet_w2_page, "title_detail"))+" ")])])],1),_c('div',{staticClass:"detail-card-right"},_vm._l((_vm.itemaTableINETW3),function(item_inet_data,i){return _c('div',{key:i,staticClass:"block-content-section2"},[_c('div',{staticStyle:{"width":"100%","display":"flex"}},[_c('div',{staticClass:"text-box-section2"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(item_inet_data, "title"))+" ")]),_c('div',[_c('a',{attrs:{"href":`${_vm.env}/api/v1/file/${_vm.FiltersLanguage(item_inet_data, 'file')}`,"target":"_blank"}},[_c('v-icon',{staticClass:"icon-download"},[_vm._v(" mdi-download ")])],1)])]),_c('div',{staticStyle:{"width":"100%"}},[_c('hr',{staticClass:"hr-border",style:(i + 1 === _vm.item_inet_w2_page.download_INET_W2.length
                  ? 'display:none'
                  : 'margin-top:15px; margin-bottom:15px;')})])])}),0)]),_c('div',{staticClass:"layout-remark"},[_c('div',{staticClass:"mb-5"},[_c('span',{staticClass:"title-remark"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_inet_w2_page, "remark_title"))+" ")]),_c('span',{staticClass:"description-remark"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_inet_w2_page, "description_remark"))+" ")]),_c('a',{staticClass:"link-remark",attrs:{"href":`${_vm.FiltersLanguage(_vm.item_inet_w2_page, 'link_remark')}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_inet_w2_page, "link_remark"))+" ")]),_vm._v(") ")]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"title-description-remark"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_inet_w2_page, "remark_title_section1"))+" ")]),_c('div',{staticClass:"description-remark",domProps:{"innerHTML":_vm._s(
            _vm.FiltersLanguage(_vm.item_inet_w2_page, 'description_remark_section1')
          )}})]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"title-description-remark"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_inet_w2_page, "remark_title_section2"))+" ")]),_c('a',{staticClass:"link-remark",attrs:{"href":`${_vm.FiltersLanguage(_vm.item_inet_w2_page, 'link_login')}`,"target":"_blank"}},[_c('u',[_vm._v("Login")])]),_c('span',{staticClass:"description-remark"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_inet_w2_page, "title_link_login"))+" ")]),_c('div',{staticClass:"description-remark",domProps:{"innerHTML":_vm._s(
            _vm.FiltersLanguage(_vm.item_inet_w2_page, 'description_remark_section2')
          )}})]),_c('div',[_c('div',{staticClass:"title-description-remark"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_inet_w2_page, "remark_title_section3"))+" ")]),_c('div',{staticClass:"description-remark",domProps:{"innerHTML":_vm._s(
            _vm.FiltersLanguage(_vm.item_inet_w2_page, 'description_remark_section3')
          )}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
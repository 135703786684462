<template>
    <v-navigation-drawer v-model="drawer" clipped app bottom width="324">
      <slot name="prepend"></slot>
      <v-list class="navigation">
        <router-link
          v-for="item in menus"
          :key="item.text"
          custom
          :to="item.to"
          v-slot="{ isActive, isExactActive }"
          :id="`menu-name-${item.text}`"
        >
          <!-- เมื่อไม่มี Sub items -->
          <v-list-item
            v-if="item.items.length == 0"
            :to="item.to"
            :class="[
              isActive && 'active',
              isExactActive && 'router-link-exact-active',
            ]"
            active-class="active-item"
          >
            <v-list-item-icon>
              <v-icon color="textNav">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="menu-text">
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>

          <!-- เมื่อมี sub items -->
          <v-list-group
            v-else
            :value="false"
            no-action
            :prepend-icon="item.icon"
            :class="[
              isActive && 'active-no-action',
              isExactActive && 'router-link-exact-active-no-action',
            ]"
          >
            <template v-slot:activator>
              <v-list-item-content
                :class="[
                  isActive && 'active-no-action',
                  isExactActive && 'router-link-exact-active-no-action',
                ]"
              >
                <v-list-item-title class="menu-text">{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <router-link
              v-for="item in item.items"
              :key="item.text"
              custom
              :to="item.to"
              v-slot="{ isActive, isExactActive }"
              :id="`menu-name-${item.text}`"
            >
              <v-list-item
                style="padding-left: 42px"
                :to="item.to"
                :class="[
                  isActive && 'active',
                  isExactActive && 'router-link-exact-active',
                ]"
                active-class="active-item"
              >
                <v-list-item-icon>
                  <v-icon color="textNav">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="menu-text">{{ item.text }}</v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-group>
        </router-link>
      </v-list>
      <template v-slot:append>
        <div class="px-1 py-1">
          <v-btn outlined block color="error" @click="logout">
            logout
          </v-btn>
          <!-- <v-btn icon
              ><v-icon class="btn-reset" @click="$emit('resetpassword')">mdi-lock-reset</v-icon></v-btn
            > -->
        </div>
      </template>
    </v-navigation-drawer>
  </template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    menus: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    drawer: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  methods: {
    logout () {
      // this.$websocket.Disconnect()
      // localStorage.clear()
      // this.$vuetify.theme.dark = false
      // this.$router.push({ name: 'loginPage' }).catch(() => {})
    }
  }
}
</script>

  <style lang="css" scoped>
  .active-item {
    border-right-style: solid;
    border-right-color: var(--v-textNav-base);
    background-color: var(--v-activeNav-base) !important;
  }
  .navigation .menu-text {
    font-size: 16px;
    font-weight: 400;
    color: var(--v-textNav-base) !important;
  }
  .theme--light.v-list-item--active:hover::before,
  .theme--light.v-list-item--active::before {
    opacity: 0 !important;
  }

  >>> .navigation .active-no-action {
    color: var(--v-textNav-base) !important;
  }

  ::v-deep .active-no-action .v-list-group__header .v-icon {
    color: var(--v-textNav-base) !important;
  }
  ::v-deep .active-no-action .v-list-group__header  {
    color: var(--v-textNav-base) !important;
  }
  .v-list-item--active .menu-text,
  .v-list-item--active .v-icon {
    color: var(--v-textNav-base) !important;
  }
>>> .v-list .v-list-item--active{
  color: var(--v-textNav-base) !important;
}
  .version-text {
    display: flex;
    font-size: 11px;
    font-weight: 300;
    justify-content: center;
    align-content: center;
  }
  </style>

<template>
  <v-app-bar app clipped-left elevation="3" class="bg-menu">
    <v-app-bar-nav-icon @click="drawer = !drawer" class="color-icon">
    </v-app-bar-nav-icon>
    <div class="mx-5">
      <v-img width="87" :src="require(`@/assets/images/logoInet.png`)"> </v-img>
    </div>
    <v-toolbar-title class="title">{{ title }}</v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    title: {
      type: String,
      default: 'Untitled'
    },
    value: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: 'Untitled'
    }
  },
  computed: {
    drawer: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  }
}
</script>

<style lang="css" scoped>
.title {
  font-family: "Kanit", sans-serif !important;
  font-size: 30.556px;
  font-style: normal;
  font-weight: 700;
  color: var(--v-textMenu-base);
  border-left: solid;
  padding-left: 17px;
}
.color-icon {
  color: var(--v-textMenu-base) !important;
}
.bg-menu {
  background: linear-gradient(
    360deg,
    var(--v-gradientToolbar1-base) 44.53%,
    var(--v-gradientToolbar2-base) 100%
  );
}
</style>

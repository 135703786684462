<template>
  <v-dialog
    v-model="dialog"
    max-width="1000px"
    transition="dialog-top-transition"
    persistent
  >
    <v-card class="layout-card">
      <div class="d-flex">
        <div class="ml-auto">
          <v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
        </div>
      </div>
      <div style="overflow-y: auto">
        <div class="d-flex content-announce">
          <div class="d-flex align-center">
            <v-btn
              icon
              color="#4F9F8C"
              @click="indexShow--"
              :disabled="indexShow === 0 ? true : false"
              ><v-icon size="45">mdi-arrow-left-circle-outline</v-icon></v-btn
            >
          </div>
          <div
            v-for="(item, index) in items"
            :key="index"
            v-show="index === indexShow"
            style="width: 100%"
          >
            <div class="px-5 output ql-snow" style="min-width: 300px">
              <div class="title-show">
                {{ FiltersLanguage(item, "title") }}
              </div>
              <div
                class="ql-editor"
                v-html="FiltersLanguage(item, 'description')"
              ></div>
            </div>
            <div class="px-5 page--text" style="text-align: center">
              {{ index + 1 }}/{{ items.length }}
            </div>
          </div>
          <div class="d-flex align-center">
            <v-btn
              icon
              color="#4F9F8C"
              @click="indexShow++"
              :disabled="indexShow + 1 === items.length ? true : false"
              ><v-icon size="45"> mdi-arrow-right-circle-outline</v-icon></v-btn
            >
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      indexShow: 0
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {},
  async created () {},
  methods: {
    close () {
      this.dialog = false
      this.$emit('close')
      this.indexShow = 0
      this.languageShow = 'th'
    }
  }
}
</script>

<style scoped lang="css">
.page--text {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}
.layout-card {
  padding: 30px 20px 30px 20px;
}
.content-announce {
  min-width: 400px;
  padding: 20px;
}
.title-show {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
</style>

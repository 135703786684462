<template>
    <div>
        <cpn-loading :show="loading" />
        <div v-if="!loading" class="pa-5">
            <div>
                old website : 3,900
            </div>
            <div>
                new website : {{ items.total }}
            </div>
            <div>
                All : {{ returncommaNumber(items.total+3900) }}
            </div>
        </div>

    </div>
</template>

<script>
export default {
  created () {
    this.init()
  },
  data () {
    return {
      loading: false,
      items: [],
      keyword: '',
      pagination: {
        total: 0,
        page: 1,
        row: 9999
      }
    }
  },
  methods: {
    async init () {
      this.items = []
      this.loading = true
      const payload = {
        keyword: this.keyword,
        page: this.pagination.page,
        row: this.pagination.row
      }
      const res = await this.getStats(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style lang="css" scoped>

</style>

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        loadingAll: '#4AC080',
        // menu
        textMenu: '#ffffff',
        hrMenu: '#FFFFFF',
        textNav: '#5D9E53',
        activeNav: '#EBFFED',

        textPrimary: '#000000',
        textSecond: '#ffffff',
        bgPrimary: '#ffffff',
        linerPrimary: '#000000',
        // homeInvestorRelations
        newsdate: '#8F8F8F',
        newstext: '#000',

        // Footer
        bgFooter: '#29282D',
        textFooter: '#ffffff',
        contactLine: '#B1E382',
        gradientFooter1: '#b1e382',
        gradientFooter2: '#5dc8d2',

        // Corporaeinformation
        // section core value
        textDetailCoreValue: '#231F20',
        dividerCoreValue: '#fff',
        // section services
        bgSectionServices: '#F6F6F6',
        // section structure
        btnClose: '#CA423A',
        // annaualReport56_1
        btnDownloadReport: '#343434',
        seeAllBtn: '#343434',
        downloadebook: '#3E8377',
        textDownloadebook: '#ffffff',
        bgcard: '#eee',
        bgSectionTo561: '#F6F6F6',
        gradientBgOneReport1: '#ADEF99',
        gradientBgOneReport2: '#67EDD6',
        btnBackPage: '#97E39C',
        // shareholderInformation
        bgHeader: '#EFEFEF',
        // tockinformation
        linerHeaderStock: '#8F8F8F',
        // contactPage
        LinerContact: '#BCE778',
        hoverLink: '#4AC080',
        btnMap: '#3C4D5C',
        // inetW2
        inetW2DetailCard: '#F6F6F6',
        titleSection2: '#343434',
        textSection2: '#000',
        mainIconDownloadInetW2: '#343434',
        linerContent: '#e7e7e7',
        // corporateGovernance
        bgGovernanacePolicy: '#EDEDED',
        tilteGovernanacePolicy: '#3c4d5c',
        verticalLineCorporate: '#d0d0d0',
        gradientUnderLine1: '#99EB7F',
        gradientUnderLine2: '#41E8CC',
        gradientBgCacEsg1: '#ffffff',
        gradientBgCacEsg2: '#EDEDED',
        // cacPage
        titleComplaintCacPage: '#3C4D5C',
        contentComplaintCacPage: '#EAEAEA',
        borderTextBoxWhith: '#B9BBBD',
        // managementTeam
        gradientBtnList1: '#98DB99',
        gradientBtnList2: '#6FCEC1',
        textMenuList: '#ffffff',
        hoverListText: '#86D5AB',
        hoverList: '#343434',
        backgroundItemDialog: '#D9D9D9',
        backgroundManagementTeam: '#F6F6F6',
        // financialInformation
        titleFinancial: '#343434',
        bgBoxFinancial: '#EEEEEE',
        btnViewMore: '#5DD2A6',
        iconDownload: '#343434',
        activeSelect: '#008363',
        bgTitleTable: '#3C4D5C',
        TitleOrigin: '#3F3E40',
        bgTitleOrigin: '#EEEEEE',
        bgHoverTitleOrigin: '#5A5A5A',
        hoverListItem1: '#A0DE92',
        hoverListItem2: '#70CFC0',
        hoverListItem3: '#CAF1C9',
        hoverListItem4: '#BAF1E4',
        hoverListItem5: '#DAF2DA',
        hoverListItem6: '#D0F1EA',

        sumValChart: '#30CA98',
        // component table
        loadingTable: '#4AC080',
        // esgPage
        lineESG: '#000',
        grayBoxESG: '#3C4D5C',
        // econnmyNews , environmentNew , societyNews
        newstextForESG: '#000',
        newsdateForESG: '#8F8F8F',
        // shareholdingStructure
        bgSectionTableTop10: '#F6F6F6',
        // ShareholderMeeting
        bgsection2_1: '#EDEDED',
        bgsection2_2: '#F5F5F5',
        title_now: '#033c5a',
        title_now_year: '#23AFB0',
        shadowText: '#ffffff',
        hoverIconDownload: '#ffffff',
        hrFile: '#000',
        bgHeaderFile: '#D9D9D9',
        bgFile: '#F5F5F5',
        // newsAll
        btnPaginationDefault: '#E4E4E4',
        PaginationText: '#000',
        newstextForInvestorRelationsNews: '#000',
        newsdateForInvestorRelationsNews: '#8F8F8F',
        disableBg: '#e4e4e4',
        textComingSoon: '#8F8F8F',
        // detail news
        colorDate: '#8F8F8F',
        hoverBtn: '#eeffe9',
        // group new
        borderListsNews: '#F5F5F5',
        bgListsNews: '#ffffff',
        gradientToolbar1: '#AEE3AD',
        gradientToolbar2: '#85D5D3',
        // admin page
        bgAdminPage1: '#4A99AA',
        bgAdminPage2: '#509F8A',
        primaryIconAdmin: '#4CC558',
        textPrimaryAdmin: '#000000',
        bgActiveAdmin: '#EBFFED',
        bgLogin: '#ffffff',
        textLoginbtn: '#ffffff',
        yesBtn: '#4CC558',
        cancleBtn: '#E74444',
        divider: '#D2D2D2',
        showStatus: '#36CE45',
        comingStatus: '#00B2FF',
        endStatus: '#FF8A1E',
        bgFileImg: '#E6E6E6',
        bgAddsection: '#F3F3F3',
        alertColor: '#00D1FF',
        // addmin page
        bgBtnResetPassword: '#EBF6EC',
        textBtnResetPassword: '#4CC558'
      },
      dark: {
        loadingAll: '#4AC080',
        // menu
        textMenu: '#ffffff',
        hrMenu: '#FFFFFF',
        textNav: '#5D9E53',
        activeNav: '#EBFFED',

        textPrimary: '#000000',
        textSecond: '#ffffff',
        bgPrimary: '#ffffff',
        linerPrimary: '#000000',
        // homeInvestorRelations
        newsdate: '#8F8F8F',
        newstext: '#000',

        // Footer
        bgFooter: '#29282D',
        textFooter: '#ffffff',
        contactLine: '#B1E382',
        gradientFooter1: '#b1e382',
        gradientFooter2: '#5dc8d2',

        // Corporaeinformation
        // section core value
        textDetailCoreValue: '#231F20',
        dividerCoreValue: '#fff',
        // section services
        bgSectionServices: '#F6F6F6',
        // section structure
        btnClose: '#CA423A',
        // annaualReport56_1
        btnDownloadReport: '#343434',
        seeAllBtn: '#343434',
        downloadebook: '#3E8377',
        textDownloadebook: '#ffffff',
        bgcard: '#eee',
        bgSectionTo561: '#F6F6F6',
        gradientBgOneReport1: '#ADEF99',
        gradientBgOneReport2: '#67EDD6',
        btnBackPage: '#97E39C',
        // shareholderInformation
        bgHeader: '#EFEFEF',
        // tockinformation
        linerHeaderStock: '#8F8F8F',
        // contactPage
        LinerContact: '#BCE778',
        hoverLink: '#4AC080',
        btnMap: '#3C4D5C',
        // inetW2
        inetW2DetailCard: '#F6F6F6',
        titleSection2: '#343434',
        textSection2: '#000',
        mainIconDownloadInetW2: '#343434',
        linerContent: '#e7e7e7',
        bgGovernanacePolicy: '#EDEDED',
        tilteGovernanacePolicy: '#3c4d5c',
        verticalLineCorporate: '#d0d0d0',
        gradientUnderLine1: '#99EB7F',
        gradientUnderLine2: '#41E8CC',
        gradientBgCacEsg1: '#ffffff',
        gradientBgCacEsg2: '#EDEDED',
        // cacPage
        titleComplaintCacPage: '#3C4D5C',
        contentComplaintCacPage: '#EAEAEA',
        borderTextBoxWhith: '#B9BBBD',
        // managementTeam
        gradientBtnList1: '#98DB99',
        gradientBtnList2: '#6FCEC1',
        textMenuList: '#ffffff',
        hoverListText: '#86D5AB',
        hoverList: '#343434',
        backgroundItemDialog: '#D9D9D9',
        backgroundManagementTeam: '#F6F6F6',
        // financialInformation
        titleFinancial: '#343434',
        bgBoxFinancial: '#EEEEEE',
        btnViewMore: '#5DD2A6',
        iconDownload: '#343434',
        activeSelect: '#008363',
        bgTitleTable: '#3C4D5C',
        TitleOrigin: '#3F3E40',
        bgTitleOrigin: '#EEEEEE',
        bgHoverTitleOrigin: '#5A5A5A',
        hoverListItem1: '#A0DE92',
        hoverListItem2: '#70CFC0',
        hoverListItem3: '#CAF1C9',
        hoverListItem4: '#BAF1E4',
        hoverListItem5: '#DAF2DA',
        hoverListItem6: '#D0F1EA',
        sumValChart: '#30CA98',
        // component table
        loadingTable: '#4AC080',
        // esgPage
        lineESG: '#000',
        grayBoxESG: '#3C4D5C',
        // econnmyNews , environmentNew , societyNews
        newstextForESG: '#000',
        newsdateForESG: '#8F8F8F',
        // shareholdingStructure
        bgSectionTableTop10: '#F6F6F6',
        // ShareholderMeeting
        bgsection2_1: '#EDEDED',
        bgsection2_2: '#F5F5F5',
        title_now: '#033c5a',
        title_now_year: '#23AFB0',
        shadowText: '#ffffff',
        hoverIconDownload: '#ffffff',
        hrFile: '#000',
        bgHeaderFile: '#D9D9D9',
        bgFile: '#F5F5F5',
        // newsAll
        btnPaginationDefault: '#E4E4E4',
        PaginationText: '#000',
        newstextForInvestorRelationsNews: '#000',
        newsdateForInvestorRelationsNews: '#8F8F8F',
        disableBg: '#e4e4e4',
        textComingSoon: '#8F8F8F',
        // detail news
        colorDate: '#8F8F8F',
        hoverBtn: '#eeffe9',
        // group new
        borderListsNews: '#F5F5F5',
        bgListsNews: '#ffffff',
        gradientToolbar1: '#AEE3AD',
        gradientToolbar2: '#85D5D3',
        bgAdminPage1: '#4A99AA',
        bgAdminPage2: '#509F8A',
        primaryIconAdmin: '#4CC558',
        textPrimaryAdmin: '#000000',
        bgActiveAdmin: '#EBFFED',
        bgLogin: '#ffffff',
        textLoginbtn: '#ffffff',
        yesBtn: '#4CC558',
        cancleBtn: '#E74444',
        divider: '#D2D2D2',
        showStatus: '#36CE45',
        comingStatus: '#00B2FF',
        endStatus: '#FF8A1E',
        bgFileImg: '#E6E6E6',
        bgAddsection: '#F3F3F3',
        alertColor: '#00D1FF',
        // addmin page
        bgBtnResetPassword: '#EBF6EC',
        textBtnResetPassword: '#4CC558'
      }
    }
  }
})

<template>
  <div>
    <div class="layout-comment-search">
      <v-spacer></v-spacer>
      <div class="box-text-field">
        <div>
          <v-text-field
            v-model="search"
            :append-icon="'mdi-magnify'"
            label="ค้นหาจากหัวข้อ"
            color="primaryIconAdmin"
          ></v-text-field>
        </div>
      </div>
    </div>
    <tableAdminAll
      ref="table"
      :headers="headers"
      :items="items"
      :item-key="itemKey"
      :pagination="pagination"
      @onChangePagination="changePagination"
      warpHeader
      showExpand
      :search="search"
      :height="$vuetify.breakpoint.height-345"
    >
      <template v-slot:[`item.number`]="{ item }">
        <span>
          {{
            pagination.row * pagination.page -
            pagination.row +
            (items.indexOf(item) + 1)
          }}
        </span>
      </template>
      <template v-slot:[`item.create_date`]="{ item }">
        {{ returnFormateDate(item.create_date, "th") }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <div class="layout-action">
          <v-btn icon
            ><v-icon
              class="btn-edit"
              @click="editFile(item, items.indexOf(item))"
              >mdi-pencil</v-icon
            ></v-btn
          >
          <v-btn icon @click="deleteFile(item, items.indexOf(item))"
            ><v-icon class="btn-delete">mdi-trash-can-outline</v-icon></v-btn
          >
          <v-btn icon
            ><v-icon
              class="btn-down"
              @click="$emit('addFileSub', items.indexOf(item))"
              >mdi-plus-circle</v-icon
            ></v-btn
          >
          <v-btn
            icon
            @click="changeIndex('down', items.indexOf(item))"
            :disabled="items.indexOf(item) + 1 === items.length"
            ><v-icon class="btn-down"
              >mdi-arrow-down-circle-outline</v-icon
            ></v-btn
          >
          <v-btn
            icon
            @click="changeIndex('up', items.indexOf(item))"
            :disabled="items.indexOf(item) === 0"
            ><v-icon class="btn-up">mdi-arrow-up-circle-outline</v-icon></v-btn
          >
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, itemAll }">
        <td :colspan="headers.length" style="background-color: #efffee">
          <!-- {{ item.items }} -->
          <div class="px-8">
            <v-data-table
              :headers="headersSub"
              :items="itemAll.items"
              hide-default-header
              hide-default-footer
            >
              <template v-slot:[`item.number`]="{ item }">
                <span>
                  {{ itemAll.items.indexOf(item) + 1 }}
                </span>
              </template>
              <template v-slot:[`item.file_th`]="{ item }">
                <a
                  :href="`${env}/api/v1/file/${item.file_th}`"
                  target="_blank"
                  >{{ item.file_th }}</a
                >
              </template>
              <template v-slot:[`item.file_eng`]="{ item }">
                <a
                  :href="`${env}/api/v1/file/${item.file_eng}`"
                  target="”_blank”"
                  >{{ item.file_eng }}</a
                >
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div class="layout-action">
                  <v-btn
                    icon
                    @click="
                      editFileSub(
                        item,
                        items.indexOf(itemAll),
                        itemAll.items.indexOf(item)
                      )
                    "
                    ><v-icon class="btn-edit">mdi-pencil</v-icon></v-btn
                  >
                  <v-btn
                    icon
                    @click="
                      deleteFileSub(
                        item,
                        items.indexOf(itemAll),
                        itemAll.items.indexOf(item)
                      )
                    "
                    ><v-icon class="btn-delete"
                      >mdi-trash-can-outline</v-icon
                    ></v-btn
                  >
                  <v-btn
                    icon
                    :disabled="
                      itemAll.items.indexOf(item) + 1 === itemAll.items.length
                    "
                    @click="
                      changeIndexSub(
                        'down',
                        items.indexOf(itemAll),
                        itemAll.items.indexOf(item)
                      )
                    "
                    ><v-icon class="btn-down"
                      >mdi-arrow-down-circle-outline</v-icon
                    ></v-btn
                  >
                  <v-btn
                    icon
                    :disabled="itemAll.items.indexOf(item) === 0"
                    @click="
                      changeIndexSub(
                        'up',
                        items.indexOf(itemAll),
                        itemAll.items.indexOf(item)
                      )
                    "
                    ><v-icon class="btn-up"
                      >mdi-arrow-up-circle-outline</v-icon
                    ></v-btn
                  >
                </div>
              </template>
            </v-data-table>
          </div>
        </td>
      </template>
    </tableAdminAll>
  </div>
</template>

<script>
import tableAdminAll from '../../../components/adminpage/tableAll/tableAdminAll.vue'
export default {
  components: {
    tableAdminAll
  },
  props: {
    pagination: {
      type: Object,
      default: () => {
        return { page: 1, row: 10, total: 0 }
      }
    },
    headers: {
      type: Array,
      default: () => []
    },
    paginationSub: {
      type: Object,
      default: () => {
        return { page: 1, row: 10, total: 0 }
      }
    },
    headersSub: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: 'index'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: '',
      env: ''
    }
  },
  created () {
    this.env = process.env.VUE_APP_API
  },
  methods: {
    changePagination (val) {
      this.$emit('changePagination', val)
    },
    editFile (item, index) {
      this.$emit('editFile', { item: item, index: index })
      this.$refs.table.closeAll()
    },
    changeIndex (status, index) {
      this.$emit('changeIndex', { status: status, index: index })
      this.$refs.table.closeAll()
    },
    deleteFile (item, index) {
      this.$emit('deleteFile', { item: item, index: index })
      this.$refs.table.closeAll()
    },
    deleteFileSub (item, indexMain, indexSub) {
      this.$emit('deleteFileSub', {
        item: item,
        indexMain: indexMain,
        indexSub: indexSub
      })
    },
    changeIndexSub (status, indexMain, indexData) {
      this.$emit('changeIndexSub', {
        status: status,
        indexMain: indexMain,
        index: indexData
      })
    },
    editFileSub (item, indexMain, indexSub) {
      this.$emit('editFileSub', {
        item: item,
        indexMain: indexMain,
        indexSub: indexSub
      })
    }
  }
}
</script>

<style scoped>
>>> table .v-data-table-header th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  min-width: 100px !important;
}
>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-top: 10px;
  padding-bottom: 10px;
}
.layout-comment-search {
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.layout-action {
  min-width: 180px;
}
.btn-edit {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-delete {
  color: var(--v-cancleBtn-base) !important;
}
.btn-down {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-up {
  color: var(--v-primaryIconAdmin-base) !important;
}
>>> .v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
@media screen and (max-width: 500px) {
  .layout-comment-search {
    flex-wrap: wrap;
  }
  .box-text-field {
    width: 100%;
  }
}
</style>

<template>
    <v-dialog
      v-model="dialog"
      max-width="700px"
      transition="dialog-top-transition"
      persistent
    >
      <v-card class="layout-card">
        <v-form ref="fromUser">
          <div class="from-persion-card">
            <div class="header-title">
              <v-icon class="icon-header">{{ iconTitle }}</v-icon
              >{{ titleHeader }}
            </div>
            <v-spacer></v-spacer>
            <div>
              <v-btn icon @click="close()"> <v-icon>mdi-close</v-icon></v-btn>
            </div>
          </div>
          <hr class="hr-header" />
          <div class="from-layout">
            <div class="two-colum">
              <div class="title-text-field">ชื่อ</div>
              <v-text-field
                v-model="itemForm.first_name"
                placeholder="กรุณากรอกชื่อ"
                outlined
                dense
                color="primaryIconAdmin"
                :rules="[Rule.noInPut, Rule.notNull]"
                :loading="loading"
                :disabled="loading"
              ></v-text-field>
            </div>
            <div class="two-colum">
              <div class="title-text-field">นามสกุล</div>
              <v-text-field
                v-model="itemForm.last_name"
                placeholder="กรุณากรอกนามสกุล"
                outlined
                dense
                color="primaryIconAdmin"
                :rules="[Rule.noInPut, Rule.notNull]"
                :loading="loading"
                :disabled="loading"
              ></v-text-field>
            </div>
            <div class="two-colum">
              <div class="title-text-field">ชื่อผู้ใช้งาน (E-mail)</div>
              <v-text-field
                v-model="itemForm.username"
                placeholder="กรุณากรอกอีเมล"
                outlined
                dense
                color="primaryIconAdmin"
                :rules="[Rule.noInPut, Rule.englishOnly, Rule.emailOnly]"
                :loading="loading"
                :disabled="loading"
              ></v-text-field>
            </div>
            <div class="two-colum">
              <div class="title-text-field">สิทธิ</div>
              <v-select
                v-model="itemForm.permissions[0]"
                :items="itemPermission"
                item-text="name"
                item-value="permission_id"
                placeholder="กรุณาเลือกสิทธิ"
                outlined
                dense
                color="primaryIconAdmin"
                item-color="primaryIconAdmin"
                :rules="[Rule.noInPut, Rule.notNull]"
                :loading="loading || loadingItemPermission"
                :disabled="loading || loadingItemPermission"
              ></v-select>
            </div>
            <!-- <div v-if="status === 'edit'" class="one-colum">
                <v-btn class="btn-reset-password" elevation="0" rounded @click="$emit('resetPassword',itemForm.username)"><v-icon>mdi-pencil</v-icon>แก้ไขรหัสผ่าน</v-btn>
                <div style="font-size: 18px;">*แบบฟอร์มแก้ไขรหัสผ่านจะถูกส่งไปยังอีเมลผู้ใช้งาน</div>
            </div> -->
          </div>
          <div class="layout-btn-record-cancle">
            <div class="px-1">
              <v-btn
                elevation="0"
                class="btn-add mb-2"
                rounded
                @click="validateFrom()"
                :loading="loading"
                >บันทึก</v-btn
              >
            </div>
            <div class="px-1">
              <v-btn
                elevation="0"
                class="btn-cancle mb-2"
                rounded
                @click="close()"
                :loading="loading"
                >ยกเลิก</v-btn
              >
            </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </template>

<script>
export default {
  components: { },
  data () {
    return {
      env: '',
      itemForm: {
        name_title: '',
        first_name: '',
        last_name: '',
        phone: '',
        gender: '',
        username: '',
        permissions: [],
        image: '',
        email_verify: '',
        date_of_birth: '',
        street: '',
        zip_code: '',
        city: '',
        country_code: '',
        country: '',
        time_zone: '',
        description: ''
      }
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    iconTitle: {
      type: String,
      default: ''
    },
    titleHeader: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: 'add'
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingItemPermission: {
      type: Boolean,
      default: false
    },
    itemPermission: {
      type: Array,
      default: () => []
    },
    dataFromUser: {
      type: Object,
      default: () => {
        return {
          name_title: '',
          first_name: '',
          last_name: '',
          phone: '',
          gender: '',
          username: '',
          permissions: [],
          image: '',
          email_verify: '',
          date_of_birth: '',
          street: '',
          zip_code: '',
          city: '',
          country_code: '',
          country: '',
          time_zone: '',
          description: ''
        }
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    dialog: {
      handler: function (newValue) {
        if (newValue === true && this.status === 'edit') {
          this.itemForm = JSON.parse(JSON.stringify(this.dataFromUser))
          this.itemForm.username = this.itemForm.methods[0].username
        }
      },
      deep: true
    }
  },
  async created () {
    this.env = process.env.VUE_APP_API
  },
  methods: {
    close () {
      this.dialog = false
      this.$emit('resetForm')
      this.$refs.fromUser.reset()
      this.itemForm = {
        name_title: '',
        first_name: '',
        last_name: '',
        phone: '',
        gender: '',
        username: '',
        permissions: [],
        image: '',
        email_verify: '',
        date_of_birth: '',
        street: '',
        zip_code: '',
        city: '',
        country_code: '',
        country: '',
        time_zone: '',
        description: ''
      }
    },

    validateFrom () {
      if (
        this.$refs.fromUser.validate()) {
        if (this.status === 'add') {
          // TODO Update Data
          this.$swal({
            allowEnterKey: false,
            title: 'เพิ่มผู้ใช้งาน',
            text: 'คุณต้องการเพิ่มบัญชีผู้ใช้งานใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#E74444',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#4CC558'
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('addAndEdit', {
                status: 'add',
                item: this.itemForm
              })
            }
          })
        } else if (this.status === 'edit') {
          this.$swal({
            allowEnterKey: false,
            title: 'แก้ไขผู้ใช้งาน',
            text: 'คุณต้องการแก้ไขบัญชีผู้ใช้งานใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#E74444',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#4CC558'
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('addAndEdit', {
                status: 'edit',
                item: this.itemForm
              })
            }
          })
        }
      }
    }

  }
}
</script>

  <style scoped lang="css">
  >>> .v-text-field--enclosed.v-input--dense:not(
      .v-text-field--solo
    ).v-text-field--outlined
    .v-input__prepend-outer {
    display: none !important;
  }
  .btn-reset-password{
    color: var(--v-textBtnResetPassword-base) !important;
    background-color: var(--v-bgBtnResetPassword-base) !important;
    font-size: 16px;
  }
  .layout-card {
    padding-bottom: 30px;
  }
  .v-input--selection-controls {
    margin-top: 5px;
    padding-top: 4px;
  }
  .btn-add {
    color: var(--v-textLoginbtn-base) !important;
    background-color: var(--v-primaryIconAdmin-base) !important;
    font-size: 16px;
  }
  .btn-cancle {
    color: var(--v-textLoginbtn-base) !important;
    background-color: var(--v-cancleBtn-base) !important;
    font-size: 16px;
  }
  .from-persion-card {
    display: flex;
    height: 100%;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .from-layout {
    height: 100%;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
  }
  .header-title {
    color: var(--v-primaryIconAdmin-base) !important;
    font-size: 24px;
    font-weight: 600;
  }
  .icon-header {
    color: var(--v-primaryIconAdmin-base) !important;
    font-size: 24px;
    margin-right: 10px;
  }

  .hr-header {
    color: var(--v-primaryIconAdmin-base) !important;
  }
  .comment-img {
    color: var(--v-textPrimaryAdmin-base) !important;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }
  .title-text-field {
    font-size: 16px;
  }
  .layout-two-colum {
    width: 100%;
    display: flex;
  }
  .layout-file-img {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
  .box-show-img {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .width-img {
    /* max-height: 250px;
        max-width: 250px; */
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .bg-file-img {
    background-color: var(--v-bgFileImg-base);
    height: 250px;
    width: 250px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #adadad;
  }
  .file-input {
    width: 50%;
  }
  .two-colum {
    width: 50%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .one-colum {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .layout-add-positions {
    display: flex;
    widows: 100%;
    flex-wrap: wrap;
    background-color: var(--v-bgAddsection-base);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .layout-add-educationals {
    display: flex;
    widows: 100%;
    flex-wrap: wrap;
    background-color: var(--v-bgAddsection-base);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .layout-btn-record-cancle {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .layout-two-btn {
    width: 200px;
    display: flex;
  }
  .btn-delete {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .title-year {
    font-size: 22px;
    font-weight: 600;
    color: var(--v-textPrimaryAdmin-base) !important;
    text-align: center;
    margin-top: 20px;
  }

  .border-validate {
    border: #ff6363 solid 2px;
  }
  .border-validate-none {
    border: unset;
  }
  .text-validate {
    font-size: 12px;
    color: var(--v-error-base);
    padding-left: 12px;
  }
  @media screen and (max-width: 650px) {
    .from-layout {
      padding-left: 20px;
      padding-right: 20px;
    }
    .file-input {
      width: 100%;
    }
    .layout-two-colum {
      flex-wrap: wrap;
    }
    .two-colum {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
    .one-colum {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  </style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="700px"
    transition="dialog-top-transition"
    persistent
  >
    <v-card>
      <div class="from-persion-card">
        <div class="header-title">
          <v-icon class="icon-header">{{ iconTitle }}</v-icon
          >{{ titleHeader }}
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn icon @click="close()"> <v-icon>mdi-close</v-icon></v-btn>
        </div>
      </div>
      <hr class="hr-header" />
      <div class="from-layout">
        <v-form ref="formPersionTeam">
          <div class="layout-file-img">
            <div class="box-show-img">
              <div class="bg-file-img">
                <v-img
                  class="width-img"
                  v-if="
                    data_profile.profile.image !== '' &&
                    data_profile.profile.image !== null
                  "
                  :src="`${env}/api/v1/file/${data_profile.profile.image}`"
                ></v-img>
              </div>
            </div>
            <div class="file-input">
              <template>
                <div
                  v-if="
                    status === 'edit' && oldItem.profile.image !== undefined
                  "
                >
                  ไฟล์เก่า :
                  <a
                    :href="`${env}/api/v1/file/${oldItem.profile.image}`"
                    target="_blank"
                    >{{ oldItem.profile.image }}</a
                  >
                  *เลือกไฟล์หากต้องการเปลี่ยน
                </div>
                <v-file-input
                  v-model="file_image"
                  accept="image/*"
                  placeholder="กรุณาเลือกรูป"
                  outlined
                  dense
                  append-icon="mdi-paperclip"
                  color="primaryIconAdmin"
                  :rules="status !== 'edit' ? [Rule.noInPut, Rule.notNull] : []"
                  :loading="loadingFileImage"
                  @change="uploadFile"
                ></v-file-input>
              </template>
            </div>
          </div>
          <div class="comment-img">*ขนาดรูป 612x612 px</div>
          <div class="layout-two-colum">
            <div class="two-colum">
              <div class="title-text-field">(คำนำหน้าถ้ามี) + ชื่อจริง ไทย</div>
              <div class="box-text-field">
                <v-text-field
                  v-model="data_profile.profile.firstname_th"
                  placeholder="กรุณากรอกชื่อจริง"
                  outlined
                  dense
                  color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
            <div class="two-colum">
              <div class="title-text-field">นามสกุล + (คำต่อท้ายถ้ามี) ไทย</div>
              <div class="box-text-field">
                <v-text-field
                  v-model="data_profile.profile.lastname_th"
                  placeholder="กรุณากรอกนามสกุล"
                  outlined
                  dense
                  color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="layout-two-colum">
            <div class="two-colum">
              <div class="title-text-field">
                (คำนำหน้าถ้ามี) + ชื่อจริง อังกฤษ
              </div>
              <div class="box-text-field">
                <v-text-field
                  v-model="data_profile.profile.firstname_eng"
                  placeholder="กรุณากรอกชื่อจริง"
                  outlined
                  dense
                  color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
            <div class="two-colum">
              <div class="title-text-field">
                นามสกุล + (คำต่อท้ายถ้ามี) อังกฤษ
              </div>
              <div class="box-text-field">
                <v-text-field
                  v-model="data_profile.profile.lastname_eng"
                  placeholder="กรุณากรอกนามสกุล"
                  outlined
                  dense
                  color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="layout-two-colum">
            <div class="two-colum">
              <div class="title-text-field">วันเกิด</div>
              <DatePicker
                v-model="data_profile.profile.date_of_birth"
                :max="max_end_date"
                placeholder="กรุณาเลือกวันที่"
                :rules="[Rule.noInPut, Rule.notNull]"
                color="green"
              />
            </div>
            <div class="two-colum">
              <div class="title-text-field">
                วันที่ได้รับแต่งตั้งเป็นกรรมการ
              </div>
              <div class="box-text-field">
                <DatePicker
                  v-model="date_positions"
                  :max="max_end_date"
                  placeholder="กรุณาเลือกวันที่"
                  :rules="[Rule.noInPut, Rule.notNull]"
                  color="green"
                />
              </div>
            </div>
          </div>
          <div>
            <v-btn
              @click="pushPosition()"
              elevation="0"
              class="btn-add mb-2"
              rounded
              ><v-icon>mdi-plus</v-icon>เพิ่มตำแหน่ง</v-btn
            >
          </div>
          <div
            class="layout-add-positions"
            v-for="(item, index) in data_profile.positions"
            :key="index"
          >
            <div class="btn-delete">
              <v-btn
                icon
                color="cancleBtn"
                @click="deletePosition(index)"
                :disabled="data_profile.positions.length === 1 ? true : false"
                ><v-icon>mdi-trash-can-outline</v-icon></v-btn
              >
            </div>
            <div class="one-colum">
              <div class="title-text-field">ชื่อตำแหน่ง ไทย</div>
              <div class="box-text-field">
                <v-text-field
                  v-model="item.title_th"
                  placeholder="กรุณากรอกตำแหน่ง"
                  outlined
                  dense
                  color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
            <div class="one-colum">
              <div class="title-text-field">ชื่อตำแหน่ง อังกฤษ</div>
              <div class="box-text-field">
                <v-text-field
                  v-model="item.title_eng"
                  placeholder="กรุณากรอกตำแหน่ง"
                  outlined
                  dense
                  color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
            <div class="one-colum">
              <div class="title-text-field">
                เลือกแสดงหมวดหมู่ (เลือกได้หลายหมวดหมู่)
              </div>
              <div class="box-text-field">
                <v-select
                  v-model="item.position_tags"
                  :items="itemsPositions"
                  item-text="text"
                  item-value="value"
                  placeholder="กรุณาเลือกหมวดหมู่"
                  outlined
                  dense
                  chips
                  clearable
                  multiple
                  color="primaryIconAdmin"
                  item-color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-select>
              </div>
            </div>
          </div>
          <div>
            <v-btn
              @click="pushEducationals()"
              elevation="0"
              class="btn-add mb-2"
              rounded
              ><v-icon>mdi-plus</v-icon>เพิ่มวุฒิการศึกษา</v-btn
            >
          </div>
          <div
            class="layout-add-educationals"
            v-for="(items, index1) in data_profile.profile.educationals"
            :key="index1 + 'edu'"
          >
            <div class="btn-delete">
              <v-btn
                icon
                color="cancleBtn"
                @click="deleteEducationals(index1)"
                :disabled="
                  data_profile.profile.educationals.length === 1 ? true : false
                "
                ><v-icon>mdi-trash-can-outline</v-icon></v-btn
              >
            </div>
            <div class="one-colum">
              <div class="title-text-field">วุฒิการศึกษา ไทย</div>
              <div class="box-text-field">
                <v-text-field
                  v-model="items.title_th"
                  placeholder="กรุณากรอกวุฒิการศึกษา"
                  outlined
                  dense
                  color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
            <div class="one-colum">
              <div class="title-text-field">วุฒิการศึกษา อังกฤษ</div>
              <div class="box-text-field">
                <v-text-field
                  v-model="items.title_eng"
                  placeholder="กรุณากรอกวุฒิการศึกษา"
                  outlined
                  dense
                  color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="layout-two-colum">
            <div class="two-colum">
              <div class="title-text-field">จำนวนหุ้นที่ถือหุ้นในบริษัท</div>
              <div class="box-text-field">
                <v-text-field
                  v-model="data_profile.stock.volume"
                  placeholder="กรุณากรอกจำนวนหุ้นที่ถือหุ้นในบริษัท"
                  outlined
                  dense
                  color="primaryIconAdmin"
                  :rules="[Rule.notNull, Rule.countingFloatOnly]"
                  @blur="validateFloatVolume()"
                ></v-text-field>
              </div>
            </div>
            <div class="two-colum">
              <div class="title-text-field">สัดส่วนการถือหุ้นในบริษัท</div>
              <div class="box-text-field">
                <v-text-field
                  v-model="data_profile.stock.ratio"
                  placeholder="กรุณากรอกสัดส่วนการถือหุ้นในบริษัท"
                  outlined
                  dense
                  color="primaryIconAdmin"
                  :rules="[Rule.notNull, Rule.countingFloatOnly]"
                  @blur="validateFloatRatio()"
                ></v-text-field>
              </div>
            </div>
          </div>
        </v-form>
        <div class="one-colum">
          <div class="title-text-field">
            ความสัมพันธ์ทางครอบครัวระหว่างกรรมการและผู้บริหาร
          </div>
          <div>
            <v-radio-group
              v-model="data_profile.profile.relationship.executive"
              row
            >
              <v-radio
                label="มี"
                :value="true"
                color="primaryIconAdmin"
              ></v-radio>
              <v-radio
                label="ไม่มี"
                :value="false"
                color="primaryIconAdmin"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>

        <div class="layout-btn-record-cancle">
          <div class="layout-two-btn">
            <v-btn
              elevation="0"
              class="btn-add mb-2"
              rounded
              @click="validateFrom()"
              >บันทึก</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              class="btn-cancle mb-2"
              rounded
              @click="close()"
              >ยกเลิก</v-btn
            >
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
// import DatePicker from '../../../components/adminpage/selectDatePicker_th.vue'
import DatePicker from '../../../components/datepicker_th.vue'
export default {
  components: {
    DatePicker
  },
  data () {
    return {
      env: '',
      signInData: {
        username: ''
      },
      editedIndex: 1,
      NameComponent: 'เปลี่ยนรหัสผ่าน',
      appointment_date: '',
      max_end_date: '',
      date_positions: '',
      file_image: null,
      oldItem: {
        profile: {
          firstname_th: '',
          firstname_eng: '',
          lastname_th: '',
          lastname_eng: '',
          date_of_birth: '',
          image: '',
          educationals: [
            {
              title_eng: '',
              title_th: '',
              start_date: '',
              end_date: '',
              description_eng: '',
              description_th: ''
            }
          ],
          relationship: {
            executive: false
          }
        },
        group_tags: [],
        positions: [
          {
            title_eng: '',
            title_th: '',
            description_eng: '',
            description_th: '',
            start_date: '',
            end_date: '',
            position_tags: []
          }
        ],
        stock: {
          volume: 0,
          ratio: 0
        },
        status: true
      },
      data_profile: {
        profile: {
          firstname_th: '',
          firstname_eng: '',
          lastname_th: '',
          lastname_eng: '',
          date_of_birth: '',
          image: '',
          educationals: [
            {
              title_eng: '',
              title_th: '',
              start_date: '',
              end_date: '',
              description_eng: '',
              description_th: ''
            }
          ],
          relationship: {
            executive: false
          }
        },
        group_tags: [],
        positions: [
          {
            title_eng: '',
            title_th: '',
            description_eng: '',
            description_th: '',
            start_date: '',
            end_date: '',
            position_tags: []
          }
        ],
        stock: {
          volume: 0,
          ratio: 0
        },
        status: true
      },
      default_data_profile: {
        profile: {
          firstname_th: '',
          firstname_eng: '',
          lastname_th: '',
          lastname_eng: '',
          date_of_birth: '',
          image: '',
          educationals: [
            {
              title_eng: '',
              title_th: '',
              start_date: '',
              end_date: '',
              description_eng: '',
              description_th: ''
            }
          ],
          relationship: {
            executive: false
          }
        },
        group_tags: [],
        positions: [
          {
            title_eng: '',
            title_th: '',
            description_eng: '',
            description_th: '',
            start_date: '',
            end_date: '',
            position_tags: []
          }
        ],
        stock: {
          volume: 0,
          ratio: 0
        },
        status: true
      },
      itemsPositions: [
        { text: 'คณะกรรมการบริษัท', value: 'BoardOfDirectors' },
        { text: 'คณะกรรมการบริหาร', value: 'TheExecutiveCommittee' },
        { text: 'คณะกรรมการตรวจสอบ', value: 'TheAuditCommittee' },
        {
          text: 'คณะกรรมการสรรหา กำหนดค่าตอบแทน และกำกับดูแลกิจการ',
          value: 'TheNominationRemunerationAndCorporateGovernanceCommittee'
        },
        {
          text: 'คณะกรรมการการบริหารความเสี่ยง',
          value: 'TheRiskManagementCommittee'
        },
        {
          text: 'คณะกรรมการประเมินผลการปฏิบัติงานของกรรมการผู้จัดการ',
          value: 'TheCEOPerformanceReviewCommittee'
        },
        { text: ' ผู้บริหารบริษัท', value: 'ManagementTeam' }
      ]
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    iconTitle: {
      type: String,
      default: ''
    },
    titleHeader: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: 'add'
    },
    loadingFileImage: {
      type: Boolean,
      default: false
    },
    dataProfile: {
      type: Object,
      default: () => {
        return {
          profile: {
            firstname_th: '',
            firstname_eng: '',
            lastname_th: '',
            lastname_eng: '',
            date_of_birth: '',
            image: '',
            educationals: [
              {
                title_eng: '',
                title_th: '',
                start_date: '',
                end_date: '',
                description_eng: '',
                description_th: ''
              }
            ],
            relationship: {
              executive: false
            }
          },
          group_tags: [],
          positions: [
            {
              title_eng: '',
              title_th: '',
              description_eng: '',
              description_th: '',
              start_date: '',
              end_date: '',
              position_tags: []
            }
          ],
          stock: {
            volume: 0,
            ratio: 0
          },
          status: true
        }
      }
    }
  },
  watch: {
    dataProfile: {
      handler: function (newValue) {
        if (this.dialog === true) {
          this.data_profile.profile.image = newValue.profile.image
        }
      },
      deep: true
    },
    dialog: {
      handler: function (newValue) {
        if (newValue === true && this.status === 'edit') {
          this.oldItem = JSON.parse(JSON.stringify(this.dataProfile))
          this.data_profile = JSON.parse(JSON.stringify(this.dataProfile))
          this.data_profile.profile.date_of_birth = this.$moment(
            this.data_profile.profile.date_of_birth
          ).format('YYYY-MM-DD')
          if (
            this.data_profile.positions[0].start_date !== '' &&
            this.data_profile.positions[0].start_date !== null
          ) {
            this.date_positions = this.$moment(
              this.data_profile.positions[0].start_date
            ).format('YYYY-MM-DD')
          }
        } else if (newValue === true && this.status === 'add') {
          this.data_profile.stock.volume = '0'
          this.data_profile.stock.ratio = '0'
        }
      },
      deep: true
    }
  },
  async created () {
    this.env = process.env.VUE_APP_API
    this.max_end_date = this.$moment().format('YYYY-MM-DD')
  },
  methods: {
    validateFloatVolume () {
      if (
        this.data_profile.stock.volume !== '' &&
        this.data_profile.stock.volume !== null &&
        this.data_profile.stock.volume !== undefined
      ) {
        const val = this.data_profile.stock.volume
        const array = val.toString().split('.')
        if (array.length <= 2) {
          if (array.length === 1) {
            this.data_profile.stock.volume = parseInt(
              this.data_profile.stock.volume
            )
          } else {
            const valNew = this.data_profile.stock.volume
            const parts = valNew.toString().split('.')
            if (
              parts[1] !== '' &&
              parts[1] !== null &&
              parts[1] !== undefined
            ) {
              // numberPart
              let numberPart = 0
              if (
                parts[0] === '' ||
                parts[0] === null ||
                parts[0] === undefined
              ) {
                numberPart = 0
              } else {
                numberPart = parseInt(parts[0]).toString()
              }

              // decimalPart
              const decimalPart = parseFloat('0.' + parts[1]).toString()
              const newDecimal = decimalPart.toString().split('.')
              this.data_profile.stock.volume = numberPart + '.' + newDecimal[1]
            }
          }
        }
      }
    },
    validateFloatRatio () {
      if (
        this.data_profile.stock.ratio !== '' &&
        this.data_profile.stock.ratio !== null &&
        this.data_profile.stock.ratio !== undefined
      ) {
        const val = this.data_profile.stock.ratio
        const array = val.toString().split('.')
        if (array.length <= 2) {
          if (array.length === 1) {
            this.data_profile.stock.ratio = parseInt(
              this.data_profile.stock.ratio
            )
          } else {
            const valNew = this.data_profile.stock.ratio
            const parts = valNew.toString().split('.')
            if (
              parts[1] !== '' &&
              parts[1] !== null &&
              parts[1] !== undefined
            ) {
              // numberPart
              let numberPart = 0
              if (
                parts[0] === '' ||
                parts[0] === null ||
                parts[0] === undefined
              ) {
                numberPart = 0
              } else {
                numberPart = parseInt(parts[0]).toString()
              }
              // decimalPart
              const decimalPart = parseFloat('0.' + parts[1]).toString()
              const newDecimal = decimalPart.toString().split('.')
              this.data_profile.stock.ratio = numberPart + '.' + newDecimal[1]
            }
          }
        }
      }
    },
    close () {
      this.$refs.formPersionTeam.reset()
      this.file_image = null
      this.dialog = false
      this.data_profile = JSON.parse(JSON.stringify(this.default_data_profile))
      this.date_positions = ''
    },
    uploadFile () {
      if (this.status === 'edit' && this.file_image === null) {
        this.data_profile.profile.image = this.oldItem.profile.image
      } else {
        this.$emit('uploadFile', {
          file: this.file_image
        })
      }
    },
    validateFrom () {
      if (this.$refs.formPersionTeam.validate()) {
        if (this.status === 'add') {
          // TODO Update Data
          this.$swal({
            allowEnterKey: false,
            title: 'เพิ่มบุคคล',
            text: 'คุณต้องการเพิ่มบุคคลนี้ใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#E74444',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#4CC558'
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('addAndEdit', {
                status: 'add',
                data: this.data_profile,
                date_positions: this.date_positions
              })
            }
          })
        } else if (this.status === 'edit') {
          this.$swal({
            allowEnterKey: false,
            title: 'แก้ไขข้อมูลบุคคล',
            text: 'คุณต้องการแก้ไขข้อมูลบุคคลนี้ใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#E74444',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#4CC558'
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('addAndEdit', {
                status: 'edit',
                data: this.data_profile,
                date_positions: this.date_positions
              })
            }
          })
        }
      }
    },
    pushPosition () {
      this.data_profile.positions.push({
        title_eng: '',
        title_th: '',
        description_eng: '',
        description_th: '',
        start_date: '',
        end_date: '',
        position_tags: []
      })
    },
    deletePosition (index) {
      if (index > -1) {
        this.data_profile.positions.splice(index, 1)
      }
    },
    pushEducationals () {
      this.data_profile.profile.educationals.push({
        title_eng: '',
        title_th: '',
        start_date: '',
        end_date: '',
        description_eng: '',
        description_th: ''
      })
    },
    deleteEducationals (index) {
      if (index > -1) {
        this.data_profile.profile.educationals.splice(index, 1)
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="css">
>>> .v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__prepend-outer {
  display: none !important;
}
.v-input--selection-controls {
  margin-top: 5px;
  padding-top: 4px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
.btn-cancle {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-cancleBtn-base) !important;
  font-size: 16px;
}
.from-persion-card {
  display: flex;
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.from-layout {
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.header-title {
  color: var(--v-primaryIconAdmin-base) !important;
  font-size: 24px;
  font-weight: 600;
}
.icon-header {
  color: var(--v-primaryIconAdmin-base) !important;
  font-size: 24px;
  margin-right: 10px;
}

.hr-header {
  color: var(--v-primaryIconAdmin-base) !important;
}
.comment-img {
  color: var(--v-textPrimaryAdmin-base) !important;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}
.title-text-field {
  font-size: 16px;
}
.layout-two-colum {
  width: 100%;
  display: flex;
}
.layout-file-img {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.box-show-img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.width-img {
  /* max-height: 250px;
  max-width: 250px; */
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.bg-file-img {
  background-color: var(--v-bgFileImg-base);
  height: 250px;
  width: 250px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #adadad;
}
.file-input {
  width: 50%;
}
.two-colum {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}
.one-colum {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.layout-add-positions {
  display: flex;
  widows: 100%;
  flex-wrap: wrap;
  background-color: var(--v-bgAddsection-base);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.layout-add-educationals {
  display: flex;
  widows: 100%;
  flex-wrap: wrap;
  background-color: var(--v-bgAddsection-base);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.layout-btn-record-cancle {
  width: 100%;
  display: flex;
  justify-content: center;
}
.layout-two-btn {
  width: 200px;
  display: flex;
}
.btn-delete {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

@media screen and (max-width: 650px) {
  .from-layout {
    padding-left: 20px;
    padding-right: 20px;
  }
  .file-input {
    width: 100%;
  }
  .layout-two-colum {
    flex-wrap: wrap;
  }
  .two-colum {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .one-colum {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"layout-all"},[_c('div',{staticClass:"contactPage-content"},[_c('div',{staticClass:"title-content"},[_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page, "title"))+" "),_c('div',[_c('v-img',{staticClass:"title-icon",attrs:{"src":require(`@/${_vm.item_contact_page.img_dot}`)}})],1)])]),_c('hr',{staticClass:"hr-border"}),_c('div',{staticClass:"middle-content"},[_c('div',{staticClass:"content-area-1"},[_c('v-img',{staticClass:"img_content slide-in",attrs:{"contain":"","src":require(`@/${_vm.item_contact_page.img_building}`)}})],1),_c('div',{staticClass:"content-area-2"},[_c('div',[_c('div',{staticClass:"contact-title"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page, "name_company"))+" ")]),_c('v-divider',{staticClass:"contact-line my-3"}),_c('div',{staticClass:"contact-text"},[(_vm.$vuetify.breakpoint.name !== 'xs'&&_vm.$vuetify.breakpoint.name !== 'sm'&&_vm.$vuetify.breakpoint.name !== 'md')?_c('div',{staticClass:"layuot-address",domProps:{"innerHTML":_vm._s(_vm.FiltersLanguage(_vm.item_contact_page, 'address_company'))}}):_vm._e(),(_vm.$vuetify.breakpoint.name === 'xs'||_vm.$vuetify.breakpoint.name === 'sm'||_vm.$vuetify.breakpoint.name === 'md')?_c('div',{staticClass:"layuot-address",domProps:{"innerHTML":_vm._s( _vm.FiltersLanguage(_vm.item_contact_page, 'address_company_mobile'))}}):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.phone, "title"))+" "),_c('a',{staticClass:"link-text",attrs:{"href":`tel:${_vm.FiltersLanguage(
                    _vm.item_contact_page.phone,
                    'val'
                  )}`}},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.phone, "val"))+" ")])]),_c('div',[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.fax, "title"))+" "),_c('a',{staticClass:"link-text",attrs:{"href":`fax:${_vm.FiltersLanguage(
                    _vm.item_contact_page.fax,
                    'val'
                  )}`}},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.fax, "val"))+" ")])]),_c('div',[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.email, "title"))+" "),_c('a',{staticClass:"link-text",attrs:{"href":`mailto:${_vm.FiltersLanguage(
                    _vm.item_contact_page.email,
                    'val'
                  )}`}},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.email, "val"))+" ")])]),_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.service_center, "title"))+" "),_c('a',{staticClass:"link-text",attrs:{"href":`tel:${_vm.FiltersLanguage(
                  _vm.item_contact_page.service_center,
                  'val'
                )}`}},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.service_center, "val"))+" ")])]),_c('div',{staticClass:"btn-map"},[_c('v-btn',{staticClass:"clickMap-btn",attrs:{"rounded":"","large":"","href":"https://goo.gl/maps/qBJhSux79csvuWjt7","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page, "btn_map"))+" ")])],1)],1)]),_c('div',{staticClass:"content-area-3"},[_c('div',{staticClass:"contact-box-2"},[_c('div',{staticClass:"contact-title-noc"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page, "noc_center"))+" ")]),_c('div',{staticClass:"noc_text"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.phone_noc, "title"))+" "),_c('a',{staticClass:"link-text",attrs:{"href":`tel:${_vm.FiltersLanguage(
                  _vm.item_contact_page.phone_noc,
                  'val'
                )}`}},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.phone_noc, "val"))+" ")])]),_c('div',{staticClass:"noc_text"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.fax_noc, "title"))+" "),_c('a',{staticClass:"link-text",attrs:{"href":`fax:${_vm.FiltersLanguage(
                  _vm.item_contact_page.fax_noc,
                  'val'
                )}`}},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.fax_noc, "val"))+" ")])]),_c('div',{staticClass:"noc_text"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.email_noc, "title"))+" "),_c('a',{staticClass:"link-text",attrs:{"href":`mailto:${_vm.FiltersLanguage(
                  _vm.item_contact_page.email_noc,
                  'val'
                )}`}},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_contact_page.email_noc, "val"))+" ")])])])]),_c('div',{staticClass:"content-area-4"},[_c('v-img',{staticClass:"img_content slide-in",staticStyle:{"animation-delay":"1s"},attrs:{"src":require(`@/${_vm.item_contact_page.img_contact}`)}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <!-- report561_page -->
    <div class="main-content">
      <!-- title -->
      <div class="layout-all">
      <div class="title-content">
        <div class="d-flex justify-center">
          {{ FiltersLanguage(item_report561_page, "title") }}
          <div>
            <v-img
              class="title-icon"
              :src="require(`@/${item_annual_report_page.img_dot}`)"
            ></v-img>
          </div>
        </div>
      </div>
      <hr class="hr-border" />
      <!--display report -->
      <div class="report-content">
        <diV
          v-for="(item_report561, i) in itema561"
          :key="i"
          class="report-card slide-in-fast"  :style="`animation-delay: ${i===0 ?0.3: i/2.5}s; animation-duration:0.5s;`"

        >
          <div>
            <v-hover v-slot="{ hover }">
              <v-card onclick="">
                <v-img
                  class="report-img"
                   :src="`${env}/api/v1/file/${FiltersLanguage(item_report561, 'banner')}`"
                >
                <a
                      :href="`${env}/api/v1/file/${FiltersLanguage(item_report561, 'file')}`"
                      target="_blank"
                    >
                  <div
                    v-if="hover"
                    class="downloade-report"
                    style="height: 100%"
                  >
                    <div>
                      <div>
                        <v-icon class="icon-download">
                          mdi-download
                        </v-icon>
                      </div>
                      <div class="download-text">{{ FiltersLanguage(item_report561_page, "title_download") }}</div>
                    </div>
                  </div>
                  </a>
                </v-img>
              </v-card>
            </v-hover>
          </div>

          <div class="report-year">
            {{ FiltersLanguage(item_report561, "title") }}
          </div>
          </diV>
        </diV>
        <hr class="hr-border-2" />
        <div class="layout-btn-back">
          <div
            class="btn-return-page"
            @click="$router.push({ name: 'annualReport56_1' }).catch(() => {})"
          >
            <v-icon>mdi-arrow-left-thin</v-icon
            ><span>
              {{ FiltersLanguage(item_report561_page, "btn_back") }}</span
            >
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      env: '',
      loadingTable561: false,
      paginationTable561: {
        total: 0,
        page: 1,
        row: 999
      },
      itema561: []
    }
  },
  created () {
    this.env = process.env.VUE_APP_API
    this.initTable561()
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  methods: {
    async initTable561 () {
      this.loadingTable561 = true
      const payload = {
        page: this.paginationTable561.page,
        row: this.paginationTable561.row,
        keyword: '56-1'
      }
      this.itema561 = []
      const res = await this.getList(payload)
      this.loadingTable561 = false
      if (res.status) {
        if (res.data.data.length !== 0) {
          this.itema561 = res.data.data[0].items
        } else {
          this.itema561 = []
        }
        this.paginationTable561.total = res.data.pagination.total
        this.paginationTable561.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    }
  },
  computed: {
    ...mapGetters([
      'item_annual_report',
      'item_annual_report_now',
      'item_annual_report_page',
      'item_report561_page',
      'item_report561'
    ])
  }
}
</script>

<style scoped>
.layout-all {
  margin-right: 20px;
  margin-left: 20px;
}
.report-content {
  display: flex;
  flex-wrap: wrap;
}

.title-icon {
  width: 16px;
  height: 16px;
  padding: 3px;
}
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
}
.hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 40px;
  margin-bottom: 40px;
}
.hr-border-2 {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 40px;
  margin-bottom: 80px;
}
.report-card {
  width: 25%;
  padding: 10px;
}

.downloade-report {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--v-textSecond-base) !important;
  width: 100%;
  font-size: 24px;
  opacity: 0.75;
  background: var(--v-downloadebook-base) !important;
}
.report-year {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
}
.icon-download {
  display: flex;
  justify-items: center;
  font-size: 100px;
  color: var(--v-textSecond-base) !important;
}

.download-text {
  padding-top: 22px;
  font-size: 32px;
  font-weight: 600;
  color: var(--v-bgPrimary-base) !important;
}

.report-img {
  width: 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}
.layout-btn-back {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btn-return-page {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  background-color: var(--v-btnBackPage-base) !important;
  border-radius: 51px;
  margin-bottom: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 45px;
  padding-right: 40px;
  font-size: 14px;
  font-weight: 400;
  color: var(--v-textPrimary-base) !important;
}
.btn-return-page:hover {
  background-color: var(--v-hoverLink-base) !important;
}

@media screen and (max-width: 1290px) {
  .report-card {
    width: 33.333%;
    padding: 15px;
    margin-top: 5px;
  }
  .report-img {
    width: 100%;
  }
}
@media screen and (max-width: 984px) {
  .report-card {
    width: 50%;
  }
  .hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
}
@media screen and (max-width: 560px) {
  .report-card {
    width: 100%;
  }
}
</style>

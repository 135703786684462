<template>
  <v-dialog
    v-model="dialog"
    max-width="700px"
    transition="dialog-top-transition"
    persistent
  >
    <v-card class="layout-card">
      <v-form ref="formTable">
        <div class="from-persion-card">
          <div class="header-title">
            <v-icon class="icon-header">{{ iconTitle }}</v-icon
            >{{ titleHeader }}
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn icon @click="close(), reset()">
              <v-icon>mdi-close</v-icon></v-btn
            >
          </div>
        </div>
        <hr class="hr-header" />
        <div class="title-year">บัญชีทางการเงินที่สำคัญ</div>
        <div class="mx-7 mt-2" style="color:#929292;">*ข้อมูลตัวเลขไม่ต้องใส่เครื่องหมาย comma (,)</div>
        <div class="from-layout">
          <div class="two-colum">
            <div class="title-text-field">ปี(พ.ศ)</div>
            <div class="box-text-field">
              <div class="box-text-field">
                <v-autocomplete
                  class="width-select"
                  v-model="year"
                  :items="itemsYear"
                  item-text="text"
                  item-value="val"
                  placeholder="กรุณาเลือกปี"
                  color="primaryIconAdmin"
                  item-color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                  outlined
                  dense
                  @change="changeYear()"
                ></v-autocomplete>
              </div>
            </div>
          </div>
          <div class="two-colum">
            <div class="title-text-field">สินทรัพย์รวม</div>
            <div class="box-text-field">
              <div class="box-text-field">
                <v-text-field
                  v-model="data.total_assets"
                  outlined
                  dense
                  placeholder="กรุณากรอกสินทรัพย์รวม"
                  color="primaryIconAdmin"
                  item-color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="two-colum">
            <div class="title-text-field">หนี้สินรวม</div>
            <div class="box-text-field">
              <div class="box-text-field">
                <v-text-field
                  v-model="data.total_liabilities"
                  outlined
                  dense
                  placeholder="กรุณากรอกหนี้สินรวม"
                  color="primaryIconAdmin"
                  item-color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="two-colum">
            <div class="title-text-field">ส่วนของผู้ถือหุ้น</div>
            <div class="box-text-field">
              <div class="box-text-field">
                <v-text-field
                  v-model="data.shareholder_equity"
                  outlined
                  dense
                  placeholder="กรุณากรอกส่วนของผู้ถือหุ้น"
                  color="primaryIconAdmin"
                  item-color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="two-colum">
            <div class="title-text-field">มูลค่าหุ้นที่เรียกชำระแล้ว</div>
            <div class="box-text-field">
              <div class="box-text-field">
                <v-text-field
                  v-model="data.paid_up_capital"
                  outlined
                  dense
                  placeholder="กรุณากรอกมูลค่าหุ้นที่เรียกชำระแล้ว"
                  color="primaryIconAdmin"
                  item-color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="two-colum">
            <div class="title-text-field">รายได้รวม</div>
            <div class="box-text-field">
              <div class="box-text-field">
                <v-text-field
                  v-model="data.total_revenues"
                  outlined
                  dense
                  placeholder="กรุณากรอกรายได้รวม"
                  color="primaryIconAdmin"
                  item-color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="two-colum">
            <div class="title-text-field">กำไรสุทธิ</div>
            <div class="box-text-field">
              <div class="box-text-field">
                <v-text-field
                  v-model="data.net_profit"
                  outlined
                  dense
                  placeholder="กรุณากรอกกำไรสุทธิ"
                  color="primaryIconAdmin"
                  item-color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="two-colum">
            <div class="title-text-field">กำไรต่อหุ้น (บาท)</div>
            <div class="box-text-field">
              <div class="box-text-field">
                <v-text-field
                  v-model="data.eps"
                  outlined
                  dense
                  placeholder="กรุณากรอกกำไรต่อหุ้น"
                  color="primaryIconAdmin"
                  item-color="primaryIconAdmin"
                  :rules="[Rule.noInPut, Rule.notNull]"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
        <div class="layout-btn-record-cancle">
          <div class="layout-two-btn">
            <v-btn
              elevation="0"
              class="btn-add mb-2"
              rounded
              @click="validateFrom()"
              :loading="loading"
              >บันทึก</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              class="btn-cancle mb-2"
              rounded
              @click="close(), reset()"
              >ยกเลิก</v-btn
            >
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      env: '',
      year: '',
      data: {
        year_th: '',
        year_eng: '',
        total_assets: '',
        total_liabilities: '',
        shareholder_equity: '',
        paid_up_capital: '',
        total_revenues: '',
        net_profit: '',
        eps: '',
        create_by: '',
        update_by: ''
      }

    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    iconTitle: {
      type: String,
      default: ''
    },
    titleHeader: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: 'add'
    },
    loading: {
      type: Boolean,
      default: false
    },
    dataForm: {
      type: Object,
      default: () => {
        return {
          year_th: '',
          year_eng: '',
          total_assets: '',
          total_liabilities: '',
          shareholder_equity: '',
          paid_up_capital: '',
          total_revenues: '',
          net_profit: '',
          eps: '',
          create_by: '',
          update_by: ''

        }
      }
    },
    itemsYear: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    dialog: {
      handler: function (newValue) {
        if (newValue === true && this.status === 'edit') {
          this.data = JSON.parse(JSON.stringify(this.dataForm))
          this.year = this.dataForm.year_eng
        }
      },
      deep: true
    }
  },
  async created () {
    this.env = process.env.VUE_APP_API
  },
  methods: {
    close () {
      this.dialog = false
    },
    reset () {
      this.$emit('resetForm')
      this.$refs.formTable.reset()
    },
    validateFrom () {
      if (this.$refs.formTable.validate()) {
        if (this.status === 'add') {
          // TODO Update Data
          this.$swal({
            allowEnterKey: false,
            title: 'เพิ่มข้อมูลตาราง',
            text: 'คุณต้องการเพิ่มข้อมูลตาราง ใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#E74444',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#4CC558'
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('addAndEdit', {
                items: this.data,
                status: 'add'
              })
            }
          })
        } else if (this.status === 'edit') {
          this.$swal({
            allowEnterKey: false,
            title: 'แก้ไขข้อมูลตาราง',
            text: 'คุณต้องการแก้ไขข้อมูลตาราง ใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#E74444',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#4CC558'
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('addAndEdit', {
                items: this.data,
                status: 'edit'
              })
            }
          })
        }
      }
    },
    changeYear () {
      if (this.year !== null && this.year !== '' && this.year !== undefined) {
        this.data.year_th = (parseInt(this.year) + 543).toString()
        this.data.year_eng = this.year
      }
    }
  }
}
</script>

<style scoped lang="css">
>>> .v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__prepend-outer {
  display: none !important;
}
.layout-card {
  padding-bottom: 30px;
}
.v-input--selection-controls {
  margin-top: 5px;
  padding-top: 4px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
.btn-cancle {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-cancleBtn-base) !important;
  font-size: 16px;
}
.from-persion-card {
  display: flex;
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.from-layout {
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.header-title {
  color: var(--v-primaryIconAdmin-base) !important;
  font-size: 24px;
  font-weight: 600;
}
.icon-header {
  color: var(--v-primaryIconAdmin-base) !important;
  font-size: 24px;
  margin-right: 10px;
}

.hr-header {
  color: var(--v-primaryIconAdmin-base) !important;
}
.comment-img {
  color: var(--v-textPrimaryAdmin-base) !important;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}
.title-text-field {
  font-size: 16px;
}
.layout-two-colum {
  width: 100%;
  display: flex;
}
.layout-file-img {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.box-show-img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.width-img {
  /* max-height: 250px;
      max-width: 250px; */
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.bg-file-img {
  background-color: var(--v-bgFileImg-base);
  height: 250px;
  width: 250px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #adadad;
}
.file-input {
  width: 50%;
}
.two-colum {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}
.one-colum {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.layout-add-positions {
  display: flex;
  widows: 100%;
  flex-wrap: wrap;
  background-color: var(--v-bgAddsection-base);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.layout-add-educationals {
  display: flex;
  widows: 100%;
  flex-wrap: wrap;
  background-color: var(--v-bgAddsection-base);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.layout-btn-record-cancle {
  width: 100%;
  display: flex;
  justify-content: center;
}
.layout-two-btn {
  width: 200px;
  display: flex;
}
.btn-delete {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.title-year {
  font-size: 22px;
  font-weight: 600;
  color: var(--v-textPrimaryAdmin-base) !important;
  text-align: center;
  margin-top: 20px;
}
.text-fully {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
  color: var(--v-alertColor-base) !important;
}

@media screen and (max-width: 650px) {
  .from-layout {
    padding-left: 20px;
    padding-right: 20px;
  }
  .file-input {
    width: 100%;
  }
  .layout-two-colum {
    flex-wrap: wrap;
  }
  .two-colum {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .one-colum {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>

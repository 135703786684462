<template>
  <div>
    <titleHeaderMenu :iconTitle="iconTitle" :titleMenu="titleMenu" />

    <div class="btn-hearder">
      <div class="layout-btn-select">
        <div class="layout-box-category">
          <div class="box-category">เลือกหัวข้อที่ต้องการจัดการ</div>
          <v-select
            class="width-select"
            v-model="category"
            :items="itemsCategory"
            item-text="text"
            item-value="val"
            color="primaryIconAdmin"
            item-color="primaryIconAdmin"
            dense
            @change="changeCategory"
          ></v-select>
        </div>
        <!-- งบการเงิน -->
        <div
          class="layout-box-category-year"
          v-if="category === 'financial Statement'"
        >
          <div class="box-category">เลือกปี</div>
          <v-select
            class="width-select"
            v-model="yearSelectFinancialStatement"
            :items="itemsYearSelectFinancialStatement"
            item-text="text"
            item-value="val"
            color="primaryIconAdmin"
            item-color="primaryIconAdmin"
            dense
            @change="changeYearFinancialStatement()"
          ></v-select>
        </div>
        <!-- คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการ  -->
        <div class="layout-box-category-year" v-if="category === 'mDandA'">
          <div class="box-category">เลือกปี</div>
          <v-select
            class="width-select"
            v-model="yearSelectMDandA"
            :items="itemsYearSelectMDandA"
            item-text="text"
            item-value="val"
            color="primaryIconAdmin"
            item-color="primaryIconAdmin"
            dense
            @change="changeYearMDandA()"
          ></v-select>
        </div>
        <!-- เอกสารการประชุม -->
        <div
          class="layout-box-category-year"
          v-if="category === 'documentsOfMeeting'"
        >
          <div class="box-category">เลือกปี</div>
          <v-select
            class="width-select"
            v-model="yearSelectDocumentsOfMeeting"
            :items="itemsYearSelectDocumentsOfMeeting"
            item-text="text"
            item-value="val"
            color="primaryIconAdmin"
            item-color="primaryIconAdmin"
            dense
            @change="changeYearDocumentsOfMeeting()"
          ></v-select>
        </div>
        <div class="box-btn">
          <v-btn
            class="btn-add"
            elevation="0"
            rounded
            @click="(statusForm = 'add'), openDialog()"
            ><v-icon>mdi-plus</v-icon> {{ category === 'documentsOfMeeting'? 'เพิ่มหัวข้อ':'เพิ่มเอกสารดาวน์โหลด'}}   </v-btn
          >
        </div>
      </div>
    </div>
    <!-- รายงานประจำปี -->
    <tableAnnualReport
      v-if="category === 'annualReport'"
      :headers="headerTableAnnualReport"
      :items="itemsTableAnnualReports"
      :loading="loadingTableAnnualReport"
      :pagination="paginationTableAnnualReport"
      @editFile="editFileAnnualReport"
      @deleteFile="recheckDeleteFileAnnualReport"
      class="mb-10"
    />
    <dialogAnnualReport
      ref="dialogFormAnnualReport"
      v-model="statusDialogFormAnnualReport"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :status="statusForm"
      :titleYear="titleAnnualReport"
      :loadingFileDownload="loadingFileDownload"
      :loadingFileDownloadTH="loadingFileDownloadTH"
      :loadingFileDownloadENG="loadingFileDownloadENG"
      :loadingFileBannerTH="loadingFileBannerTH"
      :loadingFileBannerENG="loadingFileBannerENG"
      :dataAnnualReport="dataAnnualReport"
      @uploadFile="uploadFileAnnualReport"
      @uploadBanner="uploadBannerAnnualReport"
      @resetForm="resetFormAnnualReport"
      @addAndEdit="addAndEditDataAnnualReport"
    />
    <!-- แบบ 56-1 -->
    <tableForm561
      v-if="category === 'form56-1'"
      :headers="headerTableForm561"
      :items="itemsTableForm561"
      :loading="loadingTableForm561"
      :pagination="paginationTableForm561"
      @editFile="editFileForm561"
      @deleteFile="recheckDeleteFileForm561"
      class="mb-10"
    />
    <dialogForm561
      ref="dialogFormForm561"
      v-model="statusDialogFormForm561"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :status="statusForm"
      :titleYear="titleForm561"
      :loadingFileDownload="loadingFileDownload"
      :loadingFileDownloadTH="loadingFileDownloadTH"
      :loadingFileDownloadENG="loadingFileDownloadENG"
      :loadingFileBannerTH="loadingFileBannerTH"
      :loadingFileBannerENG="loadingFileBannerENG"
      :dataForm561="dataForm561"
      @uploadFile="uploadFileForm561"
      @uploadBanner="uploadBannerForm561"
      @resetForm="resetFormForm561"
      @addAndEdit="addAndEditDataForm561"
    />
    <!-- งบการเงิน -->
    <tableFinancialStatement
      v-if="category === 'financial Statement'"
      :headers="headerTableFinancialStatement"
      :items="itemsTableFinancialStatement"
      :loading="loadingTableFinancialStatement"
      :pagination="paginationTableFinancialStatement"
      @editFile="editFileFinancialStatement"
      @deleteFile="recheckDeleteFileFinancialStatement"
      class="mb-10"
    />
    <dialogFormFinancialStatement
      ref="dialogFormFinancialStatement"
      v-model="statusDialogFormFinancialStatement"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :status="statusForm"
      :itemsTitle="itemsTitleFinancialStatement"
      :yearTitle="yearTitle"
      :loadingFileDownload="loadingFileDownload"
      :loadingFileDownloadTH="loadingFileDownloadTH"
      :loadingFileDownloadENG="loadingFileDownloadENG"
      :dataFinancialStatement="dataFinancialStatement"
      @uploadFile="uploadFileFinancialStatement"
      @resetForm="resetFormFinancialStatement"
      @addAndEdit="addAndEditDataFinancialStatement"
    />
    <!-- คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการ -->
    <tableMdAndA
      v-if="category === 'mDandA'"
      :headers="headerTableMdAndA"
      :items="itemsTableMdAndA"
      :loading="loadingTableMdAndA"
      :pagination="paginationTableMdAndA"
      @editFile="editFileMdAndA"
      @deleteFile="recheckDeleteFileMdAndA"
      class="mb-10"
    />
    <dialogFormMdAndA
      ref="dialogFormMdAndA"
      v-model="statusDialogFormMdAndA"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :status="statusForm"
      :itemsTitle="itemsTitleMdAndA"
      :yearTitle="yearTitle"
      :loadingFileDownload="loadingFileDownload"
      :loadingFileDownloadTH="loadingFileDownloadTH"
      :loadingFileDownloadENG="loadingFileDownloadENG"
      :dataMdAndA="dataMdAndA"
      @uploadFile="uploadFileMdAndA"
      @resetForm="resetFormMdAndA"
      @addAndEdit="addAndEditDataMdAndA"
    />
    <!-- รายละเอียดการใช้สิทธิใบสำคัญแสดงสิทธิ INET-W2 -->
    <tableSubscriptionRightsOfINETW2
      v-if="category === 'iNET-W2'"
      :headers="headerTableSubscriptionRightsOfINETW2"
      :items="itemsTableSubscriptionRightsOfINETW2"
      :loading="loadingTableSubscriptionRightsOfINETW2"
      :pagination="paginationTableSubscriptionRightsOfINETW2"
      @onChangePagination="onChangeTableSubscriptionRightsOfINETW2"
      @editFile="editFileSubscriptionRightsOfINETW2"
      @deleteFile="recheckDeleteFileSubscriptionRightsOfINETW2"
      @changeIndex="recheckChangeIndexFileSubscriptionRightsOfINETW2"
      class="mb-10"
    />
    <dialogFormSubscriptionRightsOfINETW2
      ref="dialogFormSubscriptionRightsOfINETW2"
      v-model="statusDialogFormSubscriptionRightsOfINETW2"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :status="statusForm"
      :loadingFileDownload="loadingFileDownload"
      :loadingFileDownloadTH="loadingFileDownloadTH"
      :loadingFileDownloadENG="loadingFileDownloadENG"
      :dataSubscriptionRightsOfINETW2="dataSubscriptionRightsOfINETW2"
      @uploadFile="uploadFileSubscriptionRightsOfINETW2"
      @resetForm="resetFormSubscriptionRightsOfINETW2"
      @addAndEdit="addAndEditDataSubscriptionRightsOfINETW2"
    />
    <!-- นโยบายที่เกี่ยวข้อง -->
    <tableGovernancePolicy
      v-if="category === 'governancePolicy'"
      :headers="headerTableGovernancePolicy"
      :items="itemsTableGovernancePolicy"
      :loading="loadingTableGovernancePolicy"
      :pagination="paginationTableGovernancePolicy"
      @onChangePagination="onChangeTableGovernancePolicy"
      @editFile="editFileGovernancePolicy"
      @deleteFile="recheckDeleteFileGovernancePolicy"
      @changeIndex="recheckChangeIndexFileGovernancePolicy"
      class="mb-10"
    />
    <dialogFormGovernancePolicy
      ref="dialogFormGovernancePolicy"
      v-model="statusDialogFormGovernancePolicy"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :status="statusForm"
      :loadingFileDownload="loadingFileDownload"
      :loadingFileDownloadTH="loadingFileDownloadTH"
      :loadingFileDownloadENG="loadingFileDownloadENG"
      :dataGovernancePolicy="dataGovernancePolicy"
      @uploadFile="uploadFileGovernancePolicy"
      @resetForm="resetFormGovernancePolicy"
      @addAndEdit="addAndEditDataGovernancePolicy"
    />
    <!-- เอกสารการประชุม -->
    <tableDocumentsOfMeeting
      v-if="category === 'documentsOfMeeting'"
      :headers="headerTableDocumentsOfMeeting"
      :items="itemsTableDocumentsOfMeeting"
      :pagination="paginationTableDocumentsOfMeeting"
      :loading="loadingTableDocumentsOfMeeting"
      :headersSub="headerTableDocumentsOfMeetingSub"
      :paginationSub="paginationTableDocumentsOfMeetingSub"
      @onChangePagination="onChangeTableDocumentsOfMeeting"
      @editFile="editFileDataDocumentsOfMeeting"
      @deleteFile="recheckDeleteFileDocumentsOfMeeting"
      @changeIndex="recheckChangeIndexFileDocumentsOfMeeting"
      @changeIndexSub="recheckChangeIndexSubFileDocumentsOfMeeting"
      @addFileSub="openAddFileDocumentsOfMeetingSub"
      @editFileSub="editFileDocumentsOfMeetingSub"
      @deleteFileSub="recheckDeleteFileDocumentsOfMeetingSub"
      class="mb-10"
    />
    <dialogFormDocumentsOfMeeting
      ref="dialogFormDocumentsOfMeeting"
      v-model="statusDialogFormDocumentsOfMeeting"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :status="statusForm"
      :loadingFileDownload="loadingFileDownload"
      :dataDocumentsOfMeeting="dataDocumentsOfMeeting"
      @resetForm="resetFormDocumentsOfMeeting"
      @addAndEdit="addAndEditDataDocumentsOfMeeting"
    />
    <dialogFormDocumentsOfMeetingSub
      ref="dialogFormDocumentsOfMeetingSub"
      v-model="statusDialogFormDocumentsOfMeetingSub"
      :titleHeader="titleHeader"
      :iconTitle="iconTitleaAddEdit"
      :status="statusForm"
      :loadingFileDownload="loadingFileDownload"
      :loadingFileDownloadTH="loadingFileDownloadTH"
      :loadingFileDownloadENG="loadingFileDownloadENG"
      :dataDocumentsOfMeetingSub="dataDocumentsOfMeetingSub"
      @uploadFile="uploadFileDocumentsOfMeetingSub"
      @resetForm="resetFormDocumentsOfMeetingSub"
      @addAndEdit="addAndEditDataDocumentsOfMeetingSub"
    />
    <dialog-loading v-model="loading" />
  </div>
</template>

<script>
import titleHeaderMenu from '../../../components/adminpage/titleHeaderMenu.vue'
import tableAnnualReport from '../../../components/adminpage/tableFileDownloadPage/tableAnnualReport.vue'
import tableForm561 from '../../../components/adminpage/tableFileDownloadPage/tableForm56_1.vue'
import tableFinancialStatement from '../../../components/adminpage/tableFileDownloadPage/tableFinancialStatement.vue'
import tableMdAndA from '../../../components/adminpage/tableFileDownloadPage/tableMdAndA.vue'
import tableSubscriptionRightsOfINETW2 from '../../../components/adminpage/tableFileDownloadPage/tableSubscriptionRightsOfINETW2.vue'
import tableGovernancePolicy from '../../../components/adminpage/tableFileDownloadPage/tableGovernancePolicy.vue'
import tableDocumentsOfMeeting from '../../../components/adminpage/tableFileDownloadPage/tableDocumentsOfMeeting.vue'
import dialogFormFinancialStatement from '../../../components/adminpage/formFileDownloads/formFinancialStatement.vue'
import dialogFormMdAndA from '../../../components/adminpage/formFileDownloads/formMdAndA.vue'
import dialogAnnualReport from '../../../components/adminpage/formFileDownloads/formAnnualReport.vue'
import dialogForm561 from '../../../components/adminpage/formFileDownloads/formForm56_1.vue'
import dialogFormSubscriptionRightsOfINETW2 from '../../../components/adminpage/formFileDownloads/formSubscriptionRightsOfINETW2.vue'
import dialogFormGovernancePolicy from '../../../components/adminpage/formFileDownloads/formGovernancePolicy.vue'
import dialogFormDocumentsOfMeeting from '../../../components/adminpage/formFileDownloads/formDocumentsOfMeeting.vue'
import dialogFormDocumentsOfMeetingSub from '../../../components/adminpage/formFileDownloads/formDocumentsOfMeetingSub.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    titleHeaderMenu,
    tableAnnualReport, // รายงานประจำปี
    dialogAnnualReport,
    tableForm561, // แบบ56-1
    dialogForm561,
    tableFinancialStatement, // งบการเงิน
    dialogFormFinancialStatement,
    tableMdAndA, // คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการ
    dialogFormMdAndA,
    tableSubscriptionRightsOfINETW2, // รายละเอียดการใช้สิทธิใบสำคัญแสดงสิทธิ INET-W2
    dialogFormSubscriptionRightsOfINETW2,
    tableGovernancePolicy, // นโยบายที่เกี่ยวข้อง
    dialogFormGovernancePolicy,
    tableDocumentsOfMeeting, // เอกสารการประชุมม
    dialogFormDocumentsOfMeeting,
    dialogFormDocumentsOfMeetingSub
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  data () {
    return {
      loading: false,
      loadingFileDownload: false,
      loadingFileDownloadTH: false,
      loadingFileDownloadENG: false,
      loadingFileBannerTH: false,
      loadingFileBannerENG: false,
      iconTitle: 'mdi-folder-multiple-plus-outline',
      titleMenu: 'เอกสารดาวน์โหลด',
      titleHeader: '',
      iconTitleaAddEdit: '',
      statusForm: 'add',
      indexData: null,
      category: 'annualReport',
      itemsCategory: [
        { text: 'รายงานประจำปี', val: 'annualReport' }, // Annual Report
        { text: 'แบบ 56-1', val: 'form56-1' }, // แบบ 56-1
        { text: 'งบการเงิน', val: 'financial Statement' }, // Financial Statement
        { text: 'คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการ', val: 'mDandA' }, // MD&A
        {
          text: 'รายละเอียดการใช้สิทธิใบสำคัญแสดงสิทธิ INET-W3',
          val: 'iNET-W2'
        }, // The warrants to Purchase Ordinary Shares in INET–W2,
        { text: 'นโยบายที่เกี่ยวข้อง', val: 'governancePolicy' }, // Governance Policy
        { text: 'เอกสารการประชุม', val: 'documentsOfMeeting' } // Documents of Meeting
      ],

      // รายงานประจำปี
      loadingAddEditeAnnualReport: false,
      loadingTableAnnualReport: false,
      headerTableAnnualReport: [
        {
          text: 'ลำดับ',
          align: 'center',
          value: 'number',
          sortable: false,
          filterable: false
        },
        {
          text: 'ปี ภาษาไทย',
          align: 'center',
          value: 'title_th',
          sortable: false
        },
        {
          text: 'ปี ภาษาอังกฤษ',
          align: 'center',
          value: 'title_eng',
          sortable: false
        },
        {
          text: 'ไฟล์ไทย (PDF)',
          align: 'end',
          value: 'file_th',
          sortable: false,
          filterable: false
        },
        {
          text: 'ไฟล์อังกฤษ (PDF)',
          align: 'end',
          value: 'file_eng',
          sortable: false,
          filterable: false
        },
        {
          text: 'ไฟล์รูปหน้าปกไทย',
          align: 'end',
          value: 'banner_th',
          sortable: false,
          filterable: false
        },
        {
          text: 'ไฟล์รูปหน้าปกอังกฤษ',
          align: 'end',
          value: 'banner_eng',
          sortable: false,
          filterable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'end',
          value: 'create_date',
          sortable: false,
          filterable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false,
          filterable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false,
          filterable: false
        }
      ],
      itemsTableAnnualReports: [],
      itemsTableAnnualReportsAll: [],
      paginationTableAnnualReport: {
        total: 0,
        page: 1,
        row: 999
      },
      statusDialogFormAnnualReport: false,
      titleAnnualReport: '',
      dataAnnualReport: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      defalitDataAnnualReport: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      // แบบ 56-1
      loadingAddEditeForm561: false,
      loadingTableForm561: false,
      headerTableForm561: [
        {
          text: 'ลำดับ',
          align: 'center',
          value: 'number',
          sortable: false,
          filterable: false
        },
        {
          text: 'ปี ภาษาไทย',
          align: 'center',
          value: 'title_th',
          sortable: false
        },
        {
          text: 'ปี ภาษาอังกฤษ',
          align: 'center',
          value: 'title_eng',
          sortable: false
        },
        {
          text: 'ไฟล์ไทย (PDF)',
          align: 'end',
          value: 'file_th',
          sortable: false,
          filterable: false
        },
        {
          text: 'ไฟล์อังกฤษ (PDF)',
          align: 'end',
          value: 'file_eng',
          sortable: false,
          filterable: false
        },
        {
          text: 'ไฟล์รูปหน้าปกไทย',
          align: 'end',
          value: 'banner_th',
          sortable: false,
          filterable: false
        },
        {
          text: 'ไฟล์รูปหน้าปกอังกฤษ',
          align: 'end',
          value: 'banner_eng',
          sortable: false,
          filterable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'end',
          value: 'create_date',
          sortable: false,
          filterable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false,
          filterable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false,
          filterable: false
        }
      ],
      itemsTableForm561: [],
      itemsTableForm561All: [],
      paginationTableForm561: {
        total: 0,
        page: 1,
        row: 999
      },
      statusDialogFormForm561: false,
      titleForm561: '',
      dataForm561: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      defalitDataForm561: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      // งบการเงิน
      yearSelectFinancialStatement: '',
      itemsYearSelectFinancialStatement: [],
      loadingTableFinancialStatement: false,
      loadingAddEditeFinancialStatement: false,
      headerTableFinancialStatement: [
        { text: 'ลำดับ', align: 'center', value: 'number', sortable: false },
        {
          text: 'หัวข้อภาษาไทย',
          align: 'center',
          value: 'title_th',
          sortable: false
        },
        {
          text: 'หัวข้อภาษาอังกฤษ',
          align: 'center',
          value: 'title_eng',
          sortable: false
        },
        {
          text: 'ไฟล์ไทย (PDF)',
          align: 'end',
          value: 'file_th',
          sortable: false
        },
        {
          text: 'ไฟล์อังกฤษ (PDF)',
          align: 'end',
          value: 'file_eng',
          sortable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'end',
          value: 'create_date',
          sortable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false
        }
      ],
      itemsTableFinancialStatement: [],
      itemsTableFinancialStatementAll: [],
      paginationTableFinancialStatement: {
        total: 0,
        page: 1,
        row: 999
      },
      statusDialogFormFinancialStatement: false,
      defaultItemsTitleFinancialStatement: [
        { th: 'ไตรมาส 1/', eng: 'Quarter 1/' },
        { th: 'ไตรมาส 2/', eng: 'Quarter 2/' },
        { th: 'ไตรมาส 3/', eng: 'Quarter 3/' },
        { th: 'ปี', eng: 'Year End' }
      ],
      yearTitle: '',
      itemsTitleFinancialStatement: [],
      dataFinancialStatement: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      defalitDataFinancialStatement: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      // คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการ
      yearSelectMDandA: '',
      itemsYearSelectMDandA: [],
      loadingAddEditeMdAndA: false,
      loadingTableMdAndA: false,
      headerTableMdAndA: [
        { text: 'ลำดับ', align: 'center', value: 'number', sortable: false },
        {
          text: 'หัวข้อภาษาไทย',
          align: 'center',
          value: 'title_th',
          sortable: false
        },
        {
          text: 'หัวข้อภาษาอังกฤษ',
          align: 'center',
          value: 'title_eng',
          sortable: false
        },
        {
          text: 'ไฟล์ไทย (PDF)',
          align: 'end',
          value: 'file_th',
          sortable: false
        },
        {
          text: 'ไฟล์อังกฤษ (PDF)',
          align: 'end',
          value: 'file_eng',
          sortable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'end',
          value: 'create_date',
          sortable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false
        }
      ],
      itemsTableMdAndA: [],
      itemsTableMdAndAAll: [],
      paginationTableMdAndA: {
        total: 0,
        page: 1,
        row: 999
      },
      statusDialogFormMdAndA: false,
      defaultItemsTitleTableMdAndA: [
        { th: 'ไตรมาส 1/', eng: 'Quarter 1/' },
        { th: 'ไตรมาส 2/', eng: 'Quarter 2/' },
        { th: 'ไตรมาส 3/', eng: 'Quarter 3/' },
        { th: 'ปี', eng: 'Year End' }
      ],
      itemsTitleMdAndA: [],
      dataMdAndA: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      defalitDataMdAndA: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      // รายละเอียดการใช้สิทธิใบสำคัญแสดงสิทธิ INET-W2
      loadingTableSubscriptionRightsOfINETW2: false,
      loadingAddEditeSubscriptionRightsOfINETW2: false,
      headerTableSubscriptionRightsOfINETW2: [
        {
          text: 'ลำดับ',
          align: 'center',
          value: 'number',
          sortable: false,
          filterable: false
        },
        {
          text: 'หัวข้อภาษาไทย',
          align: 'center',
          value: 'title_th',
          sortable: false
        },
        {
          text: 'หัวข้อภาษาอังกฤษ',
          align: 'center',
          value: 'title_eng',
          sortable: false
        },
        {
          text: 'ไฟล์ไทย (PDF)',
          align: 'end',
          value: 'file_th',
          sortable: false,
          filterable: false
        },
        {
          text: 'ไฟล์อังกฤษ (PDF)',
          align: 'end',
          value: 'file_eng',
          sortable: false,
          filterable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'end',
          value: 'create_date',
          sortable: false,
          filterable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false,
          filterable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false,
          filterable: false
        }
      ],
      itemsTableSubscriptionRightsOfINETW2: [],
      itemsTableSubscriptionRightsOfINETW2All: [],
      paginationTableSubscriptionRightsOfINETW2: {
        total: 0,
        page: 1,
        row: 999
      },
      statusDialogFormSubscriptionRightsOfINETW2: false,
      dataSubscriptionRightsOfINETW2: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      defalitDataSubscriptionRightsOfINETW2: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      // นโยบายที่เกี่ยวข้อง
      loadingTableGovernancePolicy: false,
      loadingAddEditeGovernancePolicy: false,
      headerTableGovernancePolicy: [
        {
          text: 'ลำดับ',
          align: 'center',
          value: 'number',
          sortable: false,
          filterable: false
        },
        {
          text: 'หัวข้อภาษาไทย',
          align: 'center',
          value: 'title_th',
          sortable: false
        },
        {
          text: 'หัวข้อภาษาอังกฤษ',
          align: 'center',
          value: 'title_eng',
          sortable: false
        },
        {
          text: 'ไฟล์ไทย (PDF)',
          align: 'end',
          value: 'file_th',
          sortable: false,
          filterable: false
        },
        {
          text: 'ไฟล์อังกฤษ (PDF)',
          align: 'end',
          value: 'file_eng',
          sortable: false,
          filterable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'end',
          value: 'create_date',
          sortable: false,
          filterable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false,
          filterable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false,
          filterable: false
        }
      ],
      itemsTableGovernancePolicy: [],
      itemsTableGovernancePolicyAll: [],
      paginationTableGovernancePolicy: {
        total: 0,
        page: 1,
        row: 999
      },
      statusDialogFormGovernancePolicy: false,
      dataGovernancePolicy: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      defalitDataGovernancePolicy: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      // เอกสารการประชุม
      yearSelectDocumentsOfMeeting: '',
      itemsYearSelectDocumentsOfMeeting: [],
      loadingTableDocumentsOfMeeting: false,
      headerTableDocumentsOfMeeting: [
        { text: 'ลำดับ', align: 'center', value: 'number', sortable: false, filterable: false },
        {
          text: 'หัวข้อใหญ่ไทย',
          align: 'center',
          value: 'title_th',
          sortable: false
        },
        {
          text: 'หัวข้อใหญ่อังกฤษ',
          align: 'center',
          value: 'title_eng',
          sortable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'end',
          value: 'create_date',
          sortable: false,
          filterable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false,
          filterable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false,
          filterable: false
        },
        { text: '', value: 'data-table-expand', align: 'end', filterable: false }
      ],
      headerTableDocumentsOfMeetingSub: [
        { text: 'ลำดับ', align: 'center', value: 'number', sortable: false },
        {
          text: 'หัวข้อใหญ่ไทย',
          align: 'center',
          value: 'title_th',
          sortable: false
        },
        {
          text: 'หัวข้อใหญ่อังกฤษ',
          align: 'center',
          value: 'title_eng',
          sortable: false
        },
        {
          text: 'ไฟล์ไทย (PDF)',
          align: 'end',
          value: 'file_th',
          sortable: false,
          filterable: false
        },
        {
          text: 'ไฟล์อังกฤษ (PDF)',
          align: 'end',
          value: 'file_eng',
          sortable: false,
          filterable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'end',
          value: 'create_date',
          sortable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false
        }
      ],
      itemsTableDocumentsOfMeeting: [],
      itemsTableDocumentsOfMeetingAll: [],
      paginationTableDocumentsOfMeeting: {
        total: 0,
        page: 1,
        row: 999
      },
      paginationTableDocumentsOfMeetingSub: {
        total: 0,
        page: 1,
        row: 999
      },
      statusDialogFormDocumentsOfMeeting: false,
      dataDocumentsOfMeeting: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'text',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      defalitDocumentsOfMeeting: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'text',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      // DocumentsOfMeetingSub
      statusDialogFormDocumentsOfMeetingSub: false,
      dataDocumentsOfMeetingSub: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      defalitDocumentsOfMeetingSub: {
        list_id: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        type: 'file',
        file_th: '',
        file_eng: '',
        banner_th: '',
        banner_eng: '',
        status: true,
        verify: 0,
        edit_date: '',
        items: []
      },
      documentsOfMeetingIndexMain: null,
      documentsOfMeetingIndexSub: null
    }
  },
  async created () {
    this.setUpTableAnnualReport()
  },
  methods: {
    init () {},
    changeCategory () {
      if (this.category === 'financial Statement') {
        this.setUpTableFinancialStatement()
      } else if (this.category === 'documentsOfMeeting') {
        this.setUpTableDocumentsOfMeeting()
      } else if (this.category === 'mDandA') {
        this.setUpTableMDandA()
      } else if (this.category === 'annualReport') {
        this.setUpTableAnnualReport()
      } else if (this.category === 'form56-1') {
        this.setUpTableForm561()
      } else if (this.category === 'iNET-W2') {
        this.setUpTableSubscriptionRightsOfINETW2()
      } else if (this.category === 'governancePolicy') {
        this.setUpTableGovernancePolicy()
      }
    },
    openDialog () {
      if (this.category === 'financial Statement') {
        this.initltItemsTitleFinancialStatement()
        this.yearTitle = `${parseInt(this.yearSelectFinancialStatement) + 543}`
        this.titleHeader = 'เพิ่มเอกสารดาวน์โหลด'
        this.iconTitleaAddEdit = 'mdi-plus'
        this.statusDialogFormFinancialStatement = true
      } else if (this.category === 'mDandA') {
        this.initltItemsTitleMdAndA()
        this.yearTitle = `${parseInt(this.yearSelectMDandA) + 543}`
        this.titleHeader = 'เพิ่มเอกสารดาวน์โหลด'
        this.iconTitleaAddEdit = 'mdi-plus'
        this.statusDialogFormMdAndA = true
      } else if (this.category === 'annualReport') {
        this.initTitleAnnualReport()
        this.titleHeader = 'เพิ่มเอกสารดาวน์โหลด'
        this.iconTitleaAddEdit = 'mdi-plus'
        this.statusDialogFormAnnualReport = true
      } else if (this.category === 'form56-1') {
        this.initTitleForm561()
        this.titleHeader = 'เพิ่มเอกสารดาวน์โหลด'
        this.iconTitleaAddEdit = 'mdi-plus'
        this.statusDialogFormForm561 = true
      } else if (this.category === 'iNET-W2') {
        this.titleHeader = 'เพิ่มเอกสารดาวน์โหลด'
        this.iconTitleaAddEdit = 'mdi-plus'
        this.statusDialogFormSubscriptionRightsOfINETW2 = true
      } else if (this.category === 'governancePolicy') {
        this.titleHeader = 'เพิ่มเอกสารดาวน์โหลด'
        this.iconTitleaAddEdit = 'mdi-plus'
        this.statusDialogFormGovernancePolicy = true
      } else if (this.category === 'documentsOfMeeting') {
        this.titleHeader = 'เพิ่มหัวข้อเอกสารการประชุม'
        this.iconTitleaAddEdit = 'mdi-plus'
        this.statusDialogFormDocumentsOfMeeting = true
      }
    },
    // รายงานประจำปี
    setUpTableAnnualReport () {
      this.initTableAnnualReport()
    },
    async initTableAnnualReport () {
      this.loadingTableAnnualReport = true
      const payload = {
        page: this.paginationTableAnnualReport.page,
        row: this.paginationTableAnnualReport.row,
        keyword: 'รายงานประจำปี'
      }
      this.itemsTableAnnualReports = []
      const res = await this.getList(payload)
      this.loadingTableAnnualReport = false
      if (res.status) {
        if (res.data.data.length !== 0) {
          this.itemsTableAnnualReports = res.data.data[0].items
          this.itemsTableAnnualReportsAll = res.data.data[0]
        }
        this.paginationTableAnnualReport.total = res.data.pagination.total
        this.paginationTableAnnualReport.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    initTitleAnnualReport () {
      this.titleAnnualReport = `${
        parseInt(this.itemsTableAnnualReports[0].title_th) + 1
      }`
      this.dataAnnualReport.title_th = `${
        parseInt(this.itemsTableAnnualReports[0].title_th) + 1
      }`
      this.dataAnnualReport.title_eng = `${
        parseInt(this.itemsTableAnnualReports[0].title_eng) + 1
      }`
    },
    async uploadFileAnnualReport (data) {
      if (data.title === 'cancle edit') {
        if (data.type === 'th') {
          this.dataAnnualReport.file_th = data.file
        } else if (data.type === 'eng') {
          this.dataAnnualReport.file_eng = data.file
        }
      } else {
        const fileDownload = data.file
        const type = data.type === 'th' ? 'thai' : 'eng'
        if (fileDownload !== '' && fileDownload !== null) {
          if (data.type === 'th') {
            this.loadingFileDownloadTH = true
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = true
          }
          const payload = {
            file: fileDownload,
            title: data.title,
            description: '',
            tags: ['AnnualReport', data.year, type]
          }
          const res = await this.uploadFile(payload)
          if (data.type === 'th') {
            this.loadingFileDownloadTH = false
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = false
          }

          if (res.status) {
            if (data.type === 'th') {
              this.dataAnnualReport.file_th = res.data.filename
            } else if (data.type === 'eng') {
              this.dataAnnualReport.file_eng = res.data.filename
            }
          } else {
            this.antmessage.error(res.message)
          }
        } else {
          if (data.type === 'th') {
            this.dataAnnualReport.file_th = ''
          } else if (data.type === 'eng') {
            this.dataAnnualReport.file_eng = ''
          }
        }
      }
    },
    async uploadBannerAnnualReport (data) {
      if (data.title === 'cancle edit') {
        if (data.type === 'th') {
          this.dataAnnualReport.banner_th = data.file
        } else if (data.type === 'eng') {
          this.dataAnnualReport.banner_eng = data.file
        }
      } else {
        const fileDownload = data.file
        const type = data.type === 'th' ? 'thai' : 'eng'
        if (fileDownload !== '' && fileDownload !== null) {
          if (data.type === 'th') {
            this.loadingFileBannerTH = true
          } else if (data.type === 'eng') {
            this.loadingFileBannerENG = true
          }
          const payload = {
            file: fileDownload,
            title: data.title,
            description: '',
            tags: ['AnnualReport', data.year, type]
          }
          const res = await this.uploadFile(payload)
          if (data.type === 'th') {
            this.loadingFileBannerTH = false
          } else if (data.type === 'eng') {
            this.loadingFileBannerENG = false
          }

          if (res.status) {
            if (data.type === 'th') {
              this.dataAnnualReport.banner_th = res.data.filename
            } else if (data.type === 'eng') {
              this.dataAnnualReport.banner_eng = res.data.filename
            }
          } else {
            this.antmessage.error(res.message)
          }
        } else {
          if (data.type === 'th') {
            this.dataAnnualReport.banner_th = ''
          } else if (data.type === 'eng') {
            this.dataAnnualReport.banner_eng = ''
          }
        }
      }
    },
    resetFormAnnualReport () {
      this.titleAnnualReport = ''
      this.dataAnnualReport = this.defalitDataAnnualReport
    },
    addAndEditDataAnnualReport (data) {
      if (data.status === 'add') {
        this.addDataAnnualReport()
      } else if (data.status === 'edit') {
        this.editDataAnnualReport()
      }
    },
    async addDataAnnualReport () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataAnnualReport.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataAnnualReport.update_by = this.userInfo.user_id
      this.dataAnnualReport.create_by = this.userInfo.user_id

      const payload = Object.assign({}, this.itemsTableAnnualReportsAll)
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.update_by = this.userInfo.user_id
      payload.items.unshift(this.dataAnnualReport)

      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มไฟล์รายงานประจำปีสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableAnnualReport()
        this.statusDialogFormAnnualReport = false
        this.$refs.dialogFormAnnualReport.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    editFileAnnualReport (item) {
      this.indexData = item.index
      this.statusForm = 'edit'
      this.titleAnnualReport = item.item.title_th
      this.dataAnnualReport = Object.assign({}, item.item)
      this.titleHeader = 'แก้ไขเอกสารดาวน์โหลด'
      this.iconTitleaAddEdit = 'mdi-pencil'
      this.statusDialogFormAnnualReport = true
    },
    async editDataAnnualReport () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataAnnualReport.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataAnnualReport.update_by = this.userInfo.user_id
      const payload = Object.assign({}, this.itemsTableAnnualReportsAll)
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.update_by = this.userInfo.user_id
      payload.items[this.indexData] = this.dataAnnualReport
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขไฟล์รายงานประจำปีสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableAnnualReport()
        this.statusDialogFormAnnualReport = false
        this.$refs.dialogFormAnnualReport.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckDeleteFileAnnualReport (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบไฟล์',
        text: `คุณต้องการลบไฟล์รายงานประจำปี ${item.title_th} ใช่หรือไม่`,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.itemsTableAnnualReportsAll.items.splice(0, 1)
          this.deleteFileAnnualReports(this.itemsTableAnnualReportsAll)
        }
      })
    },
    async deleteFileAnnualReports (item) {
      this.loading = true
      const payload = Object.assign({}, item)
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.update_by = this.userInfo.user_id
      const res = await this.updateList(payload)
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบไฟล์รายงานประจำปีสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableAnnualReport()
      } else {
        this.antmessage.error(res.message)
      }
    },
    // แบบ 56-1
    setUpTableForm561 () {
      this.initTableForm561()
    },
    onChangeTableForm561 (val) {
      this.paginationTableForm561.page = val.page
      this.paginationTableForm561.row = val.row
      // this.initTableFinancialStatement()
    },
    async initTableForm561 () {
      this.loadingTableForm561 = true
      const payload = {
        page: this.paginationTableForm561.page,
        row: this.paginationTableForm561.row,
        keyword: '56-1'
      }
      this.itemsTableForm561 = []
      const res = await this.getList(payload)
      this.loadingTableForm561 = false
      if (res.status) {
        if (res.data.data.length !== 0) {
          this.itemsTableForm561 = res.data.data[0].items
          this.itemsTableForm561All = res.data.data[0]
        }
        this.paginationTableForm561.total = res.data.pagination.total
        this.paginationTableForm561.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    initTitleForm561 () {
      this.titleForm561 = `${parseInt(this.itemsTableForm561[0].title_th) + 1}`
      this.dataForm561.title_th = `${
        parseInt(this.itemsTableForm561[0].title_th) + 1
      }`
      this.dataForm561.title_eng = `${
        parseInt(this.itemsTableForm561[0].title_eng) + 1
      }`
    },
    async uploadFileForm561 (data) {
      if (data.title === 'cancle edit') {
        if (data.type === 'th') {
          this.dataForm561.file_th = data.file
        } else if (data.type === 'eng') {
          this.dataForm561.file_eng = data.file
        }
      } else {
        const fileDownload = data.file
        const type = data.type === 'th' ? 'thai' : 'eng'
        if (fileDownload !== '' && fileDownload !== null) {
          if (data.type === 'th') {
            this.loadingFileDownloadTH = true
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = true
          }
          const payload = {
            file: fileDownload,
            title: data.title,
            description: '',
            tags: ['56-1', data.year, type]
          }
          const res = await this.uploadFile(payload)
          if (data.type === 'th') {
            this.loadingFileDownloadTH = false
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = false
          }

          if (res.status) {
            if (data.type === 'th') {
              this.dataForm561.file_th = res.data.filename
            } else if (data.type === 'eng') {
              this.dataForm561.file_eng = res.data.filename
            }
          } else {
            this.antmessage.error(res.message)
          }
        } else {
          if (data.type === 'th') {
            this.dataForm561.file_th = ''
          } else if (data.type === 'eng') {
            this.dataForm561.file_eng = ''
          }
        }
      }
    },
    async uploadBannerForm561 (data) {
      if (data.title === 'cancle edit') {
        if (data.type === 'th') {
          this.dataForm561.banner_th = data.file
        } else if (data.type === 'eng') {
          this.dataForm561.banner_eng = data.file
        }
      } else {
        const fileDownload = data.file
        const type = data.type === 'th' ? 'thai' : 'eng'
        if (fileDownload !== '' && fileDownload !== null) {
          if (data.type === 'th') {
            this.loadingFileBannerTH = true
          } else if (data.type === 'eng') {
            this.loadingFileBannerENG = true
          }
          const payload = {
            file: fileDownload,
            title: data.title,
            description: '',
            tags: ['56-1', data.year, type]
          }
          const res = await this.uploadFile(payload)
          if (data.type === 'th') {
            this.loadingFileBannerTH = false
          } else if (data.type === 'eng') {
            this.loadingFileBannerENG = false
          }

          if (res.status) {
            if (data.type === 'th') {
              this.dataForm561.banner_th = res.data.filename
            } else if (data.type === 'eng') {
              this.dataForm561.banner_eng = res.data.filename
            }
          } else {
            this.antmessage.error(res.message)
          }
        } else {
          if (data.type === 'th') {
            this.dataForm561.banner_th = ''
          } else if (data.type === 'eng') {
            this.dataForm561.banner_eng = ''
          }
        }
      }
    },
    resetFormForm561 () {
      this.titleForm561 = ''
      this.dataForm561 = this.defalitDataForm561
    },
    addAndEditDataForm561 (data) {
      if (data.status === 'add') {
        this.addDataForm561()
      } else if (data.status === 'edit') {
        this.editDataForm561()
      }
    },
    async addDataForm561 () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataForm561.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataForm561.update_by = this.userInfo.user_id
      this.dataForm561.create_by = this.userInfo.user_id

      const payload = Object.assign({}, this.itemsTableForm561All)
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.update_by = this.userInfo.user_id
      payload.items.unshift(this.dataForm561)

      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มไฟล์แบบ 56-1 สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableForm561()
        this.statusDialogFormForm561 = false
        this.$refs.dialogFormForm561.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    editFileForm561 (item) {
      this.indexData = item.index
      this.statusForm = 'edit'
      this.titleForm561 = item.item.title_th
      this.dataForm561 = Object.assign({}, item.item)
      this.titleHeader = 'แก้ไขเอกสารดาวน์โหลด'
      this.iconTitleaAddEdit = 'mdi-pencil'
      this.statusDialogFormForm561 = true
    },
    async editDataForm561 () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataForm561.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataForm561.update_by = this.userInfo.user_id
      const payload = Object.assign({}, this.itemsTableForm561All)
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.update_by = this.userInfo.user_id
      payload.items[this.indexData] = this.dataForm561
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขไฟล์แบบ 56-1 สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableForm561()
        this.statusDialogFormForm561 = false
        this.$refs.dialogFormForm561.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckDeleteFileForm561 (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบไฟล์',
        text: `คุณต้องการลบไฟล์แบบ 56-1 ปี ${item.title_th} ใช่หรือไม่`,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.itemsTableForm561All.items.splice(0, 1)
          this.deleteFileForm561(this.itemsTableForm561All)
        }
      })
    },
    async deleteFileForm561 (item) {
      this.loading = true
      const payload = Object.assign({}, item)
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.update_by = this.userInfo.user_id
      const res = await this.updateList(payload)
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบไฟล์แบบ 56-1สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableForm561()
      } else {
        this.antmessage.error(res.message)
      }
    },
    // งบการเงิน
    changeYearFinancialStatement () {
      this.initTableFinancialStatement()
    },
    async createFinancialStatement (items) {
      this.loadingTableFinancialStatement = true
      const data = items
      data.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      const res = await this.createList(data)
      this.loadingTableFinancialStatement = false
      if (res.status) {
        this.initTableFinancialStatement()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async setUpTableFinancialStatement () {
      const years = this.$moment().format('YYYY')
      this.yearSelectFinancialStatement = years
      this.initItemsYearsSelectFinancialStatement()
      this.initTableFinancialStatement()
    },
    initItemsYearsSelectFinancialStatement () {
      const years = parseInt(this.$moment().format('YYYY'))
      const itemsYears = []
      for (let index = years; index >= 2014; index--) {
        itemsYears.push({
          text: JSON.stringify(index + 543),
          val: JSON.stringify(index)
        })
      }
      this.itemsYearSelectFinancialStatement = itemsYears
    },
    onChangeTableFinancialStatement (val) {
      this.paginationTableFinancialStatement.page = val.page
      this.paginationTableFinancialStatement.row = val.row
      // this.initTableFinancialStatement()
    },
    async initTableFinancialStatement () {
      this.loadingTableFinancialStatement = true
      const payload = {
        page: this.paginationTableFinancialStatement.page,
        row: this.paginationTableFinancialStatement.row,
        keyword: `งบการเงิน ${
          parseInt(this.yearSelectFinancialStatement) + 543
        }`
      }
      this.itemsTableFinancialStatement = []

      const res = await this.getList(payload)
      this.loadingTableFinancialStatement = false
      if (res.status) {
        if (res.data.data.length !== 0) {
          this.itemsTableFinancialStatement = res.data.data[0].items
          this.itemsTableFinancialStatementAll = res.data.data[0]
          this.paginationTableFinancialStatement.total =
            res.data.pagination.total
          this.paginationTableFinancialStatement.page =
            res.data.pagination.page
        } else {
          const items = {
            title_th: `งบการเงิน ${
              parseInt(this.yearSelectFinancialStatement) + 543
            }`,
            title_eng: `Financial Statement ${parseInt(
              this.yearSelectFinancialStatement
            )}`,
            file_th: '',
            file_eng: '',
            type: 'text',
            description_th: '',
            description_eng: '',
            items: []
          }
          await this.createFinancialStatement(items)
          // await this.initTableFinancialStatement()
        }
      } else {
        this.antmessage.error(res.message)
      }
    },
    initltItemsTitleFinancialStatement () {
      const items = []
      for (
        let index = 0;
        index < this.defaultItemsTitleFinancialStatement.length;
        index++
      ) {
        let status = false
        for (
          let index1 = 0;
          index1 < this.itemsTableFinancialStatement.length;
          index1++
        ) {
          let val = ''

          if (
            this.defaultItemsTitleFinancialStatement[index].eng === 'Year End'
          ) {
            val =
              this.defaultItemsTitleFinancialStatement[index].eng +
              ' ' +
              this.yearSelectFinancialStatement
          } else {
            val =
              this.defaultItemsTitleFinancialStatement[index].eng +
              this.yearSelectFinancialStatement
          }
          if (val === this.itemsTableFinancialStatement[index1].title_eng) {
            status = true
          }
        }
        if (!status) {
          let valItem = {}

          if (
            this.defaultItemsTitleFinancialStatement[index].eng === 'Year End'
          ) {
            valItem = {
              th:
                this.defaultItemsTitleFinancialStatement[index].th +
                ' ' +
                `${parseInt(this.yearSelectFinancialStatement) + 543}`,
              eng:
                this.defaultItemsTitleFinancialStatement[index].eng +
                ' ' +
                this.yearSelectFinancialStatement
            }
          } else {
            valItem = {
              th:
                this.defaultItemsTitleFinancialStatement[index].th +
                `${parseInt(this.yearSelectFinancialStatement) + 543}`,
              eng:
                this.defaultItemsTitleFinancialStatement[index].eng +
                this.yearSelectFinancialStatement
            }
          }
          items.push(valItem)
        }
      }
      this.itemsTitleFinancialStatement = items
      if (this.itemsTitleFinancialStatement.length !== 0) {
        this.dataFinancialStatement.title_th =
          this.itemsTitleFinancialStatement[0].th
        this.dataFinancialStatement.title_eng =
          this.itemsTitleFinancialStatement[0].eng
      }
    },
    async uploadFileFinancialStatement (data) {
      if (data.title === 'cancle edit') {
        if (data.type === 'th') {
          this.dataFinancialStatement.file_th = data.file
        } else if (data.type === 'eng') {
          this.dataFinancialStatement.file_eng = data.file
        }
      } else {
        const fileDownload = data.file
        const type = data.type === 'th' ? 'thai' : 'eng'
        if (fileDownload !== '' && fileDownload !== null) {
          if (data.type === 'th') {
            this.loadingFileDownloadTH = true
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = true
          }
          const payload = {
            file: fileDownload,
            title: data.title,
            description: '',
            tags: [
              'financialStatement',
              this.yearSelectFinancialStatement,
              type
            ]
          }
          const res = await this.uploadFile(payload)
          if (data.type === 'th') {
            this.loadingFileDownloadTH = false
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = false
          }

          if (res.status) {
            if (data.type === 'th') {
              this.dataFinancialStatement.file_th = res.data.filename
            } else if (data.type === 'eng') {
              this.dataFinancialStatement.file_eng = res.data.filename
            }
          } else {
            this.antmessage.error(res.message)
          }
        } else {
          if (data.type === 'th') {
            this.dataFinancialStatement.file_th = ''
          } else if (data.type === 'eng') {
            this.dataFinancialStatement.file_eng = ''
          }
        }
      }
    },
    resetFormFinancialStatement () {
      this.dataFinancialStatement = this.defalitDataFinancialStatement
    },
    addAndEditDataFinancialStatement (data) {
      if (data.status === 'add') {
        this.addDataFinancialStatement()
      } else if (data.status === 'edit') {
        this.editDataFinancialStatement()
      }
    },
    async addDataFinancialStatement () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataFinancialStatement.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataFinancialStatement.update_by = this.userInfo.user_id
      this.dataFinancialStatement.create_by = this.userInfo.user_id
      const payload = Object.assign({}, this.itemsTableFinancialStatementAll)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.items.unshift(this.dataFinancialStatement)
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มไฟล์งบการเงินสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableFinancialStatement()
        this.statusDialogFormFinancialStatement = false
        this.$refs.dialogFormFinancialStatement.close()
      } else {
        this.antmessage.error(res.message)
      }
    },
    editFileFinancialStatement (item) {
      this.indexData = item.index
      this.statusForm = 'edit'
      this.itemsTitleFinancialStatement = [
        { th: item.item.title_th, eng: item.item.title_eng }
      ]
      this.dataFinancialStatement = Object.assign({}, item.item)
      this.titleHeader = 'แก้ไขเอกสารดาวน์โหลด'
      this.iconTitleaAddEdit = 'mdi-pencil'
      this.statusDialogFormFinancialStatement = true
    },
    async editDataFinancialStatement () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataFinancialStatement.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataFinancialStatement.update_by = this.userInfo.user_id
      const payload = Object.assign({}, this.itemsTableFinancialStatementAll)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.items[this.indexData] = this.dataFinancialStatement
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขไฟล์งบการเงินสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableFinancialStatement()
        this.statusDialogFormFinancialStatement = false
        this.$refs.dialogFormFinancialStatement.close()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckDeleteFileFinancialStatement (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบไฟล์',
        text: `คุณต้องการลบไฟล์ ${item.title_th} ใช่หรือไม่`,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.itemsTableFinancialStatementAll.items.splice(0, 1)
          this.deleteFileFinancialStatement(
            this.itemsTableFinancialStatementAll
          )
        }
      })
    },
    async deleteFileFinancialStatement (item) {
      this.loading = true
      const payload = Object.assign({}, item)
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.update_by = this.userInfo.user_id
      const res = await this.updateList(payload)
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบไฟล์งบการเงินสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableFinancialStatement()
      } else {
        this.antmessage.error(res.message)
      }
    },

    // คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการ
    changeYearMDandA () {
      this.initTableMdAndA()
    },
    async createMdAndA (items) {
      this.loadingTableMdAndA = true
      const data = items
      data.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      const res = await this.createList(data)
      this.loadingTableMdAndA = false
      if (res.status) {
        this.initTableMdAndA()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async setUpTableMDandA () {
      const years = this.$moment().format('YYYY')
      this.yearSelectMDandA = years
      this.inititemsYearSelectMDandA()
      this.initTableMdAndA()
    },
    inititemsYearSelectMDandA () {
      const years = parseInt(this.$moment().format('YYYY'))
      const itemsYears = []
      for (let index = years; index >= 2015; index--) {
        itemsYears.push({
          text: JSON.stringify(index + 543),
          val: JSON.stringify(index)
        })
      }
      this.itemsYearSelectMDandA = itemsYears
    },
    onChangeTableMdAndA (val) {
      this.paginationTableMdAndA.page = val.page
      this.paginationTableMdAndA.row = val.row
      // this.initTableMdAndA()
    },
    async initTableMdAndA () {
      this.loadingTableMdAndA = true
      const payload = {
        page: this.paginationTableMdAndA.page,
        row: this.paginationTableMdAndA.row,
        keyword: `คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการ ${
          parseInt(this.yearSelectMDandA) + 543
        }`
      }
      this.itemsTableMdAndA = []

      const res = await this.getList(payload)
      this.loadingTableMdAndA = false
      if (res.status) {
        if (res.data.data.length !== 0) {
          this.itemsTableMdAndA = res.data.data[0].items
          this.itemsTableMdAndAAll = res.data.data[0]
          this.paginationTableMdAndA.total = res.data.pagination.total
          this.paginationTableMdAndA.page = res.data.pagination.page
        } else {
          const items = {
            title_th: `คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการ ${
              parseInt(this.yearSelectMDandA) + 543
            }`,
            title_eng: `MD&A ${parseInt(this.yearSelectMDandA)}`,
            file_th: '',
            file_eng: '',
            type: 'text',
            description_th: '',
            description_eng: '',
            items: []
          }
          await this.createMdAndA(items)
        }
      } else {
        this.antmessage.error(res.message)
      }
    },
    initltItemsTitleMdAndA () {
      const items = []
      for (
        let index = 0;
        index < this.defaultItemsTitleTableMdAndA.length;
        index++
      ) {
        let status = false
        for (let index1 = 0; index1 < this.itemsTableMdAndA.length; index1++) {
          let val = ''
          if (this.defaultItemsTitleTableMdAndA[index].eng === 'Year End') {
            val =
              this.defaultItemsTitleTableMdAndA[index].eng +
              ' ' +
              this.yearSelectMDandA
          } else {
            val =
              this.defaultItemsTitleTableMdAndA[index].eng +
              this.yearSelectMDandA
          }
          if (val === this.itemsTableMdAndA[index1].title_eng) {
            status = true
          }
        }
        if (!status) {
          let valItem = {}
          if (this.defaultItemsTitleTableMdAndA[index].eng === 'Year End') {
            valItem = {
              th:
                this.defaultItemsTitleTableMdAndA[index].th +
                ' ' +
                `${parseInt(this.yearSelectMDandA) + 543}`,
              eng:
                this.defaultItemsTitleTableMdAndA[index].eng +
                ' ' +
                this.yearSelectMDandA
            }
          } else {
            valItem = {
              th:
                this.defaultItemsTitleTableMdAndA[index].th +
                `${parseInt(this.yearSelectMDandA) + 543}`,
              eng:
                this.defaultItemsTitleTableMdAndA[index].eng +
                this.yearSelectMDandA
            }
          }
          items.push(valItem)
        }
      }
      this.itemsTitleMdAndA = items
      if (this.itemsTitleMdAndA.length !== 0) {
        this.dataMdAndA.title_th = this.itemsTitleMdAndA[0].th
        this.dataMdAndA.title_eng = this.itemsTitleMdAndA[0].eng
      }
    },
    async uploadFileMdAndA (data) {
      if (data.title === 'cancle edit') {
        if (data.type === 'th') {
          this.dataMdAndA.file_th = data.file
        } else if (data.type === 'eng') {
          this.dataMdAndA.file_eng = data.file
        }
      } else {
        const fileDownload = data.file
        const type = data.type === 'th' ? 'thai' : 'eng'
        if (fileDownload !== '' && fileDownload !== null) {
          if (data.type === 'th') {
            this.loadingFileDownloadTH = true
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = true
          }
          const payload = {
            file: fileDownload,
            title: data.title,
            description: '',
            tags: ['MDandA', this.yearSelectMDandA, type]
          }
          const res = await this.uploadFile(payload)
          if (data.type === 'th') {
            this.loadingFileDownloadTH = false
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = false
          }

          if (res.status) {
            if (data.type === 'th') {
              this.dataMdAndA.file_th = res.data.filename
            } else if (data.type === 'eng') {
              this.dataMdAndA.file_eng = res.data.filename
            }
          } else {
            this.antmessage.error(res.message)
          }
        } else {
          if (data.type === 'th') {
            this.dataMdAndA.file_th = ''
          } else if (data.type === 'eng') {
            this.dataMdAndA.file_eng = ''
          }
        }
      }
    },
    resetFormMdAndA () {
      this.dataMdAndA = this.defalitDataMdAndA
    },
    addAndEditDataMdAndA (data) {
      if (data.status === 'add') {
        this.addDataMdAndA()
      } else if (data.status === 'edit') {
        this.editDataMdAndA()
      }
    },
    async addDataMdAndA () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataMdAndA.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataMdAndA.update_by = this.userInfo.user_id
      this.dataMdAndA.create_by = this.userInfo.user_id
      const payload = Object.assign({}, this.itemsTableMdAndAAll)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.items.unshift(this.dataMdAndA)
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มไฟล์คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableMdAndA()
        this.statusDialogFormMdAndA = false
        this.$refs.dialogFormMdAndA.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    editFileMdAndA (item) {
      this.indexData = item.index
      this.statusForm = 'edit'
      this.itemsTitleMdAndA = [
        { th: item.item.title_th, eng: item.item.title_eng }
      ]
      this.dataMdAndA = Object.assign({}, item.item)
      this.titleHeader = 'แก้ไขเอกสารดาวน์โหลด'
      this.iconTitleaAddEdit = 'mdi-pencil'
      this.statusDialogFormMdAndA = true
    },
    async editDataMdAndA () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataMdAndA.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataMdAndA.update_by = this.userInfo.user_id
      const payload = Object.assign({}, this.itemsTableMdAndAAll)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.items[this.indexData] = this.dataMdAndA
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขไฟล์คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableMdAndA()
        this.statusDialogFormMdAndA = false
        this.$refs.dialogFormMdAndA.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckDeleteFileMdAndA (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบไฟล์',
        text: `คุณต้องการลบไฟล์ ${item.title_th} ใช่หรือไม่`,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.itemsTableMdAndAAll.items.splice(0, 1)
          this.deleteFileMdAndA(this.itemsTableMdAndAAll)
        }
      })
    },
    async deleteFileMdAndA (item) {
      this.loading = true
      const payload = Object.assign({}, item)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      const res = await this.updateList(payload)
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบไฟล์คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableMdAndA()
      } else {
        this.antmessage.error(res.message)
      }
    },

    // รายละเอียดการใช้สิทธิใบสำคัญแสดงสิทธิ INET-W2
    setUpTableSubscriptionRightsOfINETW2 () {
      this.initTableSubscriptionRightsOfINETW2()
    },
    onChangeTableSubscriptionRightsOfINETW2 (val) {
      this.paginationTableSubscriptionRightsOfINETW2.page = val.page
      this.paginationTableSubscriptionRightsOfINETW2.row = val.row
    },
    async initTableSubscriptionRightsOfINETW2 () {
      this.loadingTableSubscriptionRightsOfINETW2 = true
      const payload = {
        page: this.paginationTableSubscriptionRightsOfINETW2.page,
        row: this.paginationTableSubscriptionRightsOfINETW2.row,
        keyword: 'Subscription Rights of INET-W'
      }
      this.itemsTableSubscriptionRightsOfINETW2 = []
      const res = await this.getList(payload)
      this.loadingTableSubscriptionRightsOfINETW2 = false
      if (res.status) {
        if (res.data.data.length !== 0) {
          this.itemsTableSubscriptionRightsOfINETW2 = res.data.data[0].items
          this.itemsTableSubscriptionRightsOfINETW2All = res.data.data[0]
        }
        this.paginationTableSubscriptionRightsOfINETW2.total =
          res.data.pagination.total
        this.paginationTableSubscriptionRightsOfINETW2.page =
          res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    async uploadFileSubscriptionRightsOfINETW2 (data) {
      if (data.title === 'cancle edit') {
        if (data.type === 'th') {
          this.dataSubscriptionRightsOfINETW2.file_th = data.file
        } else if (data.type === 'eng') {
          this.dataSubscriptionRightsOfINETW2.file_eng = data.file
        }
      } else {
        const fileDownload = data.file
        const type = data.type === 'th' ? 'thai' : 'eng'
        if (fileDownload !== '' && fileDownload !== null) {
          if (data.type === 'th') {
            this.loadingFileDownloadTH = true
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = true
          }
          const payload = {
            file: fileDownload,
            title: data.title,
            description: '',
            tags: ['INETW2', type]
          }
          const res = await this.uploadFile(payload)
          if (data.type === 'th') {
            this.loadingFileDownloadTH = false
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = false
          }

          if (res.status) {
            if (data.type === 'th') {
              this.dataSubscriptionRightsOfINETW2.file_th = res.data.filename
            } else if (data.type === 'eng') {
              this.dataSubscriptionRightsOfINETW2.file_eng = res.data.filename
            }
          } else {
            this.antmessage.error(res.message)
          }
        } else {
          if (data.type === 'th') {
            this.dataSubscriptionRightsOfINETW2.file_th = ''
          } else if (data.type === 'eng') {
            this.dataSubscriptionRightsOfINETW2.file_eng = ''
          }
        }
      }
    },
    resetFormSubscriptionRightsOfINETW2 () {
      this.dataSubscriptionRightsOfINETW2 =
        this.defalitDataSubscriptionRightsOfINETW2
    },
    addAndEditDataSubscriptionRightsOfINETW2 (data) {
      if (data.status === 'add') {
        this.dataSubscriptionRightsOfINETW2.title_th = data.title_th
        this.dataSubscriptionRightsOfINETW2.title_eng = data.title_eng
        this.addDataSubscriptionRightsOfINETW2()
      } else if (data.status === 'edit') {
        this.dataSubscriptionRightsOfINETW2.title_th = data.title_th
        this.dataSubscriptionRightsOfINETW2.title_eng = data.title_eng
        this.editDataSubscriptionRightsOfINETW2()
      }
    },
    async addDataSubscriptionRightsOfINETW2 () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataSubscriptionRightsOfINETW2.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataSubscriptionRightsOfINETW2.update_by = this.userInfo.user_id
      this.dataSubscriptionRightsOfINETW2.create_by = this.userInfo.user_id
      const payload = Object.assign(
        {},
        this.itemsTableSubscriptionRightsOfINETW2All
      )
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.items.unshift(this.dataSubscriptionRightsOfINETW2)
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มไฟล์รายละเอียดการใช้สิทธิใบสำคัญแสดงสิทธิ INET-W3 สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableSubscriptionRightsOfINETW2()
        this.statusDialogFormSubscriptionRightsOfINETW2 = false
        this.$refs.dialogFormSubscriptionRightsOfINETW2.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    editFileSubscriptionRightsOfINETW2 (item) {
      this.indexData = item.index
      this.statusForm = 'edit'
      this.dataSubscriptionRightsOfINETW2 = Object.assign({}, item.item)
      this.titleHeader = 'แก้ไขเอกสารดาวน์โหลด'
      this.iconTitleaAddEdit = 'mdi-pencil'
      this.statusDialogFormSubscriptionRightsOfINETW2 = true
    },
    async editDataSubscriptionRightsOfINETW2 () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataSubscriptionRightsOfINETW2.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataSubscriptionRightsOfINETW2.update_by = this.userInfo.user_id
      const payload = Object.assign(
        {},
        this.itemsTableSubscriptionRightsOfINETW2All
      )
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.items[this.indexData] = this.dataSubscriptionRightsOfINETW2
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขไฟล์รายละเอียดการใช้สิทธิใบสำคัญแสดงสิทธิ INET-W3 สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableSubscriptionRightsOfINETW2()
        this.statusDialogFormSubscriptionRightsOfINETW2 = false
        this.$refs.dialogFormSubscriptionRightsOfINETW2.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckDeleteFileSubscriptionRightsOfINETW2 (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบไฟล์',
        text: `คุณต้องการลบไฟล์ ${item.item.title_th} ใช่หรือไม่`,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableSubscriptionRightsOfINETW2All.items)
          )
          let item1 = JSON.parse(
            JSON.stringify(this.itemsTableSubscriptionRightsOfINETW2All.items)
          )
          item1 = item1.splice(0, item.index)
          let item2 = JSON.parse(
            JSON.stringify(this.itemsTableSubscriptionRightsOfINETW2All.items)
          )
          item2 = item2.splice(item.index + 1, itemAll.length)
          const newItem = item1.concat(item2)
          this.itemsTableSubscriptionRightsOfINETW2All.items = newItem
          this.deleteFileSubscriptionRightsOfINETW2(
            this.itemsTableSubscriptionRightsOfINETW2All
          )
        }
      })
    },
    async deleteFileSubscriptionRightsOfINETW2 (item) {
      this.loading = true
      const payload = Object.assign({}, item)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      const res = await this.updateList(payload)
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบไฟล์รายละเอียดการใช้สิทธิใบสำคัญแสดงสิทธิ INET-W3 สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableSubscriptionRightsOfINETW2()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckChangeIndexFileSubscriptionRightsOfINETW2 (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'เปลี่ยนลำดับไฟล์',
        text: 'คุณต้องการเปลี่ยนลำดับไฟล์รายละเอียดการใช้สิทธิใบสำคัญแสดงสิทธิ INET-W3 ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableSubscriptionRightsOfINETW2All.items)
          )
          if (item.status === 'up') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(
                this.itemsTableSubscriptionRightsOfINETW2All.items[
                  item.index - 1
                ]
              )
            )
            itemAll[item.index - 1] = JSON.parse(
              JSON.stringify(
                this.itemsTableSubscriptionRightsOfINETW2All.items[item.index]
              )
            )
          } else if (item.status === 'down') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(
                this.itemsTableSubscriptionRightsOfINETW2All.items[
                  item.index + 1
                ]
              )
            )
            itemAll[item.index + 1] = JSON.parse(
              JSON.stringify(
                this.itemsTableSubscriptionRightsOfINETW2All.items[item.index]
              )
            )
          }
          this.itemsTableSubscriptionRightsOfINETW2All.items = itemAll
          this.changeIndexFileSubscriptionRightsOfINETW2(
            this.itemsTableSubscriptionRightsOfINETW2All
          )
        }
      })
    },
    async changeIndexFileSubscriptionRightsOfINETW2 (item) {
      this.loading = true
      const payload = Object.assign({}, item)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      const res = await this.updateList(payload)
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เปลี่ยนลำดับไฟล์รายละเอียดการใช้สิทธิใบสำคัญแสดงสิทธิ INET-W3 สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableSubscriptionRightsOfINETW2()
      } else {
        this.antmessage.error(res.message)
      }
    },
    // นโยบายที่เกี่ยวข้อง
    setUpTableGovernancePolicy () {
      this.initTableGovernancePolicy()
    },
    onChangeTableGovernancePolicy (val) {
      this.paginationTableGovernancePolicy.page = val.page
      this.paginationTableGovernancePolicy.row = val.row
    },
    async initTableGovernancePolicy () {
      this.loadingTableGovernancePolicy = true
      const payload = {
        page: this.paginationTableGovernancePolicy.page,
        row: this.paginationTableGovernancePolicy.row,
        keyword: 'Governance Policy'
      }
      this.itemsTableGovernancePolicy = []
      const res = await this.getList(payload)
      this.loadingTableGovernancePolicy = false
      if (res.status) {
        if (res.data.data.length !== 0) {
          this.itemsTableGovernancePolicy = res.data.data[0].items
          this.itemsTableGovernancePolicyAll = res.data.data[0]
        }
        this.paginationTableGovernancePolicy.total = res.data.pagination.total
        this.paginationTableGovernancePolicy.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    async uploadFileGovernancePolicy (data) {
      if (data.title === 'cancle edit') {
        if (data.type === 'th') {
          this.dataGovernancePolicy.file_th = data.file
        } else if (data.type === 'eng') {
          this.dataGovernancePolicy.file_eng = data.file
        }
      } else {
        const fileDownload = data.file
        const type = data.type === 'th' ? 'thai' : 'eng'
        if (fileDownload !== '' && fileDownload !== null) {
          if (data.type === 'th') {
            this.loadingFileDownloadTH = true
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = true
          }
          const payload = {
            file: fileDownload,
            title: data.title,
            description: '',
            tags: ['GovernancePolicy', type]
          }
          const res = await this.uploadFile(payload)
          if (data.type === 'th') {
            this.loadingFileDownloadTH = false
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = false
          }

          if (res.status) {
            if (data.type === 'th') {
              this.dataGovernancePolicy.file_th = res.data.filename
            } else if (data.type === 'eng') {
              this.dataGovernancePolicy.file_eng = res.data.filename
            }
          } else {
            this.antmessage.error(res.message)
          }
        } else {
          if (data.type === 'th') {
            this.dataGovernancePolicy.file_th = ''
          } else if (data.type === 'eng') {
            this.dataGovernancePolicy.file_eng = ''
          }
        }
      }
    },
    resetFormGovernancePolicy () {
      this.dataGovernancePolicy = this.defalitDataGovernancePolicy
    },
    addAndEditDataGovernancePolicy (data) {
      if (data.status === 'add') {
        this.dataGovernancePolicy.title_th = data.title_th
        this.dataGovernancePolicy.title_eng = data.title_eng
        this.addDataGovernancePolicy()
      } else if (data.status === 'edit') {
        this.dataGovernancePolicy.title_th = data.title_th
        this.dataGovernancePolicy.title_eng = data.title_eng
        this.editDataGovernancePolicy()
      }
    },
    async addDataGovernancePolicy () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataGovernancePolicy.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataGovernancePolicy.update_by = this.userInfo.user_id
      this.dataGovernancePolicy.create_by = this.userInfo.user_id
      const payload = Object.assign({}, this.itemsTableGovernancePolicyAll)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.items.unshift(this.dataGovernancePolicy)
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มไฟล์นโยบายที่เกี่ยวข้องสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableGovernancePolicy()
        this.statusDialogFormGovernancePolicy = false
        this.$refs.dialogFormGovernancePolicy.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    editFileGovernancePolicy (item) {
      this.indexData = item.index
      this.statusForm = 'edit'
      this.dataGovernancePolicy = Object.assign({}, item.item)
      this.titleHeader = 'แก้ไขเอกสารดาวน์โหลด'
      this.iconTitleaAddEdit = 'mdi-pencil'
      this.statusDialogFormGovernancePolicy = true
    },
    async editDataGovernancePolicy () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataGovernancePolicy.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataGovernancePolicy.update_by = this.userInfo.user_id
      const payload = Object.assign({}, this.itemsTableGovernancePolicyAll)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.items[this.indexData] = this.dataGovernancePolicy
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขไฟล์นโยบายที่เกี่ยวข้องสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableGovernancePolicy()
        this.statusDialogFormGovernancePolicy = false
        this.$refs.dialogFormGovernancePolicy.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckDeleteFileGovernancePolicy (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบไฟล์',
        text: `คุณต้องการลบไฟล์ ${item.item.title_th} ใช่หรือไม่`,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableGovernancePolicyAll.items)
          )
          let item1 = JSON.parse(
            JSON.stringify(this.itemsTableGovernancePolicyAll.items)
          )
          item1 = item1.splice(0, item.index)
          let item2 = JSON.parse(
            JSON.stringify(this.itemsTableGovernancePolicyAll.items)
          )
          item2 = item2.splice(item.index + 1, itemAll.length)
          const newItem = item1.concat(item2)
          this.itemsTableGovernancePolicyAll.items = newItem
          this.deleteFileGovernancePolicy(this.itemsTableGovernancePolicyAll)
        }
      })
    },
    async deleteFileGovernancePolicy (item) {
      this.loading = true
      const payload = Object.assign({}, item)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      const res = await this.updateList(payload)
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบไฟล์นโยบายที่เกี่ยวข้องสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableGovernancePolicy()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckChangeIndexFileGovernancePolicy (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'เปลี่ยนลำดับไฟล์',
        text: 'คุณต้องการเปลี่ยนลำดับไฟล์นโยบายที่เกี่ยวข้องใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableGovernancePolicyAll.items)
          )
          if (item.status === 'up') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(
                this.itemsTableGovernancePolicyAll.items[item.index - 1]
              )
            )
            itemAll[item.index - 1] = JSON.parse(
              JSON.stringify(
                this.itemsTableGovernancePolicyAll.items[item.index]
              )
            )
          } else if (item.status === 'down') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(
                this.itemsTableGovernancePolicyAll.items[item.index + 1]
              )
            )
            itemAll[item.index + 1] = JSON.parse(
              JSON.stringify(
                this.itemsTableGovernancePolicyAll.items[item.index]
              )
            )
          }
          this.itemsTableGovernancePolicyAll.items = itemAll
          this.changeIndexFileGovernancePolicy(
            this.itemsTableGovernancePolicyAll
          )
        }
      })
    },
    async changeIndexFileGovernancePolicy (item) {
      this.loading = true
      const payload = Object.assign({}, item)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      const res = await this.updateList(payload)
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เปลี่ยนลำดับไฟล์นโยบายที่เกี่ยวข้องสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableGovernancePolicy()
      } else {
        this.antmessage.error(res.message)
      }
    },
    // เอกสารการประชุม
    async createDocumentsOfMeeting (items) {
      this.loadingTableDocumentsOfMeeting = true
      const data = items
      data.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      const res = await this.createList(data)
      this.loadingTableDocumentsOfMeeting = false
      if (res.status) {
        this.initTableDocumentsOfMeeting()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async initTableDocumentsOfMeeting () {
      this.loadingTableDocumentsOfMeeting = true
      const payload = {
        page: this.paginationTableDocumentsOfMeeting.page,
        row: this.paginationTableDocumentsOfMeeting.row,
        keyword: `Documents of Meeting ${parseInt(
          this.yearSelectDocumentsOfMeeting
        )}`
      }
      this.itemsTableDocumentsOfMeeting = []

      const res = await this.getList(payload)
      this.loadingTableDocumentsOfMeeting = false
      if (res.status) {
        if (res.data.data.length !== 0) {
          this.itemsTableDocumentsOfMeeting = res.data.data[0].items
          for (
            let index = 0;
            index < this.itemsTableDocumentsOfMeeting.length;
            index++
          ) {
            this.itemsTableDocumentsOfMeeting[index].index = index
          }
          this.itemsTableDocumentsOfMeetingAll = res.data.data[0]
          this.paginationTableDocumentsOfMeeting.total =
            res.data.pagination.total
          this.paginationTableDocumentsOfMeeting.page =
            res.data.pagination.page
        } else {
          const items = {
            title_th: `เอกสารการประชุม ${
              parseInt(this.yearSelectDocumentsOfMeeting) + 543
            }`,
            title_eng: `Documents of Meeting ${parseInt(
              this.yearSelectDocumentsOfMeeting
            )}`,
            file_th: '',
            file_eng: '',
            type: 'text',
            description_th: '',
            description_eng: '',
            items: []
          }
          await this.createDocumentsOfMeeting(items)
        }
      } else {
        this.antmessage.error(res.message)
      }
    },
    changeYearDocumentsOfMeeting () {
      this.initTableDocumentsOfMeeting()
    },
    async setUpTableDocumentsOfMeeting () {
      const years = this.$moment().format('YYYY')
      this.yearSelectDocumentsOfMeeting = years
      this.initItemsYearsSelectDocumentsOfMeeting()
      this.initTableDocumentsOfMeeting()
    },
    initItemsYearsSelectDocumentsOfMeeting () {
      const years = parseInt(this.$moment().format('YYYY'))
      const itemsYears = []
      for (let index = years; index >= 2013; index--) {
        itemsYears.push({
          text: JSON.stringify(index + 543),
          val: JSON.stringify(index)
        })
      }
      this.itemsYearSelectDocumentsOfMeeting = itemsYears
    },
    onChangeTableDocumentsOfMeeting (val) {
      this.paginationTableDocumentsOfMeeting.page = val.page
      this.paginationTableDocumentsOfMeeting.row = val.row
      // this.inittableGovernancePolicy()
    },
    resetFormDocumentsOfMeeting () {
      this.dataDocumentsOfMeeting = this.defalitDocumentsOfMeeting
    },
    addAndEditDataDocumentsOfMeeting (data) {
      if (data.status === 'add') {
        this.dataDocumentsOfMeeting.title_th = data.title_th
        this.dataDocumentsOfMeeting.title_eng = data.title_eng
        this.addDataDocumentsOfMeetingy()
      } else if (data.status === 'edit') {
        this.dataDocumentsOfMeeting.title_th = data.title_th
        this.dataDocumentsOfMeeting.title_eng = data.title_eng
        this.editDataDocumentsOfMeeting()
      }
    },
    async addDataDocumentsOfMeetingy () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataDocumentsOfMeeting.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataDocumentsOfMeeting.update_by = this.userInfo.user_id
      this.dataDocumentsOfMeeting.create_by = this.userInfo.user_id

      const payload = Object.assign({}, this.itemsTableDocumentsOfMeetingAll)
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.update_by = this.userInfo.user_id
      payload.items.unshift(this.dataDocumentsOfMeeting)

      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มหัวข้อเอกสารการประชุมสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableDocumentsOfMeeting()
        this.statusDialogFormDocumentsOfMeeting = false
        this.$refs.dialogFormDocumentsOfMeeting.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async editDataDocumentsOfMeeting () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataDocumentsOfMeeting.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataDocumentsOfMeeting.update_by = this.userInfo.user_id
      const payload = Object.assign({}, this.itemsTableDocumentsOfMeetingAll)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.items[this.indexData] = this.dataDocumentsOfMeeting
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขหัวข้อเอกสารการประชุมสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableDocumentsOfMeeting()
        this.statusDialogFormDocumentsOfMeeting = false
        this.$refs.dialogFormDocumentsOfMeeting.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    editFileDataDocumentsOfMeeting (item) {
      this.indexData = item.index
      this.statusForm = 'edit'
      this.dataDocumentsOfMeeting = Object.assign({}, item.item)
      this.titleHeader = 'หัวข้อเอกสารการประชุมสำเร็จ'
      this.iconTitleaAddEdit = 'mdi-pencil'
      this.statusDialogFormDocumentsOfMeeting = true
    },
    async recheckDeleteFileDocumentsOfMeeting (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบไฟล์',
        text: `คุณต้องการลบหัวข้อ ${item.item.title_th} ใช่หรือไม่`,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableDocumentsOfMeetingAll.items)
          )
          let item1 = JSON.parse(
            JSON.stringify(this.itemsTableDocumentsOfMeetingAll.items)
          )
          item1 = item1.splice(0, item.index)
          let item2 = JSON.parse(
            JSON.stringify(this.itemsTableDocumentsOfMeetingAll.items)
          )
          item2 = item2.splice(item.index + 1, itemAll.length)
          const newItem = item1.concat(item2)
          this.itemsTableDocumentsOfMeetingAll.items = newItem
          this.deleteFileDocumentsOfMeeting(
            this.itemsTableDocumentsOfMeetingAll
          )
        }
      })
    },
    async deleteFileDocumentsOfMeeting (item) {
      this.loading = true
      const payload = Object.assign({}, item)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      const res = await this.updateList(payload)
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบหัวข้อการประชุมสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableDocumentsOfMeeting()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckChangeIndexFileDocumentsOfMeeting (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'เปลี่ยนลำดับหัวข้อ',
        text: 'คุณต้องการเปลี่ยนลำดับหัวข้อเอกสารการประชุมใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableDocumentsOfMeetingAll.items)
          )
          if (item.status === 'up') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(
                this.itemsTableDocumentsOfMeetingAll.items[item.index - 1]
              )
            )
            itemAll[item.index - 1] = JSON.parse(
              JSON.stringify(
                this.itemsTableDocumentsOfMeetingAll.items[item.index]
              )
            )
          } else if (item.status === 'down') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(
                this.itemsTableDocumentsOfMeetingAll.items[item.index + 1]
              )
            )
            itemAll[item.index + 1] = JSON.parse(
              JSON.stringify(
                this.itemsTableDocumentsOfMeetingAll.items[item.index]
              )
            )
          }
          this.itemsTableDocumentsOfMeetingAll.items = itemAll
          this.changeIndexFileDocumentsOfMeeting(
            this.itemsTableDocumentsOfMeetingAll
          )
        }
      })
    },
    async changeIndexFileDocumentsOfMeeting (item) {
      this.loading = true
      const payload = Object.assign({}, item)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      const res = await this.updateList(payload)
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เปลี่ยนลำดับหัวข้อเอกสารการประชุมสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableDocumentsOfMeeting()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async changeIndexSubFileDocumentsOfMeeting (item) {
      this.loading = true
      const payload = Object.assign({}, item)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      const res = await this.updateList(payload)
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เปลี่ยนลำดับไฟล์เอกสารการประชุมสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableDocumentsOfMeeting()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckChangeIndexSubFileDocumentsOfMeeting (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'เปลี่ยนลำดับไฟล์',
        text: 'คุณต้องการเปลี่ยนลำดับไฟล์เอกสารการประชุมใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(
              this.itemsTableDocumentsOfMeetingAll.items[item.indexMain].items
            )
          )
          if (item.status === 'up') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(
                this.itemsTableDocumentsOfMeetingAll.items[item.indexMain]
                  .items[item.index - 1]
              )
            )
            itemAll[item.index - 1] = JSON.parse(
              JSON.stringify(
                this.itemsTableDocumentsOfMeetingAll.items[item.indexMain]
                  .items[item.index]
              )
            )
          } else if (item.status === 'down') {
            itemAll[item.index] = JSON.parse(
              JSON.stringify(
                this.itemsTableDocumentsOfMeetingAll.items[item.indexMain]
                  .items[item.index + 1]
              )
            )
            itemAll[item.index + 1] = JSON.parse(
              JSON.stringify(
                this.itemsTableDocumentsOfMeetingAll.items[item.indexMain]
                  .items[item.index]
              )
            )
          }
          this.itemsTableDocumentsOfMeetingAll.items[item.indexMain].items =
            itemAll
          this.changeIndexSubFileDocumentsOfMeeting(
            this.itemsTableDocumentsOfMeetingAll
          )
        }
      })
    },
    openAddFileDocumentsOfMeetingSub (índexMain) {
      this.statusForm = 'add'
      this.documentsOfMeetingIndexMain = índexMain
      this.titleHeader = 'เพิ่มเอกสารดาวน์โหลด'
      this.iconTitleaAddEdit = 'mdi-plus'
      this.statusDialogFormDocumentsOfMeetingSub = true
    },
    async uploadFileDocumentsOfMeetingSub (data) {
      if (data.title === 'cancle edit') {
        if (data.type === 'th') {
          this.dataDocumentsOfMeetingSub.file_th = data.file
        } else if (data.type === 'eng') {
          this.dataDocumentsOfMeetingSub.file_eng = data.file
        }
      } else {
        const fileDownload = data.file
        const type = data.type === 'th' ? 'thai' : 'eng'
        if (fileDownload !== '' && fileDownload !== null) {
          if (data.type === 'th') {
            this.loadingFileDownloadTH = true
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = true
          }
          const payload = {
            file: fileDownload,
            title: data.title,
            description: '',
            tags: ['DocumentsOfMeetingSub', type]
          }
          const res = await this.uploadFile(payload)
          if (data.type === 'th') {
            this.loadingFileDownloadTH = false
          } else if (data.type === 'eng') {
            this.loadingFileDownloadENG = false
          }

          if (res.status) {
            if (data.type === 'th') {
              this.dataDocumentsOfMeetingSub.file_th = res.data.filename
            } else if (data.type === 'eng') {
              this.dataDocumentsOfMeetingSub.file_eng = res.data.filename
            }
          } else {
            this.antmessage.error(res.message)
          }
        } else {
          if (data.type === 'th') {
            this.dataDocumentsOfMeetingSub.file_th = ''
          } else if (data.type === 'eng') {
            this.dataDocumentsOfMeetingSub.file_eng = ''
          }
        }
      }
    },
    resetFormDocumentsOfMeetingSub () {
      this.dataDocumentsOfMeetingSub = this.defalitDocumentsOfMeetingSub
    },
    addAndEditDataDocumentsOfMeetingSub (data) {
      if (data.status === 'add') {
        this.dataDocumentsOfMeetingSub.title_th = data.title_th
        this.dataDocumentsOfMeetingSub.title_eng = data.title_eng
        this.addDataDocumentsOfMeetingSub()
      } else if (data.status === 'edit') {
        this.dataDocumentsOfMeetingSub.title_th = data.title_th
        this.dataDocumentsOfMeetingSub.title_eng = data.title_eng
        this.editDataDocumentsOfMeetingSub()
      }
    },
    async addDataDocumentsOfMeetingSub () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataDocumentsOfMeetingSub.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataDocumentsOfMeetingSub.update_by = this.userInfo.user_id
      this.dataDocumentsOfMeetingSub.create_by = this.userInfo.user_id
      const payload = Object.assign({}, this.itemsTableDocumentsOfMeetingAll)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.items[this.documentsOfMeetingIndexMain].update_by =
        this.userInfo.user_id
      payload.items[this.documentsOfMeetingIndexMain].edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      payload.items[this.documentsOfMeetingIndexMain].items.unshift(
        this.dataDocumentsOfMeetingSub
      )
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มไฟล์เอกสารการประชุม สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableDocumentsOfMeeting()
        this.statusDialogFormDocumentsOfMeetingSub = false
        this.$refs.dialogFormDocumentsOfMeetingSub.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    editFileDocumentsOfMeetingSub (item) {
      this.documentsOfMeetingIndexMain = item.indexMain
      this.documentsOfMeetingIndexSub = item.indexSub
      this.statusForm = 'edit'
      this.dataDocumentsOfMeetingSub = Object.assign({}, item.item)
      this.titleHeader = 'แก้ไขเอกสารดาวน์โหลด'
      this.iconTitleaAddEdit = 'mdi-pencil'
      this.statusDialogFormDocumentsOfMeetingSub = true
    },
    async editDataDocumentsOfMeetingSub () {
      this.loadingFileDownload = true
      this.loading = true
      this.dataDocumentsOfMeetingSub.edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')
      this.dataDocumentsOfMeetingSub.update_by = this.userInfo.user_id
      const payload = Object.assign({}, this.itemsTableDocumentsOfMeetingAll)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      payload.items[this.documentsOfMeetingIndexMain].update_by =
        this.userInfo.user_id
      payload.items[this.documentsOfMeetingIndexMain].edit_date = this.$moment()
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')

      payload.items[this.documentsOfMeetingIndexMain].items[
        this.documentsOfMeetingIndexSub
      ] = this.dataDocumentsOfMeetingSub
      const res = await this.updateList(payload)
      this.loadingFileDownload = false
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขไฟล์เอกสารการประชุม สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableDocumentsOfMeeting()
        this.statusDialogFormDocumentsOfMeetingSub = false
        this.$refs.dialogFormDocumentsOfMeetingSub.reset()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckDeleteFileDocumentsOfMeetingSub (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบไฟล์',
        text: `คุณต้องการลบไฟล์ ${item.item.title_th} ใช่หรือไม่`,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const itemAll = JSON.parse(
            JSON.stringify(this.itemsTableDocumentsOfMeetingAll.items[item.indexMain].items)
          )
          let item1 = JSON.parse(
            JSON.stringify(this.itemsTableDocumentsOfMeetingAll.items[item.indexMain].items)
          )
          item1 = item1.splice(0, item.indexSub)
          let item2 = JSON.parse(
            JSON.stringify(this.itemsTableDocumentsOfMeetingAll.items[item.indexMain].items)
          )
          item2 = item2.splice(item.indexSub + 1, itemAll.length)
          const newItem = item1.concat(item2)
          this.itemsTableDocumentsOfMeetingAll.items[item.indexMain].items = newItem

          this.itemsTableDocumentsOfMeetingAll.items[item.indexMain].update_by = this.userInfo.user_id
          this.itemsTableDocumentsOfMeetingAll.items[item.indexMain].edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
          this.deleteFileDocumentsOfMeetingSub(
            this.itemsTableDocumentsOfMeetingAll
          )
        }
      })
    },
    async deleteFileDocumentsOfMeetingSub (item) {
      this.loading = true
      const payload = Object.assign({}, item)
      payload.update_by = this.userInfo.user_id
      payload.edit_date = this.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
      const res = await this.updateList(payload)
      this.loading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบไฟล์เอกสารการประชุมสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableDocumentsOfMeeting()
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
.btn-hearder {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.layout-btn-select {
  width: 100%;
  display: flex;
  align-items: center;
}
.layout-box-category {
  display: flex;
  margin-top: 14px;
  padding-right: 20px;
}
.layout-box-category-year {
  display: flex;
  margin-top: 14px;
  padding-right: 20px;
}
.box-category {
  font-size: 18px;
  margin-top: 10px;
  padding-right: 10px;
}
.box-btn {
  padding-right: 20px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
.width-select {
  width: 200px;
}
.box-category {
  font-size: 18px;
  margin-top: 10px;
  padding-right: 10px;
}
@media screen and (max-width: 1000px) {
  .btn-hearder {
    flex-wrap: wrap;
  }
  .layout-box-category {
    width: 50%;
  }
  .layout-box-category-year {
    width: 35%;
  }
  .box-btn {
    padding-right: 0px;
  }
}
@media screen and (max-width: 899px) {
  .layout-btn-select {
    flex-wrap: wrap;
  }
  .layout-box-category {
    width: 60%;
  }
  .layout-box-category-year {
    width: 40%;
  }
}
@media screen and (max-width: 754px) {
  .layout-btn-select {
    flex-wrap: wrap;
  }
  .layout-box-category {
    width: 60%;
  }
  .layout-box-category-year {
    width: 40%;
  }

  .width-select {
    width: 80px;
  }
}
@media screen and (max-width: 650px) {
  .layout-btn-select {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .layout-box-category {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 10px;
  }
  .layout-box-category-year {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 466px) {
  .layout-box-category {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 450px) {
  .btn-hearder {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .box-btn {
    width: 100%;
  }
}
</style>

<template>
    <div class="mt-5">
      <tableAdminAll
        :headers="headers"
        :items="items"
        :pagination="pagination"
        @onChangePagination="changePagination"
        warpHeader
        :loading="loading"
        :height="$vuetify.breakpoint.height-345"
      >
        <template v-slot:[`item.number`]="{ item }">
          <span>
            {{
              pagination.row * pagination.page -
              pagination.row +
              (items.indexOf(item) + 1)
            }}
          </span>
        </template>
        <template v-slot:[`item.file_th`]="{ item }">
          <a  :href="`${env}/api/v1/file/${item.file_th}`" target="_blank">{{item.file_th}}</a>
        </template>
        <template v-slot:[`item.file_eng`]="{ item }">
          <a  :href="`${env}/api/v1/file/${item.file_eng}`" target=”_blank”>{{item.file_eng}}</a>
        </template>
        <template v-slot:[`item.create_date`]="{ item }">
          {{ returnFormateDate(item.create_date, "th") }}
        </template>
        <template v-slot:[`item.action`]="{item}">
          <div class="layout-action">
            <v-btn icon><v-icon class="btn-edit"  @click="editFile(item,items.indexOf(item))">mdi-pencil</v-icon></v-btn>
            <v-btn icon :disabled="(items.indexOf(item) + 1)!== 1"
            @click="deleteFile(item)"
              ><v-icon class="btn-delete">mdi-trash-can-outline</v-icon></v-btn
            >
          </div>
        </template>
      </tableAdminAll>
    </div>
  </template>

<script>
import tableAdminAll from '../../../components/adminpage/tableAll/tableAdminAll.vue'
export default {
  components: {
    tableAdminAll
  },
  props: {
    pagination: {
      type: Object,
      default: () => {
        return { page: 1, row: 10, total: 0 }
      }
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    // itemKey: {
    //   type: String,
    //   default: ''
    // },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: '',
      env: ''
    }
  },
  created () {
    this.env = process.env.VUE_APP_API
  },
  methods: {
    changePagination (val) {
      this.$emit('changePagination', val)
    },
    deleteFile (item) {
      this.$emit('deleteFile', item)
    },
    editFile (item, index) {
      this.$emit('editFile', { item: item, index: index })
    }
  }
}
</script>

  <style scoped>
  >>> table .v-data-table-header th {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    min-width: 100px !important;
  }
  >>>.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-top: 10px;
  padding-bottom: 10px;
}
  .layout-action {
    min-width: 120px;
  }
  .btn-edit {
    color: var(--v-primaryIconAdmin-base) !important;
  }
  .btn-delete {
    color: var(--v-cancleBtn-base) !important;
  }

  </style>

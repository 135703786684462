<template>
  <div>
    <titleHeaderMenu :iconTitle="iconTitle" :titleMenu="titleMenu" />
    <div class="layout-img-text">
      <div class="size-img">
        <v-img
          :src="
            require(`@/assets/images/menuAdminAll/dashboardPage/viewPage.png`)
          "
        ></v-img>
      </div>
      <div class="layout-text-view">
        <div>
          <div class="title-view">ข้อมูลผู้เข้าชมเว็บไซต์</div>
          <div class="number-view">
            <v-progress-circular
              v-if="loadingTotal"
              indeterminate
              color="green"
              class="mx-2"
            ></v-progress-circular>
            <span v-else>
              {{ returncommaNumberPayment(itemsStats.total) }}
            </span>

            <span class="unit-view">ครั้ง</span>
          </div>
        </div>
      </div>
      <div class="layout-divider">
        <v-divider class="view-line my-3"></v-divider>
      </div>
      <div class="title-years-view">
        แสดงข้อมูลผู้เข้าชมเว็บไซต์(ครั้ง)แต่ละปี
      </div>
      <div class="line-chart">
        <v-progress-linear
          v-if="loadingLinerCharViewYear"
          indeterminate
          color="primaryIconAdmin"
        ></v-progress-linear>
        <LineChartAdmin
          chartID="line-chart-year-001"
          :labels="LinerCharViewYear.labels"
          :datasets="LinerCharViewYear.datasets"
          :maintainAspectRatio="false"
          :disableLegend="true"
          setHeightChart="400"
          positionDatalabels="top"
          minWidth="1200"
        />
      </div>
      <div class="title-years-view mt-10">
        แสดงข้อมูลผู้เข้าชมเว็บไซต์(ครั้ง)แต่ละเดือน
      </div>
      <div class="layout-select">
        <div class="text-select" style="min-width: 55px">
          เลือกปีที่ต้องการดู
        </div>
        <div class="width-select">
          <v-select
            v-model="year_filter"
            :items="itemsYears"
            item-text="text"
            item-value="value"
            color="primaryIconAdmin"
            item-color="primaryIconAdmin"
            dense
            outlined
            @change="init()"
            :loading="loadingLinerCharViewMonth"
          ></v-select>
        </div>
      </div>
      <div class="line-chart" style="margin-bottom: 100px;">
        <v-progress-linear
          v-if="loadingLinerCharViewMonth"
          indeterminate
          color="primaryIconAdmin"
        ></v-progress-linear>
        <LineChartAdmin
          chartID="line-chart-month-001"
          :labels="LinerCharViewMonth.labels"
          :datasets="LinerCharViewMonth.datasets"
          :maintainAspectRatio="false"
          :disableLegend="true"
          setHeightChart="400"
          positionDatalabels="top"
          minWidth="1200"
        />
      </div>
    </div>
  </div>
</template>

<script>
import titleHeaderMenu from '../../../components/adminpage/titleHeaderMenu.vue'
import LineChartAdmin from '../../../components/chart/lineChartAdmin.vue'
export default {
  components: {
    titleHeaderMenu,
    LineChartAdmin
  },
  data () {
    return {
      iconTitle: 'mdi-view-dashboard-outline',
      titleMenu: 'แดชบอร์ด',
      itemsStats: { total: 0 },
      loadingTotal: false,
      loadingLinerCharViewYear: false,
      LinerCharViewYear: {
        labels: [],
        datasets: [
          {
            label: '',
            data: [],
            lineWidth: 1,
            lineColor: '#25AAE1',
            labelColor: '#25AAE1',
            labelColortext: 'white'
          }
        ]
      },
      loadingLinerCharViewMonth: false,
      LinerCharViewMonth: {
        labels: [],
        datasets: [
          {
            label: '',
            data: [],
            lineWidth: 1,
            lineColor: '#00B981',
            labelColor: '#00B981',
            labelColortext: 'white'
          }
        ]
      },
      year_filter: 0,
      itemsYears: []
    }
  },
  created () {
    this.itemsYear()
    this.init()
  },
  methods: {
    init () {
      this.initStatsTotal()
      this.initStatsYear()
      this.initStatsMonth()
    },
    async itemsYear () {
      const yearNow = this.$moment().year()
      this.year_filter = yearNow
      const itemsYear = []
      for (let i = 2023; i <= yearNow; i++) {
        itemsYear.push({ text: i + 543, value: i })
      }
      this.itemsYears = itemsYear
    },
    async initStatsTotal () {
      this.itemsStats = { total: 0 }
      this.loadingTotal = true
      const payload = {
        keyword: this.keyword,
        page: 1,
        row: 10
      }
      const res = await this.getStats(payload)
      this.loadingTotal = false
      if (res.status) {
        this.itemsStats = res.data
      } else {
        this.antmessage.error(res.message)
      }
    },
    async initStatsYear () {
      this.loadingLinerCharViewYear = true
      const payload = {
        action: 'year',
        start_date: '2023-01-01',
        end_date: this.$moment([this.$moment().year()]).endOf('year').format('YYYY-MM-DD')
      }
      const res = await this.getStatsByAction(payload)
      this.loadingLinerCharViewYear = false
      if (res.status) {
        this.LinerCharViewYear.labels = res.data.labels
        this.LinerCharViewYear.datasets[0] = {
          ...this.LinerCharViewYear.datasets[0],
          ...res.data.datasets[0]
        }
        // this.itemsStats = res.data
      } else {
        this.antmessage.error(res.message)
      }
    },
    async initStatsMonth () {
      this.loadingLinerCharViewMonth = true
      const payload = {
        action: 'month',
        start_date: this.$moment([this.year_filter]).startOf('year').format('YYYY-MM-DD'),
        end_date: this.$moment([this.year_filter]).endOf('year').format('YYYY-MM-DD')
      }
      const res = await this.getStatsByAction(payload)
      this.loadingLinerCharViewMonth = false
      if (res.status) {
        this.LinerCharViewMonth.labels = res.data.labels
        this.LinerCharViewMonth.datasets[0] = {
          ...this.LinerCharViewMonth.datasets[0],
          ...res.data.datasets[0]
        }
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
.layout-img-text {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}
.size-img {
  width: 350px;
  padding-right: 50px;
}
.layout-text-view {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.title-view {
  font-size: 24px;
  font-weight: 500;
  color: var(--v-textPrimaryAdmin-base);
  width: 100%;
  text-align: center;
}
.number-view {
  font-size: 32px;
  font-weight: 600;
  color: var(--v-primaryIconAdmin-base) !important;
}
.unit-view {
  color: var(--v-textPrimaryAdmin-base);
  font-size: 22px;
  font-weight: 400;
}
.layout-divider {
  width: 80%;
  padding-top: 40px;
  padding-bottom: 40px;
}
.view-line {
  border: 1px solid var(--v-divider-base);
}
.title-years-view {
  font-size: 24px;
  font-weight: 500;
  color: var(--v-textPrimaryAdmin-base);
  width: 100%;
  text-align: center;
}
.line-chart {
  width: 80%;
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.layout-select {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.text-select {
  margin-top: 3px;
  margin-right: 10px;
  font-size: 20px;
  font-weight: 400;
  color: var(--v-textPrimaryAdmin-base);
}
.width-select {
  width: 150px;
}
@media screen and (max-width: 600px) {
  .layout-img-text {
    flex-wrap: wrap;
    justify-items: center;
  }
  .layout-text-view {
    justify-content: center;
  }
  .size-img {
    width: 60%;
    padding-right: 0px;
  }
  .layout-text-view {
    margin-top: 30px;
    width: 100%;
  }
  .line-chart {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .size-img {
    width: 80%;
    padding-right: 0px;
  }
}
</style>

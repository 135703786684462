<template>
  <div>
    <router-view style="height: 100%"></router-view>
  </div>
</template>

<script>

export default {
  data () {
    return {

    }
  }
}
</script>

<style scoped></style>

<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="700px"
      transition="dialog-top-transition"
      persistent
    >
      <v-card class="layout-card">
        <v-form ref="fromUser">
          <div class="from-persion-card">
            <div class="header-title">{{ titleHeader }}</div>
            <v-spacer></v-spacer>
            <div>
              <v-btn icon @click="close()"> <v-icon>mdi-close</v-icon></v-btn>
            </div>
          </div>
          <hr class="hr-header" />
          <div
            v-if="userName !== '' && userName !== undefined"
            class="mr-5 ml-8 mt-5"
            style="font-size: 16px"
          >
            แก้ไขรหัสผ่านของ {{ userName }}
          </div>
          <div class="from-layout">
            <div class="one-colum" v-if="wrongPassword">
              <v-alert dense outlined type="error">
                รหัสผ่านไม่ตรงกันกรุณาลองใหม่อีกครั้ง
              </v-alert>
            </div>
            <div class="two-colum">
              <div class="title-text-field">รหัสผ่าน</div>
              <v-text-field
                v-model="password"
                placeholder="กรุณากรอกรหัสผ่าน"
                outlined
                dense
                color="primaryIconAdmin"
                :rules="[Rule.noInPut, Rule.notNull, Rule.englishOnly]"
                :loading="loading"
                :disabled="loading"
                @input="validatePassword"
              ></v-text-field>
            </div>
            <div class="two-colum">
              <div class="title-text-field">ยืนยันรหัสผ่าน</div>
              <v-text-field
                v-model="confirmPassword"
                placeholder="กรุณายืนยันรหัสผ่าน"
                outlined
                dense
                type="password"
                color="primaryIconAdmin"
                :rules="[Rule.noInPut, Rule.notNull, Rule.englishOnly]"
                :loading="loading"
                :disabled="loading"
                @input="validatePassword"
              ></v-text-field>
            </div>
            <div class="one-colum px-3">
              <div
                class="mb-1"
                :style="`font-size:14px; color:${
                  validateLowerCase ? '#36CE45' : 'red'
                };`"
              >
                <v-icon
                  class="mr-1"
                  :style="`font-size:24px; color:${
                    validateLowerCase ? '#36CE45' : 'red'
                  };`"
                  >{{
                    validateLowerCase
                      ? "mdi-check-circle-outline"
                      : "mdi-close-circle-outline"
                  }}</v-icon
                >มีตัวอักษรพิมพ์เล็ก
              </div>
              <div
                class="mb-1"
                :style="`font-size:14px; color:${
                  validateUpperCase ? '#36CE45' : 'red'
                };`"
              >
                <v-icon
                  class="mr-1"
                  :style="`font-size:24px; color:${
                    validateUpperCase ? '#36CE45' : 'red'
                  };`"
                  >{{
                    validateUpperCase
                      ? "mdi-check-circle-outline"
                      : "mdi-close-circle-outline"
                  }}</v-icon
                >มีตัวอักษรพิมพ์ใหญ่
              </div>
              <div
                class="mb-1"
                :style="`font-size:14px; color:${
                  validateLength ? '#36CE45' : 'red'
                };`"
              >
                <v-icon
                  class="mr-1"
                  :style="`font-size:24px; color:${
                    validateLength ? '#36CE45' : 'red'
                  };`"
                  >{{
                    validateLength
                      ? "mdi-check-circle-outline"
                      : "mdi-close-circle-outline"
                  }}</v-icon
                >มีตัวอักษรอย่างน้อย 8 ตัวอักษร
              </div>
            </div>
          </div>
          <div class="layout-btn-record-cancle">
            <div class="px-1">
              <v-btn
                elevation="0"
                class="btn-add mb-2"
                rounded
                @click="validateFrom()"
                :loading="loading"
                >บันทึก</v-btn
              >
            </div>
            <div class="px-1">
              <v-btn
                elevation="0"
                class="btn-cancle mb-2"
                rounded
                @click="close()"
                :loading="loading"
                >ยกเลิก</v-btn
              >
            </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <dialog-loading v-model="loadingForm" />
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      env: '',
      show1: true,
      password: '',
      confirmPassword: '',
      wrongPassword: false,
      loadingForm: false,
      validateLowerCase: false,
      validateUpperCase: false,
      validateLength: false
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    iconTitle: {
      type: String,
      default: ''
    },
    titleHeader: {
      type: String,
      default: ''
    },
    userName: {
      type: String,
      default: ''
    },
    userID: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {},
  async created () {
    this.env = process.env.VUE_APP_API
  },
  methods: {
    close () {
      this.dialog = false
      this.$refs.fromUser.reset()
      this.validateLowerCase = false
      this.validateUpperCase = false
      this.validateLength = false
    },

    validateFrom () {
      if (
        this.$refs.fromUser.validate() &&
        this.password === this.confirmPassword &&
        this.validateLowerCase &&
        this.validateUpperCase &&
        this.validateLength
      ) {
        this.wrongPassword = false
        this.resetPassword()
      } else if (this.password !== this.confirmPassword) {
        this.wrongPassword = true
      }
    },

    resetPassword () {
      this.$swal({
        allowEnterKey: false,
        title: this.titleHeader,
        text: `คุณแน่ใจว่าต้องการ ${this.titleHeader} ใช่หรือไม่`,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loadingForm = true
          const payload = { user_id: this.userID, password: this.password }
          const res = await this.resetPasswordAdmin(payload)
          this.loadingForm = false
          if (res.status) {
            const textCopy = this.password
            this.close()
            this.$swal({
              allowEnterKey: false,
              title: this.titleHeader,
              text: `${this.titleHeader}สำเร็จ`,
              icon: 'success',
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: 'คัดลอกรหัสผ่าน',
              confirmButtonColor: '#4CC558',
              allowOutsideClick: false,
              cancelButtonText: 'ปิด',
              cancelButtonColor: '#E74444'
            }).then((result) => {
              if (result.isConfirmed) {
                navigator.clipboard.writeText(textCopy)
                this.$swal({
                  allowEnterKey: false,
                  title: 'คัดลอกรหัสผ่านสำเร็จ',
                  icon: 'success',
                  showConfirmButton: false
                })
              }
            })
          } else {
            this.antmessage.error(res.message)
          }
        }
      })
    },
    validatePassword () {
      // พิมพ์เล็ก
      if (this.password !== null) {
        const lowerCaseLetters = /[a-z]/g
        if (this.password.match(lowerCaseLetters)) {
          this.validateLowerCase = true
        } else {
          this.validateLowerCase = false
        }

        // พิมพ์ใหญ่
        const upperCaseLetters = /[A-Z]/g
        if (this.password.match(upperCaseLetters)) {
          this.validateUpperCase = true
        } else {
          this.validateUpperCase = false
        }

        if (this.password.length >= 8) {
          this.validateLength = true
        } else {
          this.validateLength = false
        }
      }
    }
  }
}
</script>

<style scoped lang="css">
>>> .v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__prepend-outer {
  display: none !important;
}
.btn-reset-password {
  color: var(--v-textBtnResetPassword-base) !important;
  background-color: var(--v-bgBtnResetPassword-base) !important;
  font-size: 16px;
}
.layout-card {
  padding-bottom: 30px;
}
.v-input--selection-controls {
  margin-top: 5px;
  padding-top: 4px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
.btn-cancle {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-cancleBtn-base) !important;
  font-size: 16px;
}
.from-persion-card {
  display: flex;
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.from-layout {
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.header-title {
  color: var(--v-primaryIconAdmin-base) !important;
  font-size: 24px;
  font-weight: 600;
}
.icon-header {
  color: var(--v-primaryIconAdmin-base) !important;
  font-size: 24px;
  margin-right: 10px;
}

.hr-header {
  color: var(--v-primaryIconAdmin-base) !important;
}
.comment-img {
  color: var(--v-textPrimaryAdmin-base) !important;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}
.title-text-field {
  font-size: 16px;
}
.layout-two-colum {
  width: 100%;
  display: flex;
}
.layout-file-img {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.box-show-img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.width-img {
  /* max-height: 250px;
      max-width: 250px; */
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.bg-file-img {
  background-color: var(--v-bgFileImg-base);
  height: 250px;
  width: 250px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #adadad;
}
.file-input {
  width: 50%;
}
.two-colum {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}
.one-colum {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.layout-add-positions {
  display: flex;
  widows: 100%;
  flex-wrap: wrap;
  background-color: var(--v-bgAddsection-base);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.layout-add-educationals {
  display: flex;
  widows: 100%;
  flex-wrap: wrap;
  background-color: var(--v-bgAddsection-base);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.layout-btn-record-cancle {
  width: 100%;
  display: flex;
  justify-content: center;
}
.layout-two-btn {
  width: 200px;
  display: flex;
}
.btn-delete {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.title-year {
  font-size: 22px;
  font-weight: 600;
  color: var(--v-textPrimaryAdmin-base) !important;
  text-align: center;
  margin-top: 20px;
}

.border-validate {
  border: #ff6363 solid 2px;
}
.border-validate-none {
  border: unset;
}
.text-validate {
  font-size: 12px;
  color: var(--v-error-base);
  padding-left: 12px;
}
@media screen and (max-width: 650px) {
  .from-layout {
    padding-left: 20px;
    padding-right: 20px;
  }
  .file-input {
    width: 100%;
  }
  .layout-two-colum {
    flex-wrap: wrap;
  }
  .two-colum {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .one-colum {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>

<template>
    <div class="card-layout">
      <!-- hide-default-footer -->
      <v-data-table
        :headers="headers"
        :item-key="itemKey"
        :items="items"
        :page.sync="page"
        :items-per-page="row"
        :mobile-breakpoint="mobileBreakpoint"
        :height="returnHeight()"
        class="elevation-0"
        loading-text="Loading... Please wait"
        fixed-header
        @click:row="$emit(`clickRow`, $event)"
        @dblclick:row="dbClick"
        :loading="loading"
        :hide-default-footer="hideDefaultFooter"
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            color="loadingTable"
            height="3"
          ></v-progress-linear>
        </template>
        <template
          v-for="field in ScopedSlots()"
          v-slot:[field]="{ header, headers, item }"
        >
          <slot
            style
            :name="field"
            :item="item"
            :header="header"
            :headers="headers"
          />
        </template>
      </v-data-table>
      <!-- <div class="card-actions" v-if="!disabledFooter">
        <div class="pagination-layout">
          <span class="pt-2 mr-2 pagination-text">Rows Per Page : </span>
          <span>
            <v-select
              v-model="row"
              :items="perPageList"
              placeholder="Standard"
              hide-details
              dense
              class="row-per-page"
              @change="(page = 1), onChangePagination()"
              :loading="loading"
              :disabled="loading"
              color="loadingTable"
            ></v-select>
          </span>
          <span v-if="items.length == 0" class="pt-2 mx-2 pagination-text"
            >0-0 of 0</span
          >
          <span v-else class="pt-2 mr-2 ml-2 pagination-text">
            {{ row * (page - 1) + 1 }} -
            {{
              row * (page - 1) + row >= pagination.total
                ? pagination.total
                : row * (page - 1) + row
            }}
            of {{ pagination.total }}</span
          >
          <v-btn
            icon
            color="loadingTable"
            :disabled="page <= 1"
            @click="page--, onChangePagination()"
            :loading="loading"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            icon
            color="loadingTable"
            :disabled="page >= Math.ceil(pagination.total / row, 1)"
            @click="page++, onChangePagination()"
            :loading="loading"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div> -->
    </div>
  </template>

<script>
export default {
  name: 'App',
  data: () => ({
    page: 1,
    row: 10,
    scopedSlots: []
  }),
  props: {
    hideDefaultFooter: {
      type: Boolean,
      default: false
    },
    defaultPerpage: {
      type: Number,
      default: 10 //
    },
    perPageList: {
      type: Array,
      default: () => [5, 10, 20, 30]
    },
    total: {
      type: Number,
      default: 0
    },
    pagination: {
      type: Object,
      default: () => {
        return { page: 1, row: 10, total: 0 }
      }
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: 'name'
    },
    disabledFooter: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: null //
    },
    bgHeader: {
      type: String,
      default: '#F4F4F4'
    },
    ColorTextHeader: {
      type: String,
      default: '#2c2c2c'
    },
    bgTable: {
      type: String,
      default: '#FFFFFF'
    },
    mobileBreakpoint: {
      type: Number,
      default: 600
    }
  },
  watch: {
    defaultPerpage: {
      handler: function (newValue) {
        this.row = newValue
      },
      deep: true,
      immediate: true
    }
  },
  async created () {
  },
  methods: {
    ScopedSlots () {
      // เป็น Function ที่จะ Remove scopeSlot ที่ไม่ใช่ของ v-data-table
      const whitList = ['header']
      const sS = Object.keys(this.$scopedSlots)
      for (const i of whitList) {
        // Check whitList is exists in sS
        const index = sS.indexOf(i)
        if (index > -1) {
          sS.splice(index, 1)
        }
      }
      return sS
    },
    dbClick (e, { item }) {
      this.$emit('dbclickRow', item)
    },
    onChangePagination () {
      this.$emit('onChangePagination', {
        page: this.page,
        row: this.row,
        total: this.pagination.total
      })
    },
    returnHeight () {
      return this.height === null ? null : this.height - 56
    }
  }
}
</script>
  <style scoped></style>

  <style lang="css" scoped>
  >>> .v-data-table__progress th {
    padding: 0 !important;
  }
  .card-layout {
    border-radius: 10px !important;
    background-color: v-bind("bgTable");
    width: 100%;
    padding: 0;
    margin: 0;
  }
  >>> .v-data-table {
    background-color: unset !important;
  }
  ::v-deep .v-data-table__wrapper {
    font-weight: 300 !important;
    /* height: 100% !important; */
    overflow-y: auto !important;
    overflow-x: auto !important;
  }
  /* // TODO แก้ไขเรื่องการ Overflow ของ Header ใน ฏฟะฟ Table */
  >>> .v-data-table-header th {
    /* padding-top: 10px;
    padding-bottom: 10px; */
    /* white-space: nowrap !important; */
  }

  >>> .v-data-table-header tr th:first-child {
    border-radius: 5px 0px 0px 0px !important;
  }
  >>> .v-data-table-header tr th:last-child {
    border-radius: 0px 5px 0px 0px !important;
  }

  >>> .v-data-table-header-mobile tr th {
    border-radius: 5px 5px 0px 0px !important;
  }
  >>> .theme--light.v-data-table.v-data-table--fixed-header thead th {
    background-color: v-bind("bgHeader") !important;
    color: v-bind("ColorTextHeader") !important;
    font-size: 22px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  >>> .row-per-page .v-select__slot .v-select__selection {
    /* color: red !important; */
    font-weight: 200 !important;
    font-size: 12px !important;
  }
  >>> .row-per-page .v-select__slot .v-select__selections input {
    /* color: red !important; */
    display: none !important;
  }

  >>> .row-per-page .v-select__slot .v-input__append-inner {
    padding-top: 4px;
  }
  >>> tbody tr td {
    font-size: 22px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 24px !important;
  }
  .v-data-table {
    /* height: 100%; */
    padding: 0;
    margin: 0;
  }
  .card-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    padding: 10px 0px 10px 5px;
  }
  .pagination-layout {
    display: flex;
  }
  .row-per-page {
    max-width: 60px !important;
    white-space: nowrap;
    text-align: center;
  }

  .pagination-text {
    font-weight: 200;
    font-size: 12px;
  }
  </style>

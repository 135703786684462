var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"px-5 box-section1",style:(`background : url(${require('@/assets/images/dividendPolicy/backgroundSection.jpg')}); background-size: cover; background-position: center;`)},[_c('div',{staticClass:"mx-auto"},[_c('div',{staticClass:"title-content"},[_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_dividend_policy_page, "title"))+" "),_c('div',[_c('v-img',{staticClass:"title-icon",attrs:{"src":require(`@/${_vm.item_dividend_policy_page.img_dot}`)}})],1)])]),_c('hr',{staticClass:"hr-dividend-policy mb-6 mt-2 mx-auto"}),_c('div',{staticClass:"description-content"},[(_vm.$vuetify.breakpoint.name !== 'xs'&&_vm.$vuetify.breakpoint.name !== 'sm')?_c('div',{staticClass:"box-description-content",domProps:{"innerHTML":_vm._s(_vm.FiltersLanguage(_vm.item_dividend_policy_page, 'description'))}}):_vm._e(),(_vm.$vuetify.breakpoint.name === 'xs'||_vm.$vuetify.breakpoint.name === 'sm')?_c('div',{staticClass:"box-description-content",domProps:{"innerHTML":_vm._s( _vm.FiltersLanguage(_vm.item_dividend_policy_page, 'description_mobile'))}}):_vm._e()])])]),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"layout-all"},[_c('div',{staticClass:"title-table"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage( _vm.item_dividend_policy_page, "title_table_payment_info" ))+" ")]),_c('div',{staticClass:"table-style"},[_c('tableComponent',{staticClass:"custom-bg-row",attrs:{"headers":_vm.modifiedHeaders(_vm.headerTablePaymentInfo),"items":_vm.itemTablePaymentInfo,"ColorTextHeader":"#000000","loading":_vm.loadingTablePaymentInfo,"bgHeader":"#ffffff"},scopedSlots:_vm._u([{key:`item.year`,fn:function({ item }){return [_c('div',{class:`colum-year first-row-left  ${
                _vm.returnIndexItem(
                  _vm.paginationTablePaymentInfo.row,
                  _vm.paginationTablePaymentInfo.page,
                  _vm.itemTablePaymentInfo,
                  item
                ) === 1
                  ? 'first-row'
                  : ''
              }`},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(item, 'year'))+" ")])]}},{key:`item.dividend_per_share`,fn:function({ item }){return [_c('div',{class:`${
                _vm.returnIndexItem(
                  _vm.paginationTablePaymentInfo.row,
                  _vm.paginationTablePaymentInfo.page,
                  _vm.itemTablePaymentInfo,
                  item
                ) === 1
                  ? 'first-row'
                  : ''
              }`},[_vm._v(" "+_vm._s(item.dividend_per_share === 0 || item.dividend_per_share === '0' ? _vm.FiltersLanguage( _vm.item_dividend_policy_page, "text_no_dividend" ) : _vm.returncommaNumberPayment( item.dividend_per_share,null))+" ")])]}},{key:`item.dividend_in_total`,fn:function({ item }){return [_c('div',{class:`${
                _vm.returnIndexItem(
                  _vm.paginationTablePaymentInfo.row,
                  _vm.paginationTablePaymentInfo.page,
                  _vm.itemTablePaymentInfo,
                  item
                ) === 1
                  ? 'first-row'
                  : ''
              }`},[_vm._v(" "+_vm._s(item.dividend_in_total === 0 || item.dividend_in_total === '0' ? _vm.FiltersLanguage( _vm.item_dividend_policy_page, "text_no_dividend" ) : _vm.returncommaNumberPayment(item.dividend_in_total,null))+" ")])]}},{key:`item.dividend_per_net_profit`,fn:function({ item }){return [_c('div',{class:`first-row-right ${
                _vm.returnIndexItem(
                  _vm.paginationTablePaymentInfo.row,
                  _vm.paginationTablePaymentInfo.page,
                  _vm.itemTablePaymentInfo,
                  item
                ) === 1
                  ? 'first-row'
                  : ''
              }`},[_vm._v(" "+_vm._s(item.dividend_per_net_profit === 0 || item.dividend_per_net_profit === '0' ? _vm.FiltersLanguage( _vm.item_dividend_policy_page, "text_no_dividend" ) : _vm.returncommaNumberPayment(item.dividend_per_net_profit,null))+" ")])]}}],null,true)})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div :class="CheckAccesstoken() ? 'main-reset-password-page' : ''">
      <div v-if="!CheckAccesstoken()">
        <v-alert text prominent type="error" icon="mdi-alert-circle">
          ไม่สามารถใช้งานได้
        </v-alert>
      </div>
      <div v-if="CheckAccesstoken()" class="reset-password-form">
        <v-card class="login-card justify-center align-center" elevation="24">
          <div style="width: 100%" class="pa-5">
            <div class="d-flex justify-center" style="width: 100%">
              <v-img contain class="width-img-key" src="/img/resetpassword.jpg" />
            </div>
            <div class="title-form pt-5">ฟอร์มเปลี่ยนรหัสผ่าน</div>
            <div class="sub-title-form">กรุณากรอกรหัสผ่านใหม่ที่ต้องการ</div>
            <div class="d-flex justify-center py-6">
              <div class="width-text-field">
                <v-form ref="formRestPassword" v-model="validRestPasswordForm">
                  <v-text-field
                    v-model="password"
                    :rules="[Rule.noInPut, Rule.noSpace]"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    placeholder="รหัสผ่านใหม่"
                    label="กรอกรหัสผ่านใหม่"
                    :loading="loading"
                    dense
                    outlined
                    rounded
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="recheckPassword"
                    :rules="[Rule.noInPut, Rule.noSpace]"
                    placeholder="รหัสผ่านใหม่"
                    label="กรอกรหัสผ่านใหม่อีกครั้ง"
                    type="password"
                    :loading="loading"
                    dense
                    outlined
                    rounded
                    :disabled="password == '' ? true : false"
                  >
                  </v-text-field>
                </v-form>
                <v-btn
                  :disabled="!validRestPasswordForm"
                  :loading="loading"
                  block
                  color="primary"
                  rounded
                  @click="ResetPassword"
                >
                  บันทึก
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </template>

<script>
export default {
  data () {
    return {
      show: false,
      password: '',
      recheckPassword: '',
      validRestPasswordForm: false,
      loading: false,
      decodeAccesstoken: ''
    }
  },
  created () {},
  methods: {
    CheckAccesstoken () {
      try {
        const accesstoken = this.$route.query.accesstoken
        if (accesstoken !== undefined) {
          this.decodeAccesstoken = window.atob(accesstoken)
          const status = /(^[\w-]*\.[\w-]*\.[\w-]*$)/.test(
            this.decodeAccesstoken
          )
          return status
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    ResetPassword () {
      this.$swal({
        allowEnterKey: false,
        title: 'เปลี่ยนรหัสผ่าน',
        text: 'คุณต้องการเปลี่ยนรหัสผ่านใช่หรือไม่',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ตกลง'
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.password === this.recheckPassword) {
            localStorage.setItem('Accesstoken', this.decodeAccesstoken)
            this.loading = true
            const payload = {
              password: this.recheckPassword
            }
            const res = await this.resetPassword(payload)
            this.loading = false
            localStorage.clear()
            if (res.status) {
              this.$swal({
                allowOutsideClick: false,
                allowEnterKey: false,
                title: 'สำเร็จ',
                text: 'เปลี่ยนรหัสผ่านสำเร็จ',
                icon: 'success',
                confirmButtonText: 'ตกลง'
              }).then(async (result) => {
                if (result.isConfirmed) {
                //   this.OauthLogOutOneID()
                  this.$router.push({ name: 'loginAdmin' }).catch(() => {})
                }
              })
            } else {
              this.antmessage.error(res.message)
            }
          } else {
            this.$swal({
              allowEnterKey: false,
              title: 'กรุณาตรวจสอบรหัสผ่านอีกครั้ง',
              text: 'การยืนยันรหัสผ่านไม่ถูกต้อง',
              icon: 'warning',
              confirmButtonText: 'ตกลง'
            })
          }
        }
      })
    }
  }
}
</script>

  <style lang="css" scoped>
  .reset-password-form {
    max-width: 500px;
    width: 50%;
    /* height: 70%; */
  }
  .width-img-key {
    max-width: 40%;
  }
  .width-img-logo {
    max-width: 30%;
    min-width: 100px;
  }
  .main-reset-password-page {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 20px;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
  }
  .login-card {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }
  .title-form {
    width: 100%;
    color: var(--v-primary-base) !important;
    font-size: 26px;
    text-align: center;
  }
  .sub-title-form {
    width: 100%;
    color: var(--v-tprimary-base) !important;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
  }
  .width-text-field {
    width: 80%;
  }

  @media only screen and (max-width: 750px) {
    .main-reset-password-page {
      background: linear-gradient(
        136.16deg,
        var(--v-gradient1-base) 0%,
        var(--v-gradient2-base) 100%
      );
    }
    .login-card {
      max-width: 500px;
      flex-wrap: wrap;
      height: fit-content;
    }
    .reset-password-form {
      max-width: 500px;
      width: 90%;
      /* height: 70%; */
    }
    .width-text-field {
      width: 100%;
    }
  }
  </style>

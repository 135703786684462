<template>
  <div
  id="top-page"
    class="default-bg"
    :style="`${styleMain} ${
      bgImage !== ''
        ? `background-image: url( ${require(`@/${bgImage}`)}); background-size: cover; background-position: center;`
        : ''
    }`"
  >
    <div class="space-box-menu layout-name-service-all">
      <div class="layout-name-service">
        <div class="box-logo-name-service ">
          <div class="mb-1">
            <v-img
              src="@/assets/images/menu/logo.png"
              class="mb-2 size-logo"
            ></v-img>
          </div>
          <div class="d-flex align-end  box-name-service ">
            <div class="size-line mx-3">|</div>
            <div class="text-name-service">INVESTOR RELATIONS</div>
          </div>
        </div>
        <div class="d-flex">
          <div class="box-language remove-element py-2">
            <span class="ma-3">
              <span
                :class="
                  $store.state.language === 'th'
                    ? 'text-language-active'
                    : 'text-language'
                "
                >TH</span
              >
              <v-btn
                class="ml-3 mb-btn"
                text
                icon
                x-small
                :disabled="$store.state.language === 'th' ? true : false"
                @click="
                  $store.commit(
                    'setLanguage',
                    $store.state.language === 'th' ? 'eng' : 'th'
                  ),
                    $EventBus.$emit('change-language')
                "
                ><v-img
                  width="20"
                  :style="
                    $store.state.language === 'eng'
                      ? 'filter: grayscale(100%);'
                      : ''
                  "
                  :src="require(`@/assets/images/menu/TH.png`)"
                ></v-img
              ></v-btn>
            </span>
            <span class="ma-3">
              <span
                :class="
                  $store.state.language === 'eng'
                    ? 'text-language-active'
                    : 'text-language'
                "
                >EN</span
              >
              <v-btn
                class="ml-3 mb-btn"
                text
                icon
                x-small
                :disabled="$store.state.language === 'eng' ? true : false"
                @click="
                  $store.commit(
                    'setLanguage',
                    $store.state.language === 'th' ? 'eng' : 'th'
                  ),
                    $EventBus.$emit('change-language')
                "
                ><v-img
                  width="20"
                  :style="
                    $store.state.language === 'th'
                      ? 'filter: grayscale(100%);'
                      : ''
                  "
                  :src="require(`@/assets/images/menu/EN.png`)"
                ></v-img
              ></v-btn>
            </span>
          </div>
          <div>
            <a :href="`${$store.state.language === 'th'?'https://www.inet.co.th/?lang=th':'https://www.inet.co.th/?lang=en'}`" target="_blank">
            <v-btn icon x-large color="textMenu"
              ><v-icon>mdi-home</v-icon></v-btn
            >
            </a>
            <v-btn @click.stop="drawer = !drawer" icon x-large color="textMenu"
              ><v-icon>mdi-menu</v-icon></v-btn
            >

          </div>
        </div>
      </div>
      <hr class="hr-menu box-menu" />
    </div>
    <!--  -->
    <div
      :class="`text-center space-box-menu box-menu ${
        menuDark === true ? 'color-style2' : 'color-style1'
      }`"
    >
      <div>
        <button
          v-for="(item, index) in menus"
          :key="index"
          text
          @click="clickMenu(item), menuActive(item.name)"
          :class="
            item.sub_menu.length !== 0 && menuActiveVal == item.name && menuOpen
              ? 'active-menu custom-btn'
              : 'custom-btn'
          "
          :id="`btn-${item.name}`"
        >
          <span class="text-menu-main">
            {{ FiltersLanguage(item, "text") }}
          </span>
          <v-icon v-if="item.sub_menu.length !== 0" color="textMenu">
            {{
              menuActiveVal == item.name && menuOpen
                ? "mdi-chevron-up"
                : "mdi-chevron-down"
            }}
          </v-icon>
        </button>
      </div>
      <!-- <div class="box-language py-2">
        <span class="ma-3">
          <span
            :class="
              $store.state.language === 'th'
                ? 'text-language-active'
                : 'text-language'
            "
            >TH</span
          >
          <v-btn
            class="ml-3"
            text
            icon
            x-small
            :disabled="$store.state.language === 'th' ? true : false"
            @click="
              $store.commit(
                'setLanguage',
                $store.state.language === 'th' ? 'eng' : 'th'
              ),
                $EventBus.$emit('change-language')
            "
            ><v-img
              width="20"
              :style="
                $store.state.language === 'eng'
                  ? 'filter: grayscale(100%);'
                  : ''
              "
              :src="require(`@/assets/images/menu/TH.png`)"
            ></v-img
          ></v-btn>
        </span>
        <span class="ma-3">
          <span
            :class="
              $store.state.language === 'eng'
                ? 'text-language-active'
                : 'text-language'
            "
            >EN</span
          >
          <v-btn
            class="ml-3"
            text
            icon
            x-small
            :disabled="$store.state.language === 'eng' ? true : false"
            @click="
              $store.commit(
                'setLanguage',
                $store.state.language === 'th' ? 'eng' : 'th'
              ),
                $EventBus.$emit('change-language')
            "
            ><v-img
              width="20"
              :style="
                $store.state.language === 'th' ? 'filter: grayscale(100%);' : ''
              "
              :src="require(`@/assets/images/menu/EN.png`)"
            ></v-img
          ></v-btn>
        </span>
      </div> -->
      <div v-for="(item, index) in menus" :key="index" class="sub-menu">
        <!--  -->
        <div
          v-if="menuActiveVal == item.name && menuOpen"
          class="text-menu text-center"
        >
          <v-btn
            v-for="(subMenu, index2) in item.sub_menu"
            :key="index2"
            text
            style="padding-left: 38.5px;padding-right: 38.5px; "
            :class="
              subMenu.name === subMenuVal
                ? 'btn-sub-menu-active btn-sub-menu mx-0'
                : 'btn-sub-menu mx-0'
            "
            @click="
              $router.push({ name: subMenu.name }).catch(() => {}),
                returnBgMenuStyle(subMenu.name),
                (menuOpen = false),
                (menuActiveVal = '')
            "
          >
            <span class="text-menu">
              <span>{{ FiltersLanguage(subMenu, "text") }}</span>
            </span>
          </v-btn>
        </div>
      </div>
    </div>
    <div v-for="(name, index) in listMenuslot" :key="index">
      <div v-if="name === menuVal || name === subMenuVal" >
        <slot :name="name"></slot>
      </div>
    </div>
    <div v-if="drawer" class="menu-nav" absolute temporary>
      <div class="list-menu-nav">
        <div class="px-5 py-2 d-flex justify-space-between align-center">
          <div>
            <span class="ma-3">
              <span
                :class="
                  $store.state.language === 'th'
                    ? 'text-language-active'
                    : 'text-language'
                "
                >TH</span
              >
              <v-btn
                class="ml-3 mb-btn"
                text
                icon
                x-small
                :disabled="$store.state.language === 'th' ? true : false"
                @click="
                  $store.commit(
                    'setLanguage',
                    $store.state.language === 'th' ? 'eng' : 'th'
                  ),
                    $EventBus.$emit('change-language')
                "
                ><v-img
                  width="20"
                  :style="
                    $store.state.language === 'eng'
                      ? 'filter: grayscale(100%);'
                      : ''
                  "
                  :src="require(`@/assets/images/menu/TH.png`)"
                ></v-img
              ></v-btn>
            </span>
            <span class="ma-3">
              <span
                :class="
                  $store.state.language === 'eng'
                    ? 'text-language-active'
                    : 'text-language'
                "
                >EN</span
              >
              <v-btn
                class="ml-3 mb-btn"
                text
                icon
                x-small
                :disabled="$store.state.language === 'eng' ? true : false"
                @click="
                  $store.commit(
                    'setLanguage',
                    $store.state.language === 'th' ? 'eng' : 'th'
                  ),
                    $EventBus.$emit('change-language')
                "
                ><v-img
                  width="20"
                  :style="
                    $store.state.language === 'th'
                      ? 'filter: grayscale(100%);'
                      : ''
                  "
                  :src="require(`@/assets/images/menu/EN.png`)"
                ></v-img
              ></v-btn>
            </span>
          </div>
          <v-btn @click="drawer = !drawer" icon class="text-menu ma-3"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <hr class="hr-nav mt-0" />
        <div v-for="(item, index) in menus" :key="index" class="px-5">
          <button
            text
            @click="clickMenu(item), menuActive(item.name)"
            class="custom-btn-nav"
          >
            <span class="text-menu">
              {{ FiltersLanguage(item, "text") }}
            </span>
            <v-icon v-if="item.sub_menu.length !== 0" color="textMenu">
              {{
                menuActiveVal == item.name && menuOpen
                  ? "mdi-chevron-up"
                  : "mdi-chevron-down"
              }}
            </v-icon>
          </button>
          <hr
            v-if="menuActiveVal == item.name && item.sub_menu.length !== 0"
            class="hr-nav-sub-menu-main"
          />
          <div v-if="menuActiveVal == item.name" class="text-menu pl-5" >
            <div v-for="(subMenu, index2) in item.sub_menu" :key="index2">
              <hr v-if="index2 != 0" class="hr-nav-sub-menu" />
              <button
                text
                block
                :class="
                  subMenu.name === subMenuVal
                    ? 'btn-sub-menu-active-nav btn-sub-menu-nav'
                    : 'btn-sub-menu-nav'
                "
                @click="
                  $router.push({ name: subMenu.name }).catch(() => {}),
                    returnBgMenuStyle(subMenu.name),
                    (menuOpen = false),
                    (menuActiveVal = ''),
                    (drawer = false)
                "
              >
                <span class="text-menu">
                  <span>{{ FiltersLanguage(subMenu, "text") }}</span>
                </span>
              </button>
            </div>
          </div>
          <hr class="hr-nav" />
        </div>
      </div>
      <div class="bg-menu-nav" @click="drawer = !drawer"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menus: {
      type: Array,
      default: () => []
    },
    // menusAll for return BG page when page dont have in menu
    menusAll: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      lang: 'eng',
      menuDark: false,
      menuActiveVal: '',
      menuOpen: false,
      menuVal: '',
      subMenuVal: '',
      styleMain: '',
      bgImage: '',
      listMenuslot: [],
      drawer: false
    }
  },
  created () {
    this.returnBgMenuStyle(this.$router.currentRoute.name)
    this.setListMenuslot()
    this.$EventBus.$on('router-push', (name) => {
      this.returnBgMenuStyle(name)
    })
  },
  mounted () {
    this.addEventBtnMenu()
    // this.addEventBgNav()
  },
  methods: {
    setListMenuslot () {
      this.listMenuslot = []
      for (let index = 0; index < this.menus.length; index++) {
        if (this.menus[index].sub_menu.length !== 0) {
          for (
            let index1 = 0;
            index1 < this.menus[index].sub_menu.length;
            index1++
          ) {
            this.listMenuslot.push(this.menus[index].sub_menu[index1].name)
          }
        } else {
          this.listMenuslot.push(this.menus[index].name)
        }
      }
    },
    addEventBgNav () {
      const ref = '#bg-menu-nav'
      document.querySelector(ref).addEventListener('click', () => {
        this.drawer = !this.drawer
      })
    },
    addEventBtnMenu () {
      for (let index = 0; index < this.menus.length; index++) {
        const ref = '#btn-' + this.menus[index].name
        document.querySelector(ref).addEventListener(
          'blur',
          () => {
            setTimeout(() => {
              this.removeActive(this.menus[index].name)
            }, 300)
          },
          true
        )
      }
    },
    removeActive (name) {
      if (this.menuActiveVal === name) {
        this.menuOpen = !this.menuOpen
        this.menuActiveVal = ''
      }
    },
    menuActive (name) {
      if (this.menuActiveVal === name) {
        this.menuOpen = !this.menuOpen
        this.menuActiveVal = ''
      } else {
        this.menuOpen = true
        this.menuActiveVal = name
      }
    },
    clickMenu (itemMenu) {
      if (itemMenu.sub_menu.length === 0) {
        this.$router.push({ name: itemMenu.name }).catch(() => {})
        this.returnBgMenuStyle(itemMenu.name)
        this.drawer = false
      }
    },
    returnBgMenuStyle (menuName) {
      let dark = false
      let height = ''
      let bgImage = ''
      for (let index = 0; index < this.menusAll.length; index++) {
        if (menuName === this.menusAll[index].name) {
          // เมนูที่มีลูกแต่ไม่ได้อยู่ในซับเมนู
          this.menuVal = this.menusAll[index].name
          this.subMenuVal = ''
          if (this.menusAll[index].bgMenuDark !== undefined) {
            dark = this.menusAll[index].bgMenuDark
          }
          if (this.menusAll[index].height !== undefined) {
            height = 'height:' + this.menusAll[index].height + '; '
          }
          if (this.menusAll[index].bgImage !== undefined) {
            bgImage = this.menusAll[index].bgImage
          }
          this.bgImage = bgImage
          this.styleMain = height
        } else if (this.menusAll[index].sub_menu.length !== 0) {
          for (
            let index1 = 0;
            index1 < this.menusAll[index].sub_menu.length;
            index1++
          ) {
            if (menuName === this.menusAll[index].sub_menu[index1].name) {
              this.menuVal = this.menusAll[index].name
              this.subMenuVal = this.menusAll[index].sub_menu[index1].name
              if (
                this.menusAll[index].sub_menu[index1].bgMenuDark !== undefined
              ) {
                dark = this.menusAll[index].sub_menu[index1].bgMenuDark
              }
              if (this.menusAll[index].sub_menu[index1].height !== undefined) {
                height =
                  'height:' +
                  this.menusAll[index].sub_menu[index1].height +
                  '; '
              }
              if (this.menusAll[index].sub_menu[index1].bgImage !== undefined) {
                bgImage = this.menusAll[index].sub_menu[index1].bgImage
              }

              this.bgImage = bgImage
              this.styleMain = height
            }
          }
        } else if (menuName === this.menusAll[index].name) {
          this.menuVal = this.menusAll[index].name
          this.subMenuVal = ''
          if (this.menusAll[index].bgMenuDark !== undefined) {
            dark = this.menusAll[index].bgMenuDark
          }
          if (this.menusAll[index].height !== undefined) {
            height = 'height:' + this.menusAll[index].height + '; '
          }
          if (this.menusAll[index].bgImage !== undefined) {
            bgImage = this.menusAll[index].bgImage
          }
          this.bgImage = bgImage
          this.styleMain = height
        }
      }
      this.menuDark = dark
    }
  }
}
</script>

<style scoped>
.mb-btn{
  margin-bottom: 6px;
}
.size-template-menu-th {
  height: calc(100vh - 77px);
  min-height: 400px;
}
.size-template-menu-eng {
  height: calc(100vh - 130px);
  min-height: 400px;
}
.text-menu-main {
  font-family: Noto Sans Thai !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  color: var(--v-textMenu-base) !important;
  text-transform: none;
}
.default-bg {
  background: linear-gradient(
    135deg,
    #bce778 0%,
    #8ed8a3 42%,
    #6accc5 80%,
    #5dc8d2 100%
  );
}
.space-box-menu {
  padding-left: 34px;
  padding-right: 34px;
}
.layout-name-service-all {
  background: rgba(255, 255, 255, 0.15);
}
.layout-name-service {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 75px;
  padding-top: 10px;
}
.box-logo-name-service {
  display: flex;
  align-items: center;
}
.size-logo {
  width: 125px;
  max-width: 125px;
}
.size-line {
  color: var(--v-textMenu-base) !important;
  font-family: Noto Sans Thai;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.86px;
}
.text-name-service {
  color: var(--v-textMenu-base) !important;
  font-family: Noto Sans Thai;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.86px;
}
.hr-menu {
  border: 1px solid var(--v-hrMenu-base);
}
.box-menu {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
}
/* // set color bg menu */
.color-style1 {
  background: rgba(255, 255, 255, 0.15);
}
.color-style2 {
  background: rgba(69, 69, 69, 0.15);
}
/* // set color bg menu */
.text-menu {
  font-family: Noto Sans Thai !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  color: var(--v-textMenu-base) !important;
  text-transform: none;
}
.box-language {
  /* min-width: 200px !important; */
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: var(--v-textMenu-base);
}

.text-language {
  color: var(--v-textMenu-base) !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.text-language-active {
  color: var(--v-textMenu-base) !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* // set line under active menu */
button.active-menu {
  border-style: solid;
  border-image: linear-gradient(to right, #bce778, #8ed8a3, #6accc5, #5dc8d2) 0
    0 1 0;
  border-width: 3px !important;
}
button.custom-btn {
  min-height: 50px !important;
  padding-left: 10px;
  padding-right: 10px;
}

button.custom-btn:hover {
  background: rgba(57, 57, 57, 0.2);
}
.sub-menu {
  width: 100%;
  background: rgba(57, 57, 57, 0.7);
  position: absolute;
  top: 100%;
  right: 0px;
  z-index: 9999;
}
button.btn-sub-menu {
  min-height: 50px !important;
  border-radius: 0;
}
button.btn-sub-menu:hover {
  background: rgba(57, 57, 57, 0.2);
}
button.btn-sub-menu-active {
  background: rgba(57, 57, 57, 0.8);
}

/* nav bar */

.menu-nav {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  z-index: 9999;
  display: flex;
}
custom-btn-nav.bg-menu-nav {
  height: 100%;
  width: calc(100% - 370px);
  background: rgba(0, 0, 0, 0.8);
}
.list-menu-nav {
  height: 100%;
  width: 370px;
  background-color: #343434;
  overflow-y: auto;
}
.hr-nav {
  border: 1px solid #7c7c7c;
  margin-top: 15px;
  margin-bottom: 15px;
}
button.custom-btn-nav {
  width: 100%;
  min-height: 50px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  text-align: left !important;
  border-radius: 5px;
}
.custom-btn-nav:hover {
  background: rgba(255, 255, 255, 0.2);
}
button.btn-sub-menu-nav {
  min-height: 50px !important;
  width: 100%;
  padding: 10px;
  text-align: left !important;
  text-transform: none;
  border-radius: 5px;
}
button.btn-sub-menu-nav:hover {
  background: rgba(255, 255, 255, 0.2);
}
button.btn-sub-menu-active-nav {
  background: rgba(255, 255, 255, 0.4);
}
.hr-nav-sub-menu-main {
  border: 1px solid rgb(82, 150, 108);
  margin-top: 10px;
  margin-bottom: 10px;
}
.hr-nav-sub-menu {
  border: 1px solid #464646;
  margin-top: 10px;
  margin-bottom: 10px;
}

.menu-nav {
  display: none;
}
@media only screen and (max-width: 1726px) {
  .size-template-menu-eng {
    height: calc(100vh - 180px);
  }
}
/* @media only screen and (max-width: 1571px) {
  .size-template-menu-eng {
    height: calc(100vh - 180px);
  }

} */
@media only screen and (max-width: 1428px) {
  .text-menu-main {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 1366px) {
  .size-logo {
    width: 108px;
    max-width: 108px;
  }
  .text-name-service {
    font-size: 18.966px;
  }
  .size-line {
    font-size: 26.341px;
  }
  /* .size-template-menu-eng {
    height: calc(100vh - 130px);
  } */
}
@media only screen and (max-width: 1208px) {
  .size-template-menu-th {
    height: calc(100vh - 180px);
  }
}
/* @media only screen and (max-width: 1089px) {
  .size-template-menu-th {
    height: calc(100vh - 180px) !important;
  }
} */

@media only screen and (max-width: 1024px) {
  .size-template-menu-th {
    height: calc(100vh - 77px) !important;
  }
  .size-template-menu-eng {
    height: calc(100vh - 77px);
  }
  .space-box-menu {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: solid 2px white !important;
  }
  .box-menu {
    display: none;
  }
  .menu-nav {
    display: flex;
    background: rgba(0, 0, 0, 0.5);
  }
  .remove-element {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .text-name-service {
    font-size: 15.172px;
  }
  .size-line {
    font-size: 21.073px;
  }
}

@media only screen and (max-width: 700px) {
  .text-name-service {
    font-size: 18px;
  }
}
@media only screen and (max-width: 600px) {
  .layout-name-service {
    justify-content: space-between;
  }
  .size-logo {
    margin-top: 7px;
  }
}
@media only screen and (max-width: 540px) {
  /* .size-logo {
    width: 86px;
    max-width: 86px;
  } */
  .text-name-service {
    font-size: 17px;
  }
  .size-line {
    display: none;
  }
  .box-name-service {
    max-width: 120px;
    padding-left: 15px;
    margin-left: 15px;
    border-left: solid 2px white;
  }
}
@media only screen and (max-width: 430px) {
  .size-logo{
    width: 86px;
    max-width: 86px;
  }
  .text-name-service {
    font-size: 14px;
  }
}
@media only screen and (max-width: 383px) {
  .size-logo{
    width: 70px;
    max-width: 70px;
  }
  .text-name-service {
    font-size: 14px;
  }
}
@media only screen and (max-width: 320px) {
  .size-logo {
    width: 64.659px;
    max-width: 64.659px;
  }
  .text-name-service {
    font-size: 10.513px;
  }
}
</style>

<template>
  <div class="main-content d-flex justify-center">
    <div class="layout-all">
      <div class="shareholderInfo-content ">
        <div class="title-content">
          <div class="d-flex justify-center">
            {{ FiltersLanguage(item_stock_information_page, "title") }}
            <div>
              <v-img
                class="title-icon"
                :src="require(`@/${item_stock_information_page.img_dot}`)"
              ></v-img>
            </div>
          </div>
        </div>
        <hr class="hr-border" />
        <div class="shareholderInfo-details">
          <div>
            <div class="content-area">
              <!-- market -->
              <div class="left-area"> {{ FiltersLanguage(item_stock_information_page.market, "title") }}</div>
              <div class="right-area">{{ FiltersLanguage(item_stock_information_page.market, "val") }}</div>

              <!-- industry -->
              <div class="left-area"> {{ FiltersLanguage(item_stock_information_page.industry, "title") }}</div>
              <div class="right-area">{{ FiltersLanguage(item_stock_information_page.industry, "val") }}</div>

              <!-- business -->
              <div class="left-area"> {{ FiltersLanguage(item_stock_information_page.business, "title") }}</div>
              <div class="right-area">{{ FiltersLanguage(item_stock_information_page.business, "val") }}</div>

              <!-- trade_date -->
              <div class="left-area"> {{ FiltersLanguage(item_stock_information_page.trade_date, "title") }}</div>
              <div class="right-area">{{ FiltersLanguage(item_stock_information_page.trade_date, "val") }}</div>

              <!-- about_stock -->
              <div class="header-title">{{ FiltersLanguage(item_stock_information_page.about_stock, "header") }}</div>
              <div class="left-area"> {{ FiltersLanguage(item_stock_information_page.about_stock, "title") }}</div>
              <div class="right-area">{{ FiltersLanguage(item_stock_information_page.about_stock, "val") }}</div>

              <!-- about_common_stock -->
              <div class="header-title">{{ FiltersLanguage(item_stock_information_page.about_common_stock, "header") }}</div>
              <div class="left-area"> {{ FiltersLanguage(item_stock_information_page.about_common_stock.registered_capital, "title") }}</div>
              <div class="right-area">{{ FiltersLanguage(item_stock_information_page.about_common_stock.registered_capital, "val") }}</div>
              <div class="left-area"> {{ FiltersLanguage(item_stock_information_page.about_common_stock.registered_capital_paid, "title") }}</div>
              <div class="right-area">{{ FiltersLanguage(item_stock_information_page.about_common_stock.registered_capital_paid, "val") }}</div>

              <!-- about_preferred_stock -->
              <div class="header-title">{{ FiltersLanguage(item_stock_information_page.about_preferred_stock, "header") }}</div>
              <div class="left-area"> {{ FiltersLanguage(item_stock_information_page.about_preferred_stock.registered_capital, "title") }}</div>
              <div class="right-area">{{ FiltersLanguage(item_stock_information_page.about_preferred_stock.registered_capital, "val") }}</div>
              <div class="left-area"> {{ FiltersLanguage(item_stock_information_page.about_preferred_stock.registered_capital_paid, "title") }}</div>
              <div class="right-area">{{ FiltersLanguage(item_stock_information_page.about_preferred_stock.registered_capital_paid, "val") }}</div>
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {}
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  methods: {},
  computed: {
    ...mapGetters(['item_stock_information_page'])
  }
}
</script>

<style scoped>
.layout-all {
  margin-right: 20px;
  margin-left: 20px;
}
.shareholderInfo-content {
  width: 100%;
  max-width: 684px;
  margin-bottom: 85px;
}
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
}
.title-icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
.hr-border {
  color: var(--v-linerHeaderStock-base) !important;
  margin-top: 40px;
  margin-bottom: 40px;
}
/* card */
.content-area {
  display: flex;
  flex-wrap: wrap;
}
.left-area {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 12px;
  padding-bottom: 9px;
  padding-top: 9px;
  width: 50%;
  font-size: 23px;
  font-style: normal;
  font-weight: 300;
}
.right-area {
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-bottom: 9px;
  padding-top: 9px;
  width: 50%;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
}
.header-title{
  background-color: var(--v-bgHeader-base) !important;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 9px;
  margin-top: 9px;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
}
@media screen and (max-width: 984px) {
  .hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 20px;
  margin-bottom: 20px;
}
}
@media screen and (max-width: 493px) {
  .header-title{
  font-size: 20px;
}
  .left-area {
  font-size: 18px;
  padding-right: 2px;
  text-align: right;
}
.right-area {
  font-size: 18px;
  padding-right: 2px;
}

}
</style>

<template>
  <div class="main-content">
    <!-- section 1: inetW2 ใบสำคัญแสดงสิทธิ -->
    <div class="layout-all">
      <div class="layout-main-content">
        <div class="inet_w2-main-layout">
          <div class="title-content">
            <!-- title -->
            <div class="d-flex justify-center">
              {{ FiltersLanguage(item_inet_w2_page, "title") }}
              <div>
                <v-img
                  class="title-icon"
                  :src="require(`@/${item_inet_w2_page.img_dot}`)"
                ></v-img>
              </div>
            </div>
          </div>
          <div>
            <!-- exercise_date -->
            <div
              class="block-content-section1"
              v-for="(item_inet_data, i) in item_inet_w2_page.exercise_date"
              :key="`${i}-item_inet_data`"
            >
              <!-- {{ item_inet_data }}{{ i }} -->
              <div class="in-content-section1">
                <div class="left-area">
                  {{ FiltersLanguage(item_inet_data, "title") }}
                </div>
                <div class="right-area">
                  <div
                    v-for="(item, ii) in FiltersLanguage(
                      item_inet_data,
                      'description'
                    )"
                    :key="`${ii}-item`"
                  >
                    <ul>
                      <li>
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr class="hr-border" />
            <!-- exercuse_ratio_price -->
            <div class="block-content-section1">
              <div
                class="in-content-section1"
                v-for="(
                  item_inet_data, i
                ) in item_inet_w2_page.exercise_ratio_price"
                :key="i"
              >
                <div class="left-area">
                  {{ FiltersLanguage(item_inet_data, "title") }}
                </div>
                <div
                  class="right-area"
                  v-for="(iteme, i) in FiltersLanguage(
                    item_inet_data,
                    'description'
                  )"
                  :key="i"
                >
                  <ul class="d-flex flex-wrap">
                    <li v-html="iteme">
                      {{ iteme }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr class="hr-border" />

            <!-- notification_period -->
            <div class="block-content-section1">
              <div
                class="in-content-section1"
                v-for="(
                  item_inet_data, i
                ) in item_inet_w2_page.notification_period"
                :key="i"
              >
                <div class="left-area">
                  {{ FiltersLanguage(item_inet_data, "title") }}
                </div>
                <div class="right-area">
                  <div
                    v-for="(iteme, i) in FiltersLanguage(
                      item_inet_data,
                      'description'
                    )"
                    :key="i"
                  >
                    <ul>
                      <li v-html="iteme" class="set-css-p">
                        {{ iteme }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr class="hr-border" />
            <!-- place_for_exercise_of_warrants -->
            <div
              class="block-content-section1"
              v-for="(
                item_inet_data, i
              ) in item_inet_w2_page.place_for_exercise_of_warrants"
              :key="i"
            >
              <div class="in-content-section1">
                <div class="left-area">
                  {{ FiltersLanguage(item_inet_data, "title") }}
                </div>
                <div class="right-area">
                  <div v-if="$vuetify.breakpoint.name !== 'xs'">
                    <div
                      v-for="(iteme, i) in FiltersLanguage(
                        item_inet_data,
                        'description'
                      )"
                      :key="i"
                    >
                      <ul>
                        <li v-html="iteme">
                          {{ iteme }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div v-if="$vuetify.breakpoint.name === 'xs'">
                    <div
                      v-for="(iteme, i) in FiltersLanguage(
                        item_inet_data,
                        'description_mobile'
                      )"
                      :key="i"
                    >
                      <ul>
                        <li v-html="iteme">
                          {{ iteme }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="hr-border" />
          </div>
        </div>
      </div>
      <!-- section 2: inetW2 ใบสำคัญแสดงสิทธิ >> รายละเอียดการใช้สิทธิใบสำคัญแสดงสิทธิ INET-W2-->
      <div class="layout-main-content">
        <!-- left card -->
        <div class="detail-card-left">
          <v-img
            class="size-image"
            :src="require(`@/${item_inet_w2_page.img_title_detail}`)"
          >
            <div class="title-box-section2">
              {{ FiltersLanguage(item_inet_w2_page, "title_detail") }}
            </div>
          </v-img>
        </div>

        <!-- right card -->
        <div class="detail-card-right">
          <div
            class="block-content-section2"
            v-for="(item_inet_data, i) in itemaTableINETW3"
            :key="i"
          >
            <div style="width: 100%; display: flex">
              <div class="text-box-section2">
                {{ FiltersLanguage(item_inet_data, "title") }}
              </div>
              <div>
                <a
                  :href="`${env}/api/v1/file/${FiltersLanguage(item_inet_data, 'file')}`"
                  target="_blank"
                >
                  <v-icon class="icon-download"> mdi-download </v-icon>
                </a>
              </div>
            </div>
            <div style="width: 100%">
              <hr
                :style="
                  i + 1 === item_inet_w2_page.download_INET_W2.length
                    ? 'display:none'
                    : 'margin-top:15px; margin-bottom:15px;'
                "
                class="hr-border"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- remark section -->
      <div class="layout-remark">
        <div class="mb-5">
          <span class="title-remark">
            {{ FiltersLanguage(item_inet_w2_page, "remark_title") }}
          </span>
          <span class="description-remark">
            {{ FiltersLanguage(item_inet_w2_page, "description_remark") }}
          </span>
          <a
            class="link-remark"
            :href="`${FiltersLanguage(item_inet_w2_page, 'link_remark')}`"
            target="_blank"
          >
            {{ FiltersLanguage(item_inet_w2_page, "link_remark") }} </a
          >)
        </div>
        <div class="mb-5">
          <div class="title-description-remark">
            {{ FiltersLanguage(item_inet_w2_page, "remark_title_section1") }}
          </div>
          <div
            class="description-remark"
            v-html="
              FiltersLanguage(item_inet_w2_page, 'description_remark_section1')
            "
          ></div>
        </div>
        <div class="mb-5">
          <div class="title-description-remark">
            {{ FiltersLanguage(item_inet_w2_page, "remark_title_section2") }}
          </div>
          <a
            class="link-remark"
            :href="`${FiltersLanguage(item_inet_w2_page, 'link_login')}`"
            target="_blank"
          >
            <u>Login</u>
          </a>
          <span class="description-remark">
            {{ FiltersLanguage(item_inet_w2_page, "title_link_login") }}
          </span>
          <div
            class="description-remark"
            v-html="
              FiltersLanguage(item_inet_w2_page, 'description_remark_section2')
            "
          ></div>
        </div>
        <div>
          <div class="title-description-remark">
            {{ FiltersLanguage(item_inet_w2_page, "remark_title_section3") }}
          </div>
          <div
            class="description-remark"
            v-html="
              FiltersLanguage(item_inet_w2_page, 'description_remark_section3')
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      env: '',
      loadingTableINETW3: false,
      paginationTableINETW3: {
        total: 0,
        page: 1,
        row: 999
      },
      itemaTableINETW3: []
    }
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  computed: {
    ...mapGetters(['item_inet_w2_page'])
  },
  created () {
    this.env = process.env.VUE_APP_API
    this.initTableINETW3()
  },
  methods: {
    async initTableINETW3 () {
      this.loadingTableINETW3 = true
      const payload = {
        page: this.paginationTableINETW3.page,
        row: this.paginationTableINETW3.row,
        keyword: 'iNET-W2'
      }
      this.itemaTableINETW3 = []
      const res = await this.getList(payload)
      this.loadingTableINETW3 = false
      if (res.status) {
        if (res.data.data.length !== 0) {
          this.itemaTableINETW3 = res.data.data[0].items
        } else {
          this.itemaTableINETW3 = []
        }
        this.paginationTableINETW3.total = res.data.pagination.total
        this.paginationTableINETW3.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
.layout-all {
  margin-left: 20px;
  margin-right: 20px;
}
.layout-main-content {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
  width: 100%;
}
/* section 1 */
.inet_w2-main-layout {
  width: 100%;
  max-width: 684px;
}
.title-content {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  padding-bottom: 20px;
}
.title-icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
/* section1 :left-vontent and right-content */
.block-content-section1 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.in-content-section1 {
  display: flex;
  flex-wrap: wrap;
}
.left-area {
  width: 25%;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
}
.right-area {
  padding-left: 24px;
  width: 75%;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.hr-border {
  color: var(--v-linerContent-base) !important;
}
>>> .set-css-p p {
  margin-bottom: 0px !important;
}
>>> .set-css-p ol,
ul,
dl {
  margin-bottom: 0;
}
.text-box-section2 {
  margin-right: 7%;
  font-size: 18px;
  font-weight: 600;
  color: var(--v-textPrimary-base) !important;
  width: 90%;
}

/* section 2 */
/* image */
.size-image {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 20px 0px 0px 20px;
}
.detail-card-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 65%;
  border-radius: 0px 10px 10px 0px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  background: var(--v-inetW2DetailCard-base) !important;
}
.detail-card-left {
  display: flex;
  justify-content: center;
  width: 35%;
}
.title-box-section2 {
  color: var(--v-titleSection2-base) !important;
  font-size: 40px;
  font-weight: 700;
  margin-right: 40px;
  margin-left: 40px;
}
.block-content-section2 {
  flex-wrap: wrap;
  display: flex;
  max-width: 684px;
  width: 100%;
  color: var(--v-textSection2-base);
  font-size: 25px;
  font-weight: 600;
}
.icon-download {
  display: flex;
  justify-items: center;
  font-size: 30px;
  color: var(--v-mainIconDownloadInetW2-base) !important;
}
.icon-download:hover {
  background: var(
    --bright-inet,
    linear-gradient(
      136deg,
      rgba(153, 235, 127, 0.8) 0%,
      rgba(65, 232, 204, 0.8) 100%
    )
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.layout-remark {
  margin-bottom: 80px;
}
.title-remark {
  font-size: 14px;
  font-weight: 600;
  color: var(--v-titleSection2-base) !important;
}
.description-remark {
  font-size: 14px;
  font-weight: 400;
  color: var(--v-titleSection2-base) !important;
}
.link-remark {
  font-size: 14px;
  font-weight: 400;
}
.link-remark:hover {
  color: var(--v-hoverLink-base) !important;
}
.title-description-remark {
  font-size: 14px;
  font-weight: 600;
  color: var(--v-titleSection2-base) !important;
}
@media screen and (max-width: 1000px) {
  .title-box-section2 {
    color: var(--v-titleSection2-base) !important;
    font-size: 30px;
    margin-right: 20px;
    margin-left: 20px;
  }
  .text-box-section2 {
    font-size: 20px;
  }
}
@media screen and (max-width: 865px) {
  /* section1 */
  .right-area {
    padding-left: 10px;
    width: 60%;
  }
  .left-area {
    width: 40%;
  }
  /* section2 */
}
@media screen and (max-width: 695px) {
  .layout-main-content {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
  /* section 2 */
  .detail-card-right {
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .detail-card-left {
    width: 100%;
    max-height: 200px;
  }
  .size-image {
    border-radius: 20px 20px 0px 0px;
  }
}
</style>

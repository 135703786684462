<template>
  <div>
    <div class="main-content "  >
      <div class="title-news" >
        <div>
          <v-img
            class="news-icon"
            :src="require(`@/${itemIrNews.image_title}`)"
          ></v-img>
        </div>
        <div>
          {{ FiltersLanguage(itemIrNews, "title") }}
        </div>
      </div>
      <div v-if="!loading" class="news-content " >
        <diV   v-for="(item, i) in items" :key="i" :class="`news-card ${valScroll > 250 ?'slide-in-fast':''}`"  :style="` animation-duration: ${i===0 ?0.3: i/2}s;`">
          <div class="box-img" >
            <v-img
              class="news-img"
              :src="`${env}/api/v1/file/${item.banner}`"
            ></v-img>
          </div>
          <div class="services-blog-content">
            <a class="three-lines" @click="$EventBus.$emit('router-push','detailsNews'),showDetailNew(item.news_id)">
              {{ FiltersLanguage(item, "title") }}
            </a>
            <div style="text-align: end">
              <v-btn @click="$EventBus.$emit('router-push','detailsNews'),showDetailNew(item.news_id)" text class="px-1 btn-show-detail">
                <span class="more-text"
                  >{{ FiltersLanguage(readMore, "readMore") }}
                </span>
              </v-btn>
            </div>
            <div class="text-date">
              {{ returnFormateDate(item.create_date, language) }}
            </div>
          </div>
        </diV>
      </div>
      <cpn-loading :show="loading" />
      <!-- go to newsAll.vue -->
      <div class="seeAll-content">
        <v-btn @click="$EventBus.$emit('router-push','newsAll'),$router.push('news-all')" class="seeAll-btn">
          {{ FiltersLanguage(itemIrNews, "btn_view_all") }}
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
    <show-announce-to-day v-model="statusDialog" :items="itemsAnnounce" @close="closeShowExAnnounce"  />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      dialog: true,
      readMore: {
        readMore_th: 'อ่านเพิ่มเติม',
        readMore_eng: 'more'
      },
      env: '',
      loading: false,
      items: [],
      tags: 'InvestorRelationsNews',
      keyword: '',
      pagination: {
        total: 0,
        page: 1,
        row: 4
      },

      // Announce to day
      statusDialog: false,
      itemsAnnounce: [],
      loadingAnnounce: false

    }
  },
  created () {
    this.env = process.env.VUE_APP_API
    this.init()
    this.initAnnounceToDay()
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  methods: {
    async init () {
      this.items = []
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลข่าวที่จะเอามาแสดง
      const payload = {
        keyword: this.keyword,
        page: this.pagination.page,
        row: this.pagination.row,
        tags: this.tags,
        status: true
      }
      const res = await this.getNews(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination.total = res.data.pagination.total
        this.pagination.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    showDetailNew (id) {
      this.$router
        .push({ name: 'detailsNews', params: { news_id: id } })
        .catch(() => {})
    },
    async initAnnounceToDay () {
      this.itemsAnnounce = []
      this.loadingAnnounce = true
      const res = await this.getAnnouncerToDay()
      this.loadingAnnounce = false
      if (res.status) {
        this.itemsAnnounce = res.data
        if (this.itemsAnnounce.length !== 0) {
          this.statusDialog = true
        }
      } else {
        this.antmessage.error(res.message)
      }
    },
    closeShowExAnnounce () {
      this.statusDialog = false
      setTimeout(() => {
        this.itemsAnnounce = []
      }, 400)
    }
  },
  computed: {
    ...mapGetters(['itemIrNews', 'valScroll', 'announceData'])

  }
}
</script>
<style>
.main-content {
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  /* background: red; */
}
</style>
<style scoped>

.btn-show-detail:hover {
  background-color: var(--v-hoverBtn-base) !important;
}
.more-text {
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  font-weight: 500;
  text-decoration-line: underline;
  letter-spacing: 0px;
  color: var(--v-newstextForESG-base) !important;
}
.news-content {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 60px;
}
.news-icon {
  width: 70px;
  height: 70px;
}
.title-news {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px !important;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5%;
  padding-top: 4rem;
}
.news-card {
  width: 25%;
  /* w 306 ,h 455 */
  /* height: 27.813rem; */
  padding: 12px;
  /* background-color: rosybrown;
  border: 2px solid black; */
}
.box-img {
  /* max-height: 250px; */
  height: 250px;
}

.news-img {
  width: 100%;
  height: 100%;
}
.title-one-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: normal;

  font-family: "Noto Sans Thai";
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  margin-top: 20px;
  color: var(--v-newstext-base) !important;
}
.text-date {
  color: var(--v-newsdate-base) !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.three-lines {
  min-height: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  width: 90%;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  color: var(--v-newstext-base) !important;
}
.more-text {
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
  color: var(--v-newstext-base) !important;
}

.three-lines + a > {
  display: none;
}

/* go to newsAll.vue */
.seeAll-btn {
  cursor: pointer;

  height: 38;
  border-radius: 51px;
  background-color: var(--v-seeAllBtn-base) !important;
  color: var(--v-textSecond-base) !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.seeAll-content {
  display: flex;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 122px;
}
.seeAll-content:before,
.seeAll-content:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid var(--v-textPrimary-base) !important;
  margin: auto;
}

@media screen and (max-width: 1290px) {
  /* .news-card {
    width: 33.333%;
    height: 27.813rem;
    padding: 15px;
  } */
}
@media screen and (max-width: 984px) {
  .news-card {
    width: 50%;
    /* height: 27.813rem; */
  }
  .seeAll-content:before {
    margin-left: 20px;
  }
  .seeAll-content:after {
    margin-right: 20px;
  }
}
@media screen and (max-width: 560px) {
  .news-card {
    width: 100%;
    /* height: 27.813rem; */
  }
}
</style>

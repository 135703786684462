import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2'
import moment from 'moment-timezone'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { message } from 'ant-design-vue'
import VueCookies from 'vue-cookies'
import { Chart, registerables } from 'chart.js'
import {
  ChoroplethController,
  ProjectionScale,
  ColorScale,
  GeoFeature
} from 'chartjs-chart-geo'
import gradient from 'chartjs-plugin-gradient'
import zoomPlugin from 'chartjs-plugin-zoom'

import mixin from './scripts/mixin.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'ant-design-vue/dist/antd.css'
import '@/scripts/eventbus'
import lineChart from './components/chart/lineChart.vue'
import TextEditor from './components/textEditer.vue'
import showAnnounce from './components/adminpage/formAnnounce/cardShowAnnounce.vue'
import showAnnounceToDay from './views/homePage/cardShowAnnounceToDay'
import showNews from './components/adminpage/formNews/cardShowNews.vue'
// highlight.js style
import 'highlight.js/styles/tomorrow.css'

// import theme style
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

// component
import loading from '@/components/loadingData.vue'
import dialogloading from '@/components/adminpage/dialogLoading.vue'
Vue.component('line-chart', lineChart)
Vue.use(VueCookies) // You can also use <link> for styles
// ..

Vue.component('cpn-loading', loading)
Vue.component('u-text-deitor', TextEditor)
Vue.component('show-announce', showAnnounce)
Vue.component('show-announce-to-day', showAnnounceToDay)
Vue.component('show-news', showNews)
Vue.component('dialog-loading', dialogloading)

Vue.prototype.$moment = moment
Vue.use(VueAxios, axios)

Vue.mixin(mixin)
Vue.use(VueSweetalert2)

Vue.prototype.antmessage = message

Chart.register(
  ...registerables,
  ChoroplethController,
  ProjectionScale,
  ColorScale,
  GeoFeature,
  zoomPlugin,
  gradient
)

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('Accesstoken')
    if (token) config.headers.common.Authorization = `Bearer ${token}`
    return config
  },
  (error) => Promise.reject(error)
)

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    if (error.message !== 'Network Error') {
      if (error.response.status) {
        switch (error.response.status) {
          case 401:
            localStorage.clear()
            router.push({ name: 'loginAdmin' }).catch(() => {})
            break
        }
        return Promise.reject(error.response)
      }
    } else message.error('Not connect to server')
  }
)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

<template>
  <div>
    <v-dialog v-model="dialog" fullscreen>
      <div class="py-6 px-5 bg-dialog-structure fade-in">
        <div class="layout-dialog">
          <div class="background-item-dialog">
            <div style="width: 100%" class="pl-5">
              <v-btn
                color="#4F9F8C"
                text
                @click="languageShow = 'th'"
                :disabled="languageShow === 'th'"
                ><span style="font-weight: 800">TH</span></v-btn
              >
              <v-btn
                color="#4F9F8C"
                text
                @click="languageShow = 'eng'"
                :disabled="languageShow === 'eng'"
                ><span style="font-weight: 800">ENG</span></v-btn
              >
            </div>
            <div class="layout-img-dialog">
              <img
                v-if="dataProfile.profile.image"
                class="img-on-dialog"
                :src="`${env}/api/v1/file/${dataProfile.profile.image}`"
              />
            </div>
            <div class="layout-detail-profile">
              <div class="title-items-dialog">
                {{ FiltersLanguageShow(dataProfile.profile, "title") }}
                {{ FiltersLanguageShow(dataProfile.profile, "firstname") }}
                {{ FiltersLanguageShow(dataProfile.profile, "lastname") }}
              </div>
              <div class="detail-items-dialog">
                <div>
                  {{
                    FiltersLanguageShow(item_management_team_page, "title_age")
                  }}
                  : {{ dataProfile.profile.age }}
                  {{
                    FiltersLanguageShow(item_management_team_page, "unit_age")
                  }}
                </div>
                <div>
                  {{
                    FiltersLanguageShow(
                      item_management_team_page,
                      "title_position"
                    )
                  }}
                  :
                </div>
                <div
                  v-for="(items_positions, i) in dataProfile.positions"
                  :key="`${i}-items_position`"
                >
                  <ul class="set-ul">
                    <li>{{ FiltersLanguageShow(items_positions, "title") }}</li>
                  </ul>
                </div>
                <div v-if="statusShowDatePositions">
                  {{
                    FiltersLanguageShow(
                      item_management_team_page,
                      "title_start_position"
                    )
                  }}
                  :
                  {{
                    dataProfile.positions.length == 0 ||
                    dataProfile.positions[0].start_date == ""
                      ? "-"
                      : returnDateFull(
                          dataProfile.positions[0].start_date,
                          languageShow
                        )
                  }}
                </div>
                <div>
                  {{
                    FiltersLanguageShow(
                      item_management_team_page,
                      "title_educational"
                    )
                  }}
                  :
                </div>
                <div>
                  <ul class="set-ul">
                    <li
                      v-for="(items_educationals, i) in dataProfile.profile
                        .educationals"
                      :key="`${i}-items_educationals`"
                    >
                      {{ FiltersLanguageShow(items_educationals, "title") }}
                    </li>
                  </ul>
                </div>
                <div>
                  {{
                    FiltersLanguageShow(
                      item_management_team_page,
                      "title_volume"
                    )
                  }}
                  :
                  {{
                    dataProfile.stock.volume == 0
                      ? FiltersLanguageShow(
                          item_management_team_page,
                          "title_status_no"
                        )
                      : `${returncommaNumber(
                          parseFloat(dataProfile.stock.volume)
                        )} ${FiltersLanguageShow(
                          item_management_team_page,
                          "unit_volume"
                        )} `
                  }}
                </div>
                <div>
                  {{
                    FiltersLanguageShow(
                      item_management_team_page,
                      "title_ratio"
                    )
                  }}
                  :
                  {{
                    dataProfile.stock.ratio == 0
                      ? FiltersLanguageShow(
                          item_management_team_page,
                          "title_status_no"
                        )
                      : `${dataProfile.stock.ratio}%`
                  }}
                </div>
                <diV>
                  {{
                    FiltersLanguageShow(
                      item_management_team_page,
                      "title_relationship"
                    )
                  }}
                  :
                  {{
                    dataProfile.profile.relationship.executive
                      ? FiltersLanguageShow(
                          item_management_team_page,
                          "title_status_yes"
                        )
                      : FiltersLanguageShow(
                          item_management_team_page,
                          "title_status_no"
                        )
                  }}
                </diV>
              </div>
            </div>
          </div>
          <div style="width: 100%" class="d-flex justify-center mt-n5">
            <v-btn @click="close()" class="close-btn mb-5">ปิด</v-btn>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      env: '',
      languageShow: 'th',
      statusShowDatePositions: true
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dataProfile: {
      type: Object,
      default: () => {
        return {
          profile: {
            firstname_th: '',
            firstname_eng: '',
            lastname_th: '',
            lastname_eng: '',
            date_of_birth: '',
            image: '',
            educationals: [
              {
                title_eng: '',
                title_th: '',
                start_date: '',
                end_date: '',
                description_eng: '',
                description_th: ''
              }
            ],
            relationship: {
              executive: false
            }
          },
          group_tags: [
            {
              name: '',
              index: 0
            }
          ],
          positions: [
            {
              title_eng: '',
              title_th: '',
              description_eng: '',
              description_th: '',
              start_date: '',
              end_date: '',
              position_tags: []
            }
          ],
          stock: {
            volume: 0,
            ratio: 0
          },
          status: true
        }
      }
    }
  },

  computed: {
    ...mapGetters(['item_management_team_page']),
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  async created () {
    this.env = process.env.VUE_APP_API
  },
  methods: {
    FiltersLanguageShow (data, key) {
      return data[`${key}_${this.languageShow}`]
    },
    close () {
      this.dialog = false
      this.languageShow = 'th'
    }
  }
}
</script>

<style scoped>
/* dialg */
>>> .v-dialog {
  box-shadow: unset !important;
}
.layout-all {
  margin-right: 20px;
  margin-left: 20px;
}
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
  margin-bottom: 20px;
}
.title-icon {
  width: 16px;
  height: 16px;
  padding: 3px;
}
/* menu list  */
.layout-btn-list {
  display: flex;
  width: 100%;
}

.icon-btn-list {
  margin-top: 12px;
  background: linear-gradient(
    180deg,
    var(--v-gradientBtnList1-base) 0%,
    var(--v-gradientBtnList2-base) 100%
  );
}
.pointer {
  cursor: pointer;
}
.title-btn-list {
  width: 99%;
  font-size: 22px;
  color: var(--v-linerPrimary-base) !important;
  text-align: left;
}
.line-color {
  color: var(--v-linerPrimary-base) !important;
}
.text-title-list {
  font-size: 22px;
  color: var(--v-textMenuList-base) !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.text-title-list:hover {
  font-size: 22px;
  color: var(--v-hoverListText-base) !important;
  background-color: var(--v-hoverList-base) !important;
  border-radius: 15px;
}
.layout-list-all {
  background: rgba(57, 57, 57, 0.7) !important;
  border-radius: 0px 0px 20px 20px !important;
}
.v-menu__content {
  box-shadow: none !important;
}
/* show list team  */
.layout-team-all {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.items-team {
  width: 25%;
  margin-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
}
.size-img-team {
  width: 100%;
  cursor: pointer;
}
.title-items-list {
  font-size: 22px;
  font-weight: 700;
  color: var(--v-textPrimary-base) !important;
  margin-top: 20px;
}
.positions-style {
  font-size: 14px;
  font-weight: 500;
  color: var(--v-textPrimary-base) !important;
}
/* dialg */
.bg-dialog-structure {
  background-color: rgba(0, 0, 0, 0.68);
  height: 100%;
  overflow-y: auto;
}
.layout-dialog {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.background-item-dialog {
  display: flex;
  flex-wrap: wrap;
  background-color: var(--v-backgroundItemDialog-base);
  width: 60%;
  padding-top: 40px;
  padding-bottom: 70px;
}
.layout-img-dialog {
  width: 45%;
  padding-right: 30px;
  padding-left: 30px;
}
.img-on-dialog {
  object-fit: contain;
  width: 100%;
}
.layout-detail-profile {
  width: 55%;
  padding-right: 30px;
}
.title-items-dialog {
  font-size: 32px;
  font-weight: 700;
  color: var(--v-textPrimary-base) !important;
  margin-bottom: 10px;
}
.detail-items-dialog {
  font-size: 20px;
  font-weight: 400;
}
.set-ul {
  margin: 0px;
}
.close-btn {
  cursor: pointer;
  width: 148px;
  height: 38;
  border-radius: 51px;
  background-color: var(--v-btnClose-base) !important;
  color: var(--v-bgPrimary-base) !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

/* section2 Management team */
.layout-section2 {
  background-color: var(--v-backgroundManagementTeam-base) !important;
}
.space-section2 {
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 50px;
}
.title-section2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
  margin-bottom: 20px;
}
.hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media screen and (max-width: 1600px) {
  /* dialg */
  .background-item-dialog {
    width: 80%;
  }
}
@media screen and (max-width: 1400px) {
  /* dialg */
  .background-item-dialog {
    width: 90%;
  }
}
@media screen and (max-width: 990px) {
  .items-team {
    width: 33%;
  }
  /* dialog */
  .background-item-dialog {
    flex-wrap: wrap;
    width: 98%;
    justify-content: center;
  }
  .layout-dialog {
    align-content: unset;
  }
  .layout-img-dialog {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  .img-on-dialog {
    width: 50% !important;
  }
  .layout-detail-profile {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media screen and (max-width: 700px) {
  .img-on-dialog {
    width: 100% !important;
  }
}
@media screen and (max-width: 660px) {
  .items-team {
    width: 50%;
  }
}
@media screen and (max-width: 460px) {
  .items-team {
    width: 100%;
  }
}
</style>

<template>
    <v-dialog
      v-model="dialog"
      max-width="700px"
      transition="dialog-top-transition"
      persistent
    >
      <v-card class="layout-card">
        <v-form ref="formMdAndA">
          <div class="from-persion-card">
            <div class="header-title">
              <v-icon class="icon-header">{{ iconTitle }}</v-icon
              >{{ titleHeader }}
            </div>
            <v-spacer></v-spacer>
            <div>
              <v-btn icon @click="close(), reset()">
                <v-icon>mdi-close</v-icon></v-btn
              >
            </div>
          </div>
          <hr class="hr-header" />
          <div class="title-year">คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการ {{ yearTitle }}</div>
          <div class="text-fully" v-if="itemsTitle.length === 0">
            <v-alert class="mb-0" dense type="info" color="alertColor" text border="left" dark>
              เพิ่มครบทุกหัวข้อแล้ว
            </v-alert>
          </div>
          <div class="from-layout">
            <div class="one-colum">
              <div class="title-text-field">หัวข้อที่ต้องการเพิ่ม</div>
              <div class="box-text-field">
                <div class="box-text-field">
                  <v-select
                    v-model="title"
                    placeholder="กรุณาเลือกหัวข้อที่ต้องการเพิ่ม"
                    :items="itemsTitle"
                    item-text="th"
                    return-object
                    outlined
                    dense
                    color="primaryIconAdmin"
                    item-color="primaryIconAdmin"
                    :rules="[Rule.noInPut, Rule.notNull]"
                    :disabled="itemsTitle.length === 0 ? true : false"
                    readonly
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="one-colum">
              <div class="title-text-field">ไฟล์ไทย (PDF)</div>
              <template>
                <div v-if="status === 'edit' && oldItem.file_th !== undefined">
                  ไฟล์เก่า :
                  <a
                    :href="`${env}/api/v1/file/${oldItem.file_th}`"
                    target="_blank"
                    >{{ oldItem.file_th }}</a
                  >
                  *เลือกไฟล์หากต้องการเปลี่ยน
                </div>
                <v-file-input
                  v-model="file_th"
                  placeholder="กรุณาเลือกไฟล์ไทย"
                  outlined
                  dense
                  append-icon="mdi-paperclip"
                  color="primaryIconAdmin"
                  :rules="status !== 'edit' ? [Rule.noInPut, Rule.notNull] : []"
                  :loading="loadingFileDownloadTH"
                  @change="uploadFile('th')"
                  :disabled="itemsTitle.length === 0 ? true : false"
                  accept=".pdf"
                ></v-file-input>
              </template>
            </div>
            <div class="one-colum">
              <div class="title-text-field">ไฟล์อังกฤษ (PDF)</div>
              <template>
                <div v-if="status === 'edit' && oldItem.file_eng !== undefined">
                  ไฟล์เก่า :
                  <a
                    :href="`${env}/api/v1/file/${oldItem.file_eng}`"
                    target="_blank"
                    >{{ oldItem.file_eng }}</a
                  >
                  *เลือกไฟล์หากต้องการเปลี่ยน
                </div>
                <v-file-input
                  v-model="file_eng"
                  placeholder="กรุณาเลือกไฟล์อังกฤษ"
                  outlined
                  dense
                  append-icon="mdi-paperclip"
                  color="primaryIconAdmin"
                  :rules="status !== 'edit' ? [Rule.noInPut, Rule.notNull] : []"
                  :loading="loadingFileDownloadENG"
                  @change="uploadFile('eng')"
                  :disabled="itemsTitle.length === 0 ? true : false"
                  accept=".pdf"
                ></v-file-input>
              </template>
            </div>
          </div>
          <div class="layout-btn-record-cancle">
            <div class="layout-two-btn">
              <v-btn
                elevation="0"
                class="btn-add mb-2"
                rounded
                @click="validateFrom()"
                :disabled="itemsTitle.length === 0 ? true : false"
                :loading="
                  loadingFileDownload ||
                  loadingFileDownloadTH ||
                  loadingFileDownloadENG
                "
                >บันทึก</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                class="btn-cancle mb-2"
                rounded
                @click="close('m'), reset()"
                >ยกเลิก</v-btn
              >
            </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </template>

<script>
export default {
  components: {},
  data () {
    return {
      env: '',
      editedIndex: 1,
      title: '',
      file_th: null,
      file_eng: null,
      oldItem: {}
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    yearTitle: {
      type: String,
      default: ''
    },
    iconTitle: {
      type: String,
      default: ''
    },
    titleHeader: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: 'add'
    },
    itemsTitle: {
      type: Array,
      default: () => []
    },
    loadingFileDownload: {
      type: Boolean,
      default: false
    },
    loadingFileDownloadTH: {
      type: Boolean,
      default: false
    },
    loadingFileDownloadENG: {
      type: Boolean,
      default: false
    },
    dataMdAndA: {
      type: Object,
      default: () => {
        return {
          list_id: '',
          title_th: '',
          title_eng: '',
          description_th: '',
          description_eng: '',
          type: 'file',
          file_th: '',
          file_eng: '',
          banner_th: '',
          banner_eng: '',
          status: true,
          verify: 0,
          edit_date: '',
          items: []
        }
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    dialog: {
      handler: function (newValue) {
        if (newValue === true && this.status === 'edit') {
          this.oldItem = Object.assign({}, this.dataMdAndA)
        }
      },
      deep: true
    },
    itemsTitle: {
      handler: function (newValue) {
        if (newValue.length !== 0) {
          this.title = newValue[0]
        }
      },
      deep: true
    }
  },
  async created () {
    this.env = process.env.VUE_APP_API
  },
  methods: {
    close () {
      this.dialog = false
    },
    reset () {
      this.$emit('resetForm')
      this.$refs.formMdAndA.reset()
      this.oldItem = {}
    },
    uploadFile (type) {
      if (type === 'th') {
        if (this.status === 'edit' && this.file_th === null) {
          this.$emit('uploadFile', {
            type: 'th',
            file: this.oldItem.file_th,
            title: 'cancle edit'
          })
        } else {
          this.$emit('uploadFile', {
            type: 'th',
            file: this.file_th,
            title: this.title !== null ? this.title.th : ''
          })
        }
      } else if (type === 'eng') {
        if (this.status === 'edit' && this.file_eng === null) {
          this.$emit('uploadFile', {
            type: 'eng',
            file: this.oldItem.file_eng,
            title: 'cancle edit'
          })
        } else {
          this.$emit('uploadFile', {
            type: 'eng',
            file: this.file_eng,
            title: this.title !== null ? this.title.eng : ''
          })
        }
      }
    },

    validateFrom () {
      if (this.$refs.formMdAndA.validate()) {
        if (this.status === 'add') {
          // TODO Update Data
          this.$swal({
            allowEnterKey: false,
            title: 'เพิ่มไฟล์',
            text: `คุณต้องการเพิ่มไฟล์ ${this.title.th} ใช่หรือไม่`,
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#E74444',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#4CC558'
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('addAndEdit', {
                status: 'add'
              })
            }
          })
        } else if (this.status === 'edit') {
          this.$swal({
            allowEnterKey: false,
            title: 'แก้ไขไฟล์',
            text: `คุณต้องการแก้ไขไฟล์ ${this.title.th} ใช่หรือไม่`,
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#E74444',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#4CC558'
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('addAndEdit', {
                status: 'edit'
              })
            }
          })
        }
      }
    }

  }
}
</script>

  <style scoped lang="css">
  >>> .v-text-field--enclosed.v-input--dense:not(
      .v-text-field--solo
    ).v-text-field--outlined
    .v-input__prepend-outer {
    display: none !important;
  }
  .layout-card {
    padding-bottom: 30px;
  }
  .v-input--selection-controls {
    margin-top: 5px;
    padding-top: 4px;
  }
  .btn-add {
    color: var(--v-textLoginbtn-base) !important;
    background-color: var(--v-primaryIconAdmin-base) !important;
    font-size: 16px;
  }
  .btn-cancle {
    color: var(--v-textLoginbtn-base) !important;
    background-color: var(--v-cancleBtn-base) !important;
    font-size: 16px;
  }
  .from-persion-card {
    display: flex;
    height: 100%;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .from-layout {
    height: 100%;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .header-title {
    color: var(--v-primaryIconAdmin-base) !important;
    font-size: 24px;
    font-weight: 600;
  }
  .icon-header {
    color: var(--v-primaryIconAdmin-base) !important;
    font-size: 24px;
    margin-right: 10px;
  }

  .hr-header {
    color: var(--v-primaryIconAdmin-base) !important;
  }
  .comment-img {
    color: var(--v-textPrimaryAdmin-base) !important;
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
  }
  .title-text-field {
    font-size: 16px;
  }
  .layout-two-colum {
    width: 100%;
    display: flex;
  }
  .layout-file-img {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
  .box-show-img {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .width-img {
    /* max-height: 250px;
      max-width: 250px; */
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .bg-file-img {
    background-color: var(--v-bgFileImg-base);
    height: 250px;
    width: 250px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #adadad;
  }
  .file-input {
    width: 50%;
  }
  .two-colum {
    width: 50%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .one-colum {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  .layout-add-positions {
    display: flex;
    widows: 100%;
    flex-wrap: wrap;
    background-color: var(--v-bgAddsection-base);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .layout-add-educationals {
    display: flex;
    widows: 100%;
    flex-wrap: wrap;
    background-color: var(--v-bgAddsection-base);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .layout-btn-record-cancle {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .layout-two-btn {
    width: 200px;
    display: flex;
  }
  .btn-delete {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .title-year {
    font-size: 22px;
    font-weight: 600;
    color: var(--v-textPrimaryAdmin-base) !important;
    text-align: center;
    margin-top: 20px;
  }
  .text-fully {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
    color: var(--v-alertColor-base) !important;
  }

  @media screen and (max-width: 650px) {
    .from-layout {
      padding-left: 20px;
      padding-right: 20px;
    }
    .file-input {
      width: 100%;
    }
    .layout-two-colum {
      flex-wrap: wrap;
    }
    .two-colum {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
    .one-colum {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  </style>

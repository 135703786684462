<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
      transition="dialog-top-transition"
      persistent
    >
      <v-card class="pa-10">
        <div class="text-loading">loading...</div>
        <v-progress-linear
          indeterminate
          rounded
          color="green"
          height="7"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-loading{
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
</style>

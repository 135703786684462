<template>
  <div>
    <titleHeaderMenu :iconTitle="iconTitle" :titleMenu="titleMenu" />
    <div class="btn-hearder">
      <div class="box-btn">
        <v-btn
          class="btn-add"
          elevation="0"
          rounded
          @click="openFormAddEditUser('add')"
          ><v-icon>mdi-plus</v-icon>เพิ่มผู้ใช้งาน</v-btn
        >
      </div>
      <v-spacer></v-spacer>
      <div class="box-text-field">
        <v-text-field
          v-model="search"
          :append-icon="'mdi-magnify'"
          label="ค้นหาจากชื่อ"
          color="primaryIconAdmin"
        ></v-text-field>
        <v-btn @click="searchTable()" class="ml-2 btn-add" rounded elevation="0"
          >ค้นหา</v-btn
        >
      </div>
    </div>
    <div>
      <tableAdminAll
        :headers="headerTableUser"
        :items="itemsTableUser"
        :loading="loadingTableUser"
        :pagination="paginationTableUser"
        @onChangePagination="onChangeTableUser"
        warpHeader
        :height="$vuetify.breakpoint.height-280"
      >
        <template v-slot:[`item.number`]="{ item }">
          <span>
            {{
              paginationTableUser.row * paginationTableUser.page -
              paginationTableUser.row +
              (itemsTableUser.indexOf(item) + 1)
            }}
          </span>
        </template>
        <template v-slot:[`item.create_date`]="{ item }">
          {{ returnFormateDate(item.create_date, "th") }}
        </template>
        returnNamePermission
        <template v-slot:[`item.permissions`]="{ item }">
          {{ returnNamePermission(item.permissions[0]) }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div class="layout-action">
            <v-btn icon
            :disabled="userInfo.user_id === item.user_id ?true:false "
              ><v-icon
                class="btn-edit"
                @click="itemForm = JSON.parse(JSON.stringify(item)), openFormAddEditUser('edit')"
                >mdi-pencil</v-icon
              ></v-btn
            >
            <v-btn icon
              ><v-icon class="btn-reset" @click="resetPasswordUser(item)">mdi-lock-reset</v-icon></v-btn
            >
            <v-btn icon
            :disabled="userInfo.user_id === item.user_id ?true:false "
              ><v-icon class="btn-delete" @click="recheckDeleteUser(item)"
                >mdi-trash-can-outline</v-icon
              ></v-btn
            >
          </div>
        </template>
      </tableAdminAll>
    </div>
    <formAddEditUser
      ref="fromUser"
      v-model="statusOpenForm"
      :dataFromUser="itemForm"
      :status="statusForm"
      :iconTitle="iconTitleFrom"
      :titleHeader="titleFrom"
      :itemPermission="itemPermission"
      @addAndEdit="addAndEditUser"
      @resetForm=" itemForm = JSON.parse(JSON.stringify(defalutItemForm)) "
      :loading="loadingFormUser"
      :loadingItemPermission = "loadingItemPermission"
      @resetPassword="recheckResetPasswordByEmail"
    />
    <dialog-loading v-model="loadingFormUser" />
    <formResetPassword  v-model="dialogResetPassword" titleHeader="แก้ไขรหัสผ่าน" :userID="user_id" :userName="userName" />
  </div>
</template>

<script>
import titleHeaderMenu from '../../../components/adminpage/titleHeaderMenu.vue'
import tableAdminAll from '../../../components/adminpage/tableAll/tableAdminPagination.vue'
import formAddEditUser from '../../../components/adminpage/formUserPage/formAddEditUser.vue'
import formResetPassword from '../../../components/adminpage/formUserPage/resetPassword.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    titleHeaderMenu,
    tableAdminAll,
    formAddEditUser,
    formResetPassword
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  data () {
    return {
      iconTitle: 'mdi-account-multiple-plus',
      iconTitleFrom: '',
      titleMenu: 'ผู้ใช้งาน',
      titleFrom: '',
      search: '',
      loadingTableUser: false,
      headerTableUser: [
        { text: 'ลำดับ', align: 'center', value: 'number', sortable: false },
        { text: 'ชื่อ', align: 'start', value: 'first_name', sortable: false },
        {
          text: 'นามสกุล',
          align: 'start',
          value: 'last_name',
          sortable: false
        },
        {
          text: 'ชื่อผู้ใช้งาน',
          align: 'center',
          value: 'methods[0].username',
          sortable: false
        },
        {
          text: 'สิทธิ',
          align: 'center',
          value: 'permissions',
          sortable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'center',
          value: 'create_date',
          sortable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false
        }
      ],
      itemsTableUser: [],
      paginationTableUser: {
        total: 0,
        page: 1,
        row: 10
      },
      // form
      statusOpenForm: false,
      statusForm: '',
      loadingFormUser: false,
      loadingItemPermission: false,
      itemPermission: [],
      defalutItemForm: {
        name_title: '',
        first_name: '',
        last_name: '',
        phone: '',
        gender: '',
        username: '',
        permissions: [],
        image: '',
        email_verify: '',
        date_of_birth: '',
        street: '',
        zip_code: '',
        city: '',
        country_code: '',
        country: '',
        time_zone: '',
        description: ''
      },
      itemForm: {
        name_title: '',
        first_name: '',
        last_name: '',
        phone: '',
        gender: '',
        username: '',
        permissions: [],
        image: '',
        email_verify: '',
        date_of_birth: '',
        street: '',
        zip_code: '',
        city: '',
        country_code: '',
        country: '',
        time_zone: '',
        description: ''
      },

      // reset password
      dialogResetPassword: false,
      userName: '',
      user_id: ''
    }
  },
  async created () {
    this.init()
  },
  methods: {
    init () {
      this.initItemPermission()
      this.initTableUser()
    },
    returnNamePermission (id) {
      const permission = this.itemPermission.filter(
        (item) => item.permission_id === id
      )
      if (permission.length !== 0) {
        return permission[0].name
      } else {
        return 'ไม่พบสิทธิ'
      }
    },
    async initItemPermission () {
      this.itemPermission = []
      this.loadingItemPermission = true
      const res = await this.initPermission()
      this.loadingItemPermission = false
      if (res.status) {
        this.itemPermission = res.data
      } else {
        this.antmessage.error(res.message)
      }
    },
    async initTableUser () {
      this.itemsTableUser = []
      this.loadingTableUser = true
      const payload = {
        keyword: this.search,
        page: this.paginationTableUser.page,
        row: this.paginationTableUser.row,
        verify: 2
      }
      const res = await this.initUserTable(payload)
      this.loadingTableUser = false
      if (res.status) {
        this.itemsTableUser = res.data.data
        this.paginationTableUser.total = res.data.pagination.total
        this.paginationTableUser.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    searchTable () {
      this.paginationTableUser.page = 1
      this.initTableUser()
    },
    onChangeTableUser (val) {
      this.paginationTableUser.page = val.page
      this.paginationTableUser.row = val.row
      this.initTableUser()
    },
    openFormAddEditUser (status) {
      if (status === 'add') {
        this.statusForm = 'add'
        this.titleFrom = 'เพิ่มผู้ใช้งาน'
        this.iconTitleFrom = 'mdi-plus'
        this.statusOpenForm = true
      } else if (status === 'edit') {
        this.statusForm = 'edit'
        this.titleFrom = 'แก้ไขผู้ใช้งาน'
        this.iconTitleFrom = 'mdi-pencil'
        this.statusOpenForm = true
      }
    },
    addAndEditUser (item) {
      if (item.status === 'add') {
        this.addUser(item.item)
      } else if (item.status === 'edit') {
        this.editUser(item.item)
      }
    },
    async addUser (item) {
      // item.phone = '000000000'
      this.loadingFormUser = true
      const res = await this.createUser(item)
      this.loadingFormUser = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มบัญชีผู้ใช้สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.$refs.fromUser.close()
        this.initTableUser()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async editUser (item) {
      item.methods[0].username = item.username
      // item.phone = '000000000'
      this.loadingFormUser = true
      const res = await this.updateUser(item)
      this.loadingFormUser = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขบัญชีผู้ใช้สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.$refs.fromUser.close()
        this.initTableUser()
      } else {
        this.antmessage.error(res.message)
      }
    },
    recheckDeleteUser (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบผู้ใช้งาน',
        text: 'คุณต้องการลบบัญชีผู้ใช้งานนี้ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.deleteUserItem(item)
        }
      })
    },
    async deleteUserItem (item) {
      this.loadingFormUser = true
      const res = await this.deleteUser(item)
      this.loadingFormUser = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบบัญชีผู้ใช้สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.paginationTableUser.page = 1
        this.initTableUser()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckResetPasswordByEmail (email) {
      this.$swal({
        allowEnterKey: false,
        title: 'แก้ไขรหัสผ่าน',
        text: `คุณต้องการส่งฟอร์มแก้ไขรหัสผ่านไปยัง ${email} ใช่หรือไม่ `,
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.resetPasswordByEmail(email)
        }
      })
    },
    async resetPasswordByEmail (email) {
      this.loadingFormUser = true
      const payload = {
        email: email
      }
      const res = await this.sendEmailresetPasswordUser(payload)
      this.loadingFormUser = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: `แบบฟอร์มแก้ไขรหัสผ่านจะถูกส่งไปยัง ${email} `,
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
      } else {
        this.antmessage.error(res.message)
      }
    },
    resetPasswordUser (item) {
      this.dialogResetPassword = true
      this.user_id = item.user_id
      this.userName = item.methods[0].email
    }
  }
}
</script>

<style scoped>
.btn-hearder {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
.btn-edit {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-reset {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-delete {
  color: var(--v-cancleBtn-base) !important;
}
.layout-action {
  min-width: 120px;
}
.box-text-field {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 450px) {
  .btn-hearder {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .box-btn {
    width: 100%;
  }
  .box-text-field {
    margin-top: 10px;
    width: 100%;
  }
}
</style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="1800px"
    transition="dialog-top-transition"
    persistent
  >
    <v-card class="layout-card">
      <v-form ref="fromNews">
        <div class="from-persion-card">
          <div class="header-title">
            <v-icon class="icon-header">{{ iconTitle }}</v-icon
            >{{ titleHeader }}
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn icon @click="close()"> <v-icon>mdi-close</v-icon></v-btn>
          </div>
        </div>
        <hr class="hr-header" />
        <div class="from-layout">
          <div class="one-colum">
            <div class="d-flex">
              <div class="title-text-field pt-2 pr-2">ปิด/เปิด การแสดงข่าว</div>
              <div>
                <v-switch
                  v-model="itemForm.status"
                  inset
                  color="primaryIconAdmin"
                ></v-switch>
              </div>
            </div>
          </div>
          <div class="two-colum">
            <div class="title-text-field">รูปประจำข่าว</div>
            <template>
                <v-file-input
                  v-model="banner"
                  placeholder="กรุณาเลือกรูปประจำข่าว"
                  outlined
                  dense
                  append-icon="mdi-paperclip"
                  color="primaryIconAdmin"
                  :rules="status !== 'edit' ? [Rule.noInPut, Rule.notNull] : []"
                  :loading="loadingFileBanner"
                  @change="uploadFile()"
                  accept="image/*"
                ></v-file-input>
              <div
                v-if="status === 'edit' && oldItem.banner !== undefined"
                class="mt-n1 mb-7"
              >
              รูปประจำข่าวเก่า :
                <a
                  :href="`${env}/api/v1/file/${oldItem.banner}`"
                  target="_blank"
                  >{{ oldItem.banner }}</a
                >
                <br>
                *เลือกไฟล์หากต้องการเปลี่ยน
              </div>
            </template>
          </div>
          <div class="two-colum">
            <div class="title-text-field">หมวดหมู่ข่าว</div>
            <v-select
              class="width-select"
              v-model="itemForm.tags"
              :items="itemsCategory"
              item-text="text"
              item-value="val"
              color="primaryIconAdmin"
              item-color="primaryIconAdmin"
              dense
              multiple
              outlined
              clearable
              placeholder="กรุณาเลือกหมวดหมู่"
              :rules="[Rule.noInPut, Rule.notNull, Rule.noArray]"
              :loading="loading"
              :disabled="loading"
            ></v-select>
          </div>
          <div class="two-colum">
            <div class="title-text-field">ชื่อเรื่องไทย</div>
            <v-text-field
              v-model="itemForm.title_th"
              placeholder="กรุณากรอกชื่อเรื่องข่าว"
              outlined
              dense
              color="primaryIconAdmin"
              :rules="[Rule.noInPut, Rule.notNull]"
              :loading="loading"
              :disabled="loading"
            ></v-text-field>
          </div>
          <div class="two-colum">
            <div class="title-text-field">ชื่อเรื่องอังกฤษ</div>
            <v-text-field
              v-model="itemForm.title_eng"
              placeholder="กรุณากรอกชื่อเรื่องข่าว"
              outlined
              dense
              color="primaryIconAdmin"
              :rules="[Rule.noInPut, Rule.notNull]"
              :loading="loading"
              :disabled="loading"
            ></v-text-field>
          </div>
          <div class="one-colum">
            <div class="title-text-field">เนื้อหาข่าวไทย</div>
            <div class="mt-2 mb-7">
              <div
                :class="`${
                  description_th_validate
                    ? 'border-validate'
                    : 'border-validate-none'
                }`"
              >
                <u-text-deitor
                  v-model="itemForm.description_th"
                ></u-text-deitor>
              </div>
              <span v-if="description_th_validate" class="text-validate"
                >กรุณากรอกข้อมูล</span
              >
            </div>
          </div>
          <div class="one-colum">
            <div class="title-text-field">เนื้อหาข่าวอังกฤษ</div>
            <div class="mt-2 mb-7">
              <div
                :class="`${
                  description_eng_validate
                    ? 'border-validate'
                    : 'border-validate-none'
                }`"
              >
                <u-text-deitor
                  v-model="itemForm.description_eng"
                ></u-text-deitor>
              </div>
              <span v-if="description_eng_validate" class="text-validate"
                >กรุณากรอกข้อมูล</span
              >
            </div>
          </div>
        </div>

        <div class="layout-btn-record-cancle">
          <div class="px-1">
            <v-btn
              elevation="0"
              class="btn-add mb-2"
              rounded
              @click="showEx()"
              :loading="loading"
              >ดูตัวอย่าง</v-btn
            >
          </div>
          <div class="px-1">
            <v-btn
              elevation="0"
              class="btn-add mb-2"
              rounded
              @click="validateFrom()"
              :loading="loading"
              >บันทึก</v-btn
            >
          </div>
          <div class="px-1">
            <v-btn
              elevation="0"
              class="btn-cancle mb-2"
              rounded
              @click="close()"
              :loading="loading"
              >ยกเลิก</v-btn
            >
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      env: '',
      itemForm: {
        banner: '',
        title_eng: '',
        title_th: '',
        description_th: '',
        description_eng: '',
        tags: [],
        status: false
      },
      oldItem: {},
      banner: null,
      description_th_validate: false,
      description_eng_validate: false
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    iconTitle: {
      type: String,
      default: ''
    },
    titleHeader: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: 'add'
    },
    loading: {
      type: Boolean,
      default: false
    },
    loadingFileBanner: {
      type: Boolean,
      default: false
    },
    itemsCategory: {
      type: Array,
      default: () => []
    },
    dataFromNews: {
      type: Object,
      default: () => {
        return {
          banner: '',
          title_eng: '',
          title_th: '',
          description_th: '',
          description_eng: '',
          tags: [],
          status: false
        }
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    dialog: {
      handler: function (newValue) {
        if (newValue === true && this.status === 'edit') {
          this.oldItem = Object.assign({}, this.dataFromNews)
          this.itemForm = JSON.parse(JSON.stringify(this.dataFromNews))
        }
      },
      deep: true
    },
    dataFromNews: {
      handler: function (newValue) {
        if (this.dialog === true && this.status === 'add') {
          this.itemForm.banner = newValue.banner
        } else if (this.dialog === true && this.status === 'edit') {
          if (newValue.banner === '') {
            this.itemForm.banner = this.oldItem.banner
          } else {
            this.itemForm.banner = newValue.banner
          }
        }
      },
      deep: true
    },
    itemForm: {
      handler: function (newValue) {
        if (
          newValue.description_th !== '' &&
          newValue.description_th !== null &&
          this.description_th_validate === true
        ) {
          this.description_th_validate = false
        }
        if (
          newValue.description_eng !== '' &&
          newValue.description_eng !== null &&
          this.description_eng_validate === true
        ) {
          this.description_eng_validate = false
        }
      },
      deep: true
    }
  },
  async created () {
    this.env = process.env.VUE_APP_API
  },
  methods: {
    close () {
      this.dialog = false
      this.$emit('resetForm')
      this.$refs.fromNews.reset()
      this.description_th_validate = false
      this.description_eng_validate = false
      this.oldItem = {}
      this.itemForm = {
        banner: '',
        title_eng: '',
        title_th: '',
        description_th: '',
        description_eng: '',
        tags: [],
        status: false
      }
    },
    validateFrom () {
      if (
        this.$refs.fromNews.validate() &&
        this.itemForm.description_th !== '' &&
        this.itemForm.description_th !== null &&
        this.itemForm.description_eng !== '' &&
        this.itemForm.description_eng !== null
      ) {
        if (this.status === 'add') {
          // TODO Update Data
          this.$swal({
            allowEnterKey: false,
            title: 'เพิ่มข่าว',
            text: 'คุณต้องการเพิ่มข่าวใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#E74444',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#4CC558'
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('addAndEdit', {
                status: 'add',
                item: this.itemForm
              })
            }
          })
        } else if (this.status === 'edit') {
          this.$swal({
            allowEnterKey: false,
            title: 'แก้ไขข่าว',
            text: 'คุณต้องการแก้ไขข่าวใช่หรือไม่',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#E74444',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#4CC558'
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('addAndEdit', {
                status: 'edit',
                item: this.itemForm
              })
            }
          })
        }
      } else {
        if (
          this.itemForm.description_th === '' ||
          this.itemForm.description_th === null
        ) {
          this.description_th_validate = true
        }
        if (
          this.itemForm.description_eng === '' ||
          this.itemForm.description_eng === null
        ) {
          this.description_eng_validate = true
        }
      }
    },
    uploadFile () {
      this.$emit('uploadFile', {
        file: this.banner,
        title: 'รูปประจำข่าว'
      })
    },
    showEx () {
      this.$emit('showEx', this.itemForm)
    }
  }
}
</script>

<style scoped lang="css">
>>> .v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__prepend-outer {
  display: none !important;
}
.layout-card {
  padding-bottom: 30px;
}
.v-input--selection-controls {
  margin-top: 5px;
  padding-top: 4px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
.btn-cancle {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-cancleBtn-base) !important;
  font-size: 16px;
}
.from-persion-card {
  display: flex;
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.from-layout {
  height: 100%;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.header-title {
  color: var(--v-primaryIconAdmin-base) !important;
  font-size: 24px;
  font-weight: 600;
}
.icon-header {
  color: var(--v-primaryIconAdmin-base) !important;
  font-size: 24px;
  margin-right: 10px;
}

.hr-header {
  color: var(--v-primaryIconAdmin-base) !important;
}
.comment-img {
  color: var(--v-textPrimaryAdmin-base) !important;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}
.title-text-field {
  font-size: 16px;
}
.layout-two-colum {
  width: 100%;
  display: flex;
}
.layout-file-img {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.box-show-img {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.width-img {
  /* max-height: 250px;
      max-width: 250px; */
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.bg-file-img {
  background-color: var(--v-bgFileImg-base);
  height: 250px;
  width: 250px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #adadad;
}
.file-input {
  width: 50%;
}
.two-colum {
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}
.one-colum {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.layout-add-positions {
  display: flex;
  widows: 100%;
  flex-wrap: wrap;
  background-color: var(--v-bgAddsection-base);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.layout-add-educationals {
  display: flex;
  widows: 100%;
  flex-wrap: wrap;
  background-color: var(--v-bgAddsection-base);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.layout-btn-record-cancle {
  width: 100%;
  display: flex;
  justify-content: center;
}
.layout-two-btn {
  width: 200px;
  display: flex;
}
.btn-delete {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.title-year {
  font-size: 22px;
  font-weight: 600;
  color: var(--v-textPrimaryAdmin-base) !important;
  text-align: center;
  margin-top: 20px;
}

.border-validate {
  border: #ff6363 solid 2px;
}
.border-validate-none {
  border: unset;
}
.text-validate {
  font-size: 12px;
  color: var(--v-error-base);
  padding-left: 12px;
}
@media screen and (max-width: 650px) {
  .from-layout {
    padding-left: 20px;
    padding-right: 20px;
  }
  .file-input {
    width: 100%;
  }
  .layout-two-colum {
    flex-wrap: wrap;
  }
  .two-colum {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  .one-colum {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>

<template>
  <div class="main-content">
    <div class="layout-all">
      <div class="contactPage-content">
        <div class="title-content">
          <div class="d-flex justify-center">
            {{ FiltersLanguage(item_contact_page, "title") }}
            <div>
              <v-img
                class="title-icon "
                :src="require(`@/${item_contact_page.img_dot}`)"
              ></v-img>
            </div>
          </div>
        </div>
        <hr class="hr-border" />
        <div class="middle-content">
          <div class="content-area-1">
            <v-img
              contain
              class="img_content slide-in"
              :src="require(`@/${item_contact_page.img_building}`)"
            ></v-img>
          </div>
          <div class="content-area-2">
            <div>
              <div class="contact-title">
                {{ FiltersLanguage(item_contact_page, "name_company") }}
              </div>
              <!-- line -->
              <v-divider class="contact-line my-3"></v-divider>
              <div class="contact-text">
                <!-- <div
          class="layuot-address"
          v-html="FiltersLanguage(item_contact_page, 'address_company')"
        ></div> -->
        <div  class="layuot-address" v-if="$vuetify.breakpoint.name !== 'xs'&&$vuetify.breakpoint.name !== 'sm'&&$vuetify.breakpoint.name !== 'md'" v-html="FiltersLanguage(item_contact_page, 'address_company')"></div>
          <div  class="layuot-address" v-if="$vuetify.breakpoint.name === 'xs'||$vuetify.breakpoint.name === 'sm'||$vuetify.breakpoint.name === 'md'" v-html=" FiltersLanguage(item_contact_page, 'address_company_mobile')"></div>
                <!-- phone -->
                <div>
                  {{ FiltersLanguage(item_contact_page.phone, "title") }}
                  <a
                    class="link-text"
                    :href="`tel:${FiltersLanguage(
                      item_contact_page.phone,
                      'val'
                    )}`"
                  >
                    {{ FiltersLanguage(item_contact_page.phone, "val") }}
                  </a>
                </div>
                <!-- fax -->
                <div>
                  {{ FiltersLanguage(item_contact_page.fax, "title") }}
                  <a
                    class="link-text"
                    :href="`fax:${FiltersLanguage(
                      item_contact_page.fax,
                      'val'
                    )}`"
                  >
                    {{ FiltersLanguage(item_contact_page.fax, "val") }}
                  </a>
                </div>
                <!-- email -->
                <div>
                  {{ FiltersLanguage(item_contact_page.email, "title") }}
                  <a
                    class="link-text"
                    :href="`mailto:${FiltersLanguage(
                      item_contact_page.email,
                      'val'
                    )}`"
                  >
                    {{ FiltersLanguage(item_contact_page.email, "val") }}
                  </a>
                </div>
                <!-- service_center -->
                {{ FiltersLanguage(item_contact_page.service_center, "title") }}
                <a
                  class="link-text"
                  :href="`tel:${FiltersLanguage(
                    item_contact_page.service_center,
                    'val'
                  )}`"
                >
                  {{ FiltersLanguage(item_contact_page.service_center, "val") }}
                </a>
              </div>
              <div class="btn-map">
                <v-btn
                  rounded
                  large
                  href="https://goo.gl/maps/qBJhSux79csvuWjt7"
                  class="clickMap-btn"
                  target="_blank"
                >
                  {{ FiltersLanguage(item_contact_page, "btn_map") }}
                </v-btn>
              </div>
            </div>
          </div>
          <div class="content-area-3">
            <div class="contact-box-2">
              <div class="contact-title-noc">
                {{ FiltersLanguage(item_contact_page, "noc_center") }}
              </div>
              <div class="noc_text">
                <!-- phone_noc -->
                {{ FiltersLanguage(item_contact_page.phone_noc, "title") }}
                <a
                  class="link-text"
                  :href="`tel:${FiltersLanguage(
                    item_contact_page.phone_noc,
                    'val'
                  )}`"
                >
                  {{ FiltersLanguage(item_contact_page.phone_noc, "val") }}
                </a>
              </div>
              <div class="noc_text">
                <!-- fax_noc -->
                {{ FiltersLanguage(item_contact_page.fax_noc, "title") }}
                <a
                  class="link-text"
                  :href="`fax:${FiltersLanguage(
                    item_contact_page.fax_noc,
                    'val'
                  )}`"
                >
                  {{ FiltersLanguage(item_contact_page.fax_noc, "val") }}
                </a>
              </div>
              <div class="noc_text">
                <!-- email_noc -->
                {{ FiltersLanguage(item_contact_page.email_noc, "title") }} <a
                  class="link-text"
                  :href="`mailto:${FiltersLanguage(
                    item_contact_page.email_noc,
                    'val'
                  )}`"
                >
                  {{ FiltersLanguage(item_contact_page.email_noc, "val") }}
                </a>
              </div>
            </div>
          </div>
          <div class="content-area-4">
            <v-img
              class="img_content slide-in " style="animation-delay:1s;"
              :src="require(`@/${item_contact_page.img_contact}`)"
            ></v-img>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {}
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  computed: {
    ...mapGetters(['item_contact_page'])
  }
}
</script>

<style scoped>
.layout-all {
  margin-right: 20px;
  margin-left: 20px;
}
.contactPage-content {
  width: 100%;
  margin-bottom: 85px;
}
.middle-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
}
.title-icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
.hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 40px;
  margin-bottom: 40px;
}
/* Content area 4 parts */
.content-area-1 {
  display: flex;
  align-content: center;
  width: 50%;
}
.layuot-address {
  margin-top: 25px;
  margin-bottom: 40px;
}
.content-area-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 50%;
  padding-left: 60px;
  padding-bottom: 40px;
}
.link-text {
  color: var(--v-linerPrimary-base) !important;
}
.link-text:hover {
  color: var(--v-hoverLink-base) !important;
}
.content-area-3 {
  display: flex;
  align-items: center;
  width: 50%;
  padding-right: 60px;
}
.content-area-4 {
  display: flex;
  justify-content: center;
  width: 50%;
}
.img_content {
  width: 100%;
}
.contact-box {
  width: 100%;
}
.contact-box-2 {
  width: 100%;
}
.contact-title {
  color: var(--v-textPrimary-base) !important;
  font-size: 26px;
  font-weight: 700;
}
.contact-title-noc {
  display: flex;
  justify-content: flex-end;
  color: var(--v-textPrimary-base) !important;
  font-size: 28px;
  font-weight: 700;
  text-align: right;
}
.contact-line {
  border: 2px solid var(--v-LinerContact-base);
}
.contact-text {
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  color: var(--v-textPrimary-base) !important;
}
.btn-map {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
.clickMap-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--v-btnMap-base) !important;
  color: var(--v-textSecond-base) !important;
  width: 100%;
  max-width: 369.992px;
  font-size: 24px;
  font-weight: 400;
}
/* content-area-3 : noc_center  */
.noc_text {
  /* display: flex;
  justify-content: flex-end; */
  text-align: right;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  color: var(--v-textPrimary-base) !important;
}
@media screen and (max-width: 984px) {
  .hr-border {
    color: var(--v-linerPrimary-base) !important;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .content-area-2 {
    padding-left: 20px;
  }
  .content-area-3 {
    padding-right: 20px;
  }
  .contact-title {
    font-size: 26px;
  }
  .contact-title-noc {
    font-size: 26px;
  }
  .contact-text {
    font-size: 20px;
  }
  .noc_text {
    font-size: 20px;
  }
  .btn-map {
    margin-top: 20px;
  }
  .clickMap-btn {
    font-size: 22px;
  }
}

@media screen and (max-width: 755px) {
  .content-area-1 {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .content-area-2 {
    display: flex;
    width: 100%;
    padding-left: 0px;
    margin-top: 20px;
  }
  .content-area-3 {
    display: flex;
    width: 100%;
    padding-bottom: 40px;
  }
  .contact-title-noc {
    justify-content: flex-start;
    font-size: 26px;
    text-align: left;
  }
  .content-area-4 {
    width: 100%;
  }
  .btn-map {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 0px;
  }
  .clickMap-btn {
    margin-bottom: 0px;
  }
  .noc_text {
    display: flex;
    justify-content: flex-start;
  }
}
</style>

<template>
  <div>
    <div class="main-content">
      <div class="layout-all">
        <!-- title -->
        <div class="title-content">
          <div class="d-flex justify-center">
            {{ FiltersLanguage(item_esg_page, "title") }}
            <div>
              <v-img
                class="title-icon"
                :src="require(`@/${item_esg_page.img_dot}`)"
                contain
              ></v-img>
            </div>
          </div>
        </div>
        <hr class="hr-border" />
        <!-- section 1: ความยั่งยืน : ESG -->
        <div class="esg-content">
          <!-- left -->
          <div class="content-left-left">
            <v-img
              class="img-esg"
              :src="require(`@/${item_esg_page.img_logo_CAC}`)"
            ></v-img>
          </div>
          <!-- right -->
          <div class="content-left-right">
            <p
              v-if="
                $vuetify.breakpoint.name !== 'xs' &&
                $vuetify.breakpoint.name !== 'sm' &&
                $vuetify.breakpoint.name !== 'md'
              "
              v-html="FiltersLanguage(item_esg_page, 'description')"
              class="text-description-box"
            ></p>
            <p
              v-if="
                $vuetify.breakpoint.name === 'xs' ||
                $vuetify.breakpoint.name === 'sm' ||
                $vuetify.breakpoint.name === 'md'
              "
              v-html="FiltersLanguage(item_esg_page, 'description_mobile')"
              class="text-description-box"
            ></p>
          </div>
        </div>
        <div class="gray-box">
          {{ FiltersLanguage(item_esg_page, "title_dimension") }}
        </div>
      </div>
    </div>
    <div class="bg-gradient pb-5">
      <div class="main-content">
        <div class="layout-all">
          <!-- section 2 : ECONOMY -->
          <div>
            <div
              :style="{
                backgroundImage: `url(${require(`@/${item_economy.img_background_title}`)})`,
              }"
              class="bg-image-title"
              @click="$router.push({ name: 'economyNews' })"
            >
              <!-- subtopic image -->
              <v-img
                class="title-text-image"
                :src="require(`@/${item_economy.img_title}`)"
                contain
              ></v-img>
            </div>
            <!-- <div class="subtopic-content"> -->
            <!-- left -->
            <!-- <div class="subtopic-left-right">
                <diV
                  v-for="(economy_left, i) in subTopic_economy.left"
                  :key="i"
                  class="subtopic-box"
                >
                  <v-img
                    class="subtopic-icon"
                    :src="require(`@/${economy_left.img_sub}`)"
                  ></v-img>
                  <div class="subtopic-text">
                    {{ FiltersLanguage(economy_left, "titile_sub") }}
                  </div>
                </diV>
              </div> -->
            <!-- Vertical-Line -->
            <!-- <div class="vertical-line"></div> -->
            <!-- right -->
            <!-- <div class="subtopic-left-right">
                <diV
                  v-for="(economy_right, i) in subTopic_economy.right"
                  :key="i"
                  class="subtopic-box"
                >
                  <v-img
                    class="subtopic-icon"
                    :src="require(`@/${economy_right.img_sub}`)"
                  ></v-img>
                  <div class="subtopic-text">
                    {{ FiltersLanguage(economy_right, "titile_sub") }}
                  </div>
                </diV>
              </div> -->
            <!-- </div> -->
            <div class="mx-n3">
              <img
                contain
                class="image_detail"
                @click="
                  setImageDialog(
                    FiltersLanguage(item_economy, 'image_economy')
                  )
                "
                :src="
                  require(`@/${FiltersLanguage(
                    item_economy,
                    'image_economy'
                  )}`)
                "
              />
            </div>
          </div>
          <hr class="hr-border" />
          <groupNews :items="itemsEconomy" :loading="loadingEconomy" />
        </div>
      </div>
    </div>
    <div class="bg-gradient pb-5">
      <div class="main-content">
        <div class="layout-all">
          <!-- section 3 : environment -->
          <div>
            <div
              :style="{
                backgroundImage: `url(${require(`@/${item_environment.img_background_title}`)})`,
              }"
              class="bg-image-title"
              @click="$router.push({ name: 'environmentNews' })"
            >
              <!-- image-title-link -->
              <v-img
                class="title-text-image mb-5"
                :src="require(`@/${item_environment.img_title}`)"
                contain
              ></v-img>
            </div>
            <!-- <div class="subtopic-content"> -->
            <!-- left -->
            <!-- <div class="subtopic-left-right">
                <diV
                  v-for="(environment_left, i) in subTopic_environment.left"
                  :key="i"
                  class="subtopic-box"
                >
                  <v-img
                    class="subtopic-icon"
                    :src="require(`@/${environment_left.img_sub}`)"
                  ></v-img>
                  <div class="subtopic-text">
                    {{ FiltersLanguage(environment_left, "titile_sub") }}
                  </div>
                </diV>
              </div> -->
            <!-- Vertical-Line -->
            <!-- <div class="vertical-line"></div> -->
            <!-- right -->
            <!-- <div class="subtopic-left-right">
                <diV
                  v-for="(environment_right, i) in subTopic_environment.right"
                  :key="'right_item_environment' + i"
                  class="subtopic-box"
                >
                  <v-img
                    class="subtopic-icon"
                    :src="require(`@/${environment_right.img_sub}`)"
                  ></v-img>
                  <div class="subtopic-text">
                    {{ FiltersLanguage(environment_right, "titile_sub") }}
                  </div>
                </diV>
              </div> -->
            <!-- </div> -->
            <div  class="mx-n3">
              <img
                contain
                class="image_detail"
                @click="
                  setImageDialog(
                    FiltersLanguage(item_environment, 'image_environment')
                  )
                "
                :src="
                  require(`@/${FiltersLanguage(
                    item_environment,
                    'image_environment'
                  )}`)
                "
              />
            </div>
          </div>
          <hr class="hr-border" />
          <groupNews :items="itemsEnvironment" :loading="loadingEnvironment" />
        </div>
      </div>
    </div>
    <div class="bg-gradient pb-8">
      <div class="main-content">
        <div class="layout-all">
          <!-- section 4 : society -->
          <div>
            <div
              :style="{
                backgroundImage: `url(${require(`@/${item_society.img_background_title}`)})`,
              }"
              class="bg-image-title"
              @click="$router.push({ name: 'societyNews' })"
            >
              <v-img
                class="title-text-image"
                :src="require(`@/${item_society.img_title}`)"
                contain
              ></v-img>
            </div>
            <!-- <div class="subtopic-content"> -->
            <!-- left -->
            <!-- <div class="subtopic-left-right">
                <diV
                  v-for="(society_left, i) in subTopic_society.left"
                  :key="i"
                  class="subtopic-box"
                >
                  <v-img
                    class="subtopic-icon"
                    :src="require(`@/${society_left.img_sub}`)"
                  ></v-img>
                  <div class="subtopic-text">
                    {{ FiltersLanguage(society_left, "titile_sub") }}
                  </div>
                </diV>
              </div> -->
            <!-- Vertical-Line -->
            <!-- <div class="vertical-line"></div> -->
            <!-- right -->
            <!-- <div class="subtopic-left-right">
                <diV
                  v-for="(society_right, i) in subTopic_society.right"
                  :key="'right_item_environment' + i"
                  class="subtopic-box"
                >
                  <v-img
                    class="subtopic-icon"
                    :src="require(`@/${society_right.img_sub}`)"
                  ></v-img>
                  <div class="subtopic-text">
                    {{ FiltersLanguage(society_right, "titile_sub") }}
                  </div>
                </diV>
              </div> -->
            <!-- </div> -->
            <div  class="mx-n3">
              <img
                contain
                class="image_detail"
                @click="
                  setImageDialog(
                    FiltersLanguage(item_society, 'image_society')
                  )
                "
                :src="
                  require(`@/${FiltersLanguage(
                    item_society,
                    'image_society'
                  )}`)
                "
              />
            </div>
          </div>
          <hr class="hr-border" />
          <groupNews :items="itemsSociety" :loading="loadingSociety" />
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogImg" fullscreen>
      <div class="py-6 px-5 bg-dialog-structure">
        <div class="size-img-structure">
          <img
            v-if="imgDialog"
            class="img-on-dialog"
            :src="require(`@/${imgDialog}`)"
          />
          <div style="width: 100%" class="d-flex justify-center mt-n5">
            <v-btn @click="dialogImg = false" class="close-btn">
              {{ FiltersLanguage(item_economy, "btn_close") }}
              &times;</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import groupNews from '@/components/groupNews.vue'
export default {
  components: {
    groupNews
  },
  data () {
    return {
      imgDialog: '',
      dialogImg: false,
      subTopic_economy: {
        left: [],
        right: []
      },
      subTopic_environment: {
        left: [],
        right: []
      },
      subTopic_society: {
        left: [],
        right: []
      },
      loading: false,
      keyword: '',
      pagination: {
        total: 0,
        page: 1,
        row: 99999999
      },
      itemsEconomy: [],
      itemsEnvironment: [],
      itemsSociety: [],
      loadingEconomy: false,
      loadingEnvironment: false,
      loadingSociety: false
    }
  },
  computed: {
    ...mapGetters([
      'item_esg_page',
      'item_economy',
      'item_environment',
      'item_society'
    ])
  },
  created () {
    this.div_subTopic_economy()
    this.div_subTopic_environment()
    this.div_subTopic_society()
    this.initNewsEconomy()
    this.initNewsEnvironment()
    this.initNewsSociety()
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  methods: {
    setImageDialog (path) {
      this.imgDialog = path
      this.dialogImg = true
    },
    async div_subTopic_economy () {
      let index1 = 0
      const divdeLength = this.item_economy.sub_economy.length / 2

      if (this.item_economy.sub_economy.length % 2 === 0) {
        // ฝั่งซ้าย index ที่ 0 ถึง [(length/2)]
        for (let index = 0; index < divdeLength; index++) {
          this.subTopic_economy.left[index] =
            this.item_economy.sub_economy[index]
        }
        // ฝั่งขวา index ที่ [(length/2)] ถึง length
        for (
          let index = divdeLength;
          index < this.item_economy.sub_economy.length;
          index++
        ) {
          this.right_item_economy[index1] =
            this.item_economy.sub_economy[index]
          index1++
        }
      } else {
        // ฝั่งซ้าย index ที่ 0 ถึง [(length/2)+0.5 ]
        for (let index = 0; index < divdeLength + 0.5; index++) {
          this.subTopic_economy.left[index] =
            this.item_economy.sub_economy[index]
        }
        // ฝั่งขวา index ที่  [(length/2)+0.5 ] ถึง length
        for (
          let index = divdeLength + 0.5;
          index < this.item_economy.sub_economy.length;
          index++
        ) {
          this.subTopic_economy.right[index1] =
            this.item_economy.sub_economy[index]
          index1++
        }
      }
    },
    async div_subTopic_environment () {
      const molData = this.item_environment.sub_environment.length % 2
      const lengthOfArray = this.item_environment.sub_environment.length
      let index1 = 0

      if (molData === 0) {
        // ฝั่งซ้าย index ที่ 0 ถึง [(length/2)]
        for (let index = 0; index < lengthOfArray / 2; index++) {
          this.subTopic_environment.left[index] =
            this.item_environment.sub_environment[index]
        }
        // ฝั่งขวา index ที่ [(length/2)] ถึง length
        for (let index = lengthOfArray / 2; index < lengthOfArray; index++) {
          this.subTopic_environment.right[index1] =
            this.item_environment.sub_environment[index]
          index1++
        }
      } else {
        // ฝั่งซ้าย index ที่ 0 ถึง [(length/2)+0.5 ]
        for (let index = 0; index < lengthOfArray / 2 + 0.5; index++) {
          this.subTopic_environment.left[index] =
            this.item_environment.sub_environment[index]
        }
        // ฝั่งขวา index ที่  [(length/2)+0.5 ] ถึง length
        for (
          let index = lengthOfArray / 2 + 0.5;
          index < lengthOfArray;
          index++
        ) {
          this.subTopic_environment.right[index1] =
            this.item_environment.sub_environment[index]
          index1++
        }
      }
    },
    async div_subTopic_society () {
      const molData = this.item_society.sub_society.length % 2
      const lengthOfArray = this.item_society.sub_society.length
      let index1 = 0

      if (molData === 0) {
        // ฝั่งซ้าย index ที่ 0 ถึง [(length/2)]
        for (let index = 0; index < lengthOfArray / 2; index++) {
          this.subTopic_society.left[index] =
            this.item_society.sub_society[index]
        }
        // ฝั่งขวา index ที่ [(length/2)] ถึง length
        for (let index = lengthOfArray / 2; index < lengthOfArray; index++) {
          this.subTopic_society.right[index1] =
            this.item_society.sub_society[index]
          index1++
        }
      } else {
        // ฝั่งซ้าย index ที่ 0 ถึง [(length/2)+0.5 ]
        for (let index = 0; index < lengthOfArray / 2 + 0.5; index++) {
          this.subTopic_society.left[index] =
            this.item_society.sub_society[index]
        }
        // ฝั่งขวา index ที่  [(length/2)+0.5 ] ถึง length
        for (
          let index = lengthOfArray / 2 + 0.5;
          index < lengthOfArray;
          index++
        ) {
          this.subTopic_society.right[index1] =
            this.item_society.sub_society[index]
          index1++
        }
      }
    },
    async initNewsEconomy () {
      let economy = []
      this.loadingEconomy = true
      economy = await this.getNewsByTags('economy')
      const itemAll = []
      const itemsNoPoverty = []
      const itemsZeroHunger = []
      const itemsGoodHealthAndWellBeing = []
      const itemsQualityEducation = []
      const itemsGenderEquality = []
      const itemsCleanWaterAndSanitation = []
      const itemsAffordableAndCleanEnergy = []
      const itemsDecentWorkAndEconomicGrowth = []
      const itemsIndustryInnovationAndInfrastructure = []
      const itemsReducedInequalities = []
      const itemsSustainableCitiesAndCommunities = []
      const itemsResponsibleConsumptionAndProduction = []
      const itemsClimateAction = []
      const itemsLifeBelowWater = []
      const itemsLifeOnland = []
      const itemsPeaceJusticeAndStrongInstitutions = []
      const itemsPartnershipsForTheGoals = []

      for (let index = 0; index < economy.length; index++) {
        const noPoverty = economy[index].tags.find(
          (item) => item === 'noPoverty'
        )
        if (noPoverty !== undefined) {
          itemsNoPoverty.push(economy[index])
        }
        const zeroHunger = economy[index].tags.find(
          (item) => item === 'zeroHunger'
        )
        if (zeroHunger !== undefined) {
          itemsZeroHunger.push(economy[index])
        }
        const goodHealthAndWellBeing = economy[index].tags.find(
          (item) => item === 'goodHealthAndWellBeing'
        )
        if (goodHealthAndWellBeing !== undefined) {
          itemsGoodHealthAndWellBeing.push(economy[index])
        }
        const qualityEducation = economy[index].tags.find(
          (item) => item === 'qualityEducation'
        )
        if (qualityEducation !== undefined) {
          itemsQualityEducation.push(economy[index])
        }
        const genderEquality = economy[index].tags.find(
          (item) => item === 'genderEquality'
        )
        if (genderEquality !== undefined) {
          itemsGenderEquality.push(economy[index])
        }
        const cleanWaterAndSanitation = economy[index].tags.find(
          (item) => item === 'cleanWaterAndSanitation'
        )
        if (cleanWaterAndSanitation !== undefined) {
          itemsCleanWaterAndSanitation.push(economy[index])
        }
        const affordableAndCleanEnergy = economy[index].tags.find(
          (item) => item === 'affordableAndCleanEnergy'
        )
        if (affordableAndCleanEnergy !== undefined) {
          itemsAffordableAndCleanEnergy.push(economy[index])
        }
        const decentWorkAndEconomicGrowth = economy[index].tags.find(
          (item) => item === 'decentWorkAndEconomicGrowth'
        )
        if (decentWorkAndEconomicGrowth !== undefined) {
          itemsDecentWorkAndEconomicGrowth.push(economy[index])
        }
        const industryInnovationAndInfrastructure = economy[index].tags.find(
          (item) => item === 'industryInnovationAndInfrastructure'
        )
        if (industryInnovationAndInfrastructure !== undefined) {
          itemsIndustryInnovationAndInfrastructure.push(economy[index])
        }
        const reducedInequalities = economy[index].tags.find(
          (item) => item === 'reducedInequalities'
        )
        if (reducedInequalities !== undefined) {
          itemsReducedInequalities.push(economy[index])
        }
        const sustainableCitiesAndCommunities = economy[index].tags.find(
          (item) => item === 'sustainableCitiesAndCommunities'
        )
        if (sustainableCitiesAndCommunities !== undefined) {
          itemsSustainableCitiesAndCommunities.push(economy[index])
        }
        const responsibleConsumptionAndProduction = economy[index].tags.find(
          (item) => item === 'responsibleConsumptionAndProduction'
        )
        if (responsibleConsumptionAndProduction !== undefined) {
          itemsResponsibleConsumptionAndProduction.push(economy[index])
        }
        const climateAction = economy[index].tags.find(
          (item) => item === 'climateAction'
        )
        if (climateAction !== undefined) {
          itemsClimateAction.push(economy[index])
        }
        const lifeBelowWater = economy[index].tags.find(
          (item) => item === 'lifeBelowWater'
        )
        if (lifeBelowWater !== undefined) {
          itemsLifeBelowWater.push(economy[index])
        }
        const lifeOnland = economy[index].tags.find(
          (item) => item === 'lifeOnland'
        )
        if (lifeOnland !== undefined) {
          itemsLifeOnland.push(economy[index])
        }
        const peaceJusticeAndStrongInstitutions = economy[index].tags.find(
          (item) => item === 'peaceJusticeAndStrongInstitutions'
        )
        if (peaceJusticeAndStrongInstitutions !== undefined) {
          itemsPeaceJusticeAndStrongInstitutions.push(economy[index])
        }
        const partnershipsForTheGoals = economy[index].tags.find(
          (item) => item === 'partnershipsForTheGoals'
        )
        if (partnershipsForTheGoals !== undefined) {
          itemsPartnershipsForTheGoals.push(economy[index])
        }
      }

      if (itemsNoPoverty.length !== 0) {
        itemAll.push({
          tag: 'noPoverty',
          image: 'assets/images/ESGstampNews/noPoverty.png',
          data: itemsNoPoverty
        })
      }
      if (itemsZeroHunger.length !== 0) {
        itemAll.push({
          tag: 'zeroHunger',
          image: 'assets/images/ESGstampNews/zeroHunger.png',
          data: itemsZeroHunger
        })
      }
      if (itemsGoodHealthAndWellBeing.length !== 0) {
        itemAll.push({
          tag: 'goodHealthAndWellBeing',
          image: 'assets/images/ESGstampNews/goodHealthAndWellBeing.png',
          data: itemsGoodHealthAndWellBeing
        })
      }

      if (itemsQualityEducation.length !== 0) {
        itemAll.push({
          tag: 'qualityEducation',
          image: 'assets/images/ESGstampNews/qualityEducation.png',
          data: itemsQualityEducation
        })
      }

      if (itemsGenderEquality.length !== 0) {
        itemAll.push({
          tag: 'genderEquality',
          image: 'assets/images/ESGstampNews/genderEquality.png',
          data: itemsGenderEquality
        })
      }

      if (itemsCleanWaterAndSanitation.length !== 0) {
        itemAll.push({
          tag: 'cleanWaterAndSanitation',
          image: 'assets/images/ESGstampNews/cleanWaterAndSanitation.png',
          data: itemsCleanWaterAndSanitation
        })
      }

      if (itemsAffordableAndCleanEnergy.length !== 0) {
        itemAll.push({
          tag: 'affordableAndCleanEnergy',
          image: 'assets/images/ESGstampNews/affordableAndCleanEnergy.png',
          data: itemsAffordableAndCleanEnergy
        })
      }

      if (itemsDecentWorkAndEconomicGrowth.length !== 0) {
        itemAll.push({
          tag: 'decentWorkAndEconomicGrowth',
          image: 'assets/images/ESGstampNews/decentWorkAndEconomicGrowth.png',
          data: itemsDecentWorkAndEconomicGrowth
        })
      }

      if (itemsIndustryInnovationAndInfrastructure.length !== 0) {
        itemAll.push({
          tag: 'industryInnovationAndInfrastructure',
          image:
            'assets/images/ESGstampNews/industryInnovationAndInfrastructure.png',
          data: itemsIndustryInnovationAndInfrastructure
        })
      }

      if (itemsReducedInequalities.length !== 0) {
        itemAll.push({
          tag: 'reducedInequalities',
          image: 'assets/images/ESGstampNews/reducedInequalities.png',
          data: itemsReducedInequalities
        })
      }

      if (itemsSustainableCitiesAndCommunities.length !== 0) {
        itemAll.push({
          tag: 'sustainableCitiesAndCommunities',
          image:
            'assets/images/ESGstampNews/sustainableCitiesAndCommunities.png',
          data: itemsSustainableCitiesAndCommunities
        })
      }

      if (itemsResponsibleConsumptionAndProduction.length !== 0) {
        itemAll.push({
          tag: 'responsibleConsumptionAndProduction',
          image:
            'assets/images/ESGstampNews/responsibleConsumptionAndProduction.png',
          data: itemsResponsibleConsumptionAndProduction
        })
      }

      if (itemsClimateAction.length !== 0) {
        itemAll.push({
          tag: 'climateAction',
          image: 'assets/images/ESGstampNews/climateAction.png',
          data: itemsClimateAction
        })
      }

      if (itemsLifeBelowWater.length !== 0) {
        itemAll.push({
          tag: 'lifeBelowWater',
          image: 'assets/images/ESGstampNews/lifeBelowWater.png',
          data: itemsLifeBelowWater
        })
      }

      if (itemsLifeOnland.length !== 0) {
        itemAll.push({
          tag: 'lifeOnland',
          image: 'assets/images/ESGstampNews/lifeOnland.png',
          data: itemsLifeOnland
        })
      }

      if (itemsPeaceJusticeAndStrongInstitutions.length !== 0) {
        itemAll.push({
          tag: 'peaceJusticeAndStrongInstitutions',
          image:
            'assets/images/ESGstampNews/peaceJusticeAndStrongInstitutions.png',
          data: itemsPeaceJusticeAndStrongInstitutions
        })
      }

      if (itemsPartnershipsForTheGoals.length !== 0) {
        itemAll.push({
          tag: 'partnershipsForTheGoals',
          image: 'assets/images/ESGstampNews/partnershipsForTheGoals.png',
          data: itemsPartnershipsForTheGoals
        })
      }
      this.itemsEconomy = itemAll
      this.loadingEconomy = false
    },
    async initNewsEnvironment () {
      let environment = []
      this.loadingEnvironment = true
      environment = await this.getNewsByTags('environment')

      const itemAll = []
      const itemsNoPoverty = []
      const itemsZeroHunger = []
      const itemsGoodHealthAndWellBeing = []
      const itemsQualityEducation = []
      const itemsGenderEquality = []
      const itemsCleanWaterAndSanitation = []
      const itemsAffordableAndCleanEnergy = []
      const itemsDecentWorkAndEconomicGrowth = []
      const itemsIndustryInnovationAndInfrastructure = []
      const itemsReducedInequalities = []
      const itemsSustainableCitiesAndCommunities = []
      const itemsResponsibleConsumptionAndProduction = []
      const itemsClimateAction = []
      const itemsLifeBelowWater = []
      const itemsLifeOnland = []
      const itemsPeaceJusticeAndStrongInstitutions = []
      const itemsPartnershipsForTheGoals = []

      for (let index = 0; index < environment.length; index++) {
        const noPoverty = environment[index].tags.find(
          (item) => item === 'noPoverty'
        )
        if (noPoverty !== undefined) {
          itemsNoPoverty.push(environment[index])
        }
        const zeroHunger = environment[index].tags.find(
          (item) => item === 'zeroHunger'
        )
        if (zeroHunger !== undefined) {
          itemsZeroHunger.push(environment[index])
        }
        const goodHealthAndWellBeing = environment[index].tags.find(
          (item) => item === 'goodHealthAndWellBeing'
        )
        if (goodHealthAndWellBeing !== undefined) {
          itemsGoodHealthAndWellBeing.push(environment[index])
        }
        const qualityEducation = environment[index].tags.find(
          (item) => item === 'qualityEducation'
        )
        if (qualityEducation !== undefined) {
          itemsQualityEducation.push(environment[index])
        }
        const genderEquality = environment[index].tags.find(
          (item) => item === 'genderEquality'
        )
        if (genderEquality !== undefined) {
          itemsGenderEquality.push(environment[index])
        }
        const cleanWaterAndSanitation = environment[index].tags.find(
          (item) => item === 'cleanWaterAndSanitation'
        )
        if (cleanWaterAndSanitation !== undefined) {
          itemsCleanWaterAndSanitation.push(environment[index])
        }
        const affordableAndCleanEnergy = environment[index].tags.find(
          (item) => item === 'affordableAndCleanEnergy'
        )
        if (affordableAndCleanEnergy !== undefined) {
          itemsAffordableAndCleanEnergy.push(environment[index])
        }
        const decentWorkAndEconomicGrowth = environment[index].tags.find(
          (item) => item === 'decentWorkAndEconomicGrowth'
        )
        if (decentWorkAndEconomicGrowth !== undefined) {
          itemsDecentWorkAndEconomicGrowth.push(environment[index])
        }
        const industryInnovationAndInfrastructure = environment[
          index
        ].tags.find((item) => item === 'industryInnovationAndInfrastructure')
        if (industryInnovationAndInfrastructure !== undefined) {
          itemsIndustryInnovationAndInfrastructure.push(environment[index])
        }
        const reducedInequalities = environment[index].tags.find(
          (item) => item === 'reducedInequalities'
        )
        if (reducedInequalities !== undefined) {
          itemsReducedInequalities.push(environment[index])
        }
        const sustainableCitiesAndCommunities = environment[index].tags.find(
          (item) => item === 'sustainableCitiesAndCommunities'
        )
        if (sustainableCitiesAndCommunities !== undefined) {
          itemsSustainableCitiesAndCommunities.push(environment[index])
        }
        const responsibleConsumptionAndProduction = environment[
          index
        ].tags.find((item) => item === 'responsibleConsumptionAndProduction')
        if (responsibleConsumptionAndProduction !== undefined) {
          itemsResponsibleConsumptionAndProduction.push(environment[index])
        }
        const climateAction = environment[index].tags.find(
          (item) => item === 'climateAction'
        )
        if (climateAction !== undefined) {
          itemsClimateAction.push(environment[index])
        }
        const lifeBelowWater = environment[index].tags.find(
          (item) => item === 'lifeBelowWater'
        )
        if (lifeBelowWater !== undefined) {
          itemsLifeBelowWater.push(environment[index])
        }
        const lifeOnland = environment[index].tags.find(
          (item) => item === 'lifeOnland'
        )
        if (lifeOnland !== undefined) {
          itemsLifeOnland.push(environment[index])
        }
        const peaceJusticeAndStrongInstitutions = environment[index].tags.find(
          (item) => item === 'peaceJusticeAndStrongInstitutions'
        )
        if (peaceJusticeAndStrongInstitutions !== undefined) {
          itemsPeaceJusticeAndStrongInstitutions.push(environment[index])
        }
        const partnershipsForTheGoals = environment[index].tags.find(
          (item) => item === 'partnershipsForTheGoals'
        )
        if (partnershipsForTheGoals !== undefined) {
          itemsPartnershipsForTheGoals.push(environment[index])
        }
      }

      if (itemsNoPoverty.length !== 0) {
        itemAll.push({
          tag: 'noPoverty',
          image: 'assets/images/ESGstampNews/noPoverty.png',
          data: itemsNoPoverty
        })
      }
      if (itemsZeroHunger.length !== 0) {
        itemAll.push({
          tag: 'zeroHunger',
          image: 'assets/images/ESGstampNews/zeroHunger.png',
          data: itemsZeroHunger
        })
      }
      if (itemsGoodHealthAndWellBeing.length !== 0) {
        itemAll.push({
          tag: 'goodHealthAndWellBeing',
          image: 'assets/images/ESGstampNews/goodHealthAndWellBeing.png',
          data: itemsGoodHealthAndWellBeing
        })
      }

      if (itemsQualityEducation.length !== 0) {
        itemAll.push({
          tag: 'qualityEducation',
          image: 'assets/images/ESGstampNews/qualityEducation.png',
          data: itemsQualityEducation
        })
      }

      if (itemsGenderEquality.length !== 0) {
        itemAll.push({
          tag: 'genderEquality',
          image: 'assets/images/ESGstampNews/genderEquality.png',
          data: itemsGenderEquality
        })
      }

      if (itemsCleanWaterAndSanitation.length !== 0) {
        itemAll.push({
          tag: 'cleanWaterAndSanitation',
          image: 'assets/images/ESGstampNews/cleanWaterAndSanitation.png',
          data: itemsCleanWaterAndSanitation
        })
      }

      if (itemsAffordableAndCleanEnergy.length !== 0) {
        itemAll.push({
          tag: 'affordableAndCleanEnergy',
          image: 'assets/images/ESGstampNews/affordableAndCleanEnergy.png',
          data: itemsAffordableAndCleanEnergy
        })
      }

      if (itemsDecentWorkAndEconomicGrowth.length !== 0) {
        itemAll.push({
          tag: 'decentWorkAndEconomicGrowth',
          image: 'assets/images/ESGstampNews/decentWorkAndEconomicGrowth.png',
          data: itemsDecentWorkAndEconomicGrowth
        })
      }

      if (itemsIndustryInnovationAndInfrastructure.length !== 0) {
        itemAll.push({
          tag: 'industryInnovationAndInfrastructure',
          image:
            'assets/images/ESGstampNews/industryInnovationAndInfrastructure.png',
          data: itemsIndustryInnovationAndInfrastructure
        })
      }

      if (itemsReducedInequalities.length !== 0) {
        itemAll.push({
          tag: 'reducedInequalities',
          image: 'assets/images/ESGstampNews/reducedInequalities.png',
          data: itemsReducedInequalities
        })
      }

      if (itemsSustainableCitiesAndCommunities.length !== 0) {
        itemAll.push({
          tag: 'sustainableCitiesAndCommunities',
          image:
            'assets/images/ESGstampNews/sustainableCitiesAndCommunities.png',
          data: itemsSustainableCitiesAndCommunities
        })
      }

      if (itemsResponsibleConsumptionAndProduction.length !== 0) {
        itemAll.push({
          tag: 'responsibleConsumptionAndProduction',
          image:
            'assets/images/ESGstampNews/responsibleConsumptionAndProduction.png',
          data: itemsResponsibleConsumptionAndProduction
        })
      }

      if (itemsClimateAction.length !== 0) {
        itemAll.push({
          tag: 'climateAction',
          image: 'assets/images/ESGstampNews/climateAction.png',
          data: itemsClimateAction
        })
      }

      if (itemsLifeBelowWater.length !== 0) {
        itemAll.push({
          tag: 'lifeBelowWater',
          image: 'assets/images/ESGstampNews/lifeBelowWater.png',
          data: itemsLifeBelowWater
        })
      }

      if (itemsLifeOnland.length !== 0) {
        itemAll.push({
          tag: 'lifeOnland',
          image: 'assets/images/ESGstampNews/lifeOnland.png',
          data: itemsLifeOnland
        })
      }

      if (itemsPeaceJusticeAndStrongInstitutions.length !== 0) {
        itemAll.push({
          tag: 'peaceJusticeAndStrongInstitutions',
          image:
            'assets/images/ESGstampNews/peaceJusticeAndStrongInstitutions.png',
          data: itemsPeaceJusticeAndStrongInstitutions
        })
      }

      if (itemsPartnershipsForTheGoals.length !== 0) {
        itemAll.push({
          tag: 'partnershipsForTheGoals',
          image: 'assets/images/ESGstampNews/partnershipsForTheGoals.png',
          data: itemsPartnershipsForTheGoals
        })
      }
      this.itemsEnvironment = itemAll
      this.loadingEnvironment = false
    },
    async initNewsSociety () {
      let society = []
      this.loadingSociety = true
      society = await this.getNewsByTags('society')

      const itemAll = []
      const itemsNoPoverty = []
      const itemsZeroHunger = []
      const itemsGoodHealthAndWellBeing = []
      const itemsQualityEducation = []
      const itemsGenderEquality = []
      const itemsCleanWaterAndSanitation = []
      const itemsAffordableAndCleanEnergy = []
      const itemsDecentWorkAndEconomicGrowth = []
      const itemsIndustryInnovationAndInfrastructure = []
      const itemsReducedInequalities = []
      const itemsSustainableCitiesAndCommunities = []
      const itemsResponsibleConsumptionAndProduction = []
      const itemsClimateAction = []
      const itemsLifeBelowWater = []
      const itemsLifeOnland = []
      const itemsPeaceJusticeAndStrongInstitutions = []
      const itemsPartnershipsForTheGoals = []

      for (let index = 0; index < society.length; index++) {
        const noPoverty = society[index].tags.find(
          (item) => item === 'noPoverty'
        )
        if (noPoverty !== undefined) {
          itemsNoPoverty.push(society[index])
        }
        const zeroHunger = society[index].tags.find(
          (item) => item === 'zeroHunger'
        )
        if (zeroHunger !== undefined) {
          itemsZeroHunger.push(society[index])
        }
        const goodHealthAndWellBeing = society[index].tags.find(
          (item) => item === 'goodHealthAndWellBeing'
        )
        if (goodHealthAndWellBeing !== undefined) {
          itemsGoodHealthAndWellBeing.push(society[index])
        }
        const qualityEducation = society[index].tags.find(
          (item) => item === 'qualityEducation'
        )
        if (qualityEducation !== undefined) {
          itemsQualityEducation.push(society[index])
        }
        const genderEquality = society[index].tags.find(
          (item) => item === 'genderEquality'
        )
        if (genderEquality !== undefined) {
          itemsGenderEquality.push(society[index])
        }
        const cleanWaterAndSanitation = society[index].tags.find(
          (item) => item === 'cleanWaterAndSanitation'
        )
        if (cleanWaterAndSanitation !== undefined) {
          itemsCleanWaterAndSanitation.push(society[index])
        }
        const affordableAndCleanEnergy = society[index].tags.find(
          (item) => item === 'affordableAndCleanEnergy'
        )
        if (affordableAndCleanEnergy !== undefined) {
          itemsAffordableAndCleanEnergy.push(society[index])
        }
        const decentWorkAndEconomicGrowth = society[index].tags.find(
          (item) => item === 'decentWorkAndEconomicGrowth'
        )
        if (decentWorkAndEconomicGrowth !== undefined) {
          itemsDecentWorkAndEconomicGrowth.push(society[index])
        }
        const industryInnovationAndInfrastructure = society[index].tags.find(
          (item) => item === 'industryInnovationAndInfrastructure'
        )
        if (industryInnovationAndInfrastructure !== undefined) {
          itemsIndustryInnovationAndInfrastructure.push(society[index])
        }
        const reducedInequalities = society[index].tags.find(
          (item) => item === 'reducedInequalities'
        )
        if (reducedInequalities !== undefined) {
          itemsReducedInequalities.push(society[index])
        }
        const sustainableCitiesAndCommunities = society[index].tags.find(
          (item) => item === 'sustainableCitiesAndCommunities'
        )
        if (sustainableCitiesAndCommunities !== undefined) {
          itemsSustainableCitiesAndCommunities.push(society[index])
        }
        const responsibleConsumptionAndProduction = society[index].tags.find(
          (item) => item === 'responsibleConsumptionAndProduction'
        )
        if (responsibleConsumptionAndProduction !== undefined) {
          itemsResponsibleConsumptionAndProduction.push(society[index])
        }
        const climateAction = society[index].tags.find(
          (item) => item === 'climateAction'
        )
        if (climateAction !== undefined) {
          itemsClimateAction.push(society[index])
        }
        const lifeBelowWater = society[index].tags.find(
          (item) => item === 'lifeBelowWater'
        )
        if (lifeBelowWater !== undefined) {
          itemsLifeBelowWater.push(society[index])
        }
        const lifeOnland = society[index].tags.find(
          (item) => item === 'lifeOnland'
        )
        if (lifeOnland !== undefined) {
          itemsLifeOnland.push(society[index])
        }
        const peaceJusticeAndStrongInstitutions = society[index].tags.find(
          (item) => item === 'peaceJusticeAndStrongInstitutions'
        )
        if (peaceJusticeAndStrongInstitutions !== undefined) {
          itemsPeaceJusticeAndStrongInstitutions.push(society[index])
        }
        const partnershipsForTheGoals = society[index].tags.find(
          (item) => item === 'partnershipsForTheGoals'
        )
        if (partnershipsForTheGoals !== undefined) {
          itemsPartnershipsForTheGoals.push(society[index])
        }
      }

      if (itemsNoPoverty.length !== 0) {
        itemAll.push({
          tag: 'noPoverty',
          image: 'assets/images/ESGstampNews/noPoverty.png',
          data: itemsNoPoverty
        })
      }
      if (itemsZeroHunger.length !== 0) {
        itemAll.push({
          tag: 'zeroHunger',
          image: 'assets/images/ESGstampNews/zeroHunger.png',
          data: itemsZeroHunger
        })
      }
      if (itemsGoodHealthAndWellBeing.length !== 0) {
        itemAll.push({
          tag: 'goodHealthAndWellBeing',
          image: 'assets/images/ESGstampNews/goodHealthAndWellBeing.png',
          data: itemsGoodHealthAndWellBeing
        })
      }

      if (itemsQualityEducation.length !== 0) {
        itemAll.push({
          tag: 'qualityEducation',
          image: 'assets/images/ESGstampNews/qualityEducation.png',
          data: itemsQualityEducation
        })
      }

      if (itemsGenderEquality.length !== 0) {
        itemAll.push({
          tag: 'genderEquality',
          image: 'assets/images/ESGstampNews/genderEquality.png',
          data: itemsGenderEquality
        })
      }

      if (itemsCleanWaterAndSanitation.length !== 0) {
        itemAll.push({
          tag: 'cleanWaterAndSanitation',
          image: 'assets/images/ESGstampNews/cleanWaterAndSanitation.png',
          data: itemsCleanWaterAndSanitation
        })
      }

      if (itemsAffordableAndCleanEnergy.length !== 0) {
        itemAll.push({
          tag: 'affordableAndCleanEnergy',
          image: 'assets/images/ESGstampNews/affordableAndCleanEnergy.png',
          data: itemsAffordableAndCleanEnergy
        })
      }

      if (itemsDecentWorkAndEconomicGrowth.length !== 0) {
        itemAll.push({
          tag: 'decentWorkAndEconomicGrowth',
          image: 'assets/images/ESGstampNews/decentWorkAndEconomicGrowth.png',
          data: itemsDecentWorkAndEconomicGrowth
        })
      }

      if (itemsIndustryInnovationAndInfrastructure.length !== 0) {
        itemAll.push({
          tag: 'industryInnovationAndInfrastructure',
          image:
            'assets/images/ESGstampNews/industryInnovationAndInfrastructure.png',
          data: itemsIndustryInnovationAndInfrastructure
        })
      }

      if (itemsReducedInequalities.length !== 0) {
        itemAll.push({
          tag: 'reducedInequalities',
          image: 'assets/images/ESGstampNews/reducedInequalities.png',
          data: itemsReducedInequalities
        })
      }

      if (itemsSustainableCitiesAndCommunities.length !== 0) {
        itemAll.push({
          tag: 'sustainableCitiesAndCommunities',
          image:
            'assets/images/ESGstampNews/sustainableCitiesAndCommunities.png',
          data: itemsSustainableCitiesAndCommunities
        })
      }

      if (itemsResponsibleConsumptionAndProduction.length !== 0) {
        itemAll.push({
          tag: 'responsibleConsumptionAndProduction',
          image:
            'assets/images/ESGstampNews/responsibleConsumptionAndProduction.png',
          data: itemsResponsibleConsumptionAndProduction
        })
      }

      if (itemsClimateAction.length !== 0) {
        itemAll.push({
          tag: 'climateAction',
          image: 'assets/images/ESGstampNews/climateAction.png',
          data: itemsClimateAction
        })
      }

      if (itemsLifeBelowWater.length !== 0) {
        itemAll.push({
          tag: 'lifeBelowWater',
          image: 'assets/images/ESGstampNews/lifeBelowWater.png',
          data: itemsLifeBelowWater
        })
      }

      if (itemsLifeOnland.length !== 0) {
        itemAll.push({
          tag: 'lifeOnland',
          image: 'assets/images/ESGstampNews/lifeOnland.png',
          data: itemsLifeOnland
        })
      }

      if (itemsPeaceJusticeAndStrongInstitutions.length !== 0) {
        itemAll.push({
          tag: 'peaceJusticeAndStrongInstitutions',
          image:
            'assets/images/ESGstampNews/peaceJusticeAndStrongInstitutions.png',
          data: itemsPeaceJusticeAndStrongInstitutions
        })
      }

      if (itemsPartnershipsForTheGoals.length !== 0) {
        itemAll.push({
          tag: 'partnershipsForTheGoals',
          image: 'assets/images/ESGstampNews/partnershipsForTheGoals.png',
          data: itemsPartnershipsForTheGoals
        })
      }
      this.itemsSociety = itemAll
      this.loadingSociety = false
    },
    // get new
    async getNewsByTags (tag) {
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลข่าวที่จะเอามาแสดง
      const payload = {
        keyword: this.keyword,
        page: this.pagination.page,
        row: this.pagination.row,
        tags: tag,
        status: true
      }
      const res = await this.getNews(payload)
      if (res.status) {
        return res.data.data
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
.bg-dialog-structure {
  background-color: rgba(0, 0, 0, 0.68);
  height: 100%;
}
.size-img-structure {
  height: 98%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.img-on-dialog {
  max-height: 98%;
  width: 100%;
  object-fit: contain;
}
.close-btn {
  cursor: pointer;
  width: 148px;
  height: 38;
  border-radius: 51px;
  background-color: var(--v-btnClose-base) !important;
  color: var(--v-bgPrimary-base) !important;
  font-family: Noto Sans Thai;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.bg-gradient {
  background: linear-gradient(
    0deg,
    rgba(237, 237, 237, 0.92) 0%,
    rgba(240, 240, 240, 0.4) 8.33%,
    rgba(245, 245, 245, 0) 19.27%
  );
  border-bottom: 1px solid;
}
/* section 1 */
.layout-all {
  margin-right: 20px;
  margin-left: 20px;
}
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
}
.title-icon {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
.hr-border {
  color: var(--v-lineESG-base);
  margin-top: 20px;
  margin-bottom: 40px;
}
.esg-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 95px;
}

.content-left-left {
  padding-right: 30px;
  width: 51%;
  justify-content: center;
}
.content-left-right {
  justify-content: center;
  width: 49%;
  padding-left: 30px;
}

.text-description-box {
  color: var(--v-textPrimary-base) !important;
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
}
.gray-box {
  text-align: center;
  background-color: var(--v-bgTitleTable-base) !important;
  border-radius: 51px;
  margin-bottom: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 28px;
  font-weight: 600;
  color: var(--v-textSecond-base) !important;
}
/* ---------------------- economy , environment , society---------------------- */

.title-text-image {
  width: 334px;
  height: 200px;
}
.bg-image-title {
  cursor: pointer;
  margin-top: 50px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 250px;
  background-size: cover;
}
.subtopic-left-right {
  width: 50%;
}
.subtopic-content {
  display: flex;
}
.subtopic-box {
  display: flex;
  align-items: center;
  margin-top: 10px;

  margin-right: 20px;
  margin-left: 20px;
}
.subtopic-icon {
  width: 100%;
  max-width: 100px;
  height: 100px;
  margin-right: 42px;
}
.subtopic-text {
  font-size: 20px;
  font-weight: 300;
  line-height: 150%;
}
.vertical-line {
  border-right: 1px solid var(--v-lineESG-base) !important;
  margin: 10px;
  margin-bottom: 0px;
}
.image_detail{
  display: flex;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .esg-content {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 800px) {
  /* section 1 */
  .esg-content {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .content-left-left {
    width: 100%;
    padding-right: 0px;
    display: flex;
    justify-content: center;
  }
  .img-esg {
    width: 100%;
    max-width: 500px;
  }
  .content-left-right {
    width: 100%;
    padding-left: 0px;
  }
  .text-description-box {
    margin-top: 30px;
  }
  .vertical-line {
    display: none;
  }
  /* economy , environment , society */
  .subtopic-content {
    flex-wrap: wrap;
  }
  .subtopic-left-right {
    width: 100%;
  }

  .subtopic-box {
    margin-top: 20px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .subtopic-icon {
    margin-right: 22px;
  }
  @media screen and (max-width: 700px) {
  .image_economy {
    height: auto;
    width: 100%;
  }
}
  @media screen and (max-width: 688px) {
    .gray-box {
      font-size: 26px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
</style>

<template>
  <v-navigation-drawer v-model="drawer" clipped app bottom>
    <v-list class="navigation">
      <router-link
        v-for="item in menus"
        :key="item.text"
        custom
        :to="{ name: item.name }"
        v-slot="{ isActive, isExactActive }"
        :id="`menu-name-${item.text}`"
      >
        <!-- เมื่อไม่มี Sub items -->
        <v-list-item
          v-if="item.items.length == 0"
          :to="{ name: item.name }"
          :class="[
            isActive && 'active',
            isExactActive && 'router-link-exact-active',
          ]"
          active-class="active-item"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="menu-text">{{
            item.text
          }}</v-list-item-title>
        </v-list-item>

        <!-- เมื่อมี sub items -->
        <v-list-group
          v-else
          :value="false"
          no-action
          :prepend-icon="item.icon"
          :class="[
            isActive && 'active-no-action',
            isExactActive && 'router-link-exact-active-no-action',
          ]"
        >
          <template v-slot:activator>
            <v-list-item-content
              :class="[
                isActive && 'active-no-action',
                isExactActive && 'router-link-exact-active-no-action',
              ]"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <router-link
            v-for="item in item.items"
            :key="item.text"
            custom
            :to="{ name: item.name }"
            v-slot="{ isActive, isExactActive }"
            :id="`menu-name-${item.text}`"
          >
            <v-list-item
              style="padding-left: 42px"
              :to="{ name: item.name }"
              :class="[
                isActive && 'active',
                isExactActive && 'router-link-exact-active',
              ]"
              active-class="active-item"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-group>
      </router-link>
    </v-list>

    <template v-slot:append>
      <div class="version-text">
        <span>Copyright &#174;&nbsp; </span>
        {{ new Date().getFullYear() }}
        <span>&nbsp;ManageAI</span><span>&nbsp;{{ version }}</span>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    menus: {
      type: Array,
      default: () => []
    },
    version: {
      type: String,
      default: process.env.VUE_APP_VERSION
    }
  },
  computed: {
    drawer: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  methods: {}
}
</script>

<style lang="css" scoped>
>>> .active-item {
  border-right-style: solid;
  border-right-color: var(--v-primaryIconAdmin-base);
  background: var(--v-bgActiveAdmin-base);
}
>>> .v-list-item--active .menu-text {
  color: var(--v-textPrimaryAdmin-base) !important;
}
>>> .v-list-item--active .v-icon{
  color: var(--v-primaryIconAdmin-base) !important;
}
>>> .theme--light.v-icon {
  color: var(--v-primaryIconAdmin-base) !important;
}

.navigation .menu-text {
  font-size: 16px;
  font-weight: 400;
  color: var(--v-textPrimaryAdmin-base);
}
.navigation .menu-text {
  font-size: 16px;
  font-weight: 400;
  color: var(--v-textPrimaryAdmin-base);
}
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0 !important;
}
.v-list-item__title {
  color: var(--v-textPrimaryAdmin-base);
}
/* >>> .navigation .active-no-action {
    color: var(--v-primary-base) !important;
  }

  ::v-deep .active-no-action .v-list-group__header .v-icon {
    color: var(--v-primary-base) !important;
  }
  .v-list-item--active .menu-text,
  .v-list-item--active .v-icon {
    color: var(--v-primary-base) !important;
  } */

.version-text {
  display: flex;
  font-size: 11px;
  font-weight: 300;
  justify-content: center;
  align-content: center;
}
</style>

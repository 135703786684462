var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"layout-all"},[_c('div',{staticClass:"title-content"},[_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.item_shareholding_structure_page, "title"))+" "),_c('div',[_c('v-img',{staticClass:"title-icon",attrs:{"src":require(`@/${_vm.item_shareholding_structure_page.img_dot}`)}})],1)])]),_c('hr',{staticClass:"hr-border"}),_c('div',[_c('div',{staticClass:"layout-img"},[_c('div',{staticClass:"img-shareholding-structure"},[_c('v-img',{attrs:{"src":require(`@/${_vm.FiltersLanguage(
                  _vm.item_shareholding_structure_page,
                  'img_shareholder_structure'
                )}`)}})],1)])])])]),_c('div',{staticClass:"layout-section2"},[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"layout-all"},[_c('div',{staticClass:"title-table"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage( _vm.item_shareholding_structure_page, "title_table_top_10_shareholders" ))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.itemsTableTheFirstTop10ShareholdersAll, "description"))+" ")]),_c('div',{staticClass:"table-style"},[_c('tableComponent',{staticClass:"custom-bg-row",attrs:{"headers":_vm.modifiedHeaders(_vm.headerTableTop10Shareholders),"items":_vm.itemsTableTheFirstTop10Shareholders,"ColorTextHeader":"#000000","loading":_vm.loadingTableTop10,"bgHeader":"#F6F6F6","bgTable":"#F6F6F6","hideDefaultFooter":"","defaultPerpage":999},scopedSlots:_vm._u([{key:`item.rank`,fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.paginationTableTop10Shareholders.row * _vm.paginationTableTop10Shareholders.page - _vm.paginationTableTop10Shareholders.row + (_vm.itemsTableTheFirstTop10Shareholders.indexOf(item )+ 1))+" ")])]}},{key:`item.shareholders`,fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.FiltersLanguage(item, "shareholders"))+" ")])]}},{key:`item.shares`,fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.returncommaNumber (parseFloat(item.shares)))+" ")])]}},{key:`item.percent_shares`,fn:function({ item }){return [_c('div',{staticClass:"bg-shares-percentage"},[_vm._v(" "+_vm._s(_vm.returncommaNumberDecimal ( parseFloat(item.percent_shares)))+" % ")])]}}],null,true)})],1),_c('div',{staticClass:"layout-origin"},[_c('a',{class:_vm.$store.state.language === 'th'? 'set-height-btn-th title-origin': 'set-height-btn-eng title-origin',attrs:{"href":`https://${_vm.FiltersLanguage(
            _vm.item_shareholding_structure_page,
            'link_set'
          )}`,"target":"_blank"}},[_vm._v(_vm._s(_vm.FiltersLanguage(_vm.item_shareholding_structure_page, "title_origin"))+" "),_c('v-icon',{staticClass:"ml-3",attrs:{"large":""}},[_vm._v("mdi-arrow-right-thin")])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="main-content">
      <!-- title -->
      <div class="layout-all">
        <div class="title-content">
          <div class="d-flex justify-center">
            {{ FiltersLanguage(item_financial_page, "title") }}
            <div>
              <v-img
                class="title-icon"
                :src="require(`@/${item_financial_page.img_dot}`)"
              ></v-img>
            </div>
          </div>
        </div>
        <hr class="hr-border" />
        <div class="d-flex flex-wrap">
          <div class="chart-box-main chart-border-right">
            <!-- box1 -->
            <div class="chart-box">
              <div class="size-chart">
                <div class="title-chart-box">
                  <div class="title-chart title-assets">
                    {{
                      FiltersLanguage(item_financial_page, "title_chart_assets")
                    }}
                  </div>
                  <div class="val-sum-chart">
                    {{ returncommaNumber(totalAssetsNow, 0) }}
                  </div>
                </div>
                <div class="d-flex align-center">
                  <div style="width: 100%"><hr /></div>
                  <div
                    :class="
                      $store.state.language === 'th'
                        ? 'text-unit-chart'
                        : 'text-unit-chart-eng'
                    "
                  >
                    {{ FiltersLanguage(item_financial_page, "unit_chart") }}
                  </div>
                </div>
                <div class="py-8">
                  <line-chart
                    chartID="line-chart-assets"
                    :labels="FiltersLanguage(totalAssetsData, 'labels')"
                    :datasets="totalAssetsData.datasets"
                    :maintainAspectRatio="false"
                    setHeightChart="300"
                    minWidth="300"
                    :precision="0"
                    positionDatalabels="top"
                    disableLabelY
                    disableLegend
                  />
                </div>
              </div>
            </div>
            <!-- box2 -->
            <hr class="hr-chart" />
            <div class="chart-box">
              <div class="size-chart">
                <div class="title-chart-box">
                  <div class="title-chart title-revenues">
                    {{
                      FiltersLanguage(
                        item_financial_page,
                        "title_chart_revenues"
                      )
                    }}
                  </div>
                  <div class="val-sum-chart">
                    {{ returncommaNumber(totalRevenuesNow, 0) }}
                  </div>
                </div>
                <div class="d-flex align-center">
                  <div style="width: 100%"><hr /></div>
                  <div
                    :class="
                      $store.state.language === 'th'
                        ? 'text-unit-chart'
                        : 'text-unit-chart-eng'
                    "
                  >
                    {{ FiltersLanguage(item_financial_page, "unit_chart") }}
                  </div>
                </div>
                <div class="py-8">
                  <line-chart
                    chartID="line-chart-revenues"
                    :labels="FiltersLanguage(totalRevenuesData, 'labels')"
                    :datasets="totalRevenuesData.datasets"
                    :maintainAspectRatio="false"
                    setHeightChart="300"
                    minWidth="300"
                    :precision="0"
                    positionDatalabels="top"
                    disableLabelY
                    disableLegend
                  />
                </div>
              </div>
            </div>
            <hr class="hr-chart-responesive" />
          </div>
          <div class="chart-box-main">
            <!-- box1 -->
            <div class="chart-box">
              <div class="size-chart">
                <div class="title-chart-box">
                  <div class="title-chart title-liabilities">
                    {{
                      FiltersLanguage(
                        item_financial_page,
                        "title_chart_liabilities"
                      )
                    }}
                  </div>
                  <div class="val-sum-chart">
                    {{ returncommaNumber(totalLiabilitiesNow, 0) }}
                  </div>
                </div>
                <div class="d-flex align-center">
                  <div style="width: 100%"><hr /></div>
                  <div
                    :class="
                      $store.state.language === 'th'
                        ? 'text-unit-chart'
                        : 'text-unit-chart-eng'
                    "
                  >
                    {{ FiltersLanguage(item_financial_page, "unit_chart") }}
                  </div>
                </div>
                <div class="py-8">
                  <line-chart
                    chartID="line-chart-liabilities"
                    :labels="FiltersLanguage(totalLiabilitiesData, 'labels')"
                    :datasets="totalLiabilitiesData.datasets"
                    :maintainAspectRatio="false"
                    setHeightChart="300"
                    minWidth="300"
                    :precision="0"
                    positionDatalabels="top"
                    disableLabelY
                    disableLegend
                  />
                </div>
              </div>
            </div>
            <!-- box2 -->
            <hr class="hr-chart" />
            <div class="chart-box">
              <div class="size-chart">
                <div class="title-chart-box">
                  <div
                    :class="
                      $store.state.language === 'th'
                        ? 'title-chart title-net-profit'
                        : 'title-chart title-net-profit-eng'
                    "
                  >
                    {{
                      FiltersLanguage(
                        item_financial_page,
                        "title_chart_net_profit"
                      )
                    }}
                  </div>
                  <div class="val-sum-chart">
                    {{ returncommaNumber(totalNetProfitNow, 0) }}
                  </div>
                </div>
                <div class="d-flex align-center">
                  <div style="width: 100%"><hr /></div>
                  <div
                    :class="
                      $store.state.language === 'th'
                        ? 'text-unit-chart'
                        : 'text-unit-chart-eng'
                    "
                  >
                    {{ FiltersLanguage(item_financial_page, "unit_chart") }}
                  </div>
                </div>
                <div class="py-8">
                  <line-chart
                    chartID="line-chart-netProfit"
                    :labels="FiltersLanguage(totalNetProfitData, 'labels')"
                    :datasets="totalNetProfitData.datasets"
                    :maintainAspectRatio="false"
                    setHeightChart="300"
                    minWidth="300"
                    :precision="0"
                    positionDatalabels="top"
                    disableLabelY
                    disableLegend
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-center mt-5">
          <a
            :href="`https://${FiltersLanguage(
              item_financial_page,
              'link_set'
            )}`"
            target="_blank"
            :class="
              $store.state.language === 'th'
                ? 'set-height-btn-th title-origin'
                : 'set-height-btn-eng title-origin'
            "
            >{{ FiltersLanguage(item_financial_page, "title_origin") }}
            <v-icon large class="ml-3">mdi-arrow-right-thin</v-icon></a
          >
        </div>
      </div>
    </div>
    <!-- boredrline-section2 -->
    <div class="borderline-section2"></div>
    <div class="main-content">
      <div class="layout-all">
        <!-- TableFinancialHighlights -->
        <div class="title-table">
          {{ FiltersLanguage(item_financial_page, "title_table_highlights") }}
        </div>
        <div class="table-style">
          <tableComponent
            :headers="modifiedHeaders(header_table_financial_highlights)"
            :items="itemsTableFinancialHighlights"
            :loading="loadingFinancialHighlight"
            ColorTextHeader="#000000"
            bgHeader="#ffffff"
            class="custom-bg-row"
            :height="$vuetify.breakpoint.width > 599 ? null : 700"
          >
            <template v-slot:[`item.year`]="{ item }">
              <div
                :class="`colum-year first-row-left ${
                  returnIndexItem(
                    paginationTableFinancialHighlights.row,
                    paginationTableFinancialHighlights.page,
                    itemsTableFinancialHighlights,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
              {{ item[`year_${language}`] }}
              </div>
            </template>
            <template v-slot:[`item.total_assets`]="{ item }">
              <div
                :class="`${
                  returnIndexItem(
                    paginationTableFinancialHighlights.row,
                    paginationTableFinancialHighlights.page,
                    itemsTableFinancialHighlights,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
                {{ returncommaNumber(parseFloat( item.total_assets)) }}
              </div>
            </template>
            <template v-slot:[`item.total_liabilities`]="{ item }">
              <div
                :class="`${
                  returnIndexItem(
                    paginationTableFinancialHighlights.row,
                    paginationTableFinancialHighlights.page,
                    itemsTableFinancialHighlights,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
                {{ returncommaNumber(parseFloat( item.total_liabilities)) }}
              </div>
            </template>
            <template v-slot:[`item.shareholder_equity`]="{ item }">
              <div
                :class="`${
                  returnIndexItem(
                    paginationTableFinancialHighlights.row,
                    paginationTableFinancialHighlights.page,
                    itemsTableFinancialHighlights,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
                {{ returncommaNumber(parseFloat( item.shareholder_equity)) }}
              </div>
            </template>
            <template v-slot:[`item.paid_up_capital`]="{ item }">
              <div
                :class="`${
                  returnIndexItem(
                    paginationTableFinancialHighlights.row,
                    paginationTableFinancialHighlights.page,
                    itemsTableFinancialHighlights,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
                {{ returncommaNumber(parseFloat( item.paid_up_capital)) }}
              </div>
            </template>
            <template v-slot:[`item.total_revenues`]="{ item }">
              <div
                :class="`${
                  returnIndexItem(
                    paginationTableFinancialHighlights.row,
                    paginationTableFinancialHighlights.page,
                    itemsTableFinancialHighlights,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
                {{ returncommaNumber(parseFloat( item.total_revenues)) }}

              </div>
            </template>
            <template v-slot:[`item.net_profit`]="{ item }">
              <div
                :class="`${
                  returnIndexItem(
                    paginationTableFinancialHighlights.row,
                    paginationTableFinancialHighlights.page,
                    itemsTableFinancialHighlights,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
                {{ returncommaNumber(parseFloat( item.net_profit)) }}
              </div>
            </template>
            <template v-slot:[`item.eps`]="{ item }">
              <div
                :class="`first-row-right ${
                  returnIndexItem(
                    paginationTableFinancialHighlights.row,
                    paginationTableFinancialHighlights.page,
                    itemsTableFinancialHighlights,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
                {{ returncommaNumber(parseFloat( item.eps)) }}
              </div>
            </template>
          </tableComponent>
        </div>
        <div class="layout-origin">
          <a
            :href="`https://${FiltersLanguage(
              item_financial_page,
              'link_set'
            )}`"
            target="_blank"
            :class="
              $store.state.language === 'th'
                ? 'set-height-btn-th title-origin'
                : 'set-height-btn-eng title-origin'
            "
            >{{ FiltersLanguage(item_financial_page, "title_origin") }}
            <v-icon large class="ml-3">mdi-arrow-right-thin</v-icon></a
          >
        </div>
        <!-- TableFinancialRatio -->
        <div class="title-table">
          {{ FiltersLanguage(item_financial_page, "title_table_Ratio") }}
        </div>
        <div class="table-style">
          <tableComponent
            :headers="modifiedHeaders(header_table_financial_ratio)"
            :items="itemsTableFinancialRatio"
            ColorTextHeader="#000000"
            :loading="loadingTableFinancialRatio"
            bgHeader="#ffffff"
            class="custom-bg-row"
            :height="$vuetify.breakpoint.width > 599 ? null : 700"
          >
            <template v-slot:[`item.year`]="{ item }">
              <div
                :class="`colum-year first-row-left  ${
                  returnIndexItem(
                    paginationTableFinancialRatio.row,
                    paginationTableFinancialRatio.page,
                    itemsTableFinancialRatio,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
              {{ item[`year_${language}`] }}
              </div>
            </template>
            <template v-slot:[`item.roa`]="{ item }">
              <div
                :class="`${
                  returnIndexItem(
                    paginationTableFinancialRatio.row,
                    paginationTableFinancialRatio.page,
                    itemsTableFinancialRatio,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
                {{ returncommaNumber(parseFloat(item.roa)) }}
              </div>
            </template>
            <template v-slot:[`item.roe`]="{ item }">
              <div
                :class="`${
                  returnIndexItem(
                    paginationTableFinancialRatio.row,
                    paginationTableFinancialRatio.page,
                    itemsTableFinancialRatio,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
              {{ returncommaNumber(parseFloat(item.roe)) }}
              </div>
            </template>
            <template v-slot:[`item.net_profit_margin`]="{ item }">
              <div
                :class="`first-row-right ${
                  returnIndexItem(
                    paginationTableFinancialRatio.row,
                    paginationTableFinancialRatio.page,
                    itemsTableFinancialRatio,
                    item
                  ) === 1
                    ? 'first-row'
                    : ''
                }`"
              >
              {{ returncommaNumber(parseFloat(item.net_profit_margin)) }}
              </div>
            </template>
          </tableComponent>
        </div>
        <div class="layout-origin">
          <a
            :href="`https://${FiltersLanguage(
              item_financial_page,
              'link_set'
            )}`"
            target="_blank"
            :class="
              $store.state.language === 'th'
                ? 'set-height-btn-th title-origin'
                : 'set-height-btn-eng title-origin'
            "
            >{{ FiltersLanguage(item_financial_page, "title_origin") }}
            <v-icon large class="ml-3">mdi-arrow-right-thin</v-icon></a
          >
        </div>
      </div>
    </div>
    <div class="borderline-section2"></div>
    <div class="main-content">
      <div class="layout-all">
        <div class="layout-section1">
          <div class="box-1-section1">
            <v-img
              class="size-image"
              :src="require(`@/${item_financial_page.img_background}`)"
            >
              <div class="layout-select">
                <div class="title-text">
                  {{ FiltersLanguage(item_financial_page, "title_download") }}
                </div>
                <div class="select-style">
                  <v-select
                    :items="itemsYaer"
                    outlined
                    item-color="green"
                    background-color="#fff"
                    v-model="selectYear"
                    @change="intFileDownload()"
                    :disabled="loadingSelect"
                    :loading="loadingSelect"
                    color="loadingAll"
                    class="custom-select"
                  >
                    <template v-slot:selection="{ item }">
                      <span class="label-select">
                        {{ FiltersLanguage(item_financial_page, "title_year") }}
                        {{ returnFormateYear(item, language) }}</span
                      >
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="items-select">{{
                        returnFormateYear(item, language)
                      }}</span>
                    </template>
                  </v-select>
                </div>
                <div>
                  <a
                    class="btn-view-more"
                    href="https://capital.sec.or.th/webapp/corp_fin2/cgi-bin/resultFS.php?from_page=findFS&lang=T&cmb_comp_id=0652"
                    target="_blank"
                    ><span>
                      {{
                        FiltersLanguage(item_financial_page, "btn_view")
                      }}</span
                    ></a
                  >
                </div>
              </div>
            </v-img>
          </div>
          <div class="box-2-section1">
            <div
              class="box-file"
              v-for="(itemsFileDownload, i) in itemsFile"
              :key="`${i}-itemsFileDownload`"
            >
              <div class="text-file-name">
                {{ FiltersLanguage(itemsFileDownload, "title") }}
              </div>
              <div class="box-icon">
                <a
                  :href="`${env}/api/v1/file/${FiltersLanguage(
                    itemsFileDownload,
                    'file'
                  )}`"
                  target="_blank"
                >
                  <v-icon class="icon-download"> mdi-download </v-icon>
                </a>
              </div>
            </div>
          </div>
          <div
            class="box-3-section1"
            :style="`background : url(${require('@/assets/images/financialInformation/FinancialStatement.jpg')}); background-size: cover; `"
          ></div>
        </div>
      </div>
    </div>
    <div class="borderline-section3"></div>
    <div class="main-content">
      <div class="layout-all">
        <div class="layout-section1 py-5">
          <div class="box-1-section1">
            <v-img
              class="size-image"
              :src="require(`@/${item_md_and_a_page.img_background}`)"
            >
              <div class="layout-select">
                <div class="title-text-md-and-a">
                  {{ FiltersLanguage(item_md_and_a_page, "title_download") }}
                </div>
                <div class="select-style">
                  <v-select
                    :items="itemsYaerMDandA"
                    outlined
                    item-color="green"
                    background-color="#fff"
                    v-model="selectYearMDandA"
                    @change="intFileDownloadMDandA()"
                    :disabled="loadingSelectMDandA"
                    :loading="loadingSelectMDandA"
                    color="loadingAll"
                    class="custom-select"
                  >
                    <template v-slot:selection="{ item }">
                      <span class="label-select">
                        {{ FiltersLanguage(item_md_and_a_page, "title_year") }}
                        {{ returnFormateYear(item, language) }}</span
                      >
                    </template>
                    <template v-slot:item="{ item }">
                      <span class="items-select">{{
                        returnFormateYear(item, language)
                      }}</span>
                    </template>
                  </v-select>
                </div>
              </div>
            </v-img>
          </div>
          <div class="box-2-section1">
            <div
              class="box-file"
              v-for="(itemsFileDownloadMDandA, i) in itemsFileMDandA"
              :key="`${i}-itemsFileDownload`"
            >
              <div class="text-file-name">
                {{ FiltersLanguage(itemsFileDownloadMDandA, "title") }}
              </div>
              <div class="box-icon">
                <a
                  :href="`${env}/api/v1/file/${FiltersLanguage(
                    itemsFileDownloadMDandA,
                    'file'
                  )}`"
                  target="_blank"
                >
                  <v-icon class="icon-download"> mdi-download </v-icon>
                </a>
              </div>
            </div>
          </div>
          <div
            class="box-3-section1"
            :style="`background : url(${require('@/assets/images/mdAndA/MDandA.jpg')}); background-size: cover; `"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tableComponent from '../../../components/table.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    tableComponent
  },
  data () {
    return {
      // line chart
      totalAssetsNow: 0,
      totalAssetsData: {
        labels_th: [],
        labels_eng: [],
        datasets: [
          {
            label: '',
            data: [],
            lineWidth: 2,
            lineColor: '#00A959',
            labelColor: 'rgba(0,255,0,0.3)',
            labelColortext: 'black',
            bgLabelColor: 'white',
            fill: true
          }
        ]
      },
      totalLiabilitiesNow: 0,
      totalLiabilitiesData: {
        labels_th: [],
        labels_eng: [],
        datasets: [
          {
            label: '',
            data: [],
            lineWidth: 2,
            lineColor: '#00A959',
            labelColor: 'rgba(0,255,0,0.3)',
            labelColortext: 'black',
            bgLabelColor: 'white',
            fill: true
          }
        ]
      },
      totalRevenuesNow: 0,
      totalRevenuesData: {
        labels_th: [],
        labels_eng: [],
        datasets: [
          {
            label: '',
            data: [],
            lineWidth: 2,
            lineColor: '#00A959',
            labelColor: 'rgba(0,255,0,0.3)',
            labelColortext: 'black',
            bgLabelColor: 'white',
            fill: true
          }
        ]
      },
      totalNetProfitNow: 0,
      totalNetProfitData: {
        labels_th: [],
        labels_eng: [],
        datasets: [
          {
            label: '',
            data: [],
            lineWidth: 2,
            lineColor: '#00A959',
            labelColor: 'rgba(0,255,0,0.3)',
            labelColortext: 'black',
            bgLabelColor: 'white',
            fill: true
          }
        ]
      },

      minYear: 2014,
      yearNow: '',
      itemsYaer: [],
      selectYear: '',
      tag: 'financialStatement',
      itemsFile: [],
      env: '',
      header_table_financial_highlights: [],
      itemsTableFinancialHighlights: [],
      loadingTableFinancialHighlights: false,
      header_table_financial_ratio: [],
      itemsTableFinancialRatio: [],
      loadingTableFinancialRatios: false,
      paginationTableFinancialHighlights: {
        total: 0,
        page: 1,
        row: 999
      },
      paginationTableFinancialRatio: {
        total: 0,
        page: 1,
        row: 999
      },
      loadingSelect: false,
      loadingFinancialHighlight: false,
      loadingTableFinancialRatio: false,

      // MDandA
      yearNowMDandA: '',
      minYearMDandA: 2015,
      itemsYaerMDandA: [],
      selectYearMDandA: '',
      envMDandA: '',
      tagMDandA: 'MDandA',
      loadingSelectMDandA: false,
      itemsFileMDandA: []
    }
  },
  async created () {
    await this.initTableFinancialRatios()
    await this.initTableFinancialHighlights()
    // this.setDataChart()
    this.env = process.env.VUE_APP_API
    this.yearNow = this.$moment().format('YYYY')
    this.selectYear = this.$moment().format('YYYY')
    this.intFileDownload('created')
    // this.setHeadertableFinancialHighlights()
    // this.setHeaderTableFinancialRatio()
    this.$EventBus.$on('change-language', () => {
      this.intFileDownload()
      // this.setHeadertableFinancialHighlights()
      // this.setHeaderTableFinancialRatio()
      // MDandA
      this.intFileDownloadMDandA()
    })

    // MDandA
    this.envMDandA = process.env.VUE_APP_API
    this.yearNowMDandA = this.$moment().add(2, 'year').format('YYYY')
    this.selectYearMDandA = this.$moment().add(2, 'year').format('YYYY')
    this.intFileDownloadMDandA('created')
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  methods: {
    modifiedHeaders (itemHeader) {
      return itemHeader.map(header => ({
        ...header,
        text: header[`text_${this.language}`] // Change the text to text_th
      }))
    },
    setDataChart () {
      const yearTh = []
      const yearEng = []
      const itemsAssets = []
      const itemsLiabilities = []
      const itemsRevenues = []
      const itemsNetProfit = []
      for (let index = 4; index >= 0; index--) {
        const data =
          this.itemsTableFinancialHighlights[index]
        // console.log('data', data)
        yearTh.push(parseInt(data.year_th))
        yearEng.push(data.year_eng)
        itemsAssets.push(parseFloat(data.total_assets))
        itemsLiabilities.push(parseFloat(data.total_liabilities))
        itemsRevenues.push(parseFloat(data.total_revenues))
        itemsNetProfit.push(parseFloat(data.net_profit))
      }
      // totalAssets สินทรัพย์
      this.totalAssetsData.labels_th = yearTh
      this.totalAssetsData.labels_eng = yearEng
      this.totalAssetsData.datasets[0].data = itemsAssets
      this.totalAssetsNow =
        this.itemsTableFinancialHighlights[0].total_assets

      // totalRevenues รายได้
      this.totalRevenuesData.labels_th = yearTh
      this.totalRevenuesData.labels_eng = yearEng
      this.totalRevenuesData.datasets[0].data = itemsRevenues
      this.totalRevenuesNow =
        this.itemsTableFinancialHighlights[0].total_revenues

      // totalLiabilities หนี้สิน
      this.totalLiabilitiesData.labels_th = yearTh
      this.totalLiabilitiesData.labels_eng = yearEng
      this.totalLiabilitiesData.datasets[0].data = itemsLiabilities
      this.totalLiabilitiesNow =
        this.itemsTableFinancialHighlights[0].total_liabilities

      // totalNetProfit กำไร (ขาดทุน) สุทธิ
      this.totalNetProfitData.labels_th = yearTh
      this.totalNetProfitData.labels_eng = yearEng
      this.totalNetProfitData.datasets[0].data = itemsNetProfit
      this.totalNetProfitNow =
        this.itemsTableFinancialHighlights[0].net_profit
    },
    returnIndexItem (row, page, items, itemRow) {
      return row * page - row + (items.indexOf(itemRow) + 1)
    },
    itemsYearSelect (year) {
      // const minYear = 2557
      const itemsYears = []
      const maxYear = year
      // for (let minYear = this.minYear; minYear <= maxYear; minYear++) {
      //   itemsYears.push(String(minYear))
      // }
      for (let maxYears = maxYear; maxYears >= this.minYear; maxYears--) {
        itemsYears.push(String(maxYears))
      }
      this.itemsYaer = itemsYears
    },

    async intFileDownload (from = '') {
      this.loadingSelect = true
      this.itemsFile = []
      const payload = {
        page: 1,
        row: 99,
        keyword: `Financial Statement ${this.selectYear}`
        // tags: `${this.tag},${this.selectYear},${
        //   this.language === 'th' ? 'thai' : 'eng'
        // }`
      }
      const res = await this.getList(payload)
      this.loadingSelect = false
      if (res.status) {
        if (from === 'created') {
          if (
            (res.data.data.length === 0 ||
              res.data.data[0].items.length === 0) &&
            this.yearNow > this.minYear
          ) {
            this.itemsFile = []
            this.yearNow = this.yearNow - 1
            this.selectYear = String(this.yearNow)
            this.intFileDownload('created')
          } else {
            this.itemsFile = res.data.data[0].items
            this.itemsYearSelect(this.yearNow)
          }
        } else {
          if (
            res.data.data.length !== 0 &&
            res.data.data[0].items.length !== 0
          ) {
            this.itemsFile = res.data.data[0].items
          } else {
            this.itemsFile = []
          }
        }
      } else {
        this.antmessage.error(res.message)
      }
    },

    // MDandA
    itemsYearSelectMDandA (year) {
      // const minYear = 2557
      const itemsYears = []
      const maxYear = year
      for (let maxYears = maxYear; maxYears >= this.minYearMDandA; maxYears--) {
        itemsYears.push(String(maxYears))
      }
      this.itemsYaerMDandA = itemsYears
    },
    async intFileDownloadMDandA (from = '') {
      this.loadingSelectMDandA = true
      this.itemsFileMDandA = []
      const payload = {
        page: 1,
        row: 99,
        keyword: `MD&A ${this.selectYearMDandA}`
        // tags: `${this.tagMDandA},${this.selectYearMDandA},${
        //   this.language === 'th' ? 'thai' : 'eng'
        // }`
      }
      const res = await this.getList(payload)
      this.loadingSelectMDandA = false
      if (res.status) {
        if (from === 'created') {
          if (
            (res.data.data.length === 0 ||
              res.data.data[0].items.length === 0) &&
            this.yearNowMDandA > this.minYearMDandA
          ) {
            this.itemsFileMDandA = []
            this.yearNowMDandA = this.yearNowMDandA - 1
            this.selectYearMDandA = String(this.yearNowMDandA)
            this.intFileDownloadMDandA('created')
          } else {
            this.itemsFileMDandA = res.data.data[0].items
            this.itemsYearSelectMDandA(this.yearNowMDandA)
          }
        } else {
          if (
            res.data.data.length !== 0 &&
            res.data.data[0].items.length !== 0
          ) {
            this.itemsFileMDandA = res.data.data[0].items
          } else {
            this.itemsFileMDandA = []
          }
        }
      } else {
        this.antmessage.error(res.message)
      }
    },

    async initTableFinancialHighlights () {
      this.loadingTableFinancialHighlights = true
      const payload = {
        page: this.paginationTableFinancialHighlights.page,
        row: this.paginationTableFinancialHighlights.row,
        keyword: 'Financial Highlights'
      }
      this.itemsTableFinancialHighlights = []
      const res = await this.getItemsTable(payload)
      this.loadingTableFinancialHighlights = false
      if (res.status) {
        this.header_table_financial_highlights = res.data.data[0].headers
        this.itemsTableFinancialHighlights = res.data.data[0].datas
        this.paginationTableFinancialHighlights.total =
          res.data.pagination.total
        this.paginationTableFinancialHighlights.page = res.data.pagination.page
        if (this.itemsTableFinancialHighlights.length !== 0) {
          this.setDataChart()
        }
      } else {
        this.antmessage.error(res.message)
      }
    },
    async initTableFinancialRatios () {
      this.loadingTableFinancialRatios = true
      const payload = {
        page: this.paginationTableFinancialRatio.page,
        row: this.paginationTableFinancialRatio.row,
        keyword: 'Financial Ratio'
      }
      this.itemsTableFinancialRatio = []
      const res = await this.getItemsTable(payload)
      this.loadingTableFinancialRatios = false
      if (res.status) {
        this.header_table_financial_ratio = res.data.data[0].headers
        this.itemsTableFinancialRatio = res.data.data[0].datas
        this.paginationTableFinancialRatio.total = res.data.pagination.total
        this.paginationTableFinancialRatio.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    }
  },
  computed: {
    ...mapGetters(['item_financial_page', 'language', 'item_md_and_a_page'])
  }
}
</script>

<style lang="css" scoped>
.chart-box-main {
  width: 50%;
  padding: 0 20px 0 20px;
  /* background-color: red; */
}
.chart-box {
  /* background-color: blue; */
  margin-top: 50px;
}
.title-chart-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.size-chart {
  max-width: 500px;
  margin: auto;
}
.title-chart {
  color: var(--v-textPrimary-base);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}
.title-assets {
  max-width: 173px;
}
.title-liabilities {
  max-width: 134px;
}
.title-revenues {
  max-width: 135px;
}
.title-net-profit {
  max-width: 234px;
}
.title-net-profit-eng {
  max-width: 156px;
}
.val-sum-chart {
  color: var(--v-sumValChart-base);
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}
.text-unit-chart {
  color: var(--v-textPrimary-base);
  font-size: 18.52px;
  font-style: normal;
  font-weight: 300;
  min-width: 75px;
  padding-left: 15px;
}
.text-unit-chart-eng {
  color: var(--v-textPrimary-base);
  font-size: 18.52px;
  font-style: normal;
  font-weight: 300;
  min-width: 116px;
  padding-left: 15px;
}
.hr-chart {
  border: 1px solid #d0d0d0;
}
.hr-chart-responesive {
  display: none;
}
.chart-border-right {
  border-right: 1px solid #d0d0d0;
}

>>> .custom-bg-row .v-data-table .v-data-table__wrapper table tbody tr td {
  padding-left: 0;
  padding-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
>>> .custom-bg-row .v-data-table .v-data-table__wrapper table tbody tr td div {
  padding-top: 12px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
}
.first-row {
  background: linear-gradient(
    180.16deg,
    var(--v-hoverListItem3-base) 44.53%,
    var(--v-hoverListItem4-base) 100%
  );
}
.first-row-left {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.first-row-right {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
>>> .custom-select .v-input__control .v-input__slot {
  border-radius: 10px !important;
}
::v-deep .theme--light.v-list-item.v-list-item--highlighted::before {
  opacity: 0.3;
  border-radius: 10px !important;
  background: linear-gradient(
    136.16deg,
    var(--v-hoverListItem1-base) 44.53%,
    var(--v-hoverListItem2-base) 100%
  );
}
>>> .v-list-item {
  border-radius: 10px !important;
}
>>> .v-list-item:hover {
  background: linear-gradient(
    136.16deg,
    var(--v-hoverListItem3-base) 44.53%,
    var(--v-hoverListItem4-base) 100%
  );
}
.layout-all {
  margin-right: 20px;
  margin-left: 20px;
}
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
  margin-bottom: 20px;
}
.title-icon {
  width: 16px;
  height: 16px;
  padding: 3px;
}
.hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 40px;
  margin-bottom: 30px;
}
/* section1 */
.layout-section1 {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}
.box-1-section1 {
  width: 34%;
}
.layout-select {
  display: flex;
  flex-wrap: wrap;
}
.size-image {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  padding: 50px;
}
.title-text {
  width: 100%;
  font-size: 55px;
  font-weight: 700;
  color: var(--v-titleFinancial-base) !important;
  margin-bottom: 20px;
}

.title-text-md-and-a {
  width: 80%;
  font-size: 40px;
  font-weight: 700;
  color: var(--v-titleFinancial-base) !important;
  margin-bottom: 20px;
}
.select-style {
  width: 80%;
  display: flex;
}
.label-select {
  font-size: 35px !important;
}
.items-select {
  font-size: 24px !important;
}
.btn-view-more {
  text-align: center;
  background-color: var(--v-btnViewMore-base) !important;
  border-radius: 51px;
  margin-bottom: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 45px;
  padding-right: 40px;
  font-size: 14px;
  font-weight: 400;
  color: var(--v-textPrimary-base) !important;
}
.btn-view-more:hover {
  background-color: var(--v-hoverLink-base) !important;
}
.box-2-section1 {
  width: 34%;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 50px;
  background-color: var(--v-bgBoxFinancial-base) !important;
}
.box-file {
  display: flex;
  height: 25%;
  width: 100%;
}
.text-file-name {
  font-size: 25px;
  font-weight: 600;
  color: var(--v-textPrimary-base) !important;
  width: 85%;
  margin-right: 10px;
}
.box-icon {
  width: 8%;
}
.icon-download {
  font-size: 40px;
  color: var(--v-iconDownload-base) !important;
}
.icon-download:hover {
  background: linear-gradient(
    136deg,
    var(--v-gradientUnderLine1-base) 100%,
    var(--v-gradientUnderLine2-base) 100%
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.box-3-section1 {
  width: 34%;
  min-height: 200px;
  border-radius: 0px 20px 20px 0px;
}
/* section table */
/* background section2 */
.borderline-section2 {
  /* layout */
  margin-bottom: 30px;
  width: 100%;
  height: 30px;
  /* style */
  background: linear-gradient(
    180deg,
    var(--v-gradientBgCacEsg2-base) 0%,
    var(--v-gradientBgCacEsg1-base) 100%
  );
}
.borderline-section3 {
  /* layout */
  margin-bottom: 30px;
  width: 100%;
  height: 30px;
  /* style */
  background: linear-gradient(
    360deg,
    var(--v-gradientBgCacEsg2-base) 0%,
    var(--v-gradientBgCacEsg1-base) 100%
  );
}
.title-table {
  text-align: center;
  background-color: var(--v-bgTitleTable-base) !important;
  border-radius: 51px;
  margin-bottom: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 28px;
  font-weight: 600;
  color: var(--v-textSecond-base) !important;
}
.table-style {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  margin-bottom: 20px;
}
.colum-year {
  font-weight: 600;
}
/* title_origin */
.layout-origin {
  display: flex;
  justify-content: flex-end;
}
.text-origin {
  font-size: 18px;
  font-weight: 400;
  color: var(--v-textSecond-base) !important;
}
::v-deep .title-origin .v-icon.v-icon {
  color: inherit !important;
}
.set-height-btn-th {
  height: 27px;
  display: flex;
}
.set-height-btn-eng {
  height: 27px;
  display: flex;
}
.title-origin {
  text-align: center;
  background-color: var(--v-bgTitleOrigin-base) !important;
  border-radius: 51px;
  margin-bottom: 50px;
  padding-left: 45px;
  padding-right: 40px;
  /* font-size: 14px; */
  font-weight: 400;
  color: var(--v-TitleOrigin-base) !important;
  border: solid 1px var(--v-TitleOrigin-base) !important;
  align-items: center;
  /* display: flex; */
}
.title-origin:hover {
  color: var(--v-textSecond-base) !important;
  background-color: var(--v-bgHoverTitleOrigin-base) !important;
  cursor: pointer;
}
>>> .theme--light.v-data-table.v-data-table--fixed-header thead th {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 1);
}

@media screen and (max-width: 1200px) {
  /* section1 */
  /* box1 */
  .size-image {
    padding: 30px;
  }
  .select-style {
    width: 80%;
  }
}
@media screen and (max-width: 1073px) {
  /* section download */
  .title-text-md-and-a {
    width: 100%;
  }
}
@media screen and (max-width: 1044px) {
  /* section1 */
  /* box1 */
  .title-text {
    font-size: 42px;
  }
  .title-text-md-and-a {
    font-size: 36px;
  }
}
@media screen and (max-width: 1030px) {
  .chart-box-main {
    width: 100%;
  }
  .chart-border-right {
    border: unset;
  }
  .hr-chart-responesive {
    display: inherit;
    border: 1px solid #d0d0d0;
  }
}
@media screen and (max-width: 1000px) {
  .box-1-section1 {
    width: 44%;
  }

  .box-2-section1 {
    width: 46%;
  }

  .box-3-section1 {
    width: 10%;
    background-position: bottom !important ;
  }
}
@media screen and (max-width: 984px) {
  .hr-border {
    color: var(--v-linerPrimary-base) !important;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .layout-select {
    width: 100%;
  }
  .select-style {
    width: 100%;
  }
}
@media screen and (max-width: 688px) {
  .layout-section1 {
    flex-wrap: wrap;
    text-align: center;
  }
  .layout-select {
    justify-content: center;
  }
  .select-style {
    width: 90%;
  }
  .size-image {
    max-height: 300px;
    border-radius: 20px 20px 0px 0px;
  }
  .box-1-section1 {
    width: 100%;
  }

  .box-2-section1 {
    width: 100%;
    padding-bottom: 30px;
    text-align: start;
  }
  .box-file {
    margin-bottom: 20px;
    height: unset;
  }
  .box-3-section1 {
    width: 100%;
    border-radius: 0px 0px 20px 20px;
    background-position: center !important;
  }
  .label-select {
    font-size: 30px !important;
  }
  .title-table {
    font-size: 26px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (max-width: 599px) {
  .first-row {
    border-radius: 10px;
  }
}
@media screen and (max-width: 568px) {
  .val-sum-chart {
    width: 100%;
    text-align: end;
    font-size: 40px;
    margin-top: 20px;
  }
  .title-chart {
    width: 100%;
  }
  .title-assets {
    max-width: unset;
  }
  .title-liabilities {
    max-width: unset;
  }
  .title-revenues {
    max-width: unset;
  }
  .title-net-profit {
    max-width: unset;
  }
  .title-net-profit-eng {
    max-width: unset;
  }
  .title-chart-box {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 406px) {
  .set-height-btn-eng {
    display: unset;
    height: unset;
    padding-top: 5px;
  }
}
@media screen and (max-width: 384px) {
  .set-height-btn-th {
    display: unset;
    height: unset;
    padding-top: 5px;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"1000px","transition":"dialog-top-transition","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"layout-card"},[_c('v-form',{ref:"fromAnnounce"},[_c('div',{staticClass:"from-persion-card"},[_c('div',{staticClass:"header-title"},[_c('v-icon',{staticClass:"icon-header"},[_vm._v(_vm._s(_vm.iconTitle))]),_vm._v(_vm._s(_vm.titleHeader)+" ")],1),_c('v-spacer'),_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('hr',{staticClass:"hr-header"}),_c('div',{staticClass:"from-layout"},[_c('div',{staticClass:"one-colum"},[_c('div',{staticClass:"title-text-field"},[_vm._v("หัวข้อภาษาไทย")]),_c('v-text-field',{attrs:{"placeholder":"กรุณากรอกหัวข้อภาษาไทย","outlined":"","dense":"","color":"primaryIconAdmin","rules":[_vm.Rule.noInPut, _vm.Rule.notNull],"loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.itemForm.title_th),callback:function ($$v) {_vm.$set(_vm.itemForm, "title_th", $$v)},expression:"itemForm.title_th"}})],1),_c('div',{staticClass:"one-colum"},[_c('div',{staticClass:"title-text-field"},[_vm._v("หัวข้อภาษาอังกฤษ")]),_c('v-text-field',{attrs:{"placeholder":"กรุณากรอกหัวข้อภาษาอังกฤษ","outlined":"","dense":"","color":"primaryIconAdmin","rules":[_vm.Rule.noInPut, _vm.Rule.notNull],"loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.itemForm.title_eng),callback:function ($$v) {_vm.$set(_vm.itemForm, "title_eng", $$v)},expression:"itemForm.title_eng"}})],1),_c('div',{staticClass:"one-colum"},[_c('div',{staticClass:"title-text-field"},[_vm._v("เนื้อหาไทย")]),_c('div',{staticClass:"mt-2 mb-7"},[_c('div',{class:`${
                _vm.description_th_validate
                  ? 'border-validate'
                  : 'border-validate-none'
              }`},[_c('u-text-deitor',{model:{value:(_vm.itemForm.description_th),callback:function ($$v) {_vm.$set(_vm.itemForm, "description_th", $$v)},expression:"itemForm.description_th"}})],1),(_vm.description_th_validate)?_c('span',{staticClass:"text-validate"},[_vm._v("กรุณากรอกข้อมูล")]):_vm._e()])]),_c('div',{staticClass:"one-colum"},[_c('div',{staticClass:"title-text-field"},[_vm._v("เนื้อหาอังกฤษ")]),_c('div',{staticClass:"mt-2 mb-7"},[_c('div',{class:`${
                _vm.description_eng_validate
                  ? 'border-validate'
                  : 'border-validate-none'
              }`},[_c('u-text-deitor',{model:{value:(_vm.itemForm.description_eng),callback:function ($$v) {_vm.$set(_vm.itemForm, "description_eng", $$v)},expression:"itemForm.description_eng"}})],1),(_vm.description_eng_validate)?_c('span',{staticClass:"text-validate"},[_vm._v("กรุณากรอกข้อมูล")]):_vm._e()])]),_c('div',{staticClass:"two-colum"},[_c('div',{staticClass:"title-text-field"},[_vm._v("วันที่เริ่ม")]),_c('DatePicker',{attrs:{"min":_vm.minDate,"max":_vm.itemForm.end_date,"loading":_vm.loading,"color":"green","placeholder":"กรุณาเลือกวันที่"},model:{value:(_vm.itemForm.start_date),callback:function ($$v) {_vm.$set(_vm.itemForm, "start_date", $$v)},expression:"itemForm.start_date"}})],1),_c('div',{staticClass:"two-colum"},[_c('div',{staticClass:"title-text-field"},[_vm._v("วันที่สิ้นสุด")]),_c('DatePicker',{attrs:{"min":_vm.itemForm.start_date,"loading":_vm.loading,"placeholder":"กรุณาเลือกวันที่"},model:{value:(_vm.itemForm.end_date),callback:function ($$v) {_vm.$set(_vm.itemForm, "end_date", $$v)},expression:"itemForm.end_date"}})],1)]),_c('div',{staticClass:"layout-btn-record-cancle"},[_c('div',{staticClass:"px-1"},[_c('v-btn',{staticClass:"btn-add mb-2",attrs:{"elevation":"0","rounded":"","loading":_vm.loading},on:{"click":function($event){return _vm.showEx()}}},[_vm._v("ดูตัวอย่าง")])],1),_c('div',{staticClass:"px-1"},[_c('v-btn',{staticClass:"btn-add mb-2",attrs:{"elevation":"0","rounded":"","loading":_vm.loading},on:{"click":function($event){return _vm.validateFrom()}}},[_vm._v("บันทึก")])],1),_c('div',{staticClass:"px-1"},[_c('v-btn',{staticClass:"btn-cancle mb-2",attrs:{"elevation":"0","rounded":"","loading":_vm.loading},on:{"click":function($event){return _vm.close()}}},[_vm._v("ยกเลิก")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
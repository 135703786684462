<template>
  <div>
    <div v-for="item in items" :key="item.news_id" class="main-content">
      <div class="title-news">
        {{ FiltersLanguage(item, "title") }}
      </div>
      <div class="date-news">
        <span class="mr-5">
          {{ returnFormateDate(item.create_date, language) }}
        </span>
        <!-- {{
          returnFormateDate(
            item.create_date,
            language,
            language === "th" ? "h:mm" : "h:mm a"
          )
        }}
        {{ language === "th" ? "น." : "" }} -->
      </div>
      <div class="img-baner">
        <v-img
        :src="`${env}/api/v1/file/${item.banner}`"
      ></v-img>
      </div>
      <div class="output ql-snow">
        <div
          class="ql-editor base-style"
          v-html="FiltersLanguage(item, 'description')"
        ></div>
      </div>
    </div>
    <div class="text-center my-8" v-if="statusNoData">
      <v-icon size="80">mdi-alert-circle</v-icon>
      <div class="text-no-data">No data available</div>
    </div>
    <cpn-loading :show="loading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      env: '',
      statusNoData: false,
      loading: false,
      items: [],
      news_id: '',
      tags: '',
      keyword: '',
      pagination: {
        total: 0,
        page: 1,
        row: 12
      }
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  async created () {
    this.env = process.env.VUE_APP_API
    // NOTE ดึง project_id จาก URL
    this.news_id = this.$route.params.news_id
    this.init()
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },

  methods: {
    async init () {
      this.items = []
      this.loading = true
      // TODO เป็น Function ที่ใช้ในการ initial ข้อมูลข่าวที่จะเอามาแสดง
      const payload = {
        keyword: this.keyword,
        page: this.pagination.page,
        row: this.pagination.row,
        tags: this.tags,
        news_id: this.news_id
      }
      const res = await this.getNews(payload)
      this.loading = false
      if (res.status) {
        this.items = res.data.data
        this.pagination.total = res.data.pagination.total
        this.pagination.page = res.data.pagination.page
        if (this.items.length === 0) {
          this.statusNoData = true
        }
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.img-baner{
  padding: 34px 15px 44px 15px;
}
.text-no-data {
  text-align: center;
  font-size: 22px;
  color: var(--v-textComingSoon-base) !important;
}
>>> img {
  margin-bottom: 20px;
  margin-top: 20px !important;
}
.base-style {
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.main-content {
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 80px;
  margin-bottom: 80px;
}
.title-news {
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
  padding: 12px 15px;
}
.date-news {
  color: var(--v-colorDate-base) !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 15px;
}
</style>

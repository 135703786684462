<template>
  <div>
    <div class="main-content-section1">
      <div class="title-content1">
        <div class="d-flex justify-center">
          {{ FiltersLanguage(item_shareholder_meeting_page, "title_section1") }}
          <div>
            <v-img
              class="title-icon"
              :src="require(`@/${item_shareholder_meeting_page.img_dot}`)"
            ></v-img>
          </div>
        </div>
      </div>
      <div class="box-now">
        <div class="box-image-now pb-5 pt-8">
          <div :class="`title-now title-now-${language}`">
            {{ FiltersLanguage(item_shareholder_meeting_page, "title_now") }}
            <span class="title-now-year">
              {{ returnFormateYear(dateNow, language) }}</span
            >
          </div>
          <div class="box-image">
            <v-img
              :src="require(`@/${item_shareholder_meeting_page.img_now}`)"
            ></v-img>
          </div>
          <div class="footer-now">ALWAYS BY YOUR SIDE</div>
        </div>
        <div
          class="box-documents-now py-10"
          :style="`background-image: url( ${require(`@/${item_shareholder_meeting_page.img_documents_now}`)}); background-size: cover; background-position: center;`"
        >
          <v-progress-linear v-if="loadingShareholderMeetingNow" indeterminate color="teal"  class="my-5"></v-progress-linear>
          <div
            v-for="(items, i) in itemsFileNow"
            :key="i"
            class="box-item-documents overflow-box-flie"
          >
            <div v-for="(item, ii) in items.items" :key="ii" class="my-8">
              <div class="header-item">
                {{ FiltersLanguage(item, "title") }}
              </div>
              <div v-for="(file, i3) in item.items" :key="i3">
                <div class="box-file px-5 my-5">
                  <div class="tile-file">
                    {{ FiltersLanguage(file, "title") }}
                  </div>
                  <div class="box-icon">
                    <a
                      :href="`${env}/api/v1/file/${FiltersLanguage(
                        file,
                        'file'
                      )}`"
                      target="_blank"
                    >
                      <v-icon class="icon-download"> mdi-download </v-icon>
                    </a>
                  </div>
                </div>
                <hr v-if="item.items.length !== i3 + 1" class="hr-file" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-section2">
      <div class="borderline-section2"></div>
      <div class="main-content-section2">
        <div class="title-content2">
          <div class="d-flex justify-center">
            {{
              FiltersLanguage(item_shareholder_meeting_page, "title_section2")
            }}
            <div>
              <v-img
                class="title-icon"
                :src="require(`@/${item_shareholder_meeting_page.img_dot}`)"
              ></v-img>
            </div>
          </div>
        </div>

        <div class="seeAll-content">
          <div style="max-width: 260px">
            <v-select
              :items="itemsYaer"
              outlined
              item-color="green"
              background-color="#fff"
              v-model="selectYear"
              @change="intFileYear()"
              :disabled="loadingSelect"
              color="loadingAll"
              class="custom-select mt-5"
            >
              <template v-slot:selection="{ item }">
                <span class="label-select">
                  {{
                    FiltersLanguage(item_shareholder_meeting_page, "title_year")
                  }}
                  {{ returnFormateYear(item, language) }}</span
                >
              </template>
              <template v-slot:item="{ item }">
                <span class="items-select">{{
                  returnFormateYear(item, language)
                }}</span>
              </template>
            </v-select>
          </div>
        </div>

        <cpn-loading :show="loadingShareholderMeeting" />
        <div class="box-file-year">
          <div class="box-file-left">
            <div
              v-for="(item, index) in itemsFileLeft"
              :key="index"
              class="mb-8 bg-box-file"
            >
              <div class="header-file">
                {{ FiltersLanguage(item, "title") }}
              </div>
              <div
                v-for="(file, index1) in item.items"
                :key="index1"
                class="box-file pa-5"
              >
                <div class="tile-file-sub">
                  {{ FiltersLanguage(file, "title") }}
                </div>
                <div class="box-icon">
                  <a
                    :href="`${env}/api/v1/file/${FiltersLanguage(
                      file,
                      'file'
                    )}`"
                    target="_blank"
                  >
                    <v-icon class="icon-download2"> mdi-download </v-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="box-file-right">
            <div
              v-for="(item, index) in itemsFileRight"
              :key="index"
              class="mb-8 bg-box-file"
            >
              <div class="header-file">
                {{ FiltersLanguage(item, "title") }}
              </div>
              <div
                v-for="(file, index1) in item.items"
                :key="index1"
                class="box-file pa-5"
              >
                <div class="tile-file-sub">
                  {{ FiltersLanguage(file, "title") }}
                </div>
                <div class="box-icon">
                  <a
                    :href="`${env}/api/v1/file/${FiltersLanguage(
                      file,
                      'file'
                    )}`"
                    target="_blank"
                  >
                    <v-icon class="icon-download2"> mdi-download </v-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      env: '',
      minYear: 2013,
      dateNow: '',
      selectYear: '',
      itemsYaer: [],
      tag: 'shareholderMeeting',
      itemsFileNow: [],
      itemsFile: [],
      itemsFileLeft: [],
      itemsFileRight: [],
      loadingSelect: false,
      loadingShareholderMeetingNow: false,
      loadingShareholderMeeting: false
    }
  },
  computed: {
    ...mapGetters(['item_shareholder_meeting_page', 'language'])
  },
  created () {
    this.env = process.env.VUE_APP_API
    this.dateNow = this.$moment().format('YYYY')
    this.dateNow = (parseInt(this.dateNow) + 1).toString()
    this.intFileYearNow()
    // this.$EventBus.$on('change-language', () => {
    //   this.intFileYearNow()
    // })
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  methods: {
    itemsYearSelect (year) {
      // const minYear = 2557
      const itemsYears = []
      const maxYear = year
      for (let maxYears = maxYear; maxYears >= this.minYear; maxYears--) {
        itemsYears.push(String(maxYears))
      }
      this.itemsYaer = itemsYears
    },
    async intFileYearNow () {
      this.loadingShareholderMeetingNow = true
      const payload = {
        page: 1,
        row: 99,
        keyword: `Documents of Meeting ${this.dateNow}`
      }
      const res = await this.getList(payload)
      this.loadingShareholderMeetingNow = false
      if (res.status) {
        if (res.data.data.length !== 0) {
          this.itemsFileNow = res.data.data
          const yaer = this.dateNow
          this.selectYear = String(yaer)
          this.itemsYearSelect(this.dateNow)
          this.intFileYear()
        } else if (res.data.data.length === 0 && this.dateNow > this.minYear) {
          this.dateNow = this.dateNow - 1
          this.intFileYearNow()
        }
      } else {
        this.antmessage.error(res.message)
      }
    },
    async intFileYear () {
      this.loadingSelect = true
      this.loadingShareholderMeeting = true
      const payload = {
        page: 1,
        row: 99,
        keyword: `Documents of Meeting ${this.selectYear}`
      }
      this.itemsFile = []
      this.itemsFileLeft = []
      this.itemsFileRight = []
      const res = await this.getList(payload)
      setTimeout(() => {
        this.loadingShareholderMeeting = false
        this.loadingSelect = false
      }, 100)

      if (res.status) {
        this.itemsFile = res.data.data
        const length = this.itemsFile[0].items.length
        const lengthMod = length % 2
        const length2 = Math.floor(length / 2)
        if (lengthMod === 0) {
          this.itemsFileLeft = this.itemsFile[0].items.slice(0, length2)
          this.itemsFileRight = this.itemsFile[0].items.slice(length2, length)
        } else {
          this.itemsFileLeft = this.itemsFile[0].items.slice(0, length2 + 1)
          this.itemsFileRight = this.itemsFile[0].items.slice(
            length2 + 1,
            length
          )
        }
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
.footer-now {
  color: var(--v-title_now-base);
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-left: 20px;
  padding-right: 20px;
}
.title-now-th {
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.title-now-eng {
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.title-now {
  color: var(--v-title_now-base);
  padding-left: 30px;
  padding-right: 30px;
}
.title-now-year {
  color: var(--v-title_now_year-base);
  text-shadow: 3px 3px var(--v-title_now-base);
}
.box-now {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.box-image-now {
  border-radius: 20px;
  width: 49%;
  min-height: 700px;
  box-shadow: 0px 0px 5px 2px rgba(48, 48, 48, 0.3);
  /* background-color: red; */
}
.main-content-section1 {
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
  padding-right: 20px;
  padding-left: 20px;
}
.main-content-section2 {
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}
.borderline-section2 {
  width: 100%;
  height: 60px;
  background: linear-gradient(
    180deg,
    var(--v-gradientBgCacEsg2-base) 0%,
    var(--v-gradientBgCacEsg1-base) 100%
  );
}
.title-content1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
  margin-bottom: 57px;
}
.title-content2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  /* padding-top: 4rem; */
  text-align: center;
  margin-bottom: 20px;
}
.title-icon {
  width: 16px;
  height: 16px;
  padding: 3px;
}

/* custom selecter */
.seeAll-btn {
  cursor: pointer;
  height: 38;
  border-radius: 51px;
  background-color: var(--v-seeAllBtn-base) !important;
  color: var(--v-textSecond-base) !important;
  font-size: 20px;
  font-weight: 500;
}
.seeAll-content {
  display: flex;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 12px;
}
.seeAll-content:before,
.seeAll-content:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid var(--v-textPrimary-base) !important;
  margin: auto;
}
.box-select-year {
  /* width: 243px; */
  width: 20%;
}
.box-hr {
  width: 80%;
}
.box-documents-now {
  border-radius: 20px;
  width: 49%;
  padding: 0 40px 0 40px;
}
.overflow-box-flie {
  max-height: 636px;
  overflow-y: auto;
}
.box-item-documents {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 18px;
  padding-right: 18px;
}
.header-item {
  font-size: 23px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 15px;
}
.box-file {
  display: flex;
  justify-content: space-between;
}
.tile-file {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 85%;
  margin-right: 10px;
}
.box-icon {
  width: 8%;
  text-align: center;
}
.icon-download {
  font-size: 40px;
  color: var(--v-iconDownload-base) !important;
}
.icon-download:hover {
  color: var(--v-hoverIconDownload-base) !important;
}
.icon-download2 {
  font-size: 40px;
  color: var(--v-iconDownload-base) !important;
}
.icon-download2:hover {
  color: var(--v-loadingAll-base) !important;
}
.hr-file {
  border: 1px solid var(--v-hrFile-base);
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
>>> .custom-select .v-input__control .v-input__slot {
  border-radius: 10px !important;
}
::v-deep .theme--light.v-list-item.v-list-item--highlighted::before {
  opacity: 0.3;
  border-radius: 10px !important;
  background: linear-gradient(
    136.16deg,
    var(--v-hoverListItem1-base) 44.53%,
    var(--v-hoverListItem2-base) 100%
  );
}
>>> .v-list-item {
  border-radius: 10px !important;
}
>>> .v-list-item:hover {
  background: linear-gradient(
    136.16deg,
    var(--v-hoverListItem3-base) 44.53%,
    var(--v-hoverListItem4-base) 100%
  );
}
.label-select {
  font-size: 35px !important;
}
.items-select {
  font-size: 24px !important;
}

/* section2 */
.box-file-year {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.box-file-left {
  width: 49%;
}
.box-file-right {
  width: 49%;
}
.header-file {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 15px 20px 15px 20px;
  background-color: var(--v-bgHeaderFile-base);
}
.tile-file-sub {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}
.box-file-sub {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bg-box-file {
  background-color: var(--v-bgFile-base);
}
@media screen and (max-width: 1100px) {
  .box-now {
    flex-wrap: wrap;
  }
  .box-image {
    max-width: 600px;
    width: 100%;
  }
  .box-image-now {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: unset;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .box-documents-now {
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 0px 5px 2px rgba(48, 48, 48, 0.3);
    padding: 0 20px 0 20px;
  }
  .title-now {
    width: 100%;
    text-align: center;
  }
  .footer-now {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 890px) {
  .box-file-year {
    flex-wrap: wrap;
  }
  .box-file-left {
    width: 100%;
  }
  .box-file-right {
    width: 100%;
  }
  .title-now-th {
  font-size: 42px;
}
.title-now-eng {
  font-size: 42px;
}
}
@media screen and (max-width: 688px) {
  .label-select {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 450px) {
  .box-documents-now {
    padding: 0 10px 0 10px;
  }
  .title-now-th {
  font-size: 38px;
}
.title-now-eng {
  font-size: 38px;
}
}
</style>

<template>
  <div>
    <titleHeaderMenu :iconTitle="iconTitle" :titleMenu="titleMenu" />
    <div class="btn-hearder">
      <div class="layout-btn-select">
        <div class="layout-box-category">
          <div class="box-category">เลือกหมวดหมู่ที่ต้องการจัดการ</div>
          <v-select
            class="width-select"
            v-model="category"
            :items="itemsCategory"
            item-text="text"
            item-value="value"
            color="primaryIconAdmin"
            item-color="primaryIconAdmin"
            :loading="loadingTablePerson"
            @change="initTablePerson()"
            dense
          ></v-select>
        </div>
        <div class="box-btn">
          <v-btn
            class="btn-add"
            elevation="0"
            rounded
            @click="addPersonToGroup()"
            ><v-icon>mdi-plus</v-icon>เพิ่มเข้าหมวดหมู่</v-btn
          >
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="box-text-field">
        <div class="size-box-search">
          <v-text-field
            v-model="search"
            :append-icon="'mdi-magnify'"
            label="ค้นหาจากชื่อ"
            color="primaryIconAdmin"
          ></v-text-field>
        </div>
      </div>
    </div>
    <div>
      <tableAdminAll
        :headers="headerTablePerson"
        :items="itemsTablePerson"
        :loading="loadingTablePerson"
        :pagination="paginationTablePerson"
        @onChangePagination="onChangeTablePerson"
        warpHeader
        :search="search"
        :height="$vuetify.breakpoint.height-280"
      >
        <template v-slot:[`item.number`]="{ item }">
          <span>
            {{
              paginationTablePerson.row * paginationTablePerson.page -
              paginationTablePerson.row +
              (itemsTablePerson.indexOf(item) + 1)
            }}
          </span>
        </template>
        <template v-slot:[`item.profile.firstname_th`]="{ item }">
          <div class="min-width">
            {{ item.profile.firstname_th }}
          </div>
        </template>
        <template v-slot:[`item.profile.lastname_th`]="{ item }">
          <div class="min-width">
            {{ item.profile.lastname_th }}
          </div>
        </template>
        <template v-slot:[`item.profile.firstname_eng`]="{ item }">
          <div class="min-width">
            {{ item.profile.firstname_eng }}
          </div>
        </template>
        <template v-slot:[`item.profile.lastname_eng`]="{ item }">
          <div class="min-width">
            {{ item.profile.lastname_eng }}
          </div>
        </template>
        <template v-slot:[`item.create_date`]="{ item }">
          {{ returnFormateDate(item.create_date, "th") }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div class="layout-action">
            <v-btn icon
              ><v-icon
                class="btn-down"
                @click="
                  recheckChangeIndex({
                    status: 'down',
                    index: itemsTablePerson.indexOf(item),
                  })
                "
                :disabled="
                  itemsTablePerson.indexOf(item) + 1 === itemsTablePerson.length
                "
                >mdi-arrow-down-circle-outline</v-icon
              ></v-btn
            >
            <v-btn icon
              ><v-icon
                class="btn-up"
                @click="
                  recheckChangeIndex({
                    status: 'up',
                    index: itemsTablePerson.indexOf(item),
                  })
                "
                :disabled="itemsTablePerson.indexOf(item) === 0"
                >mdi-arrow-up-circle-outline</v-icon
              ></v-btn
            >
            <v-btn icon
              ><v-icon
                class="btn-delete"
                @click="recheckDeletePersionFromTeam(item)"
                >mdi-trash-can-outline</v-icon
              ></v-btn
            >
          </div>
        </template>
      </tableAdminAll>
      <formAddPerson
        ref="formAddPerson"
        v-model="statusformAddPerson"
        :titleHeader="titleHeader"
        :iconTitle="iconTitleaAddEdit"
        :itemPerson="itemPerson"
        :loadingItemPerson="loadingItemPerson"
        @addPerson="addPerson"
      />
    </div>
    <dialog-loading v-model="loadingForm" />
  </div>
</template>

<script>
import titleHeaderMenu from '../../../../components/adminpage/titleHeaderMenu.vue'
import tableAdminAll from '../../../../components/adminpage/tableAll/tableAdminAll.vue'
import formAddPerson from '../../../../components/adminpage/formTeamPage/formAddPersionToTeam.vue'
export default {
  components: {
    titleHeaderMenu,
    tableAdminAll,
    formAddPerson
  },
  data () {
    return {
      iconTitle: 'mdi-account-switch',
      titleMenu: 'จัดเรียงบุคคล',
      titleHeader: '',
      iconTitleaAddEdit: '',
      search: '',
      category: 'BoardOfDirectors',
      itemsCategory: [
        { text: 'คณะกรรมการบริษัท', value: 'BoardOfDirectors' },
        { text: 'คณะกรรมการบริหาร', value: 'TheExecutiveCommittee' },
        { text: 'คณะกรรมการตรวจสอบ', value: 'TheAuditCommittee' },
        {
          text: 'คณะกรรมการสรรหา กำหนดค่าตอบแทน และกำกับดูแลกิจการ',
          value: 'TheNominationRemunerationAndCorporateGovernanceCommittee'
        },
        {
          text: 'คณะกรรมการการบริหารความเสี่ยง',
          value: 'TheRiskManagementCommittee'
        },
        {
          text: 'คณะกรรมการประเมินผลการปฏิบัติงานของกรรมการผู้จัดการ',
          value: 'TheCEOPerformanceReviewCommittee'
        },
        { text: ' ผู้บริหารบริษัท', value: 'ManagementTeam' }
      ],
      loadingTablePerson: false,
      headerTablePerson: [
        {
          text: 'ลำดับ',
          align: 'center',
          value: 'number',
          sortable: false,
          filterable: false
        },
        {
          text: 'ชื่อไทย',
          align: 'start',
          value: 'profile.firstname_th',
          sortable: false
        },
        {
          text: 'นามสกุลไทย',
          align: 'start',
          value: 'profile.lastname_th',
          sortable: false
        },
        {
          text: 'ชื่ออังกฤษ',
          align: 'start',
          value: 'profile.firstname_eng',
          sortable: false
        },
        {
          text: 'นามสกุลอังกฤษ',
          align: 'start',
          value: 'profile.lastname_eng',
          sortable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'end',
          value: 'create_date',
          sortable: false,
          filterable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false,
          filterable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false,
          filterable: false
        }
      ],
      itemsTablePerson: [],
      paginationTablePerson: {
        total: 0,
        page: 1,
        row: 999
      },
      loadingItemPerson: false,
      itemPerson: [],
      statusformAddPerson: false,
      loadingForm: false,
      IndexChange: 0
    }
  },
  async created () {
    this.init()
  },
  watch: {
    IndexChange: {
      handler: function (newValue) {
        if (newValue === 2) {
          this.loadingForm = false
          this.IndexChange = 0
          this.$swal({
            allowEnterKey: false,
            title: 'เปลี่ยนลำดับ',
            text: 'เปลี่ยนลำดับบุคคลสำเร็จ',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false
          })
          this.initTablePerson()
        }
      }
    },
    deep: true

  },
  methods: {
    init () {
      this.initTablePerson()
    },
    onChangeTablePerson (val) {
      this.paginationTablePerson.page = val.page
      this.paginationTablePerson.row = val.row
      // this.initTablePerson()
    },
    async initTablePerson () {
      this.loadingTablePerson = true
      const payload = {
        page: this.paginationTablePerson.page,
        row: this.paginationTablePerson.row,
        tags: this.category
      }
      this.itemsTablePerson = []
      const res = await this.getTableUserTeamAuth(payload)
      this.loadingTablePerson = false
      if (res.status) {
        this.itemsTablePerson = res.data.data
        this.paginationTablePerson.total = res.data.pagination.total
        this.paginationTablePerson.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    addPersonToGroup () {
      this.initItemPerson()
      this.titleHeader = 'เพิ่มบุคคล'
      this.iconTitleaAddEdit = 'mdi-plus'
      this.statusformAddPerson = true
      // set item select person
    },
    async initItemPerson () {
      this.loadingItemPerson = true
      const payload = {
        page: 1,
        row: 999,
        tags: this.category
      }
      this.itemPerson = []
      const res = await this.getUserTeamItemOnInGroup(payload)
      this.loadingItemPerson = false
      if (res.status) {
        this.itemPerson = res.data.data
      } else {
        this.antmessage.error(res.message)
      }
    },
    addPerson (item) {
      const newItem = JSON.parse(JSON.stringify(item))
      const indexItems = this.itemsTablePerson[this.itemsTablePerson.length - 1].group_tags.filter((item) => item.name === this.category)
      let index = null
      if (indexItems.length !== 0) {
        index = indexItems[0].index + 1
      } else {
        index = this.itemsTablePerson.length
      }
      newItem.group_tags.push({ index: index, name: this.category })
      this.addPersonToTeam(newItem)
    },
    async addPersonToTeam (newDataProfile) {
      this.loadingForm = true
      const payload = newDataProfile
      const res = await this.editProfile(payload)
      this.loadingForm = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'เพิ่มเข้าหมวดหมู่',
          text: 'เพิ่มบุคคลเข้าหมวดหมู่สำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTablePerson()
        this.statusformAddPerson = false
        this.$refs.formAddPerson.close()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async recheckDeletePersionFromTeam (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบบุคคล',
        text: 'คุณต้องการลบบุคคลออกจากหมวดหมู่ ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.deletePersionFromTeam(item)
        }
      })
    },
    async deletePersionFromTeam (item) {
      const itemsNew = []
      const itemAll = JSON.parse(JSON.stringify(item))
      for (let index = 0; index < item.group_tags.length; index++) {
        if (item.group_tags[index].name !== this.category) {
          itemsNew.push(item.group_tags[index])
        }
      }
      itemAll.group_tags = itemsNew
      this.loadingForm = true
      const payload = itemAll
      const res = await this.editProfile(payload)
      this.loadingForm = false
      if (res.status) {
        this.initTablePerson()
      } else {
        this.antmessage.error(res.message)
      }
      // console.log('indexRemove', indexRemove)
    },
    async recheckChangeIndex (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'เปลี่ยนลำดับ',
        text: 'คุณต้องการเปลี่ยนลำดับบุคคลในหมวดหมู่ ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (item.status === 'up') {
            let indexMain = null
            for (let index = 0; index < this.itemsTablePerson[item.index].group_tags.length; index++) {
              if (this.itemsTablePerson[item.index].group_tags[index].name === this.category) {
                indexMain = index
              }
            }
            let indexChange = null
            for (let index1 = 0; index1 < this.itemsTablePerson[item.index - 1].group_tags.length; index1++) {
              if (this.itemsTablePerson[item.index - 1].group_tags[index1].name === this.category) {
                indexChange = index1
              }
            }
            const itemMain = JSON.parse(JSON.stringify(this.itemsTablePerson[item.index]))
            const itemChange = JSON.parse(JSON.stringify(this.itemsTablePerson[item.index - 1]))
            itemMain.group_tags[indexMain].index = this.itemsTablePerson[item.index - 1].group_tags[indexChange].index
            itemChange.group_tags[indexChange].index = this.itemsTablePerson[item.index].group_tags[indexMain].index
            this.changeIndex(itemMain)
            this.changeIndex(itemChange)
          } else if (item.status === 'down') {
            let indexMain = null
            for (let index = 0; index < this.itemsTablePerson[item.index].group_tags.length; index++) {
              if (this.itemsTablePerson[item.index].group_tags[index].name === this.category) {
                indexMain = index
              }
            }
            let indexChange = null
            for (let index1 = 0; index1 < this.itemsTablePerson[item.index + 1].group_tags.length; index1++) {
              if (this.itemsTablePerson[item.index + 1].group_tags[index1].name === this.category) {
                indexChange = index1
              }
            }
            const itemMain = JSON.parse(JSON.stringify(this.itemsTablePerson[item.index]))
            const itemChange = JSON.parse(JSON.stringify(this.itemsTablePerson[item.index + 1]))
            itemMain.group_tags[indexMain].index = this.itemsTablePerson[item.index + 1].group_tags[indexChange].index
            itemChange.group_tags[indexChange].index = this.itemsTablePerson[item.index].group_tags[indexMain].index
            this.changeIndex(itemMain)
            this.changeIndex(itemChange)
          }
        }
      })
    },
    async changeIndex (item) {
      this.loadingForm = true
      const payload = item
      const res = await this.editProfile(payload)
      // this.loadingForm = false
      if (res.status) {
        this.IndexChange = this.IndexChange + 1
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
.btn-hearder {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.box-btn {
  padding-right: 20px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
.layout-action {
  min-width: 120px;
}
.btn-down {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-up {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-delete {
  color: var(--v-cancleBtn-base) !important;
}
.min-width {
  min-width: 150px;
}
.layout-btn-select {
  display: flex;
  align-items: center;
}
.layout-box-category {
  display: flex;
  margin-top: 14px;
  padding-right: 20px;
}
.box-category {
  font-size: 18px;
  margin-top: 10px;
  padding-right: 10px;
}
.width-select {
  width: 200px;
}

@media screen and (max-width: 1000px) {
  .btn-hearder {
    flex-wrap: wrap;
  }
  .layout-box-category {
    width: 100%;
  }
  .box-btn {
    padding-right: 0px;
  }
  .box-text-field {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .size-box-search {
    width: 40%;
  }
}
@media screen and (max-width: 599px) {
  .layout-btn-select {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  .layout-box-category {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 10px;
  }
  .box-btn {
    width: 100;
  }
  .box-text-field {
    width: 100%;
  }
  .size-box-search {
    width: 100%;
  }
}
@media screen and (max-width: 466px) {
  .layout-box-category {
    display: flex;
    flex-wrap: wrap;
  }
  .box-categor {
    width: 100%;
  }
  .width-selec {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .btn-hearder {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .box-btn {
    width: 100%;
  }
  .box-text-field {
    margin-top: 10px;
    width: 100%;
  }
}
</style>

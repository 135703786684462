<template>
  <div
    id="scroll-target"
    style="overflow-y: auto; height: 100%; max-height: 100vh"
  >
    <div v-scroll:#scroll-target="onScroll">
      <menus :menus="menus" :menusAll="menusAll">
        <template v-slot:homeInvestorRelations>
          <div
            :class="`home-investor-relations  ${
              $store.state.language === 'th'
                ? 'size-template-menu-th'
                : 'size-template-menu-eng'
            } ${valScroll < 5 ? 'slide-in' : ''}`"
          >
            <div class="px-5" style="width: 100%">
              <v-img
                class="image-investor-relations mx-auto"
                src="@/assets/images/menu/homeInvestorRelations_text.png"
              ></v-img>
            </div>
          </div>
          <div class="about-setINET" v-if="offsetTop < 400">
            <div class="layout-box-set mx-5">
              <div class="box-set-left text-main-box-set">SET : INET</div>
              <div class="box-set-right text-main-box-set-number">
                {{ itemsStock.inet.close.toFixed(2) }}
              </div>
              <div class="box-set-left text-box-set">
                {{ FiltersLanguage(stock, "title_val") }} :
                {{ returncommaNumber(itemsStock.inet.volume) }}
              </div>
              <div class="box-set-right text-box-set-number">
                {{ itemsStock.inet.color === "green" ? "+" : "-"
                }}{{
                  returnABS(itemsStock.inet.close, itemsStock.inet.percent_change)
                }}
                ({{ itemsStock.inet.color === "green" ? "+" : ""
                }}{{ itemsStock.inet.percent_change.toFixed(2) }}%)
              </div>
              <div class="text-data-now box-data-now">
                {{ FiltersLanguage(stock, "title_date") }} :
                {{
                  returnDateFull(
                    itemsStock.inet.date,
                    language,
                    "D MMM yyyy HH:mm:ss"
                  )
                }}
              </div>
              <div class="box-data-now-link">
                <v-btn
                  color="#2e8a3a"
                  small
                  rounded
                  block
                  class="text-data-now elevation-0"
                  :href="FiltersLanguage(stock, 'link_set')"
                  target="_blank"
                >
                  set.or.th <v-icon>mdi-arrow-right-thin</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:corporateInformation>
          <div
            :class="`corporate-information  ${
              $store.state.language === 'th'
                ? 'size-template-menu-th'
                : 'size-template-menu-eng'
            } ${valScroll < 5 ? 'slide-in' : ''}`"
          >
            <div class="px-5" style="width: 100%">
              <v-img
                class="image-corporate-information mx-auto"
                src="@/assets/images/menu/corporateInformation_text.png"
              ></v-img>
              <div style="width: 100%; display: flex; justify-content: center;">
                <hr class="hr-corporate-information mb-6 mt-2" />
              </div>
              <div class="description-corporate-information mx-auto">
                <!-- <hr class="hr-corporate-information mb-6 mt-2" /> -->
                <p v-if="$vuetify.breakpoint.name !== 'xs'"  v-html=" FiltersLanguage(item_inet_vision, 'description1')"></p>
                <p v-if="$vuetify.breakpoint.name === 'xs'" v-html=" FiltersLanguage(item_inet_vision, 'description1_mobile')"></p>
              </div>
            </div>
          </div>
        </template>
      </menus>
      <div class="layout-content-home">
        <router-view></router-view>
      </div>
      <footerHome />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import menus from '@/components/MenuPage.vue'
import footerHome from '@/components/footerHome.vue'
export default {
  components: {
    menus,
    footerHome
  },
  data () {
    return {
      offsetTop: 0,
      itemsStock: {
        inet: {
          color: '',
          date: '',
          open: 0,
          close: 0,
          max: 0,
          min: 0,
          percent_change: 0,
          volume: 0
        },
        inet_reit: {
          color: '',
          date: '',
          open: 0,
          close: 0,
          max: 0,
          min: 0,
          percent_change: 0,
          volume: 0
        }
      },
      menusAll: [
        {
          name: 'homeInvestorRelations',
          text_th: 'หน้าหลัก',
          text_eng: 'Home',
          sub_menu: [],
          // height: '1175px',
          bgImage: 'assets/images/menu/homeInvestorRelations.png'
        },
        {
          name: 'corporateOverview',
          text_th: 'ภาพรวมข้อมูลบริษัท',
          text_eng: 'Corporate Overview',
          sub_menu: [
            {
              name: 'corporateInformation',
              text_th: 'ข้อมูลบริษัท',
              text_eng: 'Corporate Information',
              // height: '1175px',
              bgImage: 'assets/images/menu/corporateInformation.png'
            },
            {
              name: 'managementTeam', // Board of Directors and Management Team
              text_th: 'คณะกรรมการบริษัทและผู้บริหารบริษัท',
              text_eng: 'Board of Directors and Management Team',
              bgMenuDark: true
            }
          ]
        },
        {
          name: 'financialInformation',
          text_th: 'ข้อมูลทางการเงิน',
          text_eng: 'Financial Information',
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png',
          sub_menu: []
        },
        // {
        //   name: 'financialInformationMain',
        //   text_th: 'ข้อมูลทางการเงิน',
        //   text_eng: 'Financial Information',
        //   sub_menu: [
        //     {
        //       name: 'financialInformation',
        //       text_th: 'ข้อมูลทางการเงิน',
        //       text_eng: 'Financial Information',
        //       bgMenuDark: true,
        //       bgImage: 'assets/images/menu/defaultBgMenu.png'
        //     }
        // {
        //   name: 'mdAndA',
        //   text_th: 'คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการ MD&A',
        //   text_eng: 'MD&A',
        //   bgMenuDark: true,
        //   bgImage: 'assets/images/menu/defaultBgMenu.png'
        // }
        // {
        //   name: 'annualReport56_1',
        //   text_th: 'รายงานประจำปี และ 56-1 One Report',
        //   text_eng: 'Annual Report 56-1 One Report',
        //   bgMenuDark: true,
        //   bgImage: 'assets/images/menu/defaultBgMenu.png'
        // }
        // {
        //   name: 'annualReport',
        //   text_th: 'รายงานประจำปี',
        //   text_eng: 'Annual Report',
        //   bgMenuDark: true,
        //   bgImage: 'assets/images/menu/defaultBgMenu.png'
        // },
        // {
        //   name: '56_1OneReport',
        //   text_th: '56-1 One Report',
        //   text_eng: '56-1 One Report',
        //   bgMenuDark: true,
        //   bgImage: 'assets/images/menu/defaultBgMenu.png'
        // }
        //   ]
        // },
        {
          name: 'annualReport56_1',
          // text_th: 'รายงานประจำปี และ 56-1 One Report',
          text_th: 'รายงานประจำปี',
          text_eng: 'Annual Report',
          // text_eng: 'Annual Report 56-1 One Report',
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png',
          sub_menu: [
            {
              name: 'annualReport',
              text_th: 'รายงานประจำปี',
              text_eng: 'Annual Report',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            },
            {
              name: '56_1OneReport',
              text_th: '56-1 One Report',
              text_eng: '56-1 One Report',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            }
          ]
        },
        {
          name: 'shareholderInformation',
          text_th: 'ข้อมูลสำหรับผู้ถือหุ้น',
          text_eng: 'Shareholder Information',
          sub_menu: [
            {
              name: 'stockInformation',
              text_th: 'ข้อมูลหลักทรัพย์',
              text_eng: 'Stock Information',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            },
            {
              name: 'shareholdingStructure',
              text_th: 'โครงสร้างผู้ถือหุ้น',
              text_eng: 'Shareholder Structure',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            },
            {
              name: 'shareholderMeeting',
              text_th: 'การประชุมผู้ถือหุ้น',
              text_eng: 'Shareholder’s meeting',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            },
            {
              name: 'dividendPolicy',
              text_th: 'นโยบายการจ่ายเงินปันผล',
              text_eng: 'Dividend Policy',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/DividendPolicyMenu.jpg'
            },
            {
              name: 'inetW2',
              text_th: 'ใบสำคัญแสดงสิทธิ INET-W3',
              text_eng: 'The warrants to Purchase Ordinary Shares in INET–W3',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            }
          ]
        },
        {
          name: 'corporateGovernanceMain',
          text_th: 'การกำกับดูแลกิจการ',
          text_eng: 'Corporate Governance',
          sub_menu: [
            {
              name: 'corporateGovernance',
              text_th: 'การกำกับดูแลกิจการ',
              text_eng: 'Corporate Governance',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            },
            {
              name: 'cacPage',
              text_th: 'การต่อต้านการทุจริตและคอร์รัปชัน : CAC',
              text_eng: 'Anti–Corruption : CAC',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            },
            {
              name: 'esgPage',
              text_th: 'ความยั่งยืน : ESG',
              text_eng: 'Sustainability : ESG',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            }
          ]
        },
        {
          name: 'newsAll',
          text_th: 'ข่าวสารนักลงทุนสัมพันธ์',
          text_eng: 'Investor Relations News',
          sub_menu: [],
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png'
        },
        {
          name: 'detailsNews',
          text_th: 'รายละเอียดข่าว',
          text_eng: 'news detail',
          sub_menu: [],
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png'
        },
        {
          name: 'ESGstampNews',
          text_th: 'ข่าว ESG',
          text_eng: 'ESG news',
          sub_menu: [],
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png'
        },
        {
          name: 'contactPage',
          text_th: 'ติดต่อเรา',
          text_eng: 'Contact Us',
          sub_menu: [],
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png'
        },
        {
          name: 'economyNews',
          text_th: 'ข่าวมิติเศรษฐกิจ',
          text_eng: 'Economy News',
          sub_menu: [],
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png'
        },
        {
          name: 'environmentNews',
          text_th: 'ข่าวมิติสิ่งแวดล้อม',
          text_eng: 'Environment News',
          sub_menu: [],
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png'
        },
        {
          name: 'societyNews',
          text_th: 'ข่าวมิติสังคม',
          text_eng: 'Society News',
          sub_menu: [],
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png'
        }
      ],
      menus: [
        {
          name: 'homeInvestorRelations',
          text_th: 'หน้าหลัก',
          text_eng: 'Home',
          sub_menu: [],
          // height: '1175px',
          bgImage: 'assets/images/menu/homeInvestorRelations.png'
        },
        {
          name: 'corporateOverview',
          text_th: 'ภาพรวมข้อมูลบริษัท',
          text_eng: 'Corporate Overview',
          sub_menu: [
            {
              name: 'corporateInformation',
              text_th: 'ข้อมูลบริษัท',
              text_eng: 'Corporate Information',
              // height: '1175px',
              bgImage: 'assets/images/menu/corporateInformation.png'
            },
            {
              name: 'managementTeam', // Board of Directors and Management Team
              text_th: 'คณะกรรมการบริษัทและผู้บริหารบริษัท',
              text_eng: 'Board of Directors and Management Team',
              bgMenuDark: true
            }
          ]
        },
        {
          name: 'financialInformation',
          text_th: 'ข้อมูลทางการเงิน',
          text_eng: 'Financial Information',
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png',
          sub_menu: []
        },
        // {
        //   name: 'financialInformationMain',
        //   text_th: 'ข้อมูลทางการเงิน',
        //   text_eng: 'Financial Information',
        //   sub_menu: [
        //     {
        //       name: 'financialInformation',
        //       text_th: 'ข้อมูลทางการเงิน',
        //       text_eng: 'Financial Information',
        //       bgMenuDark: true,
        //       bgImage: 'assets/images/menu/defaultBgMenu.png'
        //     }
        // {
        //   name: 'mdAndA',
        //   text_th: 'คำอธิบายและบทวิเคราะห์ของฝ่ายจัดการ MD&A',
        //   text_eng: 'MD&A',
        //   bgMenuDark: true,
        //   bgImage: 'assets/images/menu/defaultBgMenu.png'
        // }
        //   ]
        // },
        {
          name: 'annualReport56_1',
          // text_th: 'รายงานประจำปี และ 56-1 One Report',
          text_th: 'รายงานประจำปี',
          text_eng: 'Annual Report',
          // text_eng: 'Annual Report 56-1 One Report',
          sub_menu: [],
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png'
        },
        {
          name: 'shareholderInformation',
          text_th: 'ข้อมูลสำหรับผู้ถือหุ้น',
          text_eng: 'Shareholder Information',
          sub_menu: [
            {
              name: 'stockInformation',
              text_th: 'ข้อมูลหลักทรัพย์',
              text_eng: 'Stock Information',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            },
            {
              name: 'shareholdingStructure',
              text_th: 'โครงสร้างผู้ถือหุ้น',
              text_eng: 'Shareholder Structure',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            },
            {
              name: 'shareholderMeeting',
              text_th: 'การประชุมผู้ถือหุ้น',
              text_eng: 'Shareholder’s meeting',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            },
            {
              name: 'dividendPolicy',
              text_th: 'นโยบายการจ่ายเงินปันผล',
              text_eng: 'Dividend Policy',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/DividendPolicyMenu.jpg'
            },
            {
              name: 'inetW2',
              text_th: 'ใบสำคัญแสดงสิทธิ INET-W3',
              text_eng: 'The warrants to Purchase Ordinary Shares in INET–W3',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            }
          ]
        },
        {
          name: 'corporateGovernanceMain',
          text_th: 'การกำกับดูแลกิจการ',
          text_eng: 'Corporate Governance',
          sub_menu: [
            {
              name: 'corporateGovernance',
              text_th: 'การกำกับดูแลกิจการ',
              text_eng: 'Corporate Governance',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            },
            {
              name: 'cacPage',
              text_th: 'การต่อต้านการทุจริตและคอร์รัปชัน : CAC',
              text_eng: 'Anti–Corruption : CAC',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            },
            {
              name: 'esgPage',
              text_th: 'ความยั่งยืน : ESG',
              text_eng: 'Sustainability : ESG',
              bgMenuDark: true,
              bgImage: 'assets/images/menu/defaultBgMenu.png'
            }
          ]
        },
        {
          name: 'newsAll',
          text_th: 'ข่าวสารนักลงทุนสัมพันธ์',
          text_eng: 'Investor Relations News',
          sub_menu: [],
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png'
        },
        {
          name: 'contactPage',
          text_th: 'ติดต่อเรา',
          text_eng: 'Contact Us',
          sub_menu: [],
          bgMenuDark: true,
          bgImage: 'assets/images/menu/defaultBgMenu.png'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['item_inet_vision', 'stock', 'valScroll', 'language'])
  },
  async created () {
    const languageLocalStorage = this.$cookies.get('language')
    if (this.$cookies.isKey('language')) {
      this.$store.commit('setLanguage', languageLocalStorage)
    } else {
      this.$cookies.set('language', this.$store.state.language, '1h')
    }
    this.init()
    this.Stats()
  },
  methods: {
    returnABS (close, change) {
      let val = (close * change) / 100
      val = Math.abs(val)
      val = val.toFixed(2)
      return val
    },
    onScroll (e) {
      this.offsetTop = e.target.scrollTop
      this.$store.commit('offsetTop', this.offsetTop)
    },
    async Stats () {
      const data = {
        path: 'homeInvestorRelations'
      }
      const res = await this.createStats(data)
      if (!res.status) {
        this.antmessage.error(res.message)
      }
    },
    async init () {
      this.loading = true
      const res = await this.getStock()
      this.loading = false
      if (res.status) {
        this.itemsStock = res.data
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style lang="css" scoped>
/* animation */
.height-main {
  height: 725px;
}
.home-investor-relations {
  width: 100%;
  display: flex;
  align-items: center;
}
.image-investor-relations {
  width: 100%;
  max-width: 568px !important;
}
/* about-setINET */
.about-setINET {
  padding-top: 26px;
  padding-bottom: 13px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(72, 203, 179, 0.8) 0%,
    rgba(122, 228, 136, 0.8) 100%
  );
}
.text-data-now {
  color: #fff;
  text-transform: none !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.box-data-now {
  width: 65%;
  background-color: #2e8a3a;
  padding: 3px 15px 3px 15px;
  border-radius: 20px;
  margin-top: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-data-now-link {
  width: 35%;
  margin-top: 28px;
}
.layout-box-set {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 450px;
}
.box-set-left {
  width: 60%;
}
.box-set-right {
  width: 40%;
}
.text-main-box-set {
  color: #fff;
  text-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
}
.text-main-box-set-number {
  text-align: end;
  color: #fff;
  text-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
}
.text-box-set {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.text-box-set-number {
  text-align: end;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.corporate-information {
  width: 100%;
  display: flex;
  align-items: center;
}

.image-corporate-information {
  width: 100%;
  max-width: 265px !important;
}

.description-corporate-information {
  max-width: 700px;
  color: #fff !important;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.35);
}
.hr-corporate-information {
  width: 420px;
  border: 2px solid white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
}
/* class in template */
.layout-content-home {
  width: 100%;
}

@media screen and (max-width: 1366px) {
  .image-investor-relations {
    width: 100%;
    max-width: 436px !important;
  }
  .height-main {
    height: 725px;
  }
  .text-main-box-set {
    font-size: 33.75px;
  }
  .text-main-box-set-number {
    font-size: 33.75px;
  }
  .text-box-set {
    font-size: 18px;
  }
  .text-box-set-number {
    font-size: 18px;
  }
  .text-data-now {
    font-size: 12.25px;
    font-weight: 400;
  }
}
@media screen and (max-width: 1112px) {
  .height-main {
    height: 725px;
  }
  .text-main-box-set {
    font-size: 33.75px;
  }
  .text-main-box-set-number {
    font-size: 33.75px;
  }
  .text-box-set {
    font-size: 18px;
  }
  .text-box-set-number {
    font-size: 18px;
  }
  .text-data-now {
    font-size: 12.25px;
    font-weight: 400;
  }
}
@media screen and (max-width: 1024px) {
  .description-corporate-information {
    font-size: 26.285px;
  }
  .height-main {
    height: 725px;
  }
  .text-main-box-set {
    font-size: 33.75px;
  }
  .text-main-box-set-number {
    font-size: 33.75px;
  }
  .text-box-set {
    font-size: 18px;
  }
  .text-box-set-number {
    font-size: 18px;
  }
  .text-data-now {
    font-size: 12.25px;
    font-weight: 400;
  }
}
@media screen and (max-width: 800px) {
  /* .description-corporate-information {
    font-size: 18.562px;
  } */
  .image-investor-relations {
    width: 100%;
    max-width: 402px !important;
  }
  .height-main {
    height: 725px;
  }
  .text-main-box-set {
    font-size: 31.5px;
  }
  .text-main-box-set-number {
    font-size: 31.5px;
  }
  .text-box-set {
    font-size: 17.5px;
  }
  .text-box-set-number {
    font-size: 17.5px;
  }
  .text-data-now {
    font-size: 12.25px;
    font-weight: 400;
  }
}
@media screen and (max-width: 500px) {
  /* .corporate-information {
    height: 600px;
  } */
  .description-corporate-information {
    font-size: 24px;
  }
  .text-main-box-set-number {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 430px) {
  .description-corporate-information {
    font-size: 22px;
  }
  .image-investor-relations {
    width: 100%;
    max-width: 325px !important;
  }
  .height-main {
    height: 725px;
  }
  .text-main-box-set {
    font-size: 39.375px;
  }
  .text-main-box-set-number {
    font-size: 39.375px;
  }
  .text-box-set {
    font-size: 17.5px;
  }
  .text-box-set-number {
    font-size: 17.5px;
  }
  .text-data-now {
    font-size: 12.25px;
    font-weight: 400;
  }
}
@media screen and (max-width: 390px) {
  .description-corporate-information {
    font-size: 19px;
  }
  .height-main {
    height: 725px;
  }
  .text-main-box-set {
    font-size: 37.125px;
  }
  .text-main-box-set-number {
    font-size: 37.125px;
  }
  .text-box-set {
    font-size: 16.5px;
  }
  .text-box-set-number {
    font-size: 16.5px;
  }
  .text-data-now {
    font-size: 12.25px;
    font-weight: 400;
  }
}
@media screen and (max-width: 379px) {
  .text-data-now {
    font-size: 11px;
    font-weight: 400;
  }
}
@media screen and (max-width: 339px) {
  .height-main {
    height: 725px;
  }
  .text-main-box-set {
    font-size: 30.125px;
  }
  .text-main-box-set-number {
    font-size: 30.125px;
  }
  .text-box-set {
    font-size: 16.5px;
  }
  .text-box-set-number {
    font-size: 16.5px;
  }
  .text-data-now {
    font-size: 10.25px;
    font-weight: 400;
  }
}
@media screen and (max-width: 320px) {
  .description-corporate-information {
    font-size: 15px;
  }
  .height-main {
    height: 725px;
  }
  .text-main-box-set {
    font-size: 28.125px;
  }
  .text-main-box-set-number {
    font-size: 28.125px;
  }
  .text-box-set {
    font-size: 12.5px;
  }
  .text-box-set-number {
    font-size: 12.5px;
  }
  .text-data-now {
    font-size: 8.75px;
    font-weight: 400;
  }
}
</style>

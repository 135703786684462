<template>
  <!-- corporateInformation -->
  <div>
    <!-- inet_mission -->
    <div class="img-bg-section1">
      <div :class="`inet_mission-card ${valScroll > 80 ?'fade-in':''} ${valScroll < 80 ?'fade-out':''}` ">
        <div class="d-flex justify-center mx-5" >
          <v-img
            contain
            class="inet_mission_title"
            :src="require(`@/${item_inet_mission.image_title}`)"
          ></v-img>
        </div>
        <div class="inet_mission_description1 mb-8 mx-11">
          <div v-if="$vuetify.breakpoint.name !== 'xs'" v-html=" FiltersLanguage(item_inet_mission, 'description1')"></div>
          <div  v-if="$vuetify.breakpoint.name === 'xs'" v-html=" FiltersLanguage(item_inet_mission, 'description1_mobile')"></div>
        </div>
        <div class="d-flex justify-center align-center mx-5">
          <v-img
            contain
            class="inet_mission_line"
            :src="require(`@/${item_inet_mission.image_line}`)"
          ></v-img>
        </div>
        <div class="inet_mission_description1 ">
          <div v-if="$vuetify.breakpoint.name !== 'xs'" v-html=" FiltersLanguage(item_inet_mission, 'description2')"></div>
          <div v-if="$vuetify.breakpoint.name === 'xs'" v-html=" FiltersLanguage(item_inet_mission, 'description2_mobile')"></div>
        </div>
      </div>
    </div>

    <!-- inet_core_value -->

    <div class="img-bg-section2">
      <div class="inet_core-card-section2">
        <div class="d-flex justify-center">
          <v-img
            contain
            class="inet_core_value_title-section2"
            :src="require(`@/${item_inet_core_value.image_title}`)"
          ></v-img>
        </div>
        <div class="inet_core_value_description1">
          <div :class="`inet_core_4step ${valScroll > 756 ?'slide-in':''} ${valScroll < 756 ?'fade-out':''}`"
            style="animation-delay: 0.3s;"
          >
            <div class="inet_core_value_img_section2">
              <v-img
                contain
                class="mx-auto size-image-core-value"
                :src="require(`@/${item_inet_core_value.image_innovation}`)"
              ></v-img>
            </div>
            <ul class="inet_core_value_text ml-3 mb-0">
              <li

                v-for="(item_inet_core_value, i) in FiltersLanguage(
                  item_inet_core_value,
                  'innovation_description'
                )"
                :key="i"
              >
                {{ item_inet_core_value }}
              </li>
            </ul>
          </div>

          <hr class="divider_core_value" />

          <div :class="`inet_core_4step ${valScroll > 756 ?'slide-in':''} ${valScroll < 756 ?'fade-out':''}`"
            style="animation-delay: 0.5s;"
          >
            <div class="inet_core_value_img_section2">
              <v-img
                contain
                class="mx-auto size-image-core-value"
                :src="require(`@/${item_inet_core_value.image_neutral}`)"
              ></v-img>
            </div>
            <ul class="inet_core_value_text ml-3 mb-0">
              <li
                class="inet_core_value_text mr-3"
                v-for="(item_inet_core_value, i) in FiltersLanguage(
                  item_inet_core_value,
                  'neutral_description'
                )"
                :key="i"
              >
                {{ item_inet_core_value }}
              </li>
            </ul>
          </div>

          <hr class="divider_core_value" />

          <div :class="`inet_core_4step ${valScroll > 756 ?'slide-in':''} ${valScroll < 756 ?'fade-out':''}`"
            style="animation-delay: 0.7s;">
            <div class="inet_core_value_img_section2">
              <v-img
                contain
                class="mx-auto size-image-core-value"
                :src="require(`@/${item_inet_core_value.image_energetic}`)"
              ></v-img>
            </div>
            <ul class="inet_core_value_text ml-3 mb-0">
              <li
                class="inet_core_value_text mr-3"
                v-for="(item_inet_core_value, i) in FiltersLanguage(
                  item_inet_core_value,
                  'energetic_description'
                )"
                :key="i"
              >
                {{ item_inet_core_value }}
              </li>
            </ul>
          </div>

          <hr class="divider_core_value" />

          <div :class="`inet_core_4step ${valScroll > 756 ?'slide-in':''} ${valScroll < 756 ?'fade-out':''}`"
            style="animation-delay: 0.9s;">
            <div class="inet_core_value_img_section2">
              <v-img
                contain
                class="mx-auto mb-3 size-image-core-value"
                :src="require(`@/${item_inet_core_value.image_trustworthy}`)"
              ></v-img>
            </div>
            <ul class="inet_core_value_text ml-3 mb-0">
              <li
                class="inet_core_value_text mr-3"
                v-for="(item_inet_core_value, i) in FiltersLanguage(
                  item_inet_core_value,
                  'trustworthy_description'
                )"
                :key="i"
              >
                {{ item_inet_core_value }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- inet_services -->
    <div class="bg-section-services d-flex justify-center">
      <div class="care-serviceAll">
        <div :class="`d-flex justify-center ${valScroll > 1800 ?'fade-in':''} ${valScroll < 1800 ?'fade-out':''}`">
          <v-img
            contain
            class="inet_services_title"
            :src="require(`@/${item_inet_core_value.image_service}`)"
          ></v-img>
        </div>
        <div :class="`d-flex justify-center align-center  ${valScroll > 1800 ?'fade-in':''} ${valScroll < 1800 ?'fade-out':''}`"  style="animation-delay: 0.5s;">
          <v-img
            contain
            class="img_serviceAll"
            @click="clickImage(FiltersLanguage(
                item_inet_core_value,
                'link_serviceAll'
              ))"
            :src="
              require(`@/${FiltersLanguage(
                item_inet_core_value,
                'image_serviceAll'
              )}`)
            "
          ></v-img>
        </div>
      </div>
    </div>
    <!-- section4 โครงสร้าง -->
    <div class="section4">
      <div class="img-row-colum">
        <div >
          <img
            contain
            class="image_structure"
            @click="
              setImageDialog(
                FiltersLanguage(item_inet_core_value, 'image_structure_1')
              )
            "
            :src="
              require(`@/${FiltersLanguage(
                item_inet_core_value,
                'image_structure_1'
              )}`)
            "
          />
        </div>

        <div class="mt-12">
          <img
            contain
            class="image_structure"
            @click="
              setImageDialog(
                FiltersLanguage(item_inet_core_value, 'image_structure_2')
              )
            "
            :src="
              require(`@/${FiltersLanguage(
                item_inet_core_value,
                'image_structure_2'
              )}`)
            "
          />
        </div>
      </div>
    </div>
    <!-- dialog item_inet_core_value.image_structure_2 -->
    <v-dialog v-model="dialogStructure2" fullscreen>
      <div class="py-6 px-5 bg-dialog-structure">
        <div class="size-img-structure">
          <img
            v-if="imgDialog"
            class="img-on-dialog"
            :src="require(`@/${imgDialog}`)"
          />
          <div style="width: 100%" class="d-flex justify-center mt-n5">
            <v-btn @click="dialogStructure2 = false" class="close-btn">
              {{ FiltersLanguage(item_inet_core_value, "btn_close") }}
              &times;</v-btn
            >
          </div>
        </div>
      </div>
    </v-dialog>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      imgDialog: '',
      dialogStructure2: false,
      isImageVisible_structure_1: false,
      isImageVisible_structure_2: false
    }
  },
  methods: {
    setImageDialog (path) {
      this.imgDialog = path
      this.dialogStructure2 = true
    },
    clickImage (url) {
      window.open(url)
    }
  },
  computed: {
    ...mapGetters(['item_inet_mission', 'item_inet_core_value', 'valScroll'])
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  }
}
</script>

<style scoped>
.img-bg-section1 {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50%;
  background: url("@/assets/images/corporateInformation/inet_mission_bg.png")
    no-repeat center center;
  background-size: cover;
  min-height: 650px;
  padding-left: 20px;
  padding-right: 20px;
}
.inet_mission-card {
  justify-items: center;
  padding-left: 37px;
  padding-right: 37px;
  width: 620px;
  height: auto;
  margin-bottom: 100px;
  border-radius: 0px 0px 20px 20px;
  background: var(--v-bgPrimary-base) !important;
}
.inet_mission_title {
  margin-bottom: 50px;
  margin-top: 85px;
  width: 100%;
  max-width: 260px;
}
.inet_mission_line {
  width: 100%;
  max-width: 250px;
}
.inet_mission_description1 {
  text-align: center;
  font-family: Noto Sans Thai;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 70px;
  margin-right: 44px !important;
    margin-left: 44px !important;
  color: var(--v-textPrimary-base) !important;
  line-height: 35px;
}
/* inet_core_value */
.inet_core_value_description1 {
  text-align: center;
  font-style: normal;
  margin-top: 30px;
  margin-right: 35px !important;
  margin-left: 35px !important;
  color: var(--v-textPrimary-base) !important;
}
.img-bg-section2 {
  display: flex;
  justify-content: center;
  width: 100%;
  background: url("@/assets/images/corporateInformation/inet_core_value_bg.png")
    no-repeat center center;
  background-size: cover;
  height: 100%;
}
.inet_core_4step {
  display: flex;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.inet_core-card-section2 {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 80px;
  margin-bottom: 70px;
  width: 880px;
  height: 100%;
  justify-items: center;
}
.inet_core_value_img_section2 {
  display: flex;
  width: 100%;
  max-width: 400px;
}
.inet_core_value_title-section2 {
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 35px;
  width: 100%;
  max-width: 380px;
}
.inet_core_value_text {
  color: var(--v-textDetailCoreValue-base) !important;
  text-align: left;
  font-size: 18.2px;
  font-weight: 400;
  line-height: normal;
}
.divider_core_value {
  color: var(--v-dividerCoreValue-base) !important;
}
/* section3 inet_services */
.bg-section-services {
  background-color: var(--v-bgSectionServices-base) !important;
  padding-top: 80px;
  padding-bottom: 80px;
}
.care-serviceAll {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.inet_services_title {
  margin-bottom: 50px;
  width: 100%;
  max-width: 270px;
}
.img_serviceAll {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 600px;
}

/* section 4 โครงสร้าง */
.section4 {
  display: flex;
  justify-content: center;
  width: 100%;
}
.img-row-colum {
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
}
.image_structure {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 865px;
}

/* dialog structure */
.img-on-dialog {
  max-height: 98%;
  width: 100%;
  object-fit: contain;
}
.bg-dialog-structure {
  background-color: rgba(0, 0, 0, 0.68);
  height: 100%;
}
.size-img-structure {
  height: 98%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}
.close-btn {
  cursor: pointer;
  width: 148px;
  height: 38;
  border-radius: 51px;
  background-color: var(--v-btnClose-base) !important;
  color: var(--v-bgPrimary-base) !important;
  font-family: Noto Sans Thai;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.size-image-core-value {
  width: 100% !important;
}

@media screen and (max-width: 560px) {
  .inet_mission_line {
    width: 100%;
    max-width: 200px;
  }
  .care-serviceAll {
    width: 100%;
  }
  .bg-section-services {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 700px) {
  .image_structure {
    height: auto;
    width: 100%;
  }

  .img-bg-section1 {
    height: 80%;
  }
  .inet_mission-card {
    justify-items: center;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    height: auto;
    border-radius: 0px 0px 20px 20px;
    background: var(--v-bgPrimary-base) !important;
  }
  /* section 2 inet_core_value */
  .img-bg-section2 {
    width: 100%;
    max-width: 690px;
    background: url("@/assets/images/corporateInformation/inet_core_value_bg.png")
      no-repeat center center;
    background-size: cover;
    min-height: 750px;
  }
  .inet_core_4step {
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .inet_core-card-section2 {
    margin-top: 70px;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
    height: 100%;
    justify-items: center;
  }
  .inet_core_value_img_section2 {
    max-width: unset;
  }
  .size-image-core-value {
  width: 100% !important;
  max-width: 400px !important;
}
.inet_core_value_description1 {
  text-align: center;
  font-style: normal;
  margin-top: 0px;
  margin-right: 10px !important;
  margin-left: 10px !important;
  color: var(--v-textPrimary-base) !important;
}
}
@media screen and (max-width: 1200px) {
  .care-serviceAll {
    width: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .img-row-colum {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

</style>

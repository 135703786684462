<template>
  <div>
    <div class="main-content">
      <!-- title -->
      <div class="layout-all">
        <div class="title-content">
          <div class="d-flex justify-center">
            {{ FiltersLanguage(item_shareholding_structure_page, "title") }}
            <div>
              <v-img
                class="title-icon"
                :src="require(`@/${item_shareholding_structure_page.img_dot}`)"
              ></v-img>
            </div>
          </div>
        </div>
        <hr class="hr-border" />
        <div>
          <div class="layout-img">
            <div class="img-shareholding-structure">
              <v-img
                :src="
                  require(`@/${FiltersLanguage(
                    item_shareholding_structure_page,
                    'img_shareholder_structure'
                  )}`)
                "
              ></v-img>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-section2">
      <!-- table_top_10_shareholders -->
      <div class="main-content">
        <div class="layout-all">
          <div class="title-table">
            {{
              FiltersLanguage(
                item_shareholding_structure_page,
                "title_table_top_10_shareholders"
              )
            }}
          </div>
          <div>
            {{FiltersLanguage(itemsTableTheFirstTop10ShareholdersAll,
                "description")}}
          </div>
          <div class="table-style">
            <tableComponent
              :headers="modifiedHeaders(headerTableTop10Shareholders)"
              :items="itemsTableTheFirstTop10Shareholders"
              ColorTextHeader="#000000"
              :loading="loadingTableTop10"
              bgHeader="#F6F6F6"
              bgTable="#F6F6F6"
              class="custom-bg-row"
              hideDefaultFooter
              :defaultPerpage="999"
            >
              <template v-slot:[`item.rank`]="{ item }">
                <div>
                  {{
                    paginationTableTop10Shareholders.row *
                      paginationTableTop10Shareholders.page -
                    paginationTableTop10Shareholders.row +
                    (itemsTableTheFirstTop10Shareholders.indexOf(item )+ 1)
                  }}
                </div>
              </template>
              <template v-slot:[`item.shareholders`]="{ item }">
                <div>
                  {{ FiltersLanguage(item, "shareholders") }}
                </div>
              </template>
              <template v-slot:[`item.shares`]="{ item }">
                <div>
                  {{returncommaNumber (parseFloat(item.shares))}}
                </div>
              </template>
              <template v-slot:[`item.percent_shares`]="{ item }">
                <div class="bg-shares-percentage">
                  {{returncommaNumberDecimal ( parseFloat(item.percent_shares))}} %
                </div>
              </template>
            </tableComponent>
          </div>
          <div class="layout-origin">
          <a
            :href="`https://${FiltersLanguage(
              item_shareholding_structure_page,
              'link_set'
            )}`"
            target="_blank"
            :class=" $store.state.language === 'th'? 'set-height-btn-th title-origin': 'set-height-btn-eng title-origin'"
            >{{ FiltersLanguage(item_shareholding_structure_page, "title_origin") }}
            <v-icon large  class="ml-3"
              >mdi-arrow-right-thin</v-icon
            ></a
          >
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tableComponent from '../../../components/table.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    tableComponent
  },
  data () {
    return {
      headerTableTop10Shareholders: [],
      loadingTableTop10: false,
      itemsTableTheFirstTop10ShareholdersAll: [],
      itemsTableTheFirstTop10Shareholders: [],
      paginationTableTop10Shareholders: {
        total: 0,
        page: 1,
        row: 999
      }
    }
  },
  created () {
    // this.setHeaderTableTop10Shareholders()
    // this.$EventBus.$on('change-language', () => {
    //   this.setHeaderTableTop10Shareholders()
    // })
    this.initTableTheFirstTop10Shareholders()
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  computed: {
    ...mapGetters(['item_shareholding_structure_page', 'language'])
  },
  methods: {
    // returnIndexItem (row, page, items, itemRow) {
    //   return row * page - row + (items.indexOf(itemRow) + 1)
    // },
    // setHeaderTableTop10Shareholders () {
    //   if (this.language === 'th') {
    //     this.headerTableTop10Shareholders =
    //       this.item_shareholding_structure_page.header_table_top_10_shareholders_th
    //   } else if (this.language === 'eng') {
    //     this.headerTableTop10Shareholders =
    //       this.item_shareholding_structure_page.header_table_top_10_shareholders_eng
    //   }
    // },
    modifiedHeaders (itemHeader) {
      return itemHeader.map(header => ({
        ...header,
        text: header[`text_${this.language}`] // Change the text to text_th
      }))
    },
    async initTableTheFirstTop10Shareholders () {
      this.loadingTableTop10 = true
      const payload = {
        page: this.paginationTableTop10Shareholders.page,
        row: this.paginationTableTop10Shareholders.row,
        keyword: 'The first top 10 shareholders'
      }
      this.itemsTableTheFirstTop10Shareholders = []
      const res = await this.getItemsTable(payload)
      this.loadingTableTop10 = false
      if (res.status) {
        this.itemsTableTheFirstTop10ShareholdersAll = res.data.data[0]
        this.headerTableTop10Shareholders = res.data.data[0].headers
        this.itemsTableTheFirstTop10Shareholders = res.data.data[0].datas
        this.paginationTableTop10Shareholders.total =
          res.data.pagination.total
        this.paginationTableTop10Shareholders.page =
          res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
>>> .theme--light.v-data-table.v-data-table--fixed-header thead th{
  background-color: unset !important;
}
>>> .custom-bg-row .v-data-table .v-data-table__wrapper table .v-data-table-header tr th:last-child {
  background: linear-gradient(
    90.16deg,
    var(--v-hoverListItem3-base) 44.53%,
    var(--v-hoverListItem4-base) 100%
  );
}
>>> .custom-bg-row .v-data-table .v-data-table__wrapper table tbody tr td {
  padding-left: 0;
  padding-right: 0;
  /* padding-top: 5px;
  padding-bottom: 5px; */
}
>>> .custom-bg-row .v-data-table .v-data-table__wrapper table tbody tr td div {
  padding-top: 17px;
  padding-bottom: 14px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
}
>>> .theme--light.v-data-table.v-data-table--fixed-header thead th {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 1);
}
.layout-all {
  margin-right: 20px;
  margin-left: 20px;
}
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  padding-top: 4rem;
  text-align: center;
  margin-bottom: 20px;
}
.title-icon {
  width: 16px;
  height: 16px;
  padding: 3px;
}
.hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 40px;
  margin-bottom: 40px;
}
.layout-img {
  width: 100%;
  display: flex;
  justify-content: center;
}
.img-shareholding-structure {
  width: 50%;
  margin-bottom: 100px;
}
/* section2 Table table_top_10_shareholders */
.layout-section2 {
  background-color: var(--v-bgSectionTableTop10-base) !important;
}
.title-table {
  text-align: center;
  background-color: var(--v-bgTitleTable-base) !important;
  border-radius: 51px;
  margin-bottom: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 28px;
  font-weight: 600;
  color: var(--v-textSecond-base) !important;
}
remark-text{
  font-size: 16px;
  font-weight: 400;
  color: var(--v-textSecond-base) !important;
}
.table-style {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  margin-bottom: 50px;
}
.bg-shares-percentage{
  background: linear-gradient(
    90.16deg,
    var(--v-hoverListItem3-base) 44.53%,
    var(--v-hoverListItem4-base) 100%
  );
}
/* origin */
.layout-origin {
  display: flex;
  justify-content: flex-end;
}
::v-deep .title-origin .v-icon.v-icon{
  color: inherit !important;
}
.set-height-btn-th {
  height: 27px;
  display: flex;
}
.set-height-btn-eng {
  height: 27px;
  display: flex;
}
.title-origin {
  text-align: center;
  background-color: var(--v-bgTitleOrigin-base) !important;
  border-radius: 51px;
  margin-bottom: 50px;
  /* height: 27px; */
  align-items: center;
  /* display: flex; */
  padding-left: 45px;
  padding-right: 40px;
  font-size: 14px;
  font-weight: 400;
  color: var(--v-TitleOrigin-base) !important;
  border: solid 1px var(--v-TitleOrigin-base) !important;
}

.title-origin:hover {
  color: var(--v-textSecond-base) !important;
  background-color: var(--v-bgHoverTitleOrigin-base) !important;
  cursor: pointer;
}
@media screen and (max-width: 984px) {
  .hr-border {
    color: var(--v-linerPrimary-base) !important;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .img-shareholding-structure {
    width: 70%;
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 688px) {
  .title-table {
    font-size: 26px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 500px) {
  .img-shareholding-structure {
    width: 90%;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 406px) {
  .set-height-btn-eng {
    display: unset;
    height: unset;
    padding-top: 5px;
  }
}
@media screen and (max-width: 384px) {
  .set-height-btn-th {
    display: unset;
    height: unset;
    padding-top: 5px;
  }
}
</style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    transition="dialog-top-transition"
  >
    <v-card class="forget-password-card">
      <v-img
        class="img-header mx-auto"
        :src="require('@/assets/images/loginAdmin/resetPassword.png')"
      />
      <div class="title-login">เปลี่ยนรหัสผ่าน</div>
      <div class="sub-title">ใส่อีเมลของคุณเพื่อเปลี่ยนรหัสผ่าน</div>
      <v-form ref="formSignIn">
        <div>
          <div class="title-text-field">อีเมล</div>
          <div class="box-text-field">
            <v-text-field
              v-model="signInData.username"
              :rules="[
                Rule.noInPut,
                Rule.noSpace,
                Rule.englishOnly,
                Rule.emailOnly,
              ]"
              label="อีเมล(ชื่อผู้ใช้งาน)"
              rounded
              outlined
              dense
              color="primaryIconAdmin"
            ></v-text-field>
          </div>
        </div>
      </v-form>
      <div class="layout-btn">
        <div>
          <v-btn
            rounded
            color="yesBtn"
            class="text-yes-btn mx-2"
            elevation="0"
            @click="save"
            >ตกลง</v-btn
          >
        </div>
        <div>
          <v-btn
            rounded
            color="cancleBtn"
            class="text-cancle-btn mx-2"
            elevation="0"
            @click="(dialog = false), reset()"
            >ยกเลิก</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      signInData: {
        username: ''
      },
      editedIndex: 1,
      NameComponent: 'เปลี่ยนรหัสผ่าน'
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    reset () {
      this.$refs.formSignIn.reset()
    },
    save () {
      if (this.$refs.formSignIn.validate()) {
        if (this.editedIndex > -1) {
          // TODO Update Data
          this.$swal({
            allowEnterKey: false,
            title: `${this.NameComponent}`,
            text: `คุณต้องการ${this.NameComponent}ใช่หรือไม่ ระบบจะทำการส่งฟอร์มไปยังอีเมลที่คุณกรอก`,
            icon: 'info',
            showCancelButton: true,
            cancelButtonText: 'ยกเลิก',
            cancelButtonColor: '#E74444',
            confirmButtonText: 'ตกลง',
            confirmButtonColor: '#4CC558'
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.$emit('emailUser', this.signInData.username)
            }
          })
        }
      }
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="css">
.forget-password-card {
  height: 100%;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.img-header {
  max-width: 35%;
  margin: 10px;
}
.text-header {
  font-weight: 500;
  font-size: 30px;
  padding-top: 30px;
  text-align: center;
  color: var(--v-primary-base);
}
.text-password {
  padding-top: 20px;
  padding-bottom: 30px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-weight: 300;
  font-size: 18px;
}
.title-text-field {
  font-size: 18px;
}
.title-login {
  width: 100%;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}
.sub-title {
  width: 100%;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.text-yes-btn {
  font-size: 18px;
  color: var(--v-textLoginbtn-base) !important;
}
.text-cancle-btn {
  font-size: 18px;
  color: var(--v-textLoginbtn-base) !important;
}
.layout-btn {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .forget-password-card {
    padding-left: 30px;
    padding-right: 30px;
  }
  .img-header {
  max-width: 45%;
}
}
</style>

<template>
  <div>
    <!-- section 1 : annual report now -->
    <div class="main-content d-flex justify-center" v-if="itemsAnnualReports.length !==0">
      <div class="report-now-cotent">
        <div class="report-now-card">
          <v-img
            class="report-now-img"
            :src="`${env}/api/v1/file/${FiltersLanguage(itemsAnnualReports[0], 'banner')}`"
          ></v-img>
        </div>
        <div class="report-now-card-2">
          <div class="report-now-download ">
            <div class="report-now-title ">
              {{ FiltersLanguage(item_annual_report_now, "title") }}
              {{ FiltersLanguage(itemsAnnualReports[0], "title") }}
            </div>
            <div class="report-now-subtTitle mt-2 d-flex justify-center">
              {{ FiltersLanguage(item_annual_report_now, "sub_title") }}
            </div>
            <div class="d-flex justify-center">
              <v-img
                contain
                class="report-now-line"
                :src="require(`@/${item_annual_report_now.image_divider}`)"
              ></v-img>
            </div>
            <div class="download-btn d-flex justify-center">

              <a
                :href="`${env}/api/v1/file/${FiltersLanguage(itemsAnnualReports[0], 'file')}`"
                target="_blank"
                class="text-download"
              >
                <v-icon class="btn-download"> mdi-download </v-icon>
                {{ FiltersLanguage(item_annual_report_now, "title_download") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- section 2 : Previous report-->
    <div class="main-content">
      <div class="report-layout-section2">
      <div class="title-content">
        <div class="d-flex justify-center">
          {{
            FiltersLanguage(item_annual_report_section, "title_annualReport")
          }}
          <div>
            <v-img
              class="title-icon"
              :src="require(`@/${item_annual_report_section.img_dot}`)"
            ></v-img>
          </div>
        </div>
      </div>
      <hr class="hr-border" />
      <div class="report-content">
        <diV
          v-for="(annual_report, i) in itemsAnnualReportsShow"
          :key="i"
          class="report-card slide-in-fast"  :style="`animation-delay: ${i===0 ?0.3: i/2.5}s; animation-duration:0.5s;`"
        >
          <div>
            <v-hover v-slot="{ hover }">
              <v-card onclick="">
                <v-img
                  class="report-img"
                  :src="`${env}/api/v1/file/${FiltersLanguage(annual_report, 'banner')}`"
                >
                <a
                      :href="`${env}/api/v1/file/${FiltersLanguage(annual_report, 'file')}`"
                      target="_blank"
                    >
                  <div
                    v-if="hover"
                    class="download-report"
                    style="height: 100%"
                  >
                  <div>
                      <div>
                        <v-icon class="icon-download">
                          mdi-download
                        </v-icon>
                      </div>
                      <div class="download-text">{{ FiltersLanguage(item_annual_report_now, "title_download") }}</div>
                    <!-- </v-btn> -->
                    </div>
                  </div>
                  </a>
                </v-img>
              </v-card>
            </v-hover>
          </div>
          <div class="report-year">
            {{ FiltersLanguage(annual_report, "title") }}
          </div>
        </diV>
      </div>
      <div class="seeAll-content">
        <v-btn
          @click="$router.push('annual-report')"
          class="seeAll-btn d-flex justify-center"
        >
          {{ FiltersLanguage(item_annual_report_section, "btn_view_all") }}
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
    </div>
    <!-- section 3 : to 56-1-one-report page-->
    <div class="main-section3">
      <div class="section-3-content">
        <div class="d-flex flex-wrap justify-center layout-section-3" >
          <a
            class="btn-report561_section"
            @click="$router.push('56-1-one-report')"
          >
            <div class="btn-report561_section-2" style="background-color: red;">
              <div>
                <div class="btn-report561-text">
                  {{ FiltersLanguage(item_report561_section, "title") }}
                </div>
                <div class="btn-report561-text-2">
                  {{ FiltersLanguage(item_report561_section, "sub_title") }}
                </div>
              </div>
            </div>
          </a>
          <div
          class="section-3-text"
          v-html="FiltersLanguage(item_report561_section, 'detail')"
        ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      env: '',
      annual_report: [],
      loadingTableAnnualReport: false,
      paginationTableAnnualReport: {
        total: 0,
        page: 1,
        row: 999
      },
      itemsAnnualReports: [],
      itemsAnnualReportsShow: []
    }
  },
  created () {
    this.env = process.env.VUE_APP_API
    this.initTableAnnualReport()
  },
  mounted () {
    const element = document.getElementById('top-page')
    element.scrollIntoView(true)
  },
  methods: {
    async initTableAnnualReport () {
      this.loadingTableAnnualReport = true
      const payload = {
        page: this.paginationTableAnnualReport.page,
        row: this.paginationTableAnnualReport.row,
        keyword: 'รายงานประจำปี'
      }
      this.itemsAnnualReports = []
      const res = await this.getList(payload)
      this.loadingTableAnnualReport = false
      if (res.status) {
        if (res.data.data.length !== 0) {
          this.itemsAnnualReports = res.data.data[0].items
          const item = res.data.data[0].items
          for (let index = 0; index < 4; index++) {
            this.itemsAnnualReportsShow.push(item[index + 1])
          }
        } else {
          this.itemsAnnualReports = []
        }
        this.paginationTableAnnualReport.total = res.data.pagination.total
        this.paginationTableAnnualReport.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    }
  },
  computed: {
    ...mapGetters([
      'item_annual_report_section',
      'item_annual_report',
      'item_annual_report_now',
      'item_report561_section'
    ])
  }
}
</script>

<style scoped>
/* section 1 */
.report-now-cotent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 126px;
  margin-bottom: 76px;
}
.download-btn {
  color: var(--v-textPrimary-base) !important;
  font-size: 32px;
  font-weight: 600;
}
.report-now-card {
  display: flex;
  justify-content: center;
  width: 50%;
  border-radius: 20px 0px 0px 20px;
  background: linear-gradient(
    136deg,
    var(--v-gradientBgOneReport1-base) 0%,
    var(--v-gradientBgOneReport2-base) 100%
  );
}
.report-now-card-2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-radius: 0px 20px 20px 0px;
  background-color: var(--v-bgcard-base) !important;
}
.report-now-title {
  font-size: 42px;
  font-weight: 700;
  width: 100%;
  color: var(--v-textPrimary-base) !important;
  display: flex;
  justify-content: center;
  text-align: center;
}
.report-now-subtTitle {
  font-size: 23px;
  font-weight: 400;
  color: var(--v-textPrimary-base) !important;
  text-align: center;
}
.report-now-line {
  margin-top: 70px;
  margin-bottom: 79px;
  width: 100%;
  max-width: 290px;
}
.report-now-img {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  max-height: 588px;
  max-width: 416px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
}
.report-now-download {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 95%;
}

/* section 2 :previous-report */
.report-layout-section2 {
  margin-left: 20px;
  margin-right: 20px;
}
.report-content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 50px;
  margin-bottom: 30px;
}
/* section 2 */
.title-icon {
  width: 16px;
  height: 16px;
  padding: 4px;
}
.title-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  margin-top: 10px;
  text-align: center;
}
.report-img {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}
.report-card {
  width: 25%;
  padding: 10px;
}

.download-report {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--v-textDownloadebook-base) !important;
  width: 100%;
  font-size: 24px;
  opacity: 0.8;
  background: var(--v-downloadebook-base) !important;
}
.text-download {
  color: var(--v-textPrimary-base) !important;
}
.btn-download {
  color: var(--v-btnDownloadReport-base) !important;
  font-size: 40px;
}
.report-year {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  color: var(--v-textPrimary-base) !important;
}
.icon-download {
  display: flex;
  justify-items: center;
  font-size: 100px;
  color: var(--v-textSecond-base) !important;
}

.download-text {
  font-size: 32px;
  font-weight: 600;
  color: var(--v-textSecond-base) !important;
}

.seeAll-btn {
  cursor: pointer;
  height: 38;
  border-radius: 51px;
  background-color: var(--v-seeAllBtn-base) !important;
  color: var(--v-textSecond-base) !important;
  font-size: 20px;
  font-weight: 500;
}
.seeAll-content{
  display: flex;
  justify-content: center;
}
.seeAll-content:before,
.seeAll-content:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid var(--v-textPrimary-base) !important;
  margin: auto;
}

/* section 3 */
.layout-section-3{
  margin-top: 50px;
  margin-bottom: 50px;
  margin-right: 20px;
  margin-left: 20px;
  align-items: center;
}
.main-section3 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 36px;
  background-color: var(--v-bgSectionTo561-base) !important;
}
.btn-report561_section {
  width: 306px;
  height: 100px;
  background-image: linear-gradient(
    136.16deg,
    var(--v-gradientFooter1-base) 44.53%,
    var(--v-gradientFooter2-base) 100%
  );
  border-radius: 100px;
  margin-right: 20px;
  padding: 4px;
  z-index: 2;
}
.btn-report561_section-2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: var(--v-bgSectionTo561-base) !important;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: var(--v-textPrimary-base) !important;
}
.section-3-text {
  font-size: 22px;
  font-weight: 700;
  width: 370px;
}
.section-3-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.btn-report561-text {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
}
.btn-report561-text-2 {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}
@media screen and (max-width: 1290px) {
  .report-card {
    width: 33.333%;
    padding: 15px;
    margin-top: 5px;
  }
  .report-img {
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .report-now-cotent {
  margin-top: 30px;
  margin-bottom: 30px;
}
  .report-now-card {
    width: 100%;
    border-radius: 20px 20px 20px 20px;
    padding: 15px;
  }
  .report-now-line {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 90%;
  max-width: 250px;
}
  .report-now-card-2 {
    width: 100%;
    border-radius: 20px 20px 20px 20px;
    padding: 15px;
  }
  /* section 2 */
  .title-content {
  margin-top: 30px;
}

.hr-border {
  color: var(--v-linerPrimary-base) !important;
  margin-top: 20px;
  margin-bottom: 30px;
}
}
@media screen and (max-width: 984px) {
  .report-card {
    width: 50%;
  }
}
@media screen and (max-width: 735px) {
  .section-3-text {
  margin-top: 30px;
  width: 100%;
  height: 100%;
  text-align: center;
}
}
@media screen and (max-width: 655px) {
  .btn-report561_section {
  margin-right: 0px;
}
}
@media screen and (max-width: 615px) {
  .report-card {
    width: 100%;
  }
  .report-now-cotent {
    width: 90%;
}
}
@media screen and (max-width: 450px) {
  /* section 1 */
  .report-now-title {
  padding-top: 10px;
  font-size: 32px;
}
.report-now-subtTitle {
  font-size: 20px;
  font-weight: 400;
  padding-top: 10px;
}
.section-3-text {
  font-size: 20px;
  font-weight: 700;

}
}
</style>

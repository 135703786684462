<template>
  <div class="card-layout">
    <v-data-table
      :headers="headers"
      :items="items"
      :page.sync="page"
      :items-per-page="row"
      :mobile-breakpoint="mobileBreakpoint"
      :height="returnHeight()"
      :class="`elevation-0 ${warpHeader === true ? '' : 'nowarp-header'}`"
      loading-text="Loading... Please wait"
      fixed-header
      @click:row="$emit(`clickRow`, $event)"
      @dblclick:row="dbClick"
      :loading="loading"
      :search="search"
      :show-expand="showExpand"
      single-expand
      :expanded.sync="expanded"
      :item-key="itemKey"
      :hide-default-header="hideDefaultHeader"
      :hide-default-footer="hideDefaultFooter"
    >
      <template v-slot:progress>
        <v-progress-linear
          indeterminate
          color="green"
          height="3"
        ></v-progress-linear>
      </template>
      <template
        v-for="field in ScopedSlots()"
        v-slot:[field]="{ header, headers, item }"
      >
        <slot
          style
          :name="field"
          :item="item"
          :header="header"
          :headers="headers"
          :itemAll="item"
        />
      </template>
    </v-data-table>
    <!-- <div class="card-actions" v-if="!disabledFooter">
      <div class="pagination-layout">
        <span class="pt-2 mr-2 pagination-text">Rows Per Page : </span>
        <span>
          <v-select
            v-model="row"
            :items="perPageList"
            placeholder="Standard"
            hide-details
            dense
            class="row-per-page"
            @change="(page = 1), onChangePagination()"
            :loading="loading"
            :disabled="loading"
            color="green"
          ></v-select>
        </span>
        <span v-if="items.length == 0" class="pt-2 mx-2 pagination-text"
          >0-0 of 0</span
        >
        <span v-else class="pt-2 mx-2 pagination-text">
          {{ row * (page - 1) + 1 }} -
          {{
            row * (page - 1) + row >= pagination.total
              ? pagination.total
              : row * (page - 1) + row
          }}
          of {{ pagination.total }}</span
        >
        <div class="d-flex btn-change-page">
          <div class="btn-last-page">
            <v-btn
              @click="goFirstPage()"
              class="px-1"
              text
              color="green"
              :loading="loading"
              :disabled="page <= 1"
            >
              <v-icon class="mr-n5">mdi-chevron-left</v-icon
              ><v-icon>mdi-chevron-left</v-icon
              ><span
                class="pagination-text ml-n2"
                :style="`text-transform: capitalize; ${
                  page <= 1 ? 'color: #a9a9a9' : 'color: black'
                }`"
                >First page</span
              ></v-btn
            >
          </div>
          <div class="d-flex mx-n1">
            <v-btn
              icon
              color="green"
              :disabled="page <= 1"
              @click="page--, onChangePagination()"
              :loading="loading"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              icon
              color="green"
              :disabled="page >= Math.ceil(pagination.total / row, 1)"
              @click="page++, onChangePagination()"
              :loading="loading"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <div class="btn-last-page">
            <v-btn
              @click="goToLastPage()"
              class="px-1"
              text
              color="green"
              :loading="loading"
              :disabled="page >= Math.ceil(pagination.total / row, 1)"
              ><span
                class="pagination-text mr-n2"
                :style="`text-transform: capitalize; ${
                  page >= Math.ceil(pagination.total / row, 1)
                    ? 'color: #a9a9a9'
                    : 'color: black'
                }`"
                >Last page</span
              ><v-icon class="mr-n5">mdi-chevron-right</v-icon
              ><v-icon>mdi-chevron-right</v-icon></v-btn
            >
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    page: 1,
    row: 10,
    scopedSlots: [],
    expanded: []
  }),
  props: {
    search: {
      type: String,
      default: ''
    },
    perPageList: {
      type: Array,
      default: () => [5, 10, 20, 30, 50]
    },
    total: {
      type: Number,
      default: 0
    },
    pagination: {
      type: Object,
      default: () => {
        return { page: 1, row: 10, total: 0 }
      }
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: 'name'
    },
    // disabledFooter: {
    //   type: Boolean,
    //   default: false
    // },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: null //
    },
    bgHeader: {
      type: String,
      default: '#F4F4F4'
    },
    ColorTextHeader: {
      type: String,
      default: '#2c2c2c'
    },
    bgTable: {
      type: String,
      default: '#FFFFFF'
    },
    mobileBreakpoint: {
      type: Number,
      default: 600
    },
    warpHeader: {
      type: Boolean,
      default: false
    },
    showExpand: {
      type: Boolean,
      default: false
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    pagination: {
      handler: function (val) {
        this.page = val.page
      },
      deep: true,
      immediate: true
    }
  },
  async created () {},
  methods: {
    ScopedSlots () {
      // เป็น Function ที่จะ Remove scopeSlot ที่ไม่ใช่ของ v-data-table
      const whitList = ['header']
      const sS = Object.keys(this.$scopedSlots)
      for (const i of whitList) {
        // Check whitList is exists in sS
        const index = sS.indexOf(i)
        if (index > -1) {
          sS.splice(index, 1)
        }
      }
      return sS
    },
    dbClick (e, { item }) {
      this.$emit('dbclickRow', item)
    },
    onChangePagination () {
      this.$emit('onChangePagination', {
        page: this.page,
        row: this.row,
        total: this.pagination.total
      })
    },
    returnHeight () {
      return this.height === null ? null : this.height - 56
    },
    goToLastPage () {
      this.page = Math.ceil(this.pagination.total / this.row, 1)
      this.$emit('onChangePagination', {
        page: this.page,
        row: this.row,
        total: this.pagination.total
      })
    },
    goFirstPage () {
      this.page = 1
      this.$emit('onChangePagination', {
        page: this.page,
        row: this.row,
        total: this.pagination.total
      })
    },
    closeAll () {
      this.expanded = []
    }
  }
}
</script>
<style scoped></style>

<style lang="css" scoped>
>>> .v-data-table__progress th {
  padding: 0 !important;
}
.card-layout {
  border-radius: 10px !important;
  background-color: v-bind("bgTable");
  width: 100%;
  padding: 0;
  margin: 0;
}
>>> .v-data-table {
  background-color: unset !important;
}
::v-deep .v-data-table__wrapper {
  font-weight: 300 !important;
  /* height: 100% !important; */
  overflow-y: auto !important;
  overflow-x: auto !important;
}
/* // TODO แก้ไขเรื่องการ Overflow ของ Header ใน ฏฟะฟ Table */
>>> .nowarp-header div table .v-data-table-header th {
  white-space: nowrap !important;
}

>>> .v-data-table-header tr th:first-child {
  border-radius: 5px 0px 0px 0px !important;
}
>>> .v-data-table-header tr th:last-child {
  border-radius: 0px 5px 0px 0px !important;
}

>>> .v-data-table-header-mobile tr th {
  border-radius: 5px 5px 0px 0px !important;
}
>>> .v-data-table-header tr th {
  background-color: v-bind("bgHeader") !important;
  color: v-bind("ColorTextHeader") !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}
>>> .row-per-page .v-select__slot .v-select__selection {
  /* color: red !important; */
  font-weight: 200 !important;
  font-size: 12px !important;
}
>>> .row-per-page .v-select__slot .v-select__selections input {
  /* color: red !important; */
  display: none !important;
}

>>> .row-per-page .v-select__slot .v-input__append-inner {
  padding-top: 4px;
}
>>> tbody tr td {
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 24px !important;
}
.v-data-table {
  /* height: 100%; */
  padding: 0;
  margin: 0;
}
.card-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: 10px 0px 10px 5px;
}
.pagination-layout {
  display: flex;
  justify-content: flex-end;
}
.row-per-page {
  max-width: 60px !important;
  white-space: nowrap;
  text-align: center;
}

.pagination-text {
  font-weight: 200;
  font-size: 12px;
}
@media only screen and (max-width: 770px) {
  .pagination-layout {
    flex-wrap: wrap;
  }
  .btn-change-page {
    width: 100%;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
}
</style>

<template>
  <div>
    <titleHeaderMenu :iconTitle="iconTitle" :titleMenu="titleMenu" />
    <div class="btn-hearder">
      <div class="box-btn">
        <v-btn
          class="btn-add"
          elevation="0"
          rounded
          @click="openDialogAdd('add', defalitDataProfile)"
          ><v-icon>mdi-plus</v-icon>เพิ่มบุคคล</v-btn
        >
      </div>
      <v-spacer></v-spacer>
      <div class="box-text-field">
        <v-text-field
          v-model="search"
          :append-icon="'mdi-magnify'"
          label="ค้นหาจากชื่อ"
          color="primaryIconAdmin"
          @keyup.enter="intUserManagement()"
        ></v-text-field>
      </div>
    </div>
    <div>
      <tableAdminAll
        itemKey="profile_id"
        :headers="headerTablePerson"
        :items="itemsTablePerson"
        :loading="loadingTablePerson"
        :pagination="paginationTablePerson"
        @onChangePagination="onChangeTablePerson"
        warpHeader
        :search="search"
        :height="$vuetify.breakpoint.height-280"
      >
        <template v-slot:[`item.number`]="{ item }">
          <span>
            {{
              paginationTablePerson.row * paginationTablePerson.page -
              paginationTablePerson.row +
              (itemsTablePerson.indexOf(item) + 1)
            }}
          </span>
        </template>
        <template v-slot:[`item.profile.firstname_th`]="{ item }">
          <div class="min-width">
            {{ item.profile.firstname_th }}
          </div>
        </template>
        <template v-slot:[`item.profile.lastname_th`]="{ item }">
          <div class="min-width">
            {{ item.profile.lastname_th }}
          </div>
        </template>
        <template v-slot:[`item.profile.firstname_eng`]="{ item }">
          <div class="min-width">
            {{ item.profile.firstname_eng }}
          </div>
        </template>
        <template v-slot:[`item.profile.lastname_eng`]="{ item }">
          <div class="min-width">
            {{ item.profile.lastname_eng }}
          </div>
        </template>
        <template v-slot:[`item.create_date`]="{ item }">
          {{ returnFormateDate(item.create_date, "th") }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div class="layout-action">
            <v-btn icon
              ><v-icon class="btn-edit" @click="openDialogAdd('edit', item)"
                >mdi-pencil</v-icon
              ></v-btn
            >
            <v-btn icon
              ><v-icon class="btn-view" @click="showDataDialogEdit(item)"
                >mdi-eye-outline</v-icon
              ></v-btn
            >
            <v-btn icon @click="recheckDeletePersionTeam(item)"
              ><v-icon class="btn-delete">mdi-trash-can-outline</v-icon></v-btn
            >
          </div>
        </template>
      </tableAdminAll>
    </div>
    <fromDialogFormPersionTeam
      ref="dataialogFormPersionTeam"
      v-model="dialogFormPersionTeam"
      :titleHeader="titleHeaderAdd"
      :iconTitle="iconTitleaAdd"
      :status="statusForm"
      :loadingFileImage="loadingFileImage"
      :dataProfile="dataProfile"
      :date_positions="date_positions"
      @addAndEdit="addAndEditDataProfile"
      @uploadFile="uploadFileImageProfile"
      @resetForm="resetFormPersionTeam()"
    />
    <dialog-loading v-model="dialogLoading" />
    <dialogDetailManagementTeam
      v-model="dialogDetailManagementTeam"
      :dataProfile="dataProfileShowDetail"
    />
  </div>
</template>

<script>
import titleHeaderMenu from '../../../../components/adminpage/titleHeaderMenu.vue'
import tableAdminAll from '../../../../components/adminpage/tableAll/tableAdminAll.vue'
import fromDialogFormPersionTeam from '../../../../components/adminpage/formTeamPage/formPersionTeam.vue'
import dialogLoading from '../../../../components/adminpage/dialogLoading.vue'
import dialogDetailManagementTeam from '../../../../components/adminpage/detailManagementTeam.vue'
export default {
  components: {
    titleHeaderMenu,
    tableAdminAll,
    fromDialogFormPersionTeam,
    dialogLoading,
    dialogDetailManagementTeam
  },
  data () {
    return {
      dialogLoading: false,
      dialogDetailManagementTeam: false,
      iconTitle: 'mdi-account-multiple-plus',
      titleMenu: 'เพิ่มบุคคล',
      titleHeaderAdd: 'เพิ่มบุคคล',
      iconTitleaAdd: 'mdi-plus',
      search: '',
      dialogFormPersionTeam: false,
      loadingTablePerson: false,
      headerTablePerson: [
        {
          text: 'ลำดับ',
          align: 'center',
          value: 'number',
          sortable: false,
          filterable: false
        },
        {
          text: 'ชื่อไทย',
          align: 'start',
          value: 'profile.firstname_th',
          sortable: false
        },
        {
          text: 'นามสกุลไทย',
          align: 'start',
          value: 'profile.lastname_th',
          sortable: false
        },
        {
          text: 'ชื่ออังกฤษ',
          align: 'start',
          value: 'profile.firstname_eng',
          sortable: false
        },
        {
          text: 'นามสกุลอังกฤษ',
          align: 'start',
          value: 'profile.lastname_eng',
          sortable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'end',
          value: 'create_date',
          sortable: false,
          filterable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false,
          filterable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false,
          filterable: false
        }
      ],
      itemsTablePerson: [],
      paginationTablePerson: {
        total: 0,
        page: 1,
        row: 10
      },
      // form
      statusForm: 'add',
      loadingFileImage: false,
      defalitDataProfile: {
        profile: {
          firstname_th: '',
          firstname_eng: '',
          lastname_th: '',
          lastname_eng: '',
          date_of_birth: '',
          image: '',
          educationals: [
            {
              title_eng: '',
              title_th: '',
              start_date: '',
              end_date: '',
              description_eng: '',
              description_th: ''
            }
          ],
          relationship: {
            executive: false
          }
        },
        group_tags: [],
        positions: [
          {
            title_eng: '',
            title_th: '',
            description_eng: '',
            description_th: '',
            start_date: '',
            end_date: '',
            position_tags: []
          }
        ],
        stock: {
          volume: 0,
          ratio: 0
        },
        status: true
      },
      dataProfile: {
        profile: {
          firstname_th: '',
          firstname_eng: '',
          lastname_th: '',
          lastname_eng: '',
          date_of_birth: '',
          image: '',
          educationals: [
            {
              title_eng: '',
              title_th: '',
              start_date: '',
              end_date: '',
              description_eng: '',
              description_th: ''
            }
          ],
          relationship: {
            executive: false
          }
        },
        group_tags: [],
        positions: [
          {
            title_eng: '',
            title_th: '',
            description_eng: '',
            description_th: '',
            start_date: '',
            end_date: '',
            position_tags: []
          }
        ],
        stock: {
          volume: 0,
          ratio: 0
        },
        status: true
      },
      date_positions: '',
      // show detail
      dataProfileShowDetail: {
        profile: {
          firstname_th: '',
          firstname_eng: '',
          lastname_th: '',
          lastname_eng: '',
          date_of_birth: '',
          image: '',
          educationals: [
            {
              title_eng: '',
              title_th: '',
              start_date: '',
              end_date: '',
              description_eng: '',
              description_th: ''
            }
          ],
          relationship: {
            executive: false
          }
        },
        group_tags: [],
        positions: [
          {
            title_eng: '',
            title_th: '',
            description_eng: '',
            description_th: '',
            start_date: '',
            end_date: '',
            position_tags: []
          }
        ],
        stock: {
          volume: 0,
          ratio: 0
        },
        status: true
      }
    }
  },
  async created () {
    this.init()
  },
  methods: {
    init () {
      this.intUserManagement()
    },
    async intUserManagement () {
      this.loadingTablePerson = true
      const payload = {
        page: this.paginationTablePerson.page,
        row: this.paginationTablePerson.row,
        keyword: this.search,
        tags: ''
      }
      this.itemsTablePerson = []
      const res = await this.getTableUserTeamAuth(payload)
      this.loadingTablePerson = false
      if (res.status) {
        this.itemsTablePerson = res.data.data
        this.paginationTablePerson.total = res.data.pagination.total
        this.paginationTablePerson.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },

    onChangeTablePerson (val) {
      this.paginationTablePerson.page = val.page
      this.paginationTablePerson.row = val.row
      // this.intUserManagement()
    },
    async uploadFileImageProfile (data) {
      if (data.file !== '' && data.file !== null) {
        this.loadingFileImage = true
        const payload = {
          file: data.file,
          title: '',
          description: '',
          tags: ['boardAndManagement']
        }
        const res = await this.uploadFile(payload)
        this.loadingFileImage = false
        if (res.status) {
          this.dataProfile.profile.image = res.data.filename
        } else {
          this.antmessage.error(res.message)
        }
      } else {
        this.dataProfile.profile.image = ''
      }
      // }
    },
    openDialogAdd (status, item) {
      this.statusForm = status
      if (status === 'add') {
        this.titleHeader = 'เพิ่มบุคคล'
        this.iconTitleaAddEdit = 'mdi-plus'
        this.dataProfile = JSON.parse(JSON.stringify(item))
      } else if (status === 'edit') {
        this.titleHeader = 'แก้ไขบุคคล'
        this.iconTitleaAddEdit = 'mdi-pencil'
        this.dataProfile = JSON.parse(JSON.stringify(item))
      }
      this.dialogFormPersionTeam = true
    },
    showDataDialogEdit (item) {
      this.dataProfileShowDetail = JSON.parse(JSON.stringify(item))
      this.dialogDetailManagementTeam = true
    },
    addAndEditDataProfile (data) {
      for (let index = 0; index < data.data.positions.length; index++) {
        if (
          data.date_positions !== '' &&
          data.date_positions !== null &&
          data.date_positions !== undefined
        ) {
          data.data.positions[index].start_date =
            data.date_positions + ' 00:00:00'
        } else {
          data.data.positions[index].start_date = ''
        }
      }
      data.data.profile.date_of_birth =
        data.data.profile.date_of_birth + ' 00:00:00'
      if (data.status === 'add') {
        this.addNewProfile(data.data)
      } else {
        this.editNewProfile(data.data)
      }
    },
    async addNewProfile (newDataProfile) {
      this.loadingFileImage = true
      this.dialogLoading = true
      const payload = newDataProfile
      const res = await this.addProfile(payload)
      this.loadingFileImage = false
      this.dialogLoading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มบุคคลสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.intUserManagement()
        this.dialogFormPersionTeam = false
        this.$refs.dataialogFormPersionTeam.close()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async editNewProfile (newDataProfile) {
      this.loadingFileImage = true
      this.dialogLoading = true
      const payload = newDataProfile
      const res = await this.editProfile(payload)
      this.loadingFileImage = false
      this.dialogLoading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'แก้ไข',
          text: 'แก้ไขข้อมูลบุคคลสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.intUserManagement()
        this.dialogFormPersionTeam = false
        this.$refs.dataialogFormPersionTeam.close()
      } else {
        this.antmessage.error(res.message)
      }
    },
    resetFormPersionTeam () {
      this.dataProfile = this.defalitDataProfile
    },
    async recheckDeletePersionTeam (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบบุคคล',
        text: 'คุณต้องการลบบุคคล ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.deletePersionTeam(item)
        }
      })
    },
    async deletePersionTeam  (item) {
      this.dialogLoading = true
      const payload = { profile_id: item.profile_id }
      const res = await this.deletePersionFromTeam(payload)
      this.dialogLoading = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบบุคคลสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.intUserManagement()
      } else {
        this.antmessage.error(res.message)
      }
    }
  }
}
</script>

<style scoped>
.btn-hearder {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
.layout-action {
  min-width: 120px;
}
.btn-edit {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-view {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-delete {
  color: var(--v-cancleBtn-base) !important;
}
.min-width {
  min-width: 150px;
}
@media screen and (max-width: 450px) {
  .btn-hearder {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .box-btn {
    width: 100%;
  }
  .box-text-field {
    margin-top: 10px;
    width: 100%;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow-y":"auto","height":"100%","max-height":"100vh"},attrs:{"id":"scroll-target"}},[_c('div',{directives:[{name:"scroll",rawName:"v-scroll:#scroll-target",value:(_vm.onScroll),expression:"onScroll",arg:"#scroll-target"}]},[_c('menus',{attrs:{"menus":_vm.menus,"menusAll":_vm.menusAll},scopedSlots:_vm._u([{key:"homeInvestorRelations",fn:function(){return [_c('div',{class:`home-investor-relations  ${
            _vm.$store.state.language === 'th'
              ? 'size-template-menu-th'
              : 'size-template-menu-eng'
          } ${_vm.valScroll < 5 ? 'slide-in' : ''}`},[_c('div',{staticClass:"px-5",staticStyle:{"width":"100%"}},[_c('v-img',{staticClass:"image-investor-relations mx-auto",attrs:{"src":require("@/assets/images/menu/homeInvestorRelations_text.png")}})],1)]),(_vm.offsetTop < 400)?_c('div',{staticClass:"about-setINET"},[_c('div',{staticClass:"layout-box-set mx-5"},[_c('div',{staticClass:"box-set-left text-main-box-set"},[_vm._v("SET : INET")]),_c('div',{staticClass:"box-set-right text-main-box-set-number"},[_vm._v(" "+_vm._s(_vm.itemsStock.inet.close.toFixed(2))+" ")]),_c('div',{staticClass:"box-set-left text-box-set"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.stock, "title_val"))+" : "+_vm._s(_vm.returncommaNumber(_vm.itemsStock.inet.volume))+" ")]),_c('div',{staticClass:"box-set-right text-box-set-number"},[_vm._v(" "+_vm._s(_vm.itemsStock.inet.color === "green" ? "+" : "-")+_vm._s(_vm.returnABS(_vm.itemsStock.inet.close, _vm.itemsStock.inet.percent_change))+" ("+_vm._s(_vm.itemsStock.inet.color === "green" ? "+" : "")+_vm._s(_vm.itemsStock.inet.percent_change.toFixed(2))+"%) ")]),_c('div',{staticClass:"text-data-now box-data-now"},[_vm._v(" "+_vm._s(_vm.FiltersLanguage(_vm.stock, "title_date"))+" : "+_vm._s(_vm.returnDateFull( _vm.itemsStock.inet.date, _vm.language, "D MMM yyyy HH:mm:ss" ))+" ")]),_c('div',{staticClass:"box-data-now-link"},[_c('v-btn',{staticClass:"text-data-now elevation-0",attrs:{"color":"#2e8a3a","small":"","rounded":"","block":"","href":_vm.FiltersLanguage(_vm.stock, 'link_set'),"target":"_blank"}},[_vm._v(" set.or.th "),_c('v-icon',[_vm._v("mdi-arrow-right-thin")])],1)],1)])]):_vm._e()]},proxy:true},{key:"corporateInformation",fn:function(){return [_c('div',{class:`corporate-information  ${
            _vm.$store.state.language === 'th'
              ? 'size-template-menu-th'
              : 'size-template-menu-eng'
          } ${_vm.valScroll < 5 ? 'slide-in' : ''}`},[_c('div',{staticClass:"px-5",staticStyle:{"width":"100%"}},[_c('v-img',{staticClass:"image-corporate-information mx-auto",attrs:{"src":require("@/assets/images/menu/corporateInformation_text.png")}}),_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"center"}},[_c('hr',{staticClass:"hr-corporate-information mb-6 mt-2"})]),_c('div',{staticClass:"description-corporate-information mx-auto"},[(_vm.$vuetify.breakpoint.name !== 'xs')?_c('p',{domProps:{"innerHTML":_vm._s( _vm.FiltersLanguage(_vm.item_inet_vision, 'description1'))}}):_vm._e(),(_vm.$vuetify.breakpoint.name === 'xs')?_c('p',{domProps:{"innerHTML":_vm._s( _vm.FiltersLanguage(_vm.item_inet_vision, 'description1_mobile'))}}):_vm._e()])],1)])]},proxy:true}])}),_c('div',{staticClass:"layout-content-home"},[_c('router-view')],1),_c('footerHome')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="">
    <v-app-bar
      app
      clipped-left
      class="bgToolbar layout-v-app-bar"
      elevation="0"
    >
      <div class="layout-box-all">
        <div class="box-left">
          <v-app-bar-nav-icon
            small
            @click="drawer = !drawer"
            class="color-icon"
          >
          </v-app-bar-nav-icon>
          <div>
            <v-img
              class="size-img"
              :src="require(`@/assets/images/logoInet.png`)"
            >
            </v-img>
          </div>
          <div class="divider-color">|</div>
          <v-toolbar-title class="title">INVESTOR RELATIONS</v-toolbar-title>
        </div>
        <v-spacer></v-spacer>
        <div class="box-right">
          <div class="username">{{ userName }}</div>
          <div class="divider-user">|</div>
          <div class="d-flex align-center">
            <v-btn small rounded class="btn-logout" dark @click="logout"
              >Logout</v-btn
            >
            <v-btn icon color="white"
              ><v-icon class="btn-reset" @click="$emit('resetpassword')"
                >mdi-lock-reset</v-icon
              ></v-btn
            >
          </div>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },

  props: {
    notifications: {
      type: Array,
      default: () => []
    },
    userName: {
      type: String,
      default: 'userName'
    },
    value: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    drawer: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  methods: {
    logout () {
      this.$emit('logout', '')
    }
  }
}
</script>

<style scoped>
.bgToolbar {
  padding-left: 30px;
  padding-right: 30px;
  background: linear-gradient(
    0deg,
    var(--v-bgAdminPage2-base) 2%,
    var(--v-bgAdminPage1-base) 65%
  );
}
.color-icon {
  color: var(--v-textSecond-base) !important;
}
.title {
  font-size: 20px;
  color: var(--v-textSecond-base) !important;
  margin-right: 10px;
}
.size-img {
  width: 87px;
  margin-left: 10px;
  margin-right: 10px;
}
.divider-color {
  font-size: 26px;
  margin-right: 20px;
  color: var(--v-textSecond-base) !important;
}
.username {
  font-size: 16px;
  margin-right: 10px;
  color: var(--v-textSecond-base) !important;
}
.divider-user {
  font-size: 26px;
  margin-right: 10px;
  font-weight: 300;
  color: var(--v-textSecond-base) !important;
}
.btn-logout {
  background-color: rgba(255, 255, 255, 0.23) !important;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}
.layout-box-all {
  width: 100%;
  display: flex;
}
.box-left {
  display: flex;
  align-items: center;
}
.box-right {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 959px) {
  .layout-box-all {
    flex-wrap: wrap;
  }

  .title {
    margin-right: 0px;
  }
  .bgToolbar {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 800px) {
  >>> .v-toolbar__content {
    height: 100px !important;
    width: 100% !important;
  }
  .layout-v-app-bar {
    display: flex;
    height: 100px !important;
  }
  .box-left {
    width: 100%;
  }
  .box-right {
    width: 100%;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 510px) {
  .box-right {
    flex-wrap: wrap;
  }
  >>> .v-toolbar__content {
    height: 150px !important;
  }
  .layout-v-app-bar {
    display: flex;
    height: 150px !important;
  }
  .title {
    font-size: 14px !important;
  }
  .size-img {
    width: 65px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .divider-color {
    margin-right: 5px;
  }
  .username {
    font-size: 14px;
  }
}
</style>

<template>
  <div>
    <titleHeaderMenu :iconTitle="iconTitle" :titleMenu="titleMenu" />
    <div class="btn-hearder">
      <div class="box-btn">
        <v-btn class="btn-add mr-2" elevation="0" rounded @click="openFormAddEditNews('add')"
          ><v-icon>mdi-plus</v-icon>เพิ่มข่าว</v-btn
        >
      </div>
      <v-spacer></v-spacer>
      <div class="box-text-field">
        <div class="layout-box-category">
          <div class="box-category">ค้นหาจากหมวดหมู่</div>
          <v-select
            class="width-select"
            v-model="category"
            :items="itemsCategory"
            item-text="text"
            item-value="val"
            color="primaryIconAdmin"
            item-color="primaryIconAdmin"
            dense
            @change="searchTable()"
          ></v-select>
        </div>
        <div class="box-search">
          <div class="d-flex align-center">
            <v-text-field
              v-model="search"
              :append-icon="'mdi-magnify'"
              label="ค้นหาจากชื่อเรื่อง"
              color="primaryIconAdmin"
            ></v-text-field>
            <v-btn
              @click="searchTable()"
              class="ml-2 btn-add"
              rounded
              elevation="0"
              >ค้นหา</v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <div>
      <tableAdminAll
        :headers="headerTableNews"
        :items="itemsTableNews"
        :loading="loadingTableNews"
        :pagination="paginationTableNews"
        @onChangePagination="onChangeTableNews"
        warpHeader
        :height="$vuetify.breakpoint.height-280"
      >
        <template v-slot:[`item.number`]="{ item }">
          <span>
            {{
              paginationTableNews.row * paginationTableNews.page -
              paginationTableNews.row +
              (itemsTableNews.indexOf(item) + 1)
            }}
          </span>
        </template>
        <template v-slot:[`item.title_th`]="{ item }">
          <div class="min-width">
            {{ item.title_th }}
          </div>
        </template>
        <template v-slot:[`item.title_eng`]="{ item }">
          <div class="min-width">
            {{ item.title_eng }}
          </div>
        </template>
        <template v-slot:[`item.create_date`]="{ item }">
          {{ returnFormateDate(item.create_date, "th") }}
        </template>
        <template v-slot:[`item.tags`]="{ item }">
          <v-chip
            v-for="(tag, index) in item.tags"
            :key="index"
            class="ma-1 chip-name-tag"
            color="primaryIconAdmin"
            >{{ returnNameTag(tag) }}</v-chip
          >
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-switch
            v-model="item.status"
            inset
            color="primaryIconAdmin"
            @change="changeStatus(item)"
          ></v-switch>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div class="layout-action">
            <v-btn icon><v-icon class="btn-edit" @click="itemForm = JSON.parse(JSON.stringify(item)),openFormAddEditNews('edit')">mdi-pencil</v-icon></v-btn>
            <v-btn icon @click="openShowExNews(item)"
              ><v-icon class="btn-view">mdi-eye-outline</v-icon></v-btn
            >
            <v-btn icon @click="recheckDeleteNews(item)"
              ><v-icon class="btn-delete">mdi-trash-can-outline</v-icon></v-btn
            >
          </div>
        </template>
      </tableAdminAll>
    </div>
    <formAddEditNews
      ref="fromNews"
      v-model="statusOpenForm"
      :dataFromNews="itemForm"
      :status="statusForm"
      :iconTitle="iconTitleFrom"
      :titleHeader="titleFrom"
      :loading="loadingFormNews"
      :loadingFileBanner="loadingFileBanner"
      :itemsCategory="itemsCategory"
      @addAndEdit="addAndEditNews"
      @resetForm="itemForm = defalutItemForm"
      @showEx="openShowExNews"
      @uploadFile="uploadFileBanner"

    />
    <dialog-loading v-model="loadingFormNews" />
    <show-news
      v-model="statusDialogEx"
      :items="itemShowExNews"
      @close="closeShowExNews"
    />
  </div>
</template>

<script>
import titleHeaderMenu from '../../../components/adminpage/titleHeaderMenu.vue'
import tableAdminAll from '../../../components/adminpage/tableAll/tableAdminPagination.vue'
import formAddEditNews from '../../../components/adminpage/formNews/formAddEditNews.vue'
export default {
  components: {
    titleHeaderMenu,
    tableAdminAll,
    formAddEditNews
  },
  data () {
    return {
      iconTitle: 'mdi-newspaper-variant-outline',
      iconTitleFrom: '',
      titleMenu: 'ข่าวสาร',
      titleFrom: '',
      search: '',
      category: 'InvestorRelationsNews',
      itemsCategory: [
        {
          text: 'ข่าวสารนักลงทุนสัมพันธ์',
          val: 'InvestorRelationsNews'
        },
        {
          text: 'economy',
          val: 'economy'
        },
        {
          text: 'environment',
          val: 'environment'
        },
        {
          text: 'society',
          val: 'society'
        },
        {
          text: '1 no poverty',
          val: 'noPoverty'
        },
        {
          text: '2 zero hunger',
          val: 'zeroHunger'
        },
        {
          text: '3 good health and well-being',
          val: 'goodHealthAndWellBeing'
        },
        {
          text: '4 quality education',
          val: 'qualityEducation'
        },
        {
          text: '5 gender equality',
          val: 'genderEquality'
        },
        {
          text: '6 clean water and sanitation',
          val: 'cleanWaterAndSanitation'
        },
        {
          text: '7 affordable and clean energy',
          val: 'affordableAndCleanEnergy'
        },
        {
          text: '8 decent work and economic growth',
          val: 'decentWorkAndEconomicGrowth'
        },
        {
          text: '9 industry,innovation and infrastructure',
          val: 'industryInnovationAndInfrastructure'
        },
        {
          text: '10 reduced inequalities',
          val: 'reducedInequalities'
        },
        {
          text: '11 sustainable cities and communities',
          val: 'sustainableCitiesAndCommunities'
        },
        {
          text: '12 responsible consumption and production',
          val: 'responsibleConsumptionAndProduction'
        },
        {
          text: '13 climate action',
          val: 'climateAction'
        },
        {
          text: '14 life below water',
          val: 'lifeBelowWater'
        },
        {
          text: '15 life on land',
          val: 'lifeOnland'
        },
        {
          text: '16 peace,justice and strong institutions',
          val: 'peaceJusticeAndStrongInstitutions'
        },
        {
          text: '17 partnerships for the goals',
          val: 'partnershipsForTheGoals'
        }
      ],
      loadingTableNews: false,
      headerTableNews: [
        { text: 'ลำดับ', align: 'center', value: 'number', sortable: false },
        {
          text: 'ชื่อเรื่องไทย',
          align: 'start',
          value: 'title_th',
          sortable: false
        },
        {
          text: 'ชื่อเรื่องอังกฤษ',
          align: 'start',
          value: 'title_eng',
          sortable: false
        },
        {
          text: 'หมวดหมู่ข่าว',
          align: 'center',
          value: 'tags',
          sortable: false
        },
        {
          text: 'วันที่สร้าง',
          align: 'center',
          value: 'create_date',
          sortable: false
        },
        {
          text: 'ผู้สร้าง',
          align: 'center',
          value: 'create_by_username',
          sortable: false
        },
        {
          text: 'ปิด/เปิด',
          align: 'center',
          value: 'status',
          sortable: false
        },
        {
          text: 'จัดการ',
          align: 'center',
          value: 'action',
          sortable: false
        }
      ],
      itemsTableNews: [],
      paginationTableNews: {
        total: 0,
        page: 1,
        row: 10
      },
      switchOnOff: true,
      // form
      statusOpenForm: false,
      statusForm: '',
      itemForm: {
        banner: '',
        title_eng: '',
        title_th: '',
        description_th: '',
        description_eng: '',
        tags: [],
        status: false
      },
      defalutItemForm: {
        banner: '',
        title_eng: '',
        title_th: '',
        description_th: '',
        description_eng: '',
        tags: [],
        status: false
      },
      loadingFormNews: false,
      statusDialogEx: false,
      itemShowExNews: {
        banner: '',
        title_th: '',
        title_eng: '',
        description_th: '',
        description_eng: '',
        tags: [],
        status: false
      },
      loadingFileBanner: false
    }
  },
  async created () {
    this.init()
  },
  methods: {
    init () {
      this.initTableNews()
    },

    onChangeTableNews (val) {
      this.paginationTableNews.page = val.page
      this.paginationTableNews.row = val.row
      this.initTableNews()
    },
    searchTable () {
      this.paginationTableNews.page = 1
      this.initTableNews()
    },
    async initTableNews () {
      this.itemsTableNews = []
      this.loadingTableNews = true
      const payload = {
        keyword: this.search,
        page: this.paginationTableNews.page,
        row: this.paginationTableNews.row,
        tags: this.category,
        status: ''
      }
      const res = await this.getNews(payload)
      this.loadingTableNews = false
      if (res.status) {
        this.itemsTableNews = res.data.data
        this.paginationTableNews.total = res.data.pagination.total
        this.paginationTableNews.page = res.data.pagination.page
      } else {
        this.antmessage.error(res.message)
      }
    },
    returnNameTag (tags) {
      const tag = this.itemsCategory.filter((item) => item.val === tags)
      if (tag.length !== 0) {
        return tag[0].text
      } else {
        return 'ไม่พบtag'
      }
    },
    async changeStatus (item) {
      const payload = JSON.parse(JSON.stringify(item))
      this.loadingFormNews = true
      const res = await this.updateNews(payload)
      this.loadingFormNews = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: `${payload.status === true ? 'เปิด' : 'ปิด'}ข่าวสำเร็จ`,
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.initTableNews()
      } else {
        this.initTableNews()
        this.antmessage.error(res.message)
      }
    },
    recheckDeleteNews (item) {
      this.$swal({
        allowEnterKey: false,
        title: 'ลบข่าว',
        text: 'คุณต้องการลบข่าวนี้ใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#E74444',
        confirmButtonText: 'ตกลง',
        confirmButtonColor: '#4CC558'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.deleteNewsItem(item)
        }
      })
    },
    async deleteNewsItem (item) {
      this.loadingFormNews = true
      const res = await this.deleteNews(item)
      this.loadingFormNews = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'ลบข่าวสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.paginationTableNews.page = 1
        this.initTableNews()
      } else {
        this.antmessage.error(res.message)
      }
    },
    openFormAddEditNews (status) {
      if (status === 'add') {
        this.statusForm = 'add'
        this.titleFrom = 'เพิ่มข่าว'
        this.iconTitleFrom = 'mdi-plus'
        this.statusOpenForm = true
      } else if (status === 'edit') {
        this.statusForm = 'edit'
        this.titleFrom = 'แก้ไขข่าว'
        this.iconTitleFrom = 'mdi-pencil'
        this.statusOpenForm = true
      }
    },
    addAndEditNews (item) {
      if (item.status === 'add') {
        this.addNews(item.item)
      } else if (item.status === 'edit') {
        this.editNews(item.item)
      }
    },
    async addNews (item) {
      this.loadingFormNews = true
      const res = await this.createNews(item)
      this.loadingFormNews = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'เพิ่มข่าวสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.$refs.fromNews.close()
        this.initTableNews()
      } else {
        this.antmessage.error(res.message)
      }
    },
    async editNews (item) {
      this.loadingFormNews = true
      const res = await this.updateNews(item)
      this.loadingFormNews = false
      if (res.status) {
        this.$swal({
          allowEnterKey: false,
          title: 'สำเร็จ',
          text: 'แก้ไขข่าวสำเร็จ',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false
        })
        this.$refs.fromNews.close()
        this.initTableNews()
      } else {
        this.antmessage.error(res.message)
      }
    },
    openShowExNews (item) {
      this.statusDialogEx = true
      this.itemShowExNews = item
    },
    closeShowExNews () {
      this.statusDialogEx = false
      setTimeout(() => {
        this.itemShowExNews = {
          banner: '',
          title_th: '',
          title_eng: '',
          description_th: '',
          description_eng: '',
          tags: [],
          status: false
        }
      }, 400)
    },
    async uploadFileBanner (data) {
      const fileDownload = data.file
      if (fileDownload !== '' && fileDownload !== null) {
        this.loadingFileBanner = true
        const payload = {
          file: fileDownload,
          title: data.title,
          description: '',
          tags: ['InvestorRelationsNews', 'banner']
        }
        const res = await this.uploadFile(payload)
        this.loadingFileBanner = false
        if (res.status) {
          this.itemForm.banner = res.data.filename
        } else {
          this.antmessage.error(res.message)
        }
      } else {
        this.itemForm.banner = ''
      }
    }
  }
}
</script>

<style scoped>
>>> table .v-data-table-header th span {
  white-space: nowrap !important;
}
.btn-hearder {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
}
.btn-add {
  color: var(--v-textLoginbtn-base) !important;
  background-color: var(--v-primaryIconAdmin-base) !important;
  font-size: 16px;
}
.layout-action {
  min-width: 120px;
}
.btn-edit {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-view {
  color: var(--v-primaryIconAdmin-base) !important;
}
.btn-delete {
  color: var(--v-cancleBtn-base) !important;
}
.box-text-field {
  display: flex;
}
.box-category {
  font-size: 18px;
  margin-top: 10px;
  padding-right: 10px;
}
.layout-box-category {
  display: flex;
  margin-top: 14px;
  padding-right: 50px;
}
.width-select {
  width: 200px;
}
.min-width {
  min-width: 150px;
}
.chip-name-tag {
  font-size: 16px;
  color: white;
  font-weight: 500;
}
@media screen and (max-width: 1000px) {
  .btn-hearder {
    flex-wrap: wrap;
    margin-top: 20px;
  }
}
@media screen and (max-width: 800px) {
  .box-text-field {
    margin-top: 10px;
    width: 100%;
  }
  .box-category {
    min-width: 150px;
  }
  .layout-box-category {
    width: 60%;
    padding-right: 20px;
  }
  .box-search {
    width: 40%;
  }
}
@media screen and (max-width: 660px) {
  /* .layout-box-category {
    width: 65%;
    padding-right: 20px;
  }
  .box-search {
    width: 35%;
  } */
  .box-text-field {
    flex-wrap: wrap;
  }
  .layout-box-category {
    width: 100%;
    padding-right: 0px;
  }
  .box-search {
    width: 100%;
  }
}
/* @media screen and (max-width: 550px) {
  .box-text-field {
    flex-wrap: wrap;
  }
  .layout-box-category {
    width: 100%;
    padding-right: 0px;
  }
  .box-search {
    width: 100%;
  }
} */
@media screen and (max-width: 450px) {
  .btn-hearder {
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .box-btn {
    width: 100%;
  }
}
</style>
../../../components/adminpage/tableAll/tableAdminAll.vue
